import React, { useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import axiosInstance from '../../axios';
import { handleLogout } from '../../actions/UserActions';
import { notificationStyle } from '../../pages/product-list/ProductListing';

function UserPrivateRoute({ children }) {
  const dispatch = useDispatch()
  const [isVerify, setIsVerify] = useState(false);
  const navigate = useNavigate()
  const [loadingComplete, setLoadingComplete] = useState(false);
  const { userData, token } = useSelector((state) => state.user);
  const { cart } = useSelector((state) => state.cart);
  useEffect(() => {
    const verifyToken = async () => {
      const response = await axiosInstance(`/auth/verify-token?token=${token}`, 'POST', {});
      if (response && response.status === 200) {
        setIsVerify(true);
      } else {
        setIsVerify(false);
        dispatch(handleLogout());
        navigate('/login')
        toast.error('Session has been expired. Please login again', notificationStyle);
      }
      setLoadingComplete(true);
    };
    verifyToken();
  }, []);

  if (loadingComplete) {
    return isVerify ? children : <Navigate to="/login" />;
  }
  return loadingComplete && !isVerify ? <Navigate to="/login" /> : <div>...loading</div>;
}

export default UserPrivateRoute;

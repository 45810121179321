import React, { useEffect, useMemo, useState } from 'react';
import {
  IconButton,
  Box,
  CloseButton,
  Flex,
  Icon,
  useColorModeValue,
  Link,
  Drawer,
  DrawerContent,
  Text,
  useDisclosure,
  BoxProps,
  FlexProps,
  Popover,
  PopoverTrigger,
  Button,
  Portal,
  PopoverContent,
  PopoverBody,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  CheckboxGroup,
  Stack,
  Checkbox,
  RangeSlider,
  RangeSliderTrack,
  RangeSliderFilledTrack,
  RangeSliderThumb,
} from '@chakra-ui/react';
import { FiHome, FiTrendingUp, FiCompass, FiStar, FiSettings, FiMenu } from 'react-icons/fi';
import { IconType } from 'react-icons';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import Header from '../header';
import './productSidebar.css';
import { fetchFilterOptions, filterOptionsSet } from '../../actions/productFilterActions';
import PriceSlider from './PriceSlider';
import { fetchProductListData } from '../../actions/ProductListingActions';
import store from '../../store';

const LinkItems = [
  { name: 'Home', icon: FiHome },
  { name: 'Trending', icon: FiTrendingUp },
  { name: 'Explore', icon: FiCompass },
  { name: 'Favourites', icon: FiStar },
  { name: 'Settings', icon: FiSettings },
];

export default function SimpleSidebar({ children }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Box minH="100vh" bg={'#fff'} display={'flex'} marginTop={'10px'}>
      <SidebarContent onClose={() => onClose()} display={{ base: 'none', md: 'block' }} className="sidebar-content" />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        // size="full"
        className="show-sidebar"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav className="filter-option" display={{ base: 'flex', sm: 'flex', md: 'none' }} onOpen={onOpen} />
      <Box
        ml={{ base: 0, md: 0 }}
        width={'100%'}
        display={'flex'}
        justifyContent={'center'}
        paddingLeft={'15px'}
        paddingRight={'15px'}
        marginBottom={'30px'}
      >
        {children}
      </Box>
    </Box>
  );
}

const SidebarContent = ({ onClose, type, ...rest }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [categoryTree, setCategoryTree] = useState([])
  const [searchParams] = useSearchParams();

  const { filter } = useSelector((state) => state.filter);


  useEffect(() => {
    const data = {}
    if (searchParams.get('search')) {
      data.search = searchParams.get('search')
    }
    dispatch(fetchFilterOptions(data));
  }, []);
  useEffect(() => {
    const data = {}
    if (searchParams.get('search') && filter.search !== searchParams.get('search')) {
      data.search = searchParams.get('search')
    }
    dispatch(fetchFilterOptions(data));
  }, [searchParams.get('search')]);

  useEffect(() => {
    if (filter?.category?.data && filter?.category?.data?.length) {
      const categoryTree = createCategoryTree(filter.category.data);
      setCategoryTree(categoryTree)
    }
  }, [filter])
  const handleCheckboxChange = (event, type) => {
    const { value, checked } = event.target;
    const updatedFilters = cloneDeep(filter); // Create a deep copy of the filter object

    if (checked) {
      updatedFilters[type].selectedOption = [...updatedFilters[type].selectedOption, value];
    } else {
      updatedFilters[type].selectedOption = updatedFilters[type].selectedOption.filter((val) => val !== value);
    }

    dispatch(filterOptionsSet(updatedFilters));
  };

  const handlePriceSliderChange = (min, max) => {
    const updatedFilters = cloneDeep(filter);
    updatedFilters.priceRange.selectedOption = { minPrice: min, maxPrice: max };

    dispatch(filterOptionsSet(updatedFilters));
  };

  const handleReset = () => {
    dispatch(fetchFilterOptions());
    dispatch(fetchProductListData());
    // Reset selected options in the filter state
    const updatedFilters = cloneDeep(filter); // Create a deep copy of the filter object

    // Reset all selected options to an empty array
    Object.keys(updatedFilters).forEach((key) => {
      if (Array.isArray(updatedFilters[key].selectedOption)) {
        updatedFilters[key].selectedOption = [];
      } else {
        updatedFilters[key].selectedOption;
      }
    });

    dispatch(filterOptionsSet(updatedFilters));
    navigate("/product")
  };

  const handleSubmit = async () => {

    let newFilter = {
      page: 1,
      limit: 12,
      search: searchParams.get("search")
        ? searchParams.get("search")
        : undefined,
    };
    for (const key of Object.keys(filter)) {
      if (key === "category") {
        const selectedCategoryIds = flattenAndCollectIds(categoryTree);
        newFilter = { ...newFilter, [key]: selectedCategoryIds };
      } else {

        newFilter = { ...newFilter, [key]: filter[key].selectedOption };
      }
    }

    dispatch(fetchProductListData(newFilter));
    onClose();
  };
  // start checkbox code
  function createCategoryTree(categories, parentCategoryId = null) {
    return categories
      .filter(category => category.parent_category_id === parentCategoryId)
      .map(category => ({
        ...category,
        children: createCategoryTree(categories, category.category_id)
      }));
  }


  const handleCategoryChange = (categoryId, isChecked, type, parentId) => {

    if (type === "parent") {
      const updatedCategory = categoryTree.map((i) => {
        if (i.category_id === categoryId) {
          return {
            ...i,
            checked: isChecked,
            isIndeterminate: false,
            children: Array.isArray(i.children) && i.children.map((child) => {
              return {
                ...child,
                checked: isChecked
              }
            })
          }
        } else {
          return i
        }
      })

      setCategoryTree(updatedCategory);
    } else {
      let updatedCategory = categoryTree.map((i) => {
        if (i.category_id === parentId) {
          return {
            ...i,
            // checked : isChecked,
            // isIndeterminate : false,
            children: Array.isArray(i.children) && i.children.map((child) => {
              if (child.category_id === categoryId) {
                return {
                  ...child,
                  checked: isChecked
                }
              } else {
                return child
              }
            })
          }
        } else {
          return i
        }
      })

      updatedCategory = updatedCategory.map((i) => {
        if (i.category_id === parentId) {
          const allChecked = Array.isArray(i.children) && i.children.every((item) => item.checked)
          return {
            ...i,
            checked: Array.isArray(i.children) && i.children.every((item) => item.checked),
            isIndeterminate: !allChecked && Array.isArray(i.children) && i.children.some((item) => item.checked),
          }
        } else {
          return i
        }
      })
      setCategoryTree(updatedCategory);
    }


  };



  const flattenAndCollectIds = (categories) => {
    const selectedCategoryIds = [];
    categories.forEach(category => {
      if (category.checked || category.isIndeterminate) {
        selectedCategoryIds.push(category.category_id);
      }
      if (category.children) {
        category.children.forEach((child) => {
          if (child.checked) {
            selectedCategoryIds.push(child.category_id)
          }
        })
      }
    });
    return selectedCategoryIds;
  };



  //end checkbox code
  return (
    <Box
      bg={'#fff'}
      borderRight="1px"
      borderRightColor={useColorModeValue('gray.200', 'gray.700')}
      w={'auto'}
      maxW={'400px'}
      minW={'300px'}
      // maxWidth={{ base: 'full', md: '400px' }}
      // pos="fixed"
      pos={'sticky'}
      top={{ md: '80px', lg: '80px' }}
      h={{ md: '100vh', sm: '100vh', base: '100vh' }}
      className="product-sidebar"
      overflowY={'scroll'}
      boxShadow={'0 10px 15px -3px rgba(0,0,0,0.1), 0 4px 6px -4px rgba(0,0,0,0.1)'}
      {...rest}
    >
      <Flex justifyContent={{ base: 'end', sm: 'end' }} padding={{ base: '10px', sm: '10px' }}>
        {' '}
        <CloseButton display={{ base: 'flex', md: 'none' }} className="show-cancelBtn" onClick={onClose} />
      </Flex>
      <div>
        <Accordion allowMultiple defaultIndex={[0]}>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left" className="common-sidebarText">
                  Filter By Category
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              {categoryTree && categoryTree.length && categoryTree.map(category => (
                <>
                  <Checkbox
                    isChecked={category.checked || false}
                    isIndeterminate={category.isIndeterminate || false}
                    onChange={(e) => handleCategoryChange(category.category_id, e.target.checked, "parent")}
                    colorScheme="orange"
                  >
                    {category.category_name}
                  </Checkbox>
                  {category.children && (
                    <div style={{ marginLeft: "20px", display: "grid" }}>
                      {category.children.map((child) => (
                        <Checkbox
                          isChecked={child.checked || false}
                          onChange={(e) => handleCategoryChange(child.category_id, e.target.checked, "child", category.category_id)}
                          colorScheme="orange"
                        >
                          {child.category_name}
                        </Checkbox>
                      ))}
                    </div>
                  )}
                </>
              ))}
              {/* <CheckboxGroup colorScheme="green" value={filter?.category?.selectedOption}>
                <Stack spacing={[1, 5]} direction={['column']} className="common-sidebarText2">
                  {filter && filter.category && filter?.category?.data && filter.category.data.length
                    ? filter.category.data.map((item) => (
                        <Checkbox
                          checked={filter?.category?.selectedOption.includes(`${item.category_id}`)}
                          value={`${item?.category_id}`}
                          name="category"
                          key={item?.category_id}
                          onChange={(e) => handleCheckboxChange(e, 'category')}
                          colorScheme="orange"
                        >
                          {item?.category_name}
                        </Checkbox>
                      ))
                    : <Text>Options are not available</Text>}
                </Stack>
              </CheckboxGroup> */}
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left" className="common-sidebarText">
                  Colours
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <CheckboxGroup colorScheme="green" value={filter?.colour?.selectedOption}>
                <Stack spacing={[1, 5]} direction={['column']} className="common-sidebarText2">
                  {filter && filter?.colour && filter?.colour?.data && filter.colour.data.length
                    ? filter.colour.data.map((val) => (
                      <Checkbox
                        checked={filter.colour.selectedOption.includes(`${val.colour}`)}
                        value={val?.colour}
                        onChange={(e) => handleCheckboxChange(e, 'colour')}
                        colorScheme="orange"
                      >
                        {val?.colour}
                      </Checkbox>
                    ))
                    : <Text>Options are not available</Text>}
                </Stack>
              </CheckboxGroup>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left" className="common-sidebarText">
                  Availability
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <CheckboxGroup colorScheme="green" value={filter?.availability?.selectedOption}>
                <Stack spacing={[1, 5]} direction={['column']} className="common-sidebarText2">
                  {filter && filter?.availability && filter?.availability?.data && filter.availability.data.length
                    ? filter.availability.data.map((val) => (
                      <Checkbox
                        checked={filter?.availability?.selectedOption.includes(`${val.availability}`)}
                        value={val?.availability}
                        onChange={(e) => handleCheckboxChange(e, 'availability')}
                        colorScheme="orange"
                      >
                        {val?.availability === 'in_stock' ? 'In Stoke' : 'Out Of Stoke'}
                      </Checkbox>
                    ))
                    : <Text>Options are not available</Text>}
                </Stack>
              </CheckboxGroup>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left" className="common-sidebarText">
                  Band Colour
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <CheckboxGroup colorScheme="green" value={filter?.bandColour?.selectedOption}>
                <Stack spacing={[1, 5]} direction={['column']} className="common-sidebarText2">
                  {filter && filter?.bandColour && filter?.bandColour?.data && filter.bandColour.data.length
                    ? filter.bandColour.data.map((val) => (
                      <Checkbox
                        checked={filter.bandColour.selectedOption.includes(`${val.bandColour}`)}
                        value={val?.bandColour}
                        onChange={(e) => handleCheckboxChange(e, 'bandColour')}
                        colorScheme="orange"
                      >
                        {val?.bandColour}
                      </Checkbox>
                    ))
                    : <Text>Options are not available</Text>}
                </Stack>
              </CheckboxGroup>
            </AccordionPanel>
          </AccordionItem>
          {/* <AccordionItem>
          <h2>
            <AccordionButton>
              <Box as="span" flex="1" textAlign="left" className="common-sidebarText">
                Band Size
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <CheckboxGroup colorScheme="green">
              <Stack spacing={[1, 5]} direction={['column']} className="common-sidebarText2">
                {data &&
                  data?.colours.map((val) => (
                    <Checkbox
                      checked={val?.colour}
                      value={val?.colour}
                      onChange={(e) => handleCheckboxChange(e, 'colour')}
                      colorScheme="orange"
                    >
                      {val?.colour}
                    </Checkbox>
                  ))}
              </Stack>
            </CheckboxGroup>
          </AccordionPanel>
        </AccordionItem> */}
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left" className="common-sidebarText">
                  Band Material
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <CheckboxGroup colorScheme="green" value={filter?.bandMaterial?.selectedOption}>
                <Stack spacing={[1, 5]} direction={['column']} className="common-sidebarText2">
                  {filter && filter.bandMaterial && filter.bandMaterial.data && filter.bandMaterial.data.length
                    ? filter.bandMaterial.data.map((val) => (
                      <Checkbox
                        checked={filter.bandMaterial.selectedOption.includes(`${val.bandMaterial}`)}
                        value={val?.bandMaterial}
                        onChange={(e) => handleCheckboxChange(e, 'bandMaterial')}
                        colorScheme="orange"
                      >
                        {val?.bandMaterial}
                      </Checkbox>
                    ))
                    : <Text>Options are not available</Text>}
                </Stack>
              </CheckboxGroup>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left" className="common-sidebarText">
                  Band Width
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <CheckboxGroup colorScheme="green" value={filter?.bandwidth?.selectedOption}>
                <Stack spacing={[1, 5]} direction={['column']} className="common-sidebarText2">
                  {filter && filter.bandwidth && filter.bandwidth.data && filter.bandwidth.data.length
                    ? filter.bandwidth.data.map((val) => (
                      <Checkbox
                        checked={filter.bandwidth.selectedOption.includes(`${val.bandwidth}`)}
                        value={val?.bandwidth}
                        onChange={(e) => handleCheckboxChange(e, 'bandwidth')}
                        colorScheme="orange"
                      >
                        {val?.bandwidth}
                      </Checkbox>
                    ))
                    : <Text>Options are not available</Text>}
                </Stack>
              </CheckboxGroup>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left" className="common-sidebarText">
                  Bazel Function
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <CheckboxGroup colorScheme="green" value={filter?.bezelfunction?.selectedOption}>
                <Stack spacing={[1, 5]} direction={['column']} className="common-sidebarText2">
                  {filter && filter.bezelfunction && filter.bezelfunction.data && filter.bezelfunction.data.length
                    ? filter.bezelfunction.data.map((val) => (
                      <Checkbox
                        checked={filter.bezelfunction.selectedOption.includes(`${val.bezelfunction}`)}
                        value={val?.bezelfunction}
                        onChange={(e) => handleCheckboxChange(e, 'bezelfunction')}
                        colorScheme="orange"
                      >
                        {val?.bezelfunction}
                      </Checkbox>
                    ))
                    : <Text>Options are not available</Text>}
                </Stack>
              </CheckboxGroup>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left" className="common-sidebarText">
                  Bazel Material
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <CheckboxGroup colorScheme="green" value={filter?.bezelMaterial?.selectedOption}>
                <Stack spacing={[1, 5]} direction={['column']} className="common-sidebarText2">
                  {filter && filter.bezelMaterial && filter.bezelMaterial.data && filter.bezelMaterial.data.length
                    ? filter.bezelMaterial.data.map((val) => (
                      <Checkbox
                        checked={filter.bezelMaterial.selectedOption.includes(`${val.bezelMaterial}`)}
                        value={val?.bezelMaterial}
                        onChange={(e) => handleCheckboxChange(e, 'bezelMaterial')}
                        colorScheme="orange"
                      >
                        {val?.bezelMaterial}
                      </Checkbox>
                    ))
                    : <Text>Options are not available</Text>}
                </Stack>
              </CheckboxGroup>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left" className="common-sidebarText">
                  Case Diameter
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <CheckboxGroup colorScheme="green" value={filter?.caseDiameter?.selectedOption}>
                <Stack spacing={[1, 5]} direction={['column']} className="common-sidebarText2">
                  {filter && filter.caseDiameter && filter.caseDiameter.data && filter.caseDiameter.data.length
                    ? filter.caseDiameter.data.map((val) => (
                      <Checkbox
                        checked={filter.caseDiameter.selectedOption.includes(`${val.caseDiameter}`)}
                        value={val?.caseDiameter}
                        onChange={(e) => handleCheckboxChange(e, 'caseDiameter')}
                        colorScheme="orange"
                      >
                        {val?.caseDiameter}
                      </Checkbox>
                    ))
                    : <Text>Options are not available</Text>}
                </Stack>
              </CheckboxGroup>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left" className="common-sidebarText">
                  Case Material
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <CheckboxGroup colorScheme="green" value={filter?.caseMaterial?.selectedOption}>
                <Stack spacing={[1, 5]} direction={['column']} className="common-sidebarText2">
                  {filter && filter.caseMaterial && filter.caseMaterial.data && filter.caseMaterial.data.length
                    ? filter.caseMaterial.data.map((val) => (
                      <Checkbox
                        checked={filter.caseMaterial.selectedOption.includes(`${val.caseMaterial}`)}
                        value={val?.caseMaterial}
                        onChange={(e) => handleCheckboxChange(e, 'caseMaterial')}
                        colorScheme="orange"
                      >
                        {val?.caseMaterial}
                      </Checkbox>
                    ))
                    : <Text>Options are not available</Text>}
                </Stack>
              </CheckboxGroup>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left" className="common-sidebarText">
                  Case Shape
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <CheckboxGroup colorScheme="green" value={filter?.caseShape?.selectedOption}>
                <Stack spacing={[1, 5]} direction={['column']} className="common-sidebarText2">
                  {filter && filter.caseShape && filter.caseShape.data && filter.caseShape.data.length
                    ? filter.caseShape.data.map((val) => (
                      <Checkbox
                        checked={filter.caseShape.selectedOption.includes(`${val.caseShape}`)}
                        value={val?.caseShape}
                        onChange={(e) => handleCheckboxChange(e, 'caseShape')}
                        colorScheme="orange"
                      >
                        {val?.caseShape}
                      </Checkbox>
                    ))
                    : <Text>Options are not available</Text>}
                </Stack>
              </CheckboxGroup>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left" className="common-sidebarText">
                  Dial Colour
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <CheckboxGroup colorScheme="green" value={filter?.dialcolour?.selectedOption}>
                <Stack spacing={[1, 5]} direction={['column']} className="common-sidebarText2">
                  {filter && filter.dialcolour && filter.dialcolour.data && filter.dialcolour.data.length
                    ? filter.dialcolour.data.map((val) => (
                      <Checkbox
                        checked={filter.dialcolour.selectedOption.includes(`${val.dialcolour}`)}
                        value={val?.dialcolour}
                        onChange={(e) => handleCheckboxChange(e, 'dialcolour')}
                        colorScheme="orange"
                      >
                        {val?.dialcolour}
                      </Checkbox>
                    ))
                    : <Text>Options are not available</Text>}
                </Stack>
              </CheckboxGroup>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left" className="common-sidebarText">
                  Display Type
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <CheckboxGroup colorScheme="green" value={filter?.displayType?.selectedOption}>
                <Stack spacing={[1, 5]} direction={['column']} className="common-sidebarText2">
                  {filter && filter.displayType && filter.displayType.data && filter.displayType.data.length
                    ? filter.displayType.data.map((val) => (
                      <Checkbox
                        checked={filter.displayType.selectedOption.includes(`${val.displayType}`)}
                        value={val?.displayType}
                        onChange={(e) => handleCheckboxChange(e, 'displayType')}
                        colorScheme="orange"
                      >
                        {val?.displayType}
                      </Checkbox>
                    ))
                    : <Text>Options are not available</Text>}
                </Stack>
              </CheckboxGroup>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left" className="common-sidebarText">
                  Movement Type
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <CheckboxGroup colorScheme="green" value={filter?.movementType?.selectedOption}>
                <Stack spacing={[1, 5]} direction={['column']} className="common-sidebarText2">
                  {filter && filter.movementType && filter.movementType.data && filter.movementType.data.length
                    ? filter.movementType.data.map((val) => (
                      <Checkbox
                        checked={filter.movementType.selectedOption.includes(`${val.movementType}`)}
                        value={val?.movementType}
                        onChange={(e) => handleCheckboxChange(e, 'movementType')}
                        colorScheme="orange"
                      >
                        {val?.movementType}
                      </Checkbox>
                    ))
                    : <Text>Options are not available</Text>}
                </Stack>
              </CheckboxGroup>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left" className="common-sidebarText">
                  Filter By Price
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <RangeSlider
                defaultValue={[Number(filter?.priceRange?.data?.minPrice), Number(filter?.priceRange?.data?.maxPrice)]}
                min={Number(filter?.priceRange?.data?.minPrice)}
                max={Number(filter?.priceRange?.data?.maxPrice)}
                step={30}
                value={[
                  Number(filter?.priceRange?.selectedOption?.minPrice),
                  Number(filter?.priceRange?.selectedOption?.maxPrice),
                ]}
                onChange={(val) => handlePriceSliderChange(val[0], val[1])}
              >
                <RangeSliderTrack bg="red.100">
                  <RangeSliderFilledTrack bg="tomato" />
                </RangeSliderTrack>
                <RangeSliderThumb boxSize={6} index={0} value={Number(filter?.priceRange?.data?.minPrice)} />
                <RangeSliderThumb boxSize={6} index={1} value={Number(filter?.priceRange?.data?.maxPrice)} />
              </RangeSlider>
              <Text>
                Price: <span>₹</span>
                {filter?.priceRange?.selectedOption?.minPrice} - <span>₹</span>
                {filter?.priceRange?.selectedOption?.maxPrice}
              </Text>
            </AccordionPanel>
          </AccordionItem>
          <Flex padding={8} justifyContent={'space-between'} paddingLeft={2} paddingRight={2}>
            <Button className="filter-reset" type="button" onClick={() => handleReset()} background={'transparent'}>
              Reset
            </Button>
            <Button className="filter-apply" onClick={() => handleSubmit()} background={'transparent'}>
              Apply
            </Button>
          </Flex>
        </Accordion>
        {/* <Popover trigger={'hover'} closeOnBlur={false} placement="bottom-start" initialFocusRef={initRef}>
          {({ isOpen, onClose }) => (
            <>
              <PopoverTrigger>
                <Button
                  fontSize={{ base: '15px', sm: '15px', md: '15px' }}
                  fontWeight={400}
                  style={{ backgroundColor: 'white' }}
                >
                  Filter By Category{' '}
                  <span style={{ marginLeft: '10px' }}>
                    {isOpen ? <ChevronDownIcon fontSize={'xl'} /> : <ChevronUpIcon fontSize={'xl'} />}
                  </span>
                </Button>
              </PopoverTrigger>
              <Portal>
                <PopoverContent width={'auto'}>
                  <PopoverBody width={'auto'} padding={'10px'}>
                    <input
                      onChange={handleChange}
                      checked={category.includes(type === 'men' ? 'Casual Shirts' : 'Dresses and Jumpsuits')}
                      style={{ marginRight: '10px' }}
                      value={type === 'men' ? 'Casual Shirts' : 'Dresses and Jumpsuits'}
                      type="checkbox"
                    />
                    <label>{type === 'men' ? 'Casual Shirts' : 'Dresses and Jumpsuits'}</label>
                    <br />
                    <br />
                    <input
                      onChange={handleChange}
                      checked={category.includes(type === 'men' ? 'Jeans' : 'Kurtas and Kurtis')}
                      style={{ marginRight: '10px' }}
                      value={type === 'men' ? 'Jeans' : 'Kurtas and Kurtis'}
                      type="checkbox"
                    />
                    <label>{type === 'men' ? 'Jeans' : 'Kurtas and Kurtis'}</label>
                  </PopoverBody>
                </PopoverContent>
              </Portal>
            </>
          )}
        </Popover> */}
      </div>
      {/* {LinkItems.map((link) => (
      <NavItem key={link.name} icon={link.icon}>
        {link.name}
      </NavItem>
    ))} */}
    </Box>
  );
};

const NavItem = ({ icon, children, ...rest }) => (
  <Link href="#" style={{ textDecoration: 'none' }} _focus={{ boxShadow: 'none' }}>
    <Flex
      align="center"
      p="4"
      mx="4"
      borderRadius="lg"
      role="group"
      cursor="pointer"
      _hover={{
        bg: 'cyan.400',
        color: 'white',
      }}
      {...rest}
    >
      {icon && (
        <Icon
          mr="4"
          fontSize="16"
          _groupHover={{
            color: 'white',
          }}
          as={icon}
        />
      )}
      {children}
    </Flex>
  </Link>
);

const MobileNav = ({ onOpen, ...rest }) => (
  <Flex
    ml={{ base: 0, md: 60 }}
    px={{ base: 4, md: 24 }}
    height="20"
    alignItems="center"
    bg={useColorModeValue('white', 'gray.900')}
    // borderBottomWidth="1px"
    // borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
    justifyContent="center"
    {...rest}
  >
    <Button
      onClick={onOpen}
      aria-label="open menu"
      marginTop={{ base: '75px', md: '80px', sm: "100px" }}
      // backgroundColor={{ base: 'red', md: 'blue', sm: "yellow" }}
      width={{ base: '75px', md: "75px", sm: '100px' }}
      height={{ base: '40px', md: "30px", sm: '50px' }}
      border={'1px solid black'}
      className="responsive-filterBtn"
      background={'transparent'}
    >
      Filters
    </Button>
    {/* <IconButton variant="outline" onClick={onOpen} aria-label="open menu"  icon={<FiMenu />} /> */}
  </Flex>
);

import React, { useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import {
  Badge,
  Box,
  Button,
  Circle,
  Flex,
  Grid,
  Icon,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Tooltip,
  useBreakpointValue,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import "./loginModal.css";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import mainLogo from "../../assets/logo/logo.png";
import { fetchUserData } from "../../actions/UserActions";
import { CommonImage } from "../../pages/user panel/common Login Image/CommonImage";

function LoginModal({ isOpen, onClose }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { homePageData } = useSelector((state) => state.home);
  const [login, setLogin] = useState({
    identifier: "",
    password: "",
  });
  const [visiblity, setVisiblity] = useState(false);
  const emailValidation =
    /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;

  const validationSchema = Yup.object({
    identifier: Yup.string()
      .required("Email is required")
      .email("Please Add Valid Email")
      .matches(emailValidation, "Please Add Valid Email")
      .trim(),
    password: Yup.string()
      .required("Password is required")
      .min(6, "Password must be at least 6 characters.")
      .trim(),
  });

  const handleSubmit = async (values) => {
    dispatch(fetchUserData(values));
    // navigate('/');
    onClose();
    // const response = await axiosInstance('/auth/login', 'POST', values);
    // if (response.status === 200) {
    //   localStorage.setItem('token', response.data.data.access_token);
    //   setLogin({
    //     identifier: '',
    //     password: '',
    //   });
    // } else {
    //   console.log('error', response.data.message);
    // }
  };
  return (
    <div>
      <Modal isCentered onClose={onClose} isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent className="animate fadeInDown two">
          {/* <ModalHeader className="common-userPaneFamily" textAlign={'center'}>
            Login
          </ModalHeader> */}
          <ModalCloseButton marginTop={"2px"} marginRight={"22px"} />
          <ModalBody>
            <div className="flex-1 h-full max-w-4xl mx-auto overflow-hidden bg-white rounded-lg shadow-xl dark:bg-gray-800">
              <div className="flex flex-col overflow-y-auto md:flex-row h-full">
                {/* <div className="modal-image"> */}
                  <CommonImage />
                {/* </div> */}
                <main className="flex items-center justify-center p-6 md:w-1/2">
                  <div className="w-full">
                    <div className="flex items-center justify-center">
                      <img
                        src={
                          homePageData &&
                          homePageData?.setting &&
                          homePageData?.setting.logo
                            ? `${process.env.REACT_APP_IMAGE_URL}${homePageData?.setting.logo}`
                            : mainLogo
                        }
                        alt="mainLogo"
                        className="modal-logo"
                        style={{ marginTop: -15, marginBottom: 21, height: 75 }}
                      />
                    </div>
                    <h1 className="mb-6 text-2xl font-semibold text-gray-700 dark:text-gray-200 common-userPaneFamily">
                      Login
                    </h1>
                    <Formik
                      enableReinitialize
                      initialValues={login}
                      validationSchema={validationSchema}
                      onSubmit={(values, { setSubmitting, resetForm }) => {
                        handleSubmit(values);
                        // setSubmitting(true);
                        // resetForm();
                        // setSubmitting(false);
                      }}
                    >
                      {({
                        field,
                        values,
                        errors,
                        touched,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isValid,
                        isSubmitting,
                      }) => (
                        <Form onSubmit={handleSubmit}>
                          <label className="block text-sm text-gray-700 dark:text-gray-400 col-span-4 sm:col-span-2 font-bold text-sm common-userPaneFamily ">
                            Email
                          </label>
                          <input
                            className={
                              touched.identifier && errors.identifier
                                ? "block w-full px-3 py-1 text-sm focus:outline-none dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-12 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white input-error common-userPaneFamily "
                                : "block w-full px-3 py-1 text-sm focus:outline-none dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-12 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white common-userPaneFamily "
                            }
                            type="text"
                            name="identifier"
                            placeholder="Email"
                            value={values.identifier}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            autocomplete="off"
                          />
                          {touched.identifier && errors.identifier && (
                            <div className="error-message">
                              {errors.identifier}
                            </div>
                          )}
                          <div className="mt-6" />
                          <label className="block text-sm text-gray-700 dark:text-gray-400 col-span-4 sm:col-span-2 font-bold text-sm common-userPaneFamily  ">
                            Password
                          </label>
                          <div className="inputWrapper">
                            <input
                              className={
                                touched.password && errors.password
                                  ? "block w-full px-3 py-1 text-sm focus:outline-none dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-12 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white input-error common-userPaneFamily "
                                  : "block w-full px-3 py-1 text-sm focus:outline-none dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-12 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white common-userPaneFamily "
                              }
                              type={visiblity ? "text" : "password"}
                              name="password"
                              placeholder="Password"
                              value={values.password}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              autocomplete="new-password"
                            />
                            <div
                              className="showHidePassword"
                              onClick={() => setVisiblity(!visiblity)}
                            >
                              {!visiblity ? (
                                <VisibilityOffIcon />
                              ) : (
                                <VisibilityIcon />
                              )}
                            </div>
                          </div>
                          {touched.password && errors.password && (
                            <div className="error-message">
                              {errors.password}
                            </div>
                          )}
                          <div className="flex justify-center">
                            <button
                              className="w-full px-4 py-2 font-bold text-white bg-blue-500 hover:bg-blue-700 focus:outline-none focus:shadow-outline w-3/4 px-4 py-2 font-bold align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-bold focus:outline-none px-4 py-2 rounded-lg text-sm text-white bg-orange-500 border border-transparent active:bg-orange-600 hover:bg-orange-600 focus:ring focus:ring-orange-300 mt-4 h-12 w-3/4 common-userPaneFamily  login-button shadow-md shadow-orange-500/20 hover:shadow-lg hover:shadow-orange-500/40 active:opacity-[0.85] common-borderRadius"
                              type="submit"
                              // to="/dashboard"
                            >
                              Login
                            </button>
                          </div>
                          <hr className="my-10" style={{ marginBottom: 15 }} />
                        </Form>
                      )}
                    </Formik>
                    <div className="flex justify-between">
                      <p className="mt-1">
                        <a
                          className="text-base font-medium hover:cursor-pointer text-color hover:underline common-userPaneFamily"
                          onClick={() => navigate("/forgot-password")}
                        >
                          Forgot your password
                        </a>
                      </p>
                      <p className="mt-1">
                        <a
                          className="text-base font-medium hover:cursor-pointer text-color hover:underline common-userPaneFamily "
                          onClick={() => navigate("/register")}
                        >
                          Create account
                        </a>
                      </p>
                    </div>
                  </div>
                </main>
              </div>
            </div>
            {/* <Formik
              enableReinitialize
              initialValues={login}
              validationSchema={validationSchema}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                handleSubmit(values);
                // setSubmitting(true);
                // resetForm();
                // setSubmitting(false);
              }}
            >
              {({ field, values, errors, touched, handleBlur, handleChange, handleSubmit, isValid, isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  <label className="block text-sm text-gray-700 dark:text-gray-400 col-span-4 sm:col-span-2 font-bold text-sm common-userPaneFamily ">
                    Email
                  </label>
                  <input
                    className={
                      touched.identifier && errors.identifier
                        ? 'block w-full px-3 py-1 text-sm focus:outline-none dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-12 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white input-error common-userPaneFamily '
                        : 'block w-full px-3 py-1 text-sm focus:outline-none dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-12 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white common-userPaneFamily '
                    }
                    type="text"
                    name="identifier"
                    placeholder="Email"
                    value={values.identifier}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autocomplete="off"
                  />
                  {touched.identifier && errors.identifier && <div className="error-message">{errors.identifier}</div>}
                  <div className="mt-6" />
                  <label className="block text-sm text-gray-700 dark:text-gray-400 col-span-4 sm:col-span-2 font-bold text-sm common-userPaneFamily  ">
                    Password
                  </label>
                  <div className="inputWrapper">
                    <input
                      className={
                        touched.password && errors.password
                          ? 'block w-full px-3 py-1 text-sm focus:outline-none dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-12 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white input-error common-userPaneFamily '
                          : 'block w-full px-3 py-1 text-sm focus:outline-none dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-12 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white common-userPaneFamily '
                      }
                      type={visiblity ? 'text' : 'password'}
                      name="password"
                      placeholder="Password"
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      autocomplete="new-password"
                    />
                    <div className="showHidePassword" onClick={() => setVisiblity(!visiblity)}>
                      {!visiblity ? <VisibilityOffIcon /> : <VisibilityIcon />}
                    </div>
                  </div>
                  {touched.password && errors.password && <div className="error-message">{errors.password}</div>}
                  <div className="flex justify-center">
                    <button
                      className="w-full px-4 py-2 font-bold text-white bg-blue-500 hover:bg-blue-700 focus:outline-none focus:shadow-outline w-3/4 px-4 py-2 font-bold align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-bold focus:outline-none px-4 py-2 rounded-lg text-sm text-white bg-orange-500 border border-transparent active:bg-orange-600 hover:bg-orange-600 focus:ring focus:ring-orange-300 mt-4 h-12 w-3/4 common-userPaneFamily  login-button shadow-md shadow-orange-500/20 hover:shadow-lg hover:shadow-orange-500/40 active:opacity-[0.85] common-borderRadius mb-4"
                      type="submit"
                      // to="/dashboard"
                    >
                      Login
                    </button>
                  </div>
                </Form>
              )}
            </Formik> */}
          </ModalBody>
          {/* <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={onClose}>
              Close
            </Button>
            <Button variant='ghost'>Secondary Action</Button>
          </ModalFooter> */}
        </ModalContent>
      </Modal>
    </div>
  );
}

export default LoginModal;

import { Helmet } from 'react-helmet-async';
import './dashboard.css';
import { AppWidgetSummary } from '../../sections/@dashboard/app';
// @mui
import {
  Grid,
  Container,
  Typography,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Checkbox,
  Stack,
  Avatar,
  IconButton,
  TablePagination,
  Card,
  Popover,
  Drawer,
  Tooltip,
  MenuItem,
} from '@mui/material';
// components
// sections
import { UserListHead } from 'src/sections/@dashboard/user';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import LoupeIcon from '@mui/icons-material/Loupe';
import { MuiThemeProvider, Paper } from '@material-ui/core';

import moment from 'moment';
import Product from 'src/components/addProducts/AddProduct';
import { getOrderDetails, getOrderList } from '../../actions/AdminOrderActions';
import totalOrder from '../../assets/dashboard/total_order.svg';
import Iconify from '../../components/iconify';
import Loader from '../../components/loader/Loader';

// ----------------------------------------------------------------------

export default function DashboardAppPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { orderList, orderDetails, loading } = useSelector((state) => state.adminOrder);
  const [openFilter, setOpenFilter] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [open, setOpen] = useState(null);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [order, setOrder] = useState('asc');
  const [orderData, setOrderData] = useState([]);
  const [pagination, setPagination] = useState({});

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    dispatch(getOrderList({ page: newPage + 1, limit: rowsPerPage }));
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    dispatch(getOrderList({ page: page + 1, limit: +event.target.value }));
  };

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function applySortFilter(array, comparator, query) {
    // const stabilizedThis = array.map((el, index) => [el, index]);
    // stabilizedThis.sort((a, b) => {
    //   const order = comparator(a[0], b[0]);
    //   if (order !== 0) return order;
    //   return a[1] - b[1];
    // });
    // if (query) {
    //   return filter(array, (_user) => _user.name?.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    // }
    // return stabilizedThis.map((el) => el[0]);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  // const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - orderList.length) : 0;

  // const filteredUsers = applySortFilter(orderList, getComparator(order, orderBy), filterName);

  // const isNotFound = !filteredUsers?.length && !!filterName;

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const TABLE_HEAD = [
    { id: 'id', label: 'ORDER ID', alignRight: false },
    { id: 'orderTime', label: 'ORDER TIME', alignRight: false },
    { id: 'customerName', label: 'CUSTOMER NAME', alignRight: false },
    { id: 'paymentStatus', label: 'PAYMENT STATUS', alignRight: false },
    { id: 'deliveryCurrentStatus', label: 'DELIVERY STATUS', alignRight: false },
    { id: 'amount', label: 'ORDER AMOUNT', alignRight: false },
    { id: '', label: 'INVOICE', alignRight: false },
  ];

  useEffect(() => {
    dispatch(getOrderList({ page: page + 1, limit: rowsPerPage }));
    dispatch(getOrderDetails());
  }, []);

  useEffect(() => {
    if (orderList?.data?.length) {
      const data =
        orderList &&
        orderList.data.length &&
        orderList?.data?.map((item) => ({
          id: item?.OrderItems[0]?.order_id,
          orderTime: item.OrderItems[0]?.createdAt,
          customerName: item.User?.username,
          deliveryCurrentStatus: item.delivery_current_status,
          paymentStatus: item.payment_status,
          amount: item.total_amount,
        }));
      setOrderData(data);
      setCount(orderList?.pagination?.totalItems);
      setPagination(orderList?.pagination);
    }
  }, [orderList]);
  return (
    <>
      <Helmet>
        <title> Dashboard </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }} className="text-lg font-bold text-gray-700 dark:text-gray-300 headers">
          Dashboard Overview
        </Typography>

        <Grid container spacing={3} style={{ opacity: loading ? 0.3 : '' }}>
          <Grid item xs={12} sm={6} md={4} paddingBottom={'20px'}>
            <AppWidgetSummary
              title="Today Order"
              total={orderDetails && orderDetails?.todayOrderCount ? orderDetails.todayOrderCount.toString() : "0"}
              icon={
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  strokeWidth="0"
                  version="1.1"
                  viewBox="0 0 16 16"
                  height="1.5em"
                  width="1.5em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M16 5l-8-4-8 4 8 4 8-4zM8 2.328l5.345 2.672-5.345 2.672-5.345-2.672 5.345-2.672zM14.398 7.199l1.602 0.801-8 4-8-4 1.602-0.801 6.398 3.199zM14.398 10.199l1.602 0.801-8 4-8-4 1.602-0.801 6.398 3.199z" />
                </svg>
              }
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4} paddingBottom={'20px'}>
            <AppWidgetSummary
              title="This Month"
              total={orderDetails && orderDetails?.currentMonthOrderCount ? orderDetails.currentMonthOrderCount.toString() : "0"}
              color="info"
              icon={
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  strokeWidth="0"
                  version="1.1"
                  viewBox="0 0 16 16"
                  height="1.5em"
                  width="1.5em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M16 5l-8-4-8 4 8 4 8-4zM8 2.328l5.345 2.672-5.345 2.672-5.345-2.672 5.345-2.672zM14.398 7.199l1.602 0.801-8 4-8-4 1.602-0.801 6.398 3.199zM14.398 10.199l1.602 0.801-8 4-8-4 1.602-0.801 6.398 3.199z" />
                </svg>
              }
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4} paddingBottom={'20px'}>
            <AppWidgetSummary
              title="Total Orders"
              total={orderDetails && orderDetails?.allTimeOrderCount ? orderDetails.allTimeOrderCount.toString() : "0"}
              color="warning"
              icon={
                <svg
                  stroke="currentColor"
                  fill="none"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  height="1.5em"
                  width="1.5em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="9" cy="21" r="1" />
                  <circle cx="20" cy="21" r="1" />
                  <path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6" />
                </svg>
              }
            />
          </Grid>

          <div className="grid gap-4 md:grid-cols-2 xl:grid-cols-4 w-full dashboardStyle">
            <div className="min-w-0 rounded-lg ring-1 ring-white ring-opacity-4 overflow-hidden bg-white dark:bg-white-800 flex h-full">
              <div className="p-4 flex items-center border border-white-200 dark:border-white-800 w-full rounded-lg order-card">
                <div className="flex items-center justify-center p-3 rounded-full h-12 w-12 text-center mr-4 text-lg text-orange-600 dark:text-orange-100 bg-orange-100 dark:bg-orange-500">
                  <svg
                    stroke="currentColor"
                    fill="none"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="9" cy="21" r="1" />
                    <circle cx="20" cy="21" r="1" />
                    <path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6" />
                  </svg>
                </div>
                <div>
                  <p className="mb-1 text-sm font-medium text-gray-600 dark:text-gray-400">
                    <span>Not Picked Count</span>{' '}
                  </p>
                  <p className="text-2xl font-bold leading-none text-gray-600 dark:text-gray-200">
                    {orderDetails && orderDetails?.deliveryStatusCounts?.notPickedCount
                      ? orderDetails.deliveryStatusCounts?.notPickedCount.toString()
                      : "0"}
                  </p>
                </div>
              </div>
            </div>
            <div className="min-w-0 rounded-lg ring-1 ring-white ring-opacity-4 overflow-hidden bg-white dark:bg-gray-800 flex h-full">
              <div className="p-4 flex items-center border border-gray-200 dark:border-gray-800 w-full rounded-lg order-card">
                <div className="flex items-center justify-center p-3 rounded-full h-12 w-12 text-center mr-4 text-lg text-blue-600 dark:text-blue-100 bg-blue-100 dark:bg-blue-500">
                  <svg
                    stroke="currentColor"
                    fill="none"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <polyline points="23 4 23 10 17 10" />
                    <polyline points="1 20 1 14 7 14" />
                    <path d="M3.51 9a9 9 0 0 1 14.85-3.36L23 10M1 14l4.64 4.36A9 9 0 0 0 20.49 15" />
                  </svg>
                </div>
                <div>
                  <p className="mb-1 text-sm font-medium text-gray-600 dark:text-gray-400">
                    <span>In Transit</span>
                  </p>
                  <p className="text-2xl font-bold leading-none text-gray-600 dark:text-gray-200">
                    {orderDetails && orderDetails?.deliveryStatusCounts?.inTransitCount
                      ? orderDetails.deliveryStatusCounts?.inTransitCount.toString()
                      : 0}
                  </p>
                </div>
              </div>
            </div>
            <div className="min-w-0 rounded-lg ring-1 ring-white ring-opacity-4 overflow-hidden bg-white dark:bg-gray-800 flex h-full">
              <div className="p-4 flex items-center border border-gray-200 dark:border-gray-800 w-full rounded-lg order-card">
                <div className="flex items-center justify-center p-3 rounded-full h-12 w-12 text-center mr-4 text-lg text-teal-600 dark:text-teal-100 bg-teal-100 dark:bg-teal-500">
                  <svg
                    stroke="currentColor"
                    fill="none"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect x="1" y="3" width="15" height="13" />
                    <polygon points="16 8 20 8 23 11 23 16 16 16 16 8" />
                    <circle cx="5.5" cy="18.5" r="2.5" />
                    <circle cx="18.5" cy="18.5" r="2.5" />
                  </svg>
                </div>
                <div>
                  <p className="mb-1 text-sm font-medium text-gray-600 dark:text-gray-400">
                    <span>Out For Delivery</span>{' '}
                  </p>
                  <p className="text-2xl font-bold leading-none text-gray-600 dark:text-gray-200">
                    {orderDetails && orderDetails?.deliveryStatusCounts?.outForDeliveryCount
                      ? orderDetails.deliveryStatusCounts?.outForDeliveryCount
                      : 0}
                  </p>
                </div>
              </div>
            </div>
            <div className="min-w-0 rounded-lg ring-1 ring-white ring-opacity-4 overflow-hidden bg-white dark:bg-gray-800 flex h-full">
              <div className="p-4 flex items-center border border-gray-200 dark:border-gray-800 w-full rounded-lg order-card">
                <div className="flex items-center justify-center p-3 rounded-full h-12 w-12 text-center mr-4 text-lg text-green-600 dark:text-green-100 bg-green-100 dark:bg-green-500">
                  <svg
                    stroke="currentColor"
                    fill="none"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <polyline points="20 6 9 17 4 12" />
                  </svg>
                </div>
                <div>
                  <p className="mb-1 text-sm font-medium text-gray-600 dark:text-gray-400">
                    <span>Order Delivered</span>{' '}
                  </p>
                  <p className="text-2xl font-bold leading-none text-gray-600 dark:text-gray-200">
                    {orderDetails && orderDetails?.deliveryStatusCounts?.deliveredCount
                      ? orderDetails.deliveryStatusCounts?.deliveredCount
                      : 0}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <h1 className="my-6 text-lg font-bold text-gray-700 dark:text-gray-300 tableTitle">Recent Order</h1>
          <Grid xs={12} md={6} lg={12} className="tableStyle" height={loading ? '100%' : ''}>
            <Loader loading={loading} />

            <TableContainer sx={{ minWidth: 800 }} style={{ opacity: loading ? 0.3 : '' }}>
              <Table>
                <UserListHead
                  order={orderData}
                  // orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={orderData?.length}
                  numSelected={selected?.length}
                  // onRequestSort={handleRequestSort}
                  checkbox={false}
                />
                <TableBody>
                  {orderData &&
                    orderData.map((row) => {
                      const { id, productName, customerName, paymentStatus, amount } = row;
                      // const selectedUser = selected.indexOf(name) !== -1;
                      return (
                        <TableRow hover key={row.id} tabIndex={0}>
                          {/* <TableCell padding="checkbox">
                    <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, name)} />
                  </TableCell> */}

                          <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              {/* <Avatar alt={name} src={avatarUrl} /> */}
                              <Typography variant="subtitle2" noWrap marginLeft={'17px'}>
                                {row.id}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">{moment(row.orderTime).format('Do MMM YYYY hh:mm')}</TableCell>
                          <TableCell align="left">{row.customerName}</TableCell>
                          <TableCell align="left">
                            <div
                              className={
                                row.paymentStatus === 'succeeded'
                                  ? 'inline-flex px-2 text-xs font-medium leading-5 rounded-full text-green-500 bg-green-100 dark:bg-green-800 dark:text-green-100 padding-style w-2/5 flex justify-center'
                                  : 'inline-flex px-2 text-xs font-medium leading-5 rounded-full text-red-500 bg-red-100 dark:text-red-100 dark:bg-red-800 padding-style w-2/5 flex justify-center'
                              }
                            >
                              {row.paymentStatus === 'succeeded' ? row.paymentStatus : 'Failed'}
                            </div>
                          </TableCell>
                          <TableCell align="left">{row.deliveryCurrentStatus && row.deliveryCurrentStatus !== "" ? row.deliveryCurrentStatus : "Failed"}</TableCell>
                          <TableCell align="left">₹{row.amount}</TableCell>
                          <TableCell align="left">
                            <MuiThemeProvider>
                              <Tooltip title="View Invoice" placement="bottom">
                                <LoupeIcon sx={{ height: 15 }} onClick={() => navigate(`/admin/invoice/${row.id}`)} />
                              </Tooltip>
                            </MuiThemeProvider>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {/* {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )} */}
                </TableBody>

                {orderData && orderData.length <= 0 && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                          Opps! No Record found
                          </Typography>

                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              component="div"
              count={orderList?.pagination?.totalItems}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Grid>
          <Popover
            open={Boolean(open)}
            anchorEl={open}
            onClose={handleCloseMenu}
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            PaperProps={{
              sx: {
                p: 1,
                width: 140,
                '& .MuiMenuItem-root': {
                  px: 1,
                  typography: 'body2',
                  borderRadius: 0.75,
                },
              },
            }}
          >
            <MenuItem>
              <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
              Edit
            </MenuItem>

            <MenuItem sx={{ color: 'error.main' }}>
              <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
              Delete
            </MenuItem>
          </Popover>
          {/* </Container> */}

          {/* Add Product Form */}
          <Drawer anchor={'right'} sx={{"& .MuiPaper-root": {
      width: '50%'
    }}} open={openDrawer} onClose={() => setOpenDrawer(false)} className="drawerStyle">
            <Product setOpenDrawer={setOpenDrawer} />
          </Drawer>
        </Grid>
      </Container>
    </>
  );
}

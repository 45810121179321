import axiosInstance from '../axios';

export const FETCH_FILTER_REQUEST = 'FETCH_FILTER_REQUEST';
export const FETCH_FILTER_SUCCESS = 'FETCH_FILTER_SUCCESS';
export const FETCH_FILTER_FAILURE = 'FETCH_FILTER_FAILURE';

export const fetchFilterRequest = () => ({
  type: FETCH_FILTER_REQUEST,
});

// Action creator for the success state
export const fetchFilterSuccess = (data) => ({
  type: FETCH_FILTER_SUCCESS,
  payload: data,
});

// Action creator for the failure state
export const fetchFilterFailure = (error) => ({
  type: FETCH_FILTER_FAILURE,
  payload: error,
});

export const fetchFilterOptions = (filter) => (dispatch) => {
  dispatch(fetchFilterRequest());
  axiosInstance(`/product/filter-options`, 'GET', null)
    .then((response) => {
      // Dispatch success action with the received data
      let newData = {}
      for (const key of Object.keys(response.data.data)) {
        if (Array.isArray(response.data.data[key])) {
        newData = {...newData, [key]:{data: response.data.data[key], selectedOption:[] }}
      } else {
        newData = {...newData, [key]:{data: response.data.data[key], selectedOption: response.data.data[key]}}
        }
      }
     if(filter?.search ){
      newData.search = filter.search
     }
      dispatch(fetchFilterSuccess(newData));
    })
    .catch((error) => {
      // Dispatch failure action with the error message
      dispatch(fetchFilterFailure(error.message));
    });
};

export const filterOptionsSet = (filter) => (dispatch) =>{
  dispatch(fetchFilterSuccess(filter));
}



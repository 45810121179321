import React, { useEffect, useState } from 'react';
import { Checkbox } from '@mui/material';
import './addCoupon.css';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import axiosInstance from '../../axios';
import Notification from '../notification';

function AddCoupon({ setOpenDrawer, couponListAPI, addEdit, id }) {
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
  const [addCoupon, setAddCoupon] = useState({
    name: '',
    code: '',
    start_date: '',
    expiry_date: '',
    type: false,
    discount: null,
    minimum_amount: null,
    is_active: false,
  });
  const validationSchema = Yup.object({
    name: Yup.string().required('Coupon Name is required').trim(),
    code: Yup.string().required('Coupon Code is required').trim(),
    start_date: Yup.string().required('Coupon Start Date is required').trim(),
    expiry_date: Yup.string().required('Coupon End Date is required').trim(),
    // type: Yup.string().required('Discount Type is required').trim(),
    // discount: Yup.number().min(1).max(100).required('Coupon Discount is required'),
    minimum_amount: Yup.string().required('Amount is required').trim(),
  });

  const handleBtnSubmit = async (values) => {
    if (addEdit && addEdit !== 'edit') {
      const response = await axiosInstance(`/offer/`, 'POST', {
        ...values,
        type: values.type === true ? 'percentage' : 'fixed',
      });
      if (response.status === 200) {
        await Notification('success', 'Add Coupon', response.data.message, 2000);
        setOpenDrawer(false);
        setAddCoupon({
          name: '',
          code: '',
          start_date: '',
          expiry_date: '',
          type: '',
          discount: null,
          minimum_amount: null,
          is_active: false,
        });
        await couponListAPI();
      } else {
        await Notification('error', 'Add Coupon', response.data.message, 2000);
      }
    }
  };

  useEffect(() => {
    // product list get by id
    if (addEdit && addEdit === 'edit') {
      const fetchData = async () => {
        const res = await axiosInstance(`/offer/${id}`, 'GET', null);
        if (res.status === 200) {
          if (res.data.data) {
            const data = res.data.data;
            setAddCoupon({
              name: data.name,
              code: data.code,
              start_date: moment(data.start_date).format('YYYY-MM-DD'),
              expiry_date: moment(data.expiry_date).format('YYYY-MM-DD'),
              type: data.type === 'percentage',
              discount: data.discount,
              minimum_amount: data.minimum_amount,
              is_active: data.is_active,
            });
          }
        }
      };
      fetchData();
    }
  }, []);

  const handleUpdate = async (values) => {
    const response = await axiosInstance(`/offer/${id}`, 'PUT', {
      ...values,
      type: values.type === true ? 'percentage' : 'fixed',
    });
    if (response.status === 200) {
      setAddCoupon({
        name: '',
        code: '',
        start_date: '',
        expiry_date: '',
        type: false,
        discount: null,
        minimum_amount: null,
        is_active: false,
      });
      setOpenDrawer(false);
      await couponListAPI();
      await Notification('success', 'Edit Coupon', response.data.message, 2000);
    } else {
      await Notification('error', 'Edit Coupon', response.response.data.message, 2000);
    }
  };
  return (
    <div>
      <button
        className="fixed focus:outline-none z-50 text-red-500 hover:bg-red-100 hover:text-gray-700 transition-colors duration-150 bg-white shadow-md mr-6 mt-2 right-0 left-auto w-8 h-8 rounded-full block text-center z-30 mr-7"
        onClick={() => setOpenDrawer(false)}
      >
        <svg
          stroke="currentColor"
          fill="none"
          strokeWidth="2"
          viewBox="0 0 24 24"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="mx-auto"
          height="1em"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
        >
          <line x1="18" y1="6" x2="6" y2="18" />
          <line x1="6" y1="6" x2="18" y2="18" />
        </svg>
      </button>
      <div className="w-full relative p-border-gray-100 bg-gray-50 border-gray-700 bg-white-800 text-black-300 model-title">
        <div>
          {addEdit && addEdit !== 'edit' ? (
            <>
              <h4 className="text-xl font-medium">Add Coupon</h4>
              <p className="mb-0 text-sm">Add your Coupon and necessary information from here</p>
            </>
          ) : (
            <>
              <h4 className="text-xl font-medium">Add Coupon</h4>
              <p className="mb-0 text-sm">Add your Coupon and necessary information from here</p>
            </>
          )}
        </div>
      </div>
      <div className="mt-12 flex flex-col w-full h-full justify-between">
        <div
          className="w-full md:w-7/12 lg:w-8/12 xl:w-8/12 relative bg-white-700 text-gray-200"
          style={{ position: 'relative', overflow: 'hidden', width: '100%', height: '100%' }}
        >
          <div
            style={{
              // position: 'absolute',
              inset: '0px',
              overflowY: 'scroll',
              marginRight: '-21px',
              marginBottom: '-21px',
            }}
          >
            <Formik
              enableReinitialize
              initialValues={addCoupon}
              validationSchema={validationSchema}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                handleBtnSubmit(values);
                // setSubmitting(true);
                // resetForm();
                // setSubmitting(false);
              }}
            >
              {({ field, values, errors, touched, handleBlur, handleChange, handleSubmit, isValid, isSubmitting }) => (
                <Form className="block">
                  <div className="px-6 pt-8 flex-grow w-full h-full pb-40 max-h-full md:pb-32 lg:pb-32 xl:pb-32">
                    <div className="flex grid-cols-6 gap-3 md:gap-5 xl:gap-6 lg:gap-6 mb-6 formLabel">
                      <label className="block text-sm text-gray-700 dark:text-gray-400 col-span-4 sm:col-span-2 font-medium text-sm">
                        Coupon Name <span className="required-field">*</span>
                      </label>
                      <div className="col-span-8 sm:col-span-4 formInput">
                        <input
                          className={
                            errors.name && touched.name
                              ? 'block w-full px-3 py-1 text-sm focus:outline-none text-black  leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white input-error'
                              : 'block w-full px-3 py-1 text-sm focus:outline-none text-black  leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white'
                          }
                          type="text"
                          name="name"
                          placeholder="Coupon Name"
                          value={values.name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {touched.name && errors.name && <div className="error-message">{errors.name}</div>}
                      </div>
                    </div>
                    <div className="flex grid-cols-6 gap-3 md:gap-5 xl:gap-6 lg:gap-6 mb-6 formLabel">
                      <label className="block text-sm text-gray-700 dark:text-gray-400 col-span-4 sm:col-span-2 font-medium text-sm">
                        Coupon Code <span className="required-field">*</span>
                      </label>
                      <div className="col-span-8 sm:col-span-4 formInput">
                        <input
                          className={
                            errors.code && touched.code
                              ? 'block w-full px-3 py-1 text-sm focus:outline-none text-black leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white input-error'
                              : 'block w-full px-3 py-1 text-sm focus:outline-none text-black leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white'
                          }
                          type="text"
                          name="code"
                          value={values.code}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="Coupon Code"
                        />
                        {touched.code && errors.code && <div className="error-message">{errors.code}</div>}
                      </div>
                    </div>
                    <div className="flex grid-cols-6 gap-3 md:gap-5 xl:gap-6 lg:gap-6 mb-6 formLabel">
                      <label className="block text-sm text-gray-700 dark:text-gray-400 col-span-4 sm:col-span-2 font-medium text-sm">
                        Coupon Start Date <span className="required-field">*</span>
                      </label>
                      <div className="col-span-8 sm:col-span-4 formInput">
                        <input
                          className={
                            errors.start_date && touched.start_date
                              ? 'block w-full px-3 py-1 text-sm focus:outline-none text-black leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white input-error'
                              : 'block w-full px-3 py-1 text-sm focus:outline-none text-black leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white'
                          }
                          type="date"
                          name="start_date"
                          value={values.start_date}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="Coupon Validity Time"
                        />
                        {touched.start_date && errors.start_date && (
                          <div className="error-message">{errors.start_date}</div>
                        )}
                      </div>
                    </div>
                    <div className="flex grid-cols-6 gap-3 md:gap-5 xl:gap-6 lg:gap-6 mb-6 formLabel">
                      <label className="block text-sm text-gray-700 dark:text-gray-400 col-span-4 sm:col-span-2 font-medium text-sm">
                        Coupon Expiry Date <span className="required-field">*</span>
                      </label>
                      <div className="col-span-8 sm:col-span-4 formInput">
                        <input
                          className={
                            errors.expiry_date && touched.expiry_date
                              ? 'block w-full px-3 py-1 text-sm focus:outline-none text-black leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white input-error'
                              : 'block w-full px-3 py-1 text-sm focus:outline-none text-black leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white'
                          }
                          type="date"
                          name="expiry_date"
                          value={values.expiry_date}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="Coupon Validity Time"
                        />
                        {touched.expiry_date && errors.expiry_date && (
                          <div className="error-message">{errors.expiry_date}</div>
                        )}
                      </div>
                    </div>
                    <div className="flex grid-cols-6 gap-3 md:gap-5 xl:gap-6 lg:gap-6 mb-6 formLabel">
                      <label className="block text-sm text-gray-700 dark:text-gray-400 col-span-4 sm:col-span-2 font-medium text-sm">
                        Discount Type
                      </label>
                      <div className="col-span-8 sm:col-span-4 formInput">
                        <div className="toggle-switch">
                          <input
                            type="checkbox"
                            className={
                              errors.type && touched.type
                                ? 'toggle-switch-checkbox input-error'
                                : 'toggle-switch-checkbox'
                            }
                            name="type"
                            checked={values.type}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            id="toggleSwitch"
                          />
                          <label className="toggle-switch-label" htmlFor="toggleSwitch">
                            <span className="toggle-switch-inner" />
                            <span className="toggle-switch-switch" />
                          </label>
                        </div>
                        {touched.type && errors.type && <div className="error-message">{errors.type}</div>}
                      </div>
                    </div>
                    <div className="flex grid-cols-6 gap-3 md:gap-5 xl:gap-6 lg:gap-6 mb-6 formLabel">
                      <label className="block text-sm text-gray-700 dark:text-gray-400 col-span-4 sm:col-span-2 font-medium text-sm">
                        Discount <span className="required-field">*</span>
                      </label>
                      <div className="col-span-8 sm:col-span-4 formInput">
                        <input
                          className={
                            errors.discount && touched.discount
                              ? 'block w-full px-3 py-1 text-sm focus:outline-none text-black leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white input-error'
                              : 'block w-full px-3 py-1 text-sm focus:outline-none text-black leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white'
                          }
                          type="number"
                          onWheel={(e) => e.target.blur()}
                          name="discount"
                          value={values.discount}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="Discount"
                        />
                        {touched.discount && errors.discount && <div className="error-message">{errors.discount}</div>}
                      </div>
                    </div>
                    <div className="flex grid-cols-6 gap-3 md:gap-5 xl:gap-6 lg:gap-6 mb-6 formLabel">
                      <label className="block text-sm text-gray-700 dark:text-gray-400 col-span-4 sm:col-span-2 font-medium text-sm">
                        Minimum Amount <span className="required-field">*</span>
                      </label>
                      <div className="col-span-8 sm:col-span-4 formInput">
                        <input
                          className={
                            errors.minimum_amount && touched.minimum_amount
                              ? 'block w-full px-3 py-1 text-sm focus:outline-none text-black leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white input-error'
                              : 'block w-full px-3 py-1 text-sm focus:outline-none text-black leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white'
                          }
                          type="number"
                          onWheel={(e) => e.target.blur()}
                          name="minimum_amount"
                          value={values.minimum_amount}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="Minimum Amount"
                        />
                        {touched.minimum_amount && errors.minimum_amount && (
                          <div className="error-message">{errors.minimum_amount}</div>
                        )}
                      </div>
                    </div>
                    <div className="flex grid-cols-6 gap-3 md:gap-5 xl:gap-6 lg:gap-6 mb-6 formLabel">
                      <label className="block text-sm text-gray-700 dark:text-gray-400 col-span-4 sm:col-span-2 font-medium text-sm">
                        Published
                      </label>
                      <div className="col-span-8 sm:col-span-4 formInput">
                        <div className="toggle">
                          <input
                            type="checkbox"
                            id="a"
                            name="is_active"
                            checked={values.is_active}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <label htmlFor="a">
                            <span className="sr-only">Toggle Switch</span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="px-6 pt-8 fixed bottom-0  right-0 py-4 lg:py-8 px-6 grid gap-4 lg:gap-6 xl:gap-6 md:flex xl:flex bg-gray-50 border-gray-100 border-gray-700 bg-white-800 text-black-300 cancel-btn">
                    <div className="flex-grow-0 md:flex-grow lg:flex-grow xl:flex-grow btn-style">
                      <button
                        className="align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-gray-600 border-gray-200 border text-gray-400 focus:outline-none rounded-lg border border-gray-200 px-4 w-full mr-3 flex items-center justify-center cursor-pointer h-12 bg-gray-200 h-12 bg-white w-full text-red-500 hover:bg-red-50 hover:border-red-100 hover:text-red-600 bg-gray-700  text-gray-500 hover:bg-gray-800 hover:text-red-700"
                        type="button"
                        onClick={() => setOpenDrawer(false)}
                      >
                        Cancel
                      </button>
                    </div>
                    <div className="flex-grow-0 md:flex-grow lg:flex-grow xl:flex-grow btn-style">
                      <button
                        className="align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-white border border-transparent common-background focus:ring focus:ring-green-300 w-full h-12"
                        type="submit"
                        onClick={addEdit && addEdit === 'edit' ? () => handleUpdate(values) : ''}
                      >
                        {' '}
                        {addEdit && addEdit !== 'edit' ? <span>Add Coupon</span> : <span>Edit Coupon</span>}
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          <div
            style={{
              position: 'absolute',
              height: '6px',
              right: '2px',
              bottom: '2px',
              left: '2px',
              borderRadius: '3px',
            }}
          >
            <div
              style={{
                position: 'relative',
                display: 'block',
                height: '100%',
                cursor: 'pointer',
                borderRadius: 'inherit',
                backgroundColor: 'rgba(0, 0, 0, 0.2)',
                width: '0px',
              }}
            />
          </div>
          {/* <div
            style={{ position: 'absolute', width: '6px', right: '2px', bottom: '2px', top: '2px', borderRadius: '3px' }}
          >
            <div
              style={{
                position: 'relative',
                display: 'block',
                width: '100%',
                cursor: 'pointer',
                borderradius: 'inherit',
                backgroundColor: 'rgba(0, 0, 0, 0.2)',
                height: '359px',
                transform: 'translateY(0px)',
              }}
            />
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default AddCoupon;

import Notification from '../components/notification';
import axiosInstance from '../axios';

export const GET_ORDER_REQUEST = 'GET_ORDER_REQUEST';
export const GET_ORDER_SUCCESS = 'GET_ORDER_SUCCESS';
export const GET_ORDER_FAILURE = 'GET_ORDER_FAILURE';
export const GET_ORDER_DETAILS_REQUEST = 'GET_ORDER_DETAILS_REQUEST';
export const GET_ORDER_DETAILS_SUCCESS = 'GET_ORDER_DETAILS_SUCCESS';
export const GET_ORDER_DETAILS_FAILURE = 'GET_ORDER_DETAILS_FAILURE';

export const fetchOrderRequest = () => ({
  type: GET_ORDER_REQUEST,
});

// Action creator for the success state
export const fetchOrderSuccess = (data) => ({
  type: GET_ORDER_SUCCESS,
  payload: data,
});

// Action creator for the failure state
export const fetchOrderFailure = (error) => ({
  type: GET_ORDER_FAILURE,
  payload: error,
});

export const fetchOrderDetailRequest = () => ({
  type: GET_ORDER_DETAILS_REQUEST,
});

// Action creator for the success state
export const fetchOrderDetailSuccess = (data) => ({
  type: GET_ORDER_DETAILS_SUCCESS,
  payload: data,
});

// Action creator for the failure state
export const fetchOrderDetailFailure = (error) => ({
  type: GET_ORDER_DETAILS_FAILURE,
  payload: error,
});

// Action creator for initiating the API call
export const getOrderList = (filter) => (dispatch) => {
  dispatch(fetchOrderSuccess());
  axiosInstance(`/order/get-all-order`, 'POST', filter)
    .then((response) => {
      // Dispatch success action with the received data
      dispatch(fetchOrderSuccess(response.data.data));
    })
    .catch((error) => {
      // Dispatch failure action with the error message
      dispatch(fetchOrderFailure(error.message));
    });
  //   const response = axiosInstance(`/order/get-all-order`, 'get');
  //   console.log('response', response);
  //   if (response?.data?.status === 200) {
  //     dispatch(fetchOrderSuccess(response.data.data));
  //     Notification('success', 'Delete Product', response.data.message, 2000);
  //   } else {
  //     Notification('error', 'Delete Product', response.data.message, 2000);
  //   }
};
export const getOrderDetails = () => (dispatch) => {
  dispatch(fetchOrderDetailRequest());
  axiosInstance(`/order/dashboard-card`, 'GET')
    .then((response) => {
      // Dispatch success action with the received data
      dispatch(fetchOrderDetailSuccess(response.data.data));
    })
    .catch((error) => {
      // Dispatch failure action with the error message
      dispatch(fetchOrderDetailFailure(error.message));
    });
  //   const response = axiosInstance(`/order/get-all-order`, 'get');
  //   console.log('response', response);
  //   if (response?.data?.status === 200) {
  //     dispatch(fetchOrderSuccess(response.data.data));
  //     Notification('success', 'Delete Product', response.data.message, 2000);
  //   } else {
  //     Notification('error', 'Delete Product', response.data.message, 2000);
  //   }
};

import { Box, Button, Card, Container, Flex } from "@chakra-ui/react";
import React, { useState } from "react";
import { AiFillFacebook, AiOutlineMail } from "react-icons/ai";
import { BiTimeFive } from "react-icons/bi";
import { BsInstagram, BsLinkedin, BsYoutube } from "react-icons/bs";
import { FaTwitter } from "react-icons/fa";
import { GiChainMail } from "react-icons/gi";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import Footer from "src/components/footer/Footer";
import Header from "src/components/header";
import "./aboutUs.css";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axiosInstance from "src/axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { notificationStyle } from "../../product-list/ProductListing";


const validationSchema = Yup.object({
  first_name: Yup.string().required('First Name is required').trim(),
  last_name: Yup.string().required('Last Name is required').trim(),
  email: Yup.string()
    .required('Email is required')
    .email('Invalid email address')
    .trim(),
  phone_number: Yup.string()
    .required('Phone Number is required')
    .matches(/^\d{10}$/, 'Phone Number must be 10 digits')
    .trim(),
  message: Yup.string().required('Message is required').trim(),
});

function ContactUs() {
  const navigate = useNavigate();
  const { homePageData } = useSelector((state) => state.home);

  const formik = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      email: '',
      phone_number: '',
      message: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      // Handle form submission here (e.g., send data to a server)
      console.log('Form values:', values);
      const submitConatct = await axiosInstance(`/user/contactus`, 'POST', values)
      console.log("submitConatct", submitConatct);
      if (submitConatct.status === 200) {
        toast.success("Form submitted successfully", notificationStyle);
        resetForm();
      } else {
        toast.error("Error sending form data to admin", notificationStyle);
      }
    },
  });

  return (
    <>
      <Header />
      <Card
        // width={{ md: "100%", lg: "80%" }}
        width={{ md: "100%", lg: "fit-content" }}
        boxShadow={" rgba(0, 0, 0, 0.1) 0px 4px 12px"}
        background={"white"}
        padding={{ md: 10, lg: 10, base: 0, sm: 0 }}
        margin={{ lg: "auto" }}
        marginTop={{ lg: "45px", base: "150px" }}
        marginInline={{ base: "10px" }}
      >
        <div className="contactForm">
          <div className="contact-form-main">
            <h1 className="contact-title">Just say Hello!</h1>
            <p className="contact-desc">Let us know more about you !</p>
            {/* <div className="contact-form-main"> */}
            <form className="" onSubmit={formik.handleSubmit}>
              {/* First Name */}
              <div className="mb-4 md:flex md:mb-0 md:justify-between w-full">
                <div className="mb-4 md:mr-2 md:mb-0 md:w-1/2">
                  <label htmlFor="first_name" className="common-userPaneFamily">First Name <span className="required-field">*</span></label>
                  <input
                    type="text"
                    id="first_name"
                    name="first_name"
                    className={
                      "w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline px-3 py-1 text-sm focus:outline-none dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none  bg-gray-100 dark:bg-white border-transparent focus:bg-white common-userPaneFamily " +
                      (formik.touched.first_name && formik.errors.first_name
                        ? 'border-red-500' // Apply red border for validation error
                        : '')
                    }
                    placeholder="First Name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.first_name}
                  />
                  {formik.touched.first_name && formik.errors.first_name && (
                    <div className="error-message">{formik.errors.first_name}</div>
                  )}
                </div>

                {/* Last Name */}
                <div className="mb-4 md:ml-2 md:w-1/2">
                  <label htmlFor="last_name" className="common-userPaneFamily">Last Name <span className="required-field">*</span></label>
                  <input
                    type="text"
                    id="last_name"
                    name="last_name"
                    className={
                      "w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline px-3 py-1 text-sm focus:outline-none dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none  bg-gray-100 dark:bg-white border-transparent focus:bg-white common-userPaneFamily " +
                      (formik.touched.last_name && formik.errors.last_name
                        ? 'border-red-500' // Apply red border for validation error
                        : '')
                    }
                    placeholder="Last Name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.last_name}
                  />
                  {formik.touched.last_name && formik.errors.last_name && (
                    <div className="error-message">{formik.errors.last_name}</div>
                  )}
                </div>
              </div>

              {/* Email */}
              <div className="mb-4 md:flex md:mb-0 md:justify-between w-full">
                <div className="mb-4 md:mr-2 md:mb-0 md:w-1/2">
                  <label htmlFor="email" className="common-userPaneFamily">Email <span className="required-field">*</span></label>
                  <input
                    type="text"
                    id="email"
                    name="email"
                    className={
                      "w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline px-3 py-1 text-sm focus:outline-none dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none  bg-gray-100 dark:bg-white border-transparent focus:bg-white common-userPaneFamily " +
                      (formik.touched.email && formik.errors.email
                        ? 'border-red-500' // Apply red border for validation error
                        : '')
                    }
                    placeholder="Email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                  />
                  {formik.touched.email && formik.errors.email && (
                    <div className="error-message">{formik.errors.email}</div>
                  )}
                </div>

                {/* Phone Number */}
                <div className="mb-4 md:ml-2 md:w-1/2">
                  <label htmlFor="phone_number" className="common-userPaneFamily">Phone Number <span className="required-field">*</span></label>
                  <input
                    type="text"
                    id="phone_number"
                    name="phone_number"
                    className={
                      "w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline px-3 py-1 text-sm focus:outline-none dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none  bg-gray-100 dark:bg-white border-transparent focus:bg-white common-userPaneFamily " +
                      (formik.touched.phone_number && formik.errors.phone_number
                        ? 'border-red-500' // Apply red border for validation error
                        : '')
                    }
                    placeholder="Phone Number"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.phone_number}
                  />
                  {formik.touched.phone_number && formik.errors.phone_number && (
                    <div className="error-message">{formik.errors.phone_number}</div>
                  )}
                </div>
              </div>

              {/* Message */}
              <div className="mb-4 md:flex md:mb-0 w-full">
                <div className="mb-4 md:mr-2 md:mb-0 w-full">
                  <label htmlFor="message" className="common-userPaneFamily">Message <span className="required-field">*</span></label>
                  <textarea
                    id="message"
                    name="message"
                    className={
                      "block w-full text-sm pl-2 pt-2 dark:text-gray-300 rounded-md focus:outline-none text-black form-textarea focus:border-green-400 border-gray-300 dark:border-gray-600 dark:focus:border-gray-600 dark:bg-gray-700 dark:focus:ring-gray-300 focus:ring focus:ring-green-300 border text-sm focus:outline-none block w-full bg-gray-100 border-transparent focus:bg-white " +
                      (formik.touched.message && formik.errors.message
                        ? 'border-red-500' // Apply red border for validation error
                        : '')
                    }
                    rows="6"
                    placeholder="Message"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.message}
                  />
                  {formik.touched.message && formik.errors.message && (
                    <div className="error-message">{formik.errors.message}</div>
                  )}
                </div>
              </div>

              {/* Submit Button */}
              <Button className="contact-submit common-userPaneFamily" type="submit">
                Submit
              </Button>
            </form>
            {/* </div> */}
          </div>
          <div className="contact-second-sec">
            <h1 className="contact-sec-title">Contact Information</h1>
            <div className="contact-margin">
              <div
                style={{
                  display: "flex",
                  gap: "15px",
                  marginBottom: "20px",
                  alignItems: "center",
                }}
              >
                <AiOutlineMail
                  fontSize={"40px"}
                  className="fontSize-responsive"
                  color="#ed691f"
                />
                <div style={{ display: "grid" }}>
                  <p className="contact-info">canonquartzofficial@gmail.com</p>
                  <p className="contact-info">shrisaisales@mail.com</p>
                  <p className="contact-info">info@canonquartz.com</p>
                </div>
              </div>
              <p className="contact-info">
                <div
                  style={{ display: "flex", gap: "15px", alignItems: "center" }}
                >
                  <BiTimeFive
                    fontSize={"40px"}
                    className="fontSize-responsive"
                    color="#ed691f"
                  />
                  <div style={{ display: "grid" }}>
                    <p className="common-userPaneFamily">Operating Hours: </p>
                    <p className="common-userPaneFamily">10:00AM to 7:00PM Monday to Saturday</p>
                  </div>
                </div>
              </p>
            </div>
            <div style={{ marginTop: "50px" }} className="contact-margin">
              <h1 className="contact-sec-title">Follow Us</h1>
              <Box
                // border={"1px solid gray"}
                alignItems="center"
              >
                <Flex alignItems="center" margin="auto">
                  <Box>
                    <div className="footer-icons">
                      <AiFillFacebook
                        fontSize={'24px'}
                        onMouseOver={({ target }) => (target.style.color = '#ed691f')}
                        onMouseOut={({ target }) => (target.style.color = 'rgb(79, 79, 79)')}
                        onClick={() => {
                          const facebookLink = homePageData?.setting?.facebook;
                          if (facebookLink) {
                            window.open(facebookLink, '_blank');
                          }
                        }}
                      />
                    </div>
                  </Box>
                  <Box>
                    <div className="footer-icons">
                      <FaTwitter
                        fontSize={'24px'}
                        onMouseOver={({ target }) => (target.style.color = '#ed691f')}
                        onMouseOut={({ target }) => (target.style.color = 'rgb(79, 79, 79)')}
                        onClick={() => {
                          const twitterLink = homePageData?.setting?.twitter;
                          if (twitterLink) {
                            window.open(twitterLink, '_blank');
                          }
                        }}
                      />
                    </div>
                  </Box>
                  <Box>
                    <div className="footer-icons">
                      <BsInstagram
                        fontSize={'24px'}
                        onMouseOver={({ target }) => (target.style.color = '#ed691f')}
                        onMouseOut={({ target }) => (target.style.color = 'rgb(79, 79, 79)')}
                        onClick={() => {
                          const instagramLink = homePageData?.setting?.instagram;
                          if (instagramLink) {
                            window.open(instagramLink, '_blank');
                          }
                        }}
                      />
                    </div>
                  </Box>
                  <Box>
                    <div className="footer-icons">
                      <BsLinkedin
                        fontSize={'24px'}
                        onMouseOver={({ target }) => (target.style.color = '#ed691f')}
                        onMouseOut={({ target }) => (target.style.color = 'rgb(79, 79, 79)')}
                        onClick={() => {
                          const linkedinLink = homePageData?.setting?.linkedin;
                          if (linkedinLink) {
                            window.open(linkedinLink, '_blank');
                          }
                        }}
                      />
                    </div>
                  </Box>
                </Flex>
              </Box>
            </div>
          </div>
        </div>
      </Card>
      <Footer />
    </>
  );
}

export default ContactUs;
import { Box, HStack, Icon, Image, Link, Stack, Text, useColorModeValue as mode } from '@chakra-ui/react';
import { FiGift } from 'react-icons/fi';
import './cartPage.css';
import { useNavigate } from 'react-router-dom';

export const CartProductMeta = (props) => {
  const navigate = useNavigate();
  const { image, name, product, id,slug } = props;
  return (
    <Stack direction="row" spacing="5" width="full">
      <Image
        // rounded="lg"
        width="120px"
        height="120px"
        fit="cover"
        src={image}
        alt={name}
        draggable="false"
        loading="lazy"
        onClick={() => navigate(`/product-details/${slug}`)}
        cursor={'pointer'}
      />
      <Box pt="4">
        <Stack spacing="0.5">
          <Text
            className="common-userPaneFamily wrap-word2"
            fontSize={'15px'}
            fontWeight={600}
            color={'rgb(79, 79, 79)'}
            onClick={() => navigate(`/product-details/${slug}`)}
            cursor={'pointer'}
          >
            {name}
          </Text>
          <Text
            fontSize={'15px'}
            fontWeight={600}
            color={'rgb(79, 79, 79)'}
            className="common-userPaneFamily wrap-word2"
          >
            {product.discount !== null && product.discount !== '' ? (
              <>
                <span
                  style={{ color: '#9fa2a3', textDecoration: 'line-through', fontSize: '13px', marginRight: '5px' }}
                >
                  ₹{product.price}
                </span>
                <span>₹</span>
                <span>
                  {Number(product.price) - ((Number(product.price) * Number(product.discount)) / 100).toFixed(2)}
                </span>
              </>
            ) : (
              <>
                <span>₹</span>
                <span>{product.price}</span>
              </>
            )}
          </Text>
        </Stack>
      </Box>
    </Stack>
  );
};

import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  Heading,
  Image,
  Link,
  SimpleGrid,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tr,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { TbTruckDelivery } from "react-icons/tb";
import moment from "moment";
import { getUserOrderListById } from "../../../actions/UserOrderActions";
import cardIcon from "../../../assets/card.svg";
import Footer from "../../../components/footer/Footer";
import Header from "../../../components/header";
import "./orderDetails.css";
import TrackPage from "./TrackPage";
import { handleCancleOrder } from "../success-page/SuccessPage";
import Loader from "../../../components/loader/Loader";
import noImage from "../../../assets/noImageAvailable.jpg";

function OrderDetails() {
  const { orderById } = useSelector((state) => state.userOrder);
  const [loader, setLoader] = useState(false);
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { homePageData } = useSelector((state) => state.home);

  useEffect(() => {
    dispatch(getUserOrderListById(params.id));
  }, []);
  return (
    <div>
      <Header />
      <Box height={loader ? "100%" : ""}>
        <Loader loading={loader} />
        <div style={{ opacity: loader ? 0.3 : "" }}>
          <Box
            display={"flex"}
            marginTop={10}
            className="details-box"
            width={"100%"}
            alignItems={"center"}
          >
            <Box padding={8} width={"100%"} className="order-details-container">
              <Box marginLeft={{ md: "17px" }} marginBottom={{ base: "15px" }}>
                <Text
                  size={{ md: "lg", base: "15px" }}
                  fontWeight="semibold"
                  fontSize={{ md: "25px", base: "20px" }}
                  className="common-userPaneFamily"
                >
                  Order Id : {orderById?.order_id}
                </Text>
                <Flex
                  justifyContent={{ md: "space-between" }}
                  className="order-estimate"
                  width={{ md: "59%", base: "100%" }}
                  alignItems={"center"}
                >
                  <Text fontSize={"13px"}>
                    Order Date:{" "}
                    {moment(orderById?.order_date).format("Do MMM YYYY")}
                  </Text>
                  {orderById?.delivery_current_status !== "Cancelled" &&
                  orderById?.delivery_current_status !== "Delivered" &&
                  orderById?.delivery_date !== null &&
                  orderById?.delivery_date !== "" ? (
                    <Text
                      fontSize={{ md: "16px" }}
                      className="estimate-text"
                      fontWeight={"bold"}
                      gap={"10px"}
                      display={"flex"}
                    >
                      <TbTruckDelivery
                        fontSize="25px"
                        className="estimate-icon"
                      />
                      Estimated delivery:{orderById?.delivery_date}
                    </Text>
                  ) : (
                    ""
                  )}
                </Flex>
              </Box>
              <Box
                display={{ md: "flex", base: "block", sm: "block" }}
                justifyContent={"start"}
              >
                <Grid
                  // templateColumns={{
                  //   md: 'repeat(2, 1fr)',
                  //   lg: 'repeat(2, 1fr)',
                  //   base: 'repeat(1, 1fr)',
                  //   sm: 'repeat(1, 1fr)',
                  // }}
                  width={{ md: "65%", base: "100%" }}
                  // margin={{ md: '20px' }}
                  className="common-borderRadius"
                >
                  <Grid
                    templateColumns={{
                      md: "repeat(2, 1fr)",
                      lg: "repeat(2, 1fr)",
                      base: "repeat(1, 1fr)",
                      sm: "repeat(1, 1fr)",
                    }}
                    width={{ md: "90%", base: "100%" }}
                    height={"fit-content"}
                    borderWidth="1px"
                    margin={{ md: "20px" }}
                    className="common-borderRadius"
                  >
                    <Stack
                      spacing="8"
                      //   borderWidth="1px"
                      rounded="lg"
                      padding={{ md: 8, base: 4 }}
                      className="delivery-box"
                      // width={{ md: '45%', lg: '32%', base: '100%', sm: '100%' }}
                      marginTop={{ base: "10px", sm: "10px" }}
                      height={"-webkit-fit-content"}
                    >
                      <Text
                        size={{ md: "lg", base: "15px" }}
                        fontWeight="semibold"
                        fontSize={"20px"}
                        className="common-userPaneFamily"
                      >
                        Delivery
                      </Text>
                      <Stack spacing="6">
                        <Text
                          fontSize="19px"
                          display={"grid"}
                          className="common-userPaneFamily shipping-add2"
                        >
                          {orderById?.address || orderById?.User.address}
                          <span>
                            {orderById?.city || orderById?.User.city},
                            {orderById?.state || orderById?.User.state},{" "}
                            {orderById?.zip_code || orderById?.User.zip_code}
                          </span>
                          <span>
                            {orderById?.country || orderById?.User.country}
                          </span>
                          <span>
                            Ph.
                            {orderById?.phone_number ||
                              orderById?.User.phone_number}
                          </span>
                        </Text>
                      </Stack>
                    </Stack>
                    <Stack
                      spacing="8"
                      //   borderWidth="1px"
                      rounded="lg"
                      className="delivery-box"
                      padding={{ md: 8, base: 4 }}
                      // width={{ md: '45%', lg: '32%', base: '100%', sm: '100%' }}
                      marginTop={{ base: "10px", sm: "10px" }}
                      height={"-webkit-fit-content"}
                    >
                      <Text
                        size="lg"
                        fontWeight="semibold"
                        fontSize={"20px"}
                        className="common-userPaneFamily"
                      >
                        Payment
                      </Text>
                      <Stack spacing="6">
                        <Text
                          fontSize="19px"
                          display={"grid"}
                          className="common-userPaneFamily shipping-add"
                        >
                          <Flex alignItems={"center"}>
                            <Image
                              src={cardIcon}
                              height={"50px"}
                              width={"50px"}
                            />{" "}
                            <span style={{ marginLeft: "10px" }}>
                              *****
                              {orderById &&
                                JSON.parse(orderById?.payment_method)?.card
                                  ?.last4}
                            </span>
                          </Flex>
                          {/* <span>Paris, 750008</span> */}
                          {/* <span>France</span>
              <span>Ph.90928-92901</span> */}
                        </Text>
                      </Stack>
                    </Stack>
                  </Grid>
                  {orderById &&
                  orderById?.delivery_current_status === "Cancelled" ? (
                    <>
                      <Stack
                        position={"relative"}
                        borderWidth="1px"
                        spacing="8"
                        margin={{ md: "20px" }}
                        marginTop={{ base: "15px" }}
                        width={{ md: "90%" }}
                        //   borderWidth="1px"
                        rounded="lg"
                        padding={{ md: 8, base: 4 }}
                        className="common-borderRadius"
                      >
                        <Box opacity={0.3}>
                          <Text
                            size={{ md: "lg", base: "15px" }}
                            fontWeight="semibold"
                            fontSize={"20px"}
                            className="common-userPaneFamily"
                          >
                            Track Item
                          </Text>
                          <Text>
                            Tracking no:{" "}
                            {orderById && orderById?.tracking_number}
                          </Text>
                        </Box>
                        <Box textAlign={"center"}>
                          <Text color={"#ed691f"}>
                            Order has been Cancelled
                          </Text>
                        </Box>
                        {/* <Box
                          marginTop={6}
                          opacity={0.3}
                          className="track-responsive"
                        >
                          <TrackPage data={orderById} />
                        </Box> */}
                      </Stack>
                    </>
                  ) : (
                    <Stack
                      borderWidth="1px"
                      spacing="8"
                      margin={{ md: "20px" }}
                      marginTop={{ base: "15px" }}
                      width={{ md: "90%" }}
                      //   borderWidth="1px"
                      rounded="lg"
                      padding={{ md: 8, base: 4 }}
                      className="common-borderRadius"
                    >
                      <Box>
                        <Text
                          size={{ md: "lg", base: "15px" }}
                          fontWeight="semibold"
                          fontSize={"20px"}
                          className="common-userPaneFamily"
                        >
                          Track Order
                        </Text>
                        <Text>
                          Tracking no: {orderById && orderById?.tracking_number}
                        </Text>
                        <Link
                          href={orderById && orderById?.order_track_url}
                          target="_blank"
                        >
                        Click here to track your order
                        </Link>
                        {/* <Text>
                          Order Tracking URL: {orderById && orderById?.order_track_url}
                        </Text> */}
                      </Box>
                      <Box marginTop={6} className="track-responsive">
                      </Box>
                    </Stack>
                  )}
                </Grid>
                <Stack
                  spacing="8"
                  borderLeft={{ md: "1px solid lightgray" }}
                  rounded="none"
                  className="delivery-box order-summary-box"
                  padding={{ md: 8, base: 4 }}
                  paddingTop={{ md: 0 }}
                  width={{ md: "45%", lg: "35%", base: "100%", sm: "100%" }}
                  marginTop={{ base: "10px", sm: "10px" }}
                  height={"-webkit-fit-content"}
                >
                  <Text
                    size="lg"
                    fontWeight="semibold"
                    fontSize={"20px"}
                    className="common-userPaneFamily"
                  >
                    Order Summary
                  </Text>
                  <Stack>
                    {orderById &&
                      orderById?.OrderItems.length &&
                      orderById?.OrderItems.map((item, index) => (
                        <Flex
                          key={index}
                          borderWidth={"1px"}
                          className="common-borderRadius"
                          padding={"8px"}
                        >
                          <Box
                            position={"relative"}
                            maxWidth={"81px"}
                            className="image-size"
                          >
                            {item?.Product?.ProductImages &&
                            item?.Product?.ProductImages.length ? (
                              <Image
                                width={{
                                  base: "100%",
                                  sm: "100%",
                                  md: "100%",
                                  lg: "100%",
                                }}
                                src={`${process.env.REACT_APP_IMAGE_URL}${item?.Product?.ProductImages[0]?.image_url}`}
                                position={"relative"}
                                cursor={"pointer"}
                                onClick={() =>
                                  navigate(
                                    `/product-details/${item.Product.slug}`
                                  )
                                }
                              />
                            ) : (
                              <Image
                                width={{
                                  base: "100%",
                                  sm: "100%",
                                  md: "100%",
                                  lg: "100%",
                                }}
                                src={noImage}
                                position={"relative"}
                                cursor={"pointer"}
                                onClick={() =>
                                  navigate(
                                    `/product-details/${item.Product.slug}`
                                  )
                                }
                              />
                            )}
                          </Box>
                          <SimpleGrid display={"initial"} width={"100%"}>
                            <Text
                              className="common-userPaneFamily wrap-word2"
                              fontSize={"15px"}
                              marginBottom={"20px"}
                              fontWeight={600}
                              color={"rgb(79, 79, 79)"}
                              paddingLeft={"10px"}
                              maxWidth="100%"
                              onClick={() =>
                                navigate(
                                  `/product-details/${item?.Product?.slug}`
                                )
                              }
                            >
                              {item?.Product?.name}
                            </Text>
                            <Text className="watch-price4" paddingLeft={"10px"}>
                              <span>₹</span>
                              {item?.Product?.price}
                            </Text>
                            <Text className="qty-text" paddingLeft={"10px"}>
                              <span>Qty:</span> {item?.quantity}
                            </Text>
                          </SimpleGrid>
                        </Flex>
                      ))}
                  </Stack>
                  <Stack spacing="6">
                    <Stack spacing={{ md: "6", base: "3" }}>
                      <Flex justify="space-between">
                        <Text
                          fontWeight="medium"
                          className="common-userPaneFamily common-cartPage"
                        >
                          Bag Total
                        </Text>
                        <Text
                          fontWeight="medium"
                          className="common-userPaneFamily common-cartPage"
                        >
                          <span>₹</span> {orderById?.total_amount}
                        </Text>
                      </Flex>
                      {orderById &&
                        orderById?.discount_code &&
                        orderById?.discount_code !== "" && (
                          <Flex justify="space-between">
                            <Text
                              fontWeight="medium"
                              className="common-userPaneFamily common-cartPage"
                            >
                              Coupon Discount (
                              <span style={{ color: "#ed691f" }}>
                                {orderById && orderById?.discount_code}
                              </span>
                              )
                            </Text>
                            <Text
                              fontWeight="medium"
                              className="common-userPaneFamily common-cartPage"
                            >
                              <span style={{ color: "#ed691f" }}>
                                - ₹{orderById && orderById?.discount_amount}
                              </span>
                            </Text>
                          </Flex>
                        )}
                      {/* <Flex justify="space-between">
                        <Text
                          fontWeight="medium"
                          className="common-userPaneFamily common-cartPage"
                        >
                          Estimated Tax (
                          <span style={{ color: "#ed691f" }}>GST 18%</span>)
                        </Text>
                        <Text
                          fontWeight="medium"
                          className="common-userPaneFamily common-cartPage"
                        >
                          <span>₹{orderById && orderById?.tax_amount}</span>
                        </Text>
                      </Flex> */}
                      <Divider />
                      <Flex justify="space-between">
                        <Text
                          fontSize="lg"
                          fontWeight="semibold"
                          className="common-userPaneFamily"
                        >
                          Total
                        </Text>
                        <Text
                          fontSize="lg"
                          fontWeight="semibold"
                          className="common-userPaneFamily"
                        >
                          <span>₹{orderById?.main_amount}</span>
                        </Text>
                      </Flex>
                    </Stack>
                  </Stack>
                </Stack>
              </Box>
              <Box className="continue-box2">
                <Button
                  // display={'flex'}
                  // justifyContent={'center'}
                  onClick={() =>
                    !!(
                      orderById &&
                      ['Not Picked',1,2,'Cancelled'].includes(orderById?.delivery_current_status)
                    ) && handleCancleOrder(orderById?.order_id, setLoader)
                  }
                  className="continue-shoppingBtn order-shoppingBtn"
                  isDisabled={
                    (
                      orderById &&
                      ['Not Picked',1,2,'Cancelled'].includes(orderById?.delivery_current_status)
                    )
                  }
                >
                  Cancel Order
                </Button>
                <Button
                  // display={'flex'}
                  // justifyContent={'center'}
                  onClick={() => navigate("/product")}
                  className="continue-shoppingBtn order-shoppingBtn"
                  disabled={!!loader}
                >
                  {homePageData &&
                  homePageData?.setting &&
                  homePageData?.setting?.continueShopping
                    ? homePageData?.setting?.continueShopping
                    : "Continue Shopping"}
                </Button>
              </Box>
              {/* <Box display={'flex'} justifyContent={'center'}>
            <Button
              display={'flex'}
              justifyContent={'center'}
              onClick={() => navigate('/product')}
              className="continue-shoppingBtn2"
            >
              {homePageData && homePageData?.setting && homePageData?.setting?.continueShopping
                ? homePageData?.setting?.continueShopping
                : 'Continue Shopping'}
            </Button>
          </Box> */}
            </Box>
          </Box>
        </div>
      </Box>
      <Footer />
    </div>
  );
}

export default OrderDetails;

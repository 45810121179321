import React, { useEffect, useState } from "react";
// import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
// import "./Single.css";
import {
  Box,
  Container,
  Stack,
  Text,
  Image,
  VStack,
  Button,
  Heading,
  SimpleGrid,
  StackDivider,
  useColorModeValue,
  List,
  ListItem,
  Grid,
  useToast,
  IconButton,
  useBreakpointValue,
  Flex,
  useDisclosure,
  Badge,
} from "@chakra-ui/react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import axios from "axios";
import Slider from "react-slick";
import {
  AiFillHeart,
  AiOutlineHeart,
  AiOutlineShoppingCart,
} from "react-icons/ai";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchHomeData } from "../../../actions/HomePageActions";
import {
  fetchProductById,
  fetchProductByIdSuccess,
  fetchProductListData,
} from "../../../actions/ProductListingActions";
import Header from "../../../components/header";
import Footer from "../../../components/footer/Footer";
import "./details.css";
import newArrival1 from "../../../assets/newArrival1.webp";
import newArrival2 from "../../../assets/newArrival2.webp";
import newArrival3 from "../../../assets/newArrival3.webp";
import newArrival4 from "../../../assets/newArrival4.webp";
import newArrival5 from "../../../assets/newArrival5.webp";
import Specification from "./Specification";
import WatchSlider from "../../../components/watch-slider/WatchSlider";
import store from "../../../store";
import {
  addtocart,
  addtocartNew,
  handleCartQuantity,
} from "../../../actions/CartActions";
import { addWishlist, removeWishlist } from "../../../actions/wishlistActions";
import NewProduct from "./NewProduct";
import { notificationStyle } from "../../product-list/ProductListing";
import LoginModal from "../../../components/login-modal/LoginModal";
import Loader from "../../../components/loader/Loader";
import noImage from "../../../assets/noImageAvailable.jpg";

const DetailsPage = () => {
  const { slug } = useParams();
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const productData = useSelector((state) => state.productList);
  const { productById, loading } = useSelector((state) => state.productById);
  const { homePageData } = useSelector((state) => state.home);
  const { userData, token } = useSelector((state) => state.user);
  const { wishlist } = useSelector((state) => state.wishlist);
  const { cart } = useSelector((state) => state.cart);
  // const [matchData, setMatchData] = useState({});
  const [findwishlist, setfindwishlist] = useState({});

  useEffect(() => {
    if (!homePageData.length) {
      dispatch(fetchHomeData());
    }
    // dispatch(fetchProductById(slug));
    // dispatch(fetchHomeData());
    // dispatch(fetchProductListData());

    return () => {
      dispatch(fetchProductByIdSuccess({}));
    };
  }, []);

  useEffect(() => {
    dispatch(fetchProductById(slug));
  }, [slug]);
  useEffect(() => {
    if (wishlist && wishlist.length) {
      const product = wishlist.find(
        (i) => i.product_id === productById?.product?.product_id
      );
      setfindwishlist(product);
    }
  }, [productById]);
  useEffect(() => {
    if (wishlist && wishlist.length) {
      const product = wishlist.find(
        (i) => i.product_id === productById?.product?.product_id
      );
      setfindwishlist(product);
    }
  }, [wishlist]);
  const [slider, setSlider] = useState(null);
  const [qty, setQty] = useState(1);
  useEffect(() => {
    if (
      cart &&
      cart.length &&
      !!cart.find((i) => i.product_id === productById?.product?.product_id)
    )
      setQty(
        cart.find((i) => i.product_id === productById?.product?.product_id)
          .quantity
      );
  }, [cart]);

  const top = useBreakpointValue({ base: "90%", md: "50%" });
  const side = useBreakpointValue({ base: "30%", md: "30px" });
  const handleAdd = (userId, productId, quantity) => {
    if (token) {
      const data = {
        userId,
        quantity,
        productId,
      };
      dispatch(addtocartNew(data));
      toast.success("Item Added to cart", notificationStyle);
    } else {
      onOpen();
    }
  };
  const handleQuantity = async (cartId, quantity) => {
    setQty(quantity);
  };
  const settings = {
    dots: false,
    arrows: false,
    fade: true,
    infinite: true,
    autoplay: false,
    speed: 1500,
    // autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  function SampleNextArrow({ onClick }) {
    return (
      <Box
        fontSize={{ base: "20px", sm: "25px", md: "30px", lg: "30px" }}
        padding={{
          base: "2px 2px",
          sm: "3px 5px",
          md: "4px 8px",
          lg: "5px 12px",
        }}
        // borderRadius="50%"
        color="black"
        // boxShadow="0 0 5px 3px #ccc"
        // border={'1px solid lightgray'}
        display="flex"
        width={{ base: "20px", sm: "30px", md: "40px", lg: "50px" }}
        position="absolute"
        top="45%"
        right={"-34px"}
        // backgroundColor="white"
        className="arrow arrow-right"
        onClick={onClick}
      >
        <IoIosArrowForward />
      </Box>
    );
  }
  function SamplePrevArrow({ onClick }) {
    return (
      <Box
        fontSize={{ base: "20px", sm: "25px", md: "30px", lg: "30px" }}
        padding={{
          base: "2px 2px",
          sm: "3px 5px",
          md: "4px 8px",
          lg: "5px 12px",
        }}
        // borderRadius="50%"
        color="black"
        // boxShadow="0 0 5px 3px #ccc"
        // border={'1px solid lightgray'}
        display="flex"
        width={{ base: "20px", sm: "30px", md: "40px", lg: "50px" }}
        position="absolute"
        top="45%"
        left={"-34px"}
        // backgroundColor="white"
        className="arrow arrow-left"
        zIndex={1}
        onClick={onClick}
      >
        <IoIosArrowBack />
      </Box>
    );
  }
  const settings2 = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 2,
    initialSlide: 0,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const handleAddWishlist = (userId, productId) => {
    if (token) {
      const data = {
        userId,
        productId,
      };
      dispatch(addWishlist(data));
      toast.success("Item Added to wishlist", notificationStyle);
    } else {
      onOpen();
    }
  };
  const handleRemove = (userId, productId, wishlistId) => {
    const data = {
      userId,
      wishlistId,
    };
    dispatch(removeWishlist(data));
    toast.success("Remove Successfully", notificationStyle);
  };
  return (
    <>
      <Header headerData={homePageData} />
      <Box height={loading ? "80vh" : ""}>
        <Loader loading={loading}>
          <div style={{ opacity: loading ? 0.3 : 1 }}>
            <Container maxW={"90%"} className="details-container">
              <SimpleGrid
                columns={{ base: 1, sm: 2, md: 2, lg: 2 }}
                spacing={{ base: 8, md: 10 }}
                py={{ base: 18, md: 24 }}
                className="product-details-responsive"
              >
                <Grid
                  rowGap={"-30px"}
                  columnGap={"10px"}
                  gridTemplateColumns={"auto auto"}
                  alignItems="center"
                >
                  {/* product carousel  */}
                  {productById?.product &&
                    productById?.product?.ProductImages?.length !== 0 ? (
                    <Box
                      position={"sticky"}
                      // height={"600px"}
                      height={'max-content'}
                      top={"100px"}
                      className="sticky-slider"
                      width={"full"}
                      overflow={"hidden"}
                    >
                      <IconButton
                        aria-label="left-arrow"
                        variant="ghost"
                        position="absolute"
                        left={side}
                        top={top}
                        transform={"translate(0%, -50%)"}
                        zIndex={2}
                        className="left-arrow"
                        onClick={() => slider?.slickPrev()}
                        _hover={{ background: "none" }}
                      >
                        <IoIosArrowBack size="30px" className="arrow-icon" />
                      </IconButton>
                      {/* Right Icon */}
                      <IconButton
                        aria-label="right-arrow"
                        variant="ghost"
                        position="absolute"
                        right={side}
                        className="right-arrow"
                        top={top}
                        transform={"translate(0%, -50%)"}
                        zIndex={2}
                        onClick={() => slider?.slickNext()}
                        _hover={{ background: "none" }}
                      >
                        <IoIosArrowForward size="30px" />
                      </IconButton>
                      {/* Slider */}
                      <Slider {...settings} ref={(slider) => setSlider(slider)}>
                        {productById?.product &&
                          productById?.product?.ProductImages?.length !== 0 &&
                          productById?.product?.ProductImages.map(
                            (card, index) => (
                              <div className="details-image">
                                <Image
                                  src={`${process.env.REACT_APP_IMAGE_URL}${card.image_url}`}
                                  key={card.product_id}
                                  className="common-borderRadius"
                                  width={"80%"}
                                />
                              </div>
                            )
                          )}
                      </Slider>
                    </Box>
                  ) : (
                    <Box
                      position={"sticky"}
                      // height={"600px"}
                      height={'max-content'}
                      top={"100px"}
                      className="sticky-slider"
                      width={"full"}
                      overflow={"hidden"}
                    >
                      <Image
                        width={"100%"}
                        src={noImage}
                        className="common-borderRadius"
                      />
                    </Box>
                  )}
                </Grid>
                <Stack spacing={{ base: 6, md: 10 }} position={"relative"}>
                  <Box as={"header"}>
                    <Flex justifyContent={"space-between"}>
                      <Heading
                        className="common-userPaneFamily"
                        lineHeight={1.1}
                        fontWeight={600}
                        fontSize={{ base: "20px", sm: "25px", lg: "2xl" }}
                        color={"rgb(79,79,79)"}
                        textTransform={"uppercase"}
                      >
                        {productById?.product?.name}
                      </Heading>
                    </Flex>
                    <Text
                      className="common-userPaneFamily"
                      color={"rgb(99, 99, 99)"}
                      marginTop={"3px"}
                      fontSize={"base"}
                    >
                      {productById?.product?.Category?.category_name}
                    </Text>
                    {productById?.product?.discount !== null &&
                      productById?.product?.discount !== "" ? (
                      <Text
                        className="common-userPaneFamily"
                        marginTop={"20px"}
                        fontWeight={600}
                        fontSize={"2xl"}
                        // color={'rgb(79,79,79)'}
                        color={"#ed691f"}
                      >
                        <Flex
                          alignItems={"center"}
                          gap={{ md: "7px", base: "15px" }}
                          className="details-discount"
                        >
                          <Box className="product-tag-res">
                            <span className="new-product-item-price2">
                              ₹{productById?.product?.price}
                            </span>
                            ₹
                            {(
                              Number(productById?.product?.price) -
                              (Number(productById?.product?.price) *
                                Number(productById?.product?.discount)) /
                              100
                            ).toFixed(2)}
                          </Box>
                          <Box className="discount-responsive">
                            <span className="discount-tag">
                              {parseInt(
                                Number(productById?.product?.discount),
                                10
                              )}
                              % off
                            </span>
                          </Box>
                        </Flex>
                      </Text>
                    ) : (
                      <Text
                        className="common-userPaneFamily"
                        marginTop={"20px"}
                        fontWeight={600}
                        fontSize={"2xl"}
                        // color={'rgb(79,79,79)'}
                        color={"#ed691f"}
                      >
                        ₹ {productById?.product?.price}
                        {/* <span className="discount-tag">50% off</span> */}
                      </Text>
                    )}
                  </Box>

                  <Stack
                    marginTop={"-20px"}
                    spacing={{ base: 4, sm: 6 }}
                    direction={"column"}
                    alignItems={"start"}
                    divider={
                      <StackDivider
                        borderColor={useColorModeValue("gray.200", "gray.600")}
                      />
                    }
                  >
                    <VStack spacing={{ base: 4, sm: 6 }}>
                      <Text
                        fontSize={{ base: "15px", sm: "15px", lg: "17px" }}
                        color={"rgb(99, 99, 99)"}
                        className="common-userPaneFamily"
                      >
                        {productById?.product?.short_description}
                      </Text>
                    </VStack>
                    <Box>
                      <Text
                        fontSize={{ base: "16px", lg: "18px" }}
                        color={"rgb(79,79,79)"}
                        // color={'#ed691f'}
                        fontWeight={"600"}
                        textTransform={"uppercase"}
                        className="common-userPaneFamily"
                        mb={"4"}
                      >
                        Description
                      </Text>
                      <Text
                        className="common-userPaneFamily"
                        color={"rgb(99, 99, 99)"}
                        fontSize={{ base: "15px", sm: "15px", lg: "17px" }}
                      >
                        <div
                          dangerouslySetInnerHTML={{
                            __html: productById?.product?.description,
                          }}
                        />
                      </Text>
                    </Box>
                  </Stack>

                  <Flex
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    className="details-btn"
                    gap={'5px'}
                  >
                    <Box
                      border={"1px solid #ed691f"}
                      className="qty-btn common-borderRadius"
                      display={'flex'}
                    >
                      <Button
                        className="qty-minus-res"
                        borderRight={"0.5px solid lightgray"}
                        borderRadius={0}
                        background={'transparent'}
                        m={"2px"}
                        onClick={() =>
                          handleQuantity(
                            cart.find(
                              (i) =>
                                i.product_id === productById?.product.product_id
                            )?.cart_id,
                            qty - 1
                          )
                        }
                        isDisabled={qty <= 1}
                      >
                        -
                      </Button>
                      <Button
                        m={"2px"}
                        className="qty-text-res"
                        _hover={{ background: "none" }}
                        background={'transparent'}
                      >
                        {qty}
                      </Button>
                      <Button
                        m={"2px"}
                        borderLeft={"0.5px solid lightgray"}
                        borderRadius={0}
                        className="qty-plus-res"
                        background={'transparent'}
                        onClick={() =>
                          handleQuantity(
                            cart.find(
                              (i) =>
                                i.product_id ===
                                productById?.product?.product_id
                            )?.cart_id,
                            qty + 1
                          )
                        }
                      // isDisabled={qty >= productById?.product?.quantity}
                      >
                        +
                      </Button>
                    </Box>
                    <Button
                      rounded={"none"}
                      w={"60%"}
                      // mt={3}
                      //   size={{ base: '29px', md: '20px', sm: '20px', lg: 'lg' }}
                      py={"7"}
                      fontSize={{
                        base: "15px",
                        md: "15px",
                        sm: "15px",
                        lg: "15px",
                      }}
                      //   backgroundColor={'#ed691f'}
                      className="addcart-btn common-borderRadius"
                      //   color={useColorModeValue('white', 'gray.900')}
                      textTransform={"uppercase"}
                      //   _hover={{
                      //     transform: 'translateY(2px)',
                      //     boxShadow: 'lg',
                      //   }}
                      background={'transparent'}
                      leftIcon={<AiOutlineShoppingCart size={"20px"} />}
                      onClick={() =>
                        handleAdd(
                          userData?.user_id,
                          productById?.product.product_id,
                          qty
                        )
                      }
                    >
                      {homePageData &&
                        homePageData?.setting &&
                        homePageData?.setting?.addToCart
                        ? homePageData?.setting?.addToCart
                        : "Add To Cart"}
                    </Button>
                    {findwishlist && findwishlist?.wishlist_id ? (
                      <Box className="icon-container2">
                        <AiOutlineHeart
                          size={"30px"}
                          color="#ed691f"
                          cursor={"pointer"}
                          className="header-icon addwishlist2"
                          position="absolute"
                          marginTop={"-6px"}
                          onClick={() =>
                            handleRemove(
                              userData.user_id,
                              productById?.product.product_id,
                              findwishlist.wishlist_id
                            )
                          }
                        />
                        <AiFillHeart
                          background="#ed691f"
                          className="header-icon fillWishlist2"
                          position="absolute"
                          marginTop={"-6px"}
                          size={"30px"}
                          color="#ed691f"
                          cursor={"pointer"}
                        />
                      </Box>
                    ) : (
                      <Box className="icon-container">
                        <AiOutlineHeart
                          // ref={btnRef}
                          // onClick={onOpen}
                          size={"30px"}
                          color="#ed691f"
                          cursor={"pointer"}
                          className="header-icon addwishlist"
                          position="absolute"
                          marginTop={"-6px"}
                        />
                        <AiFillHeart
                          background="#ed691f"
                          className="header-icon fillWishlist"
                          position="absolute"
                          marginTop={"-6px"}
                          size={"30px"}
                          color="#ed691f"
                          cursor={"pointer"}
                          onClick={() =>
                            handleAddWishlist(
                              userData.user_id,
                              productById?.product?.product_id
                            )
                          }
                        />
                      </Box>
                    )}
                    {/* </div> */}
                  </Flex>
                </Stack>
              </SimpleGrid>
              <Text className="history-text">Specification</Text>
              <Specification matchData={productById?.product} />
            </Container>
            {productById &&
              productById?.productsList &&
              productById?.productsList.length >= 3 ? (
              <Box
                width={{ base: "80%", sm: "90%", md: "90%", lg: "95%" }}
                margin="auto"
                marginTop={{ md: 4 }}
              >
                <Text className="history-text">You May Also like</Text>
                <NewProduct sliderData={productById?.productsList} />
                {/* <Slider {...settings2}>
          {data.map((item, i) => (
            <Box key={i} className="product-shadow" background={'white'}>
              <Image width={'90%'} margin="auto" src={item.image} className="watchImage-effect" />
              <Text className="watch-details">{item.name}</Text>
              <Text className="watch-price">
                <span>₹</span>
                {item.price}
              </Text>
            </Box>
          ))}
        </Slider> */}
              </Box>
            ) : (
              ""
            )}
          </div>
        </Loader>
      </Box>
      <Footer />
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <LoginModal isOpen={isOpen} onClose={onClose} />
    </>
  );
};

export default DetailsPage;

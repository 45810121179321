import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import '../dashboard/dashboard.css';
import './coupons.css';
import {
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  Card,
  Container,
  Popover,
  MenuItem,
  Drawer,
  Box,
  FormControlLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material';
import { UserListHead } from 'src/sections/@dashboard/user';
import Scrollbar from 'src/components/scrollbar/Scrollbar';
import Iconify from 'src/components/iconify/Iconify';
import AddCoupon from 'src/components/addCoupon/AddCoupon';
import axiosInstance from 'src/axios';
import Notification from 'src/components/notification';
import { IOSSwitch } from 'src/utils/toggleButton/toggleButton';
import moment from 'moment';

// ----------------------------------------------------------------------

export default function CouponPage() {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [open, setOpen] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [couponListing, setCouponListing] = useState([]);
  const [couponId, setCouponId] = useState(null);
  const [addEdit, setAddEdit] = useState('add');
  const handleOpenMenu = (event, id) => {
    setOpen(event.currentTarget);
    setCouponId(id);
  };

  const couponListAPI = async () => {
    const res = await axiosInstance(`/offer/`, 'GET', null);
    if (res.status === 200) {
      setCouponListing(
        res.data.data.map((data, index) => ({
          id: data.offer_id,
          name: data.name,
          code: data.code,
          type: data.type,
          discount: data.discount,
          startDate: data.start_date,
          expirydate: data.expiry_date,
          status: data.is_active,
          minimum_amount: data.minimum_amount,
        }))
      );
      // Notification('success', 'Category List', res.data.message, 2000);
    } else {
      // Notification('error', 'Category List', res.data.message, 2000);
    }
  };
  useEffect(() => {
    const couponData = async () => {
      const res = await axiosInstance(`/offer/`, 'GET', null);
      if (res.status === 200) {
        setCouponListing(
          res.data.data.map((data, index) => ({
            id: data.offer_id,
            name: data.name,
            code: data.code,
            type: data.type,
            discount: data.discount,
            startDate: data.start_date,
            expirydate: data.expiry_date,
            status: data.is_active,
            minimum_amount: data.minimum_amount,
          }))
        );
        Notification('success', 'Category List', res.data.message, 2000);
      } else {
        Notification('error', 'Category List', res.data.message, 2000);
      }
    };
    couponData();
  }, []);

  const handleCloseMenu = () => {
    setOpen(null);
  };
  // handle edit button
  const handleEdit = () => {
    setOpenDrawer(true);
    setOpen(null);
    setAddEdit('edit');
  };

  const handleAddData = () => {
    setOpenDrawer(true);
    setAddEdit('add');
  };

  // handle delete model
  const handleOpenDeleteModal = () => {
    setOpenDialog(true);
    setOpen(null);
  };
  const handleCloseDeleteModal = () => {
    setOpenDialog(false);
  };

  // delete api call
  const handleDeleteCoupon = async () => {
    const response = await axiosInstance(`/offer/${couponId}`, 'DELETE', null);

    if (response.status === 200) {
      Notification('success', 'Delete Coupon', response.data.message, 2000);
      setOpenDialog(false);
      await couponListAPI();
    } else {
      Notification('error', 'Delete Coupon', response.data.message, 2000);
    }
  };

  const TABLE_HEAD = [
    { id: 'name', label: 'OFFER NAME', alignRight: false },
    { id: 'code', label: 'CODE', alignRight: false },
    { id: 'type', label: 'TYPE', alignRight: false },
    { id: 'discount', label: 'DISCOUNT', alignRight: false },
    { id: 'startDate', label: 'START DATE', alignRight: false },
    { id: 'expirydate', label: 'END DATE', alignRight: false },
    { id: 'minimumAmount', label: 'AMOUNT', alignRight: false },
    { id: 'status', label: 'STATUS', alignRight: false },
    { id: '' },
  ];

  const updateValue = async (e, id) => {
    const { checked } = e.target;
    const data = couponListing.map((val) => {
      if (val.id === id) {
        return {
          ...val,
          status: checked,
        };
      }
      return val;
    });
    setCouponListing(data);

    const fetchData = async () => {
      const payload = {
        offer_id: id,
        is_active: checked,
      };
      const response = await axiosInstance(`/offer/status`, 'POST', payload);

      if (response.status === 200) {
        Notification('success', 'Status', response.data.message, 2000);
      } else {
        Notification('error', 'Status', response.data.message, 2000);
      }
    };

    fetchData();
  };
  return (
    <>
      <Helmet>
        <title> Coupons </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={4}>
          <Typography variant="h4" className="text-lg font-bold text-gray-700 dark:text-gray-300 headers">
            Coupons
          </Typography>
          <div>
            <button
              className="align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-white  border border-transparent common-background focus:ring focus:ring-purple-300  rounded-md common-shadow"
              type="button"
              onClick={() => handleAddData()}
            >
              <span className="mr-3">
                <svg
                  stroke="currentColor"
                  fill="none"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <line x1="12" y1="5" x2="12" y2="19" />
                  <line x1="5" y1="12" x2="19" y2="12" />
                </svg>
              </span>
              Add Coupons
            </button>
          </div>
        </Stack>
        {/* Coupons Table */}

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead headLabel={TABLE_HEAD} />
                {couponListing.length > 0 ? (
                  <TableBody>
                    {couponListing.map((row) => {
                      const { id, name, code, type, discount, startDate, expirydate, status } = row;
                      return (
                        <TableRow hover key={name} role="checkbox">
                          <TableCell component="th" scope="row" padding="none">
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={2}
                              justifyContent="start"
                              style={{ paddingLeft: 12 }}
                            >
                              <Typography align="left">{name}</Typography>
                            </Stack>
                          </TableCell>

                          <TableCell align="left">{code}</TableCell>
                          <TableCell align="left">{type}</TableCell>

                          <TableCell align="left">{discount}</TableCell>
                          <TableCell align="left">{moment(startDate).format('DD-MM-YYYY')}</TableCell>
                          <TableCell align="left">{moment(expirydate).format('DD-MM-YYYY')}</TableCell>

                          <TableCell align="left">{row.minimum_amount}</TableCell>

                          <TableCell align="left">
                            <FormControlLabel
                              control={
                                <IOSSwitch sx={{ m: 1 }} checked={status} onChange={(e) => updateValue(e, id)} />
                              }
                            />
                            {status}
                          </TableCell>

                          <TableCell align="right">
                            <IconButton size="large" color="inherit" onClick={(e) => handleOpenMenu(e, id)}>
                              <Iconify icon={'eva:more-vertical-fill'} />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                ) : (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Box
                            className="not-fount-img"
                            component="img"
                            src="/assets/illustrations/illustration_404.svg"
                            sx={{ height: 160, mx: 'auto', my: { xs: 5, sm: 10 } }}
                          />
                          <Typography variant="h4" paragraph className="not-fount-1">
                            No records has been added yet.
                          </Typography>
                          <Typography variant="h6" paragraph className="not-fount-2">
                            Add a new record by simpley clicking the button on top right side.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
        </Card>
        <Popover
          open={Boolean(open)}
          anchorEl={open}
          onClose={handleCloseMenu}
          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          PaperProps={{
            sx: {
              p: 1,
              width: 140,
              '& .MuiMenuItem-root': {
                px: 1,
                typography: 'body2',
                borderRadius: 0.75,
              },
            },
          }}
        >
          <MenuItem onClick={() => handleEdit()}>
            <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
            Edit
          </MenuItem>

          <MenuItem sx={{ color: 'error.main' }} onClick={handleOpenDeleteModal}>
            <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
            Delete
          </MenuItem>
        </Popover>
      </Container>

      <Dialog
        open={openDialog}
        keepMounted
        onClose={handleCloseDeleteModal}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{'Delete Category'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to delete Coupon?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteModal}>No</Button>
          <Button onClick={() => handleDeleteCoupon()}>Yes</Button>
        </DialogActions>
      </Dialog>

      {/* Add Product Form */}
      <Drawer anchor={'right'} sx={{"& .MuiPaper-root": {
      width: '50%'
    }}} open={openDrawer} onClose={() => setOpenDrawer(false)} className="drawerStyle">
        <AddCoupon setOpenDrawer={setOpenDrawer} couponListAPI={couponListAPI} addEdit={addEdit} id={couponId} />
      </Drawer>
    </>
  );
}

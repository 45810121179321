import {
  Avatar,
  AvatarBadge,
  Box,
  Button,
  Flex,
  Grid,
  Image,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BiUser } from "react-icons/bi";
import { HiUserCircle } from "react-icons/hi";
import { MdOutlineSecurity } from "react-icons/md";
import { FiLogOut } from "react-icons/fi";
import { BsClockHistory } from "react-icons/bs";
import navLogo from "../../assets/logo/logo.png";
import "./header.css";
import SideBar from "../sidebar/Sidebar";
import SearchBar from "../searchbar/SearchBar";
import HomeMenu from "../home-menu/HomeMenu";
import WishlistPage from "../wishlist-page/WishlistPage";
import CartDrawer from "../cart-page-drawer/CartDrawer";
import { fetchHomeData } from "../../actions/HomePageActions";
import store from "../../store";
import { handleLogout } from "../../actions/UserActions";

function Header({ headerData }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { homePageData } = useSelector((state) => state.home);
  const { userData, token } = useSelector((state) => state.user);
  useEffect(() => {
    dispatch(fetchHomeData());
  }, []);
  const handleLogoutclick = () => {
    dispatch(handleLogout());
    navigate("/login");
  };
  return (
    <>
      <div>
        <Box position={"fixed"} top="0" zIndex={"100"} bg="#ffffff" width="100%">
          {homePageData?.setting?.offerDescription && homePageData?.setting?.offerDescription !== "" &&
            <div style={{ height: "45px", width: "100%", backgroundColor: "#ed691f", display: "flex", alignItems: "center", justifyContent: "center" }} className="header-offer">
              <div className="offer_main">
                <span style={{ color: "white", fontSize: '20px' }} dangerouslySetInnerHTML={{ __html: homePageData?.setting?.offerDescription }} />
              </div>
            </div>}
          <Flex
            height={{ base: "4.2rem", md: "4.94rem" }}
            // px={{ base: '1rem', md: '3rem' }}
            // justify={'space-between'}
            // gap={{ base: '0.5rem', sm: '1rem', md: '2rem', lg: '2rem' }}
            align={"center"}
          // width="90%"
          // margin="auto"
          >
            <Box display={{ lg: "none" }}>
              <SideBar
                headerData={
                  headerData ? headerData.navigation : homePageData?.navigation
                }
              />
            </Box>

            {/* <Link to="/"> */}
            <Box
              display={{ base: "flex", sm: "flex", lg: "block", md: "flex" }}
              justifyContent={{
                base: "center",
                sm: "center",
                md: "center",
                lg: "none",
              }}
              className="responsive-logo"
              width={{ base: "65px", sm: "85px", md: "100px", lg: "100px" }}
            >
              <Image
                src={
                  homePageData &&
                    homePageData?.setting &&
                    homePageData?.setting.logo
                    ? `${process.env.REACT_APP_IMAGE_URL}${homePageData?.setting.logo}`
                    : navLogo
                }
                alt="logo"
                style={{ marginLeft: 10 }}
                onClick={() => navigate("/")}
                cursor={"pointer"}
              // margin={'auto'}
              // className="md:w-3/12"
              // width={{ base: '90%', sm: '60%', md: '26%', lg: '100%' }}
              // height={{ base: '2rem', md: '100%' }}
              />
            </Box>

            {/* </Link> */}

            <Box
              width="61%"
              display={{ base: "none", lg: "block" }}
              marginLeft={"30px"}
            >
              <SearchBar />
            </Box>

            <Flex
              gap={{ base: "0.5rem", md: "1.5rem" }}
              position={"absolute"}
              right={0}
              align="center"
            >

              <div
                className="ml-6 flex justify-between col-span-3"
                style={{ alignItems: "center" }}
              >
                {token ? (
                  <div className="w-100 md:mr-6 leading-4 responsive-icon">
                    <Menu>
                      <MenuButton
                        as={Button}
                        background={"none"}
                        padding={0}
                        fontWeight={"initial"}
                        _active={{ background: "none" }}
                        _hover={{ background: "none" }}
                      >
                        <div className="w-100 mb-2 flex justify-center sm:h-6">
                          <BiUser
                            size={25}
                            color="#ed691f"
                            cursor={"pointer"}
                            className="header-icon"
                          // onClick={() => navigate('/login')}
                          />
                        </div>
                        <Text
                          fontSize={{
                            base: "0.675rem",
                            sm: "0.675rem",
                            md: "0.875rem",
                            lg: "0.875rem",
                          }}
                          className="icon-name"
                        >
                          PROFILE
                        </Text>
                      </MenuButton>
                      <MenuList
                        background={"white"}
                        border={"1px solid #f4f4f5"}
                        boxShadow={"rgba(0, 0, 0, 0.1) 0px 4px 12px"}
                      >
                        {/* icon={<HiUserCircle />} */}
                        <Flex
                          justifyContent={"space-between"}
                          padding={3}
                          alignItems={"center"}
                        >
                          <Avatar width={"2rem"} height={"2rem"}>
                            <AvatarBadge boxSize="1em" bg="green.500" />
                          </Avatar>
                          <MenuItem
                            fontWeight={"bold"}
                            className="common-userPaneFamily"
                            _hover={{ background: "none", width: '100%' }}
                            background={'transparent'}
                          >
                            <Grid>
                              {userData?.username}
                              <Text fontWeight={400}>{userData?.email}</Text>
                            </Grid>
                          </MenuItem>
                        </Flex>
                        <MenuDivider marginTop={0} />
                        {/* <MenuGroup className="common-userPaneFamily" fontSize={'md'} title="Profile"> */}
                        <MenuItem
                          className="common-userPaneFamily profile-hover"
                          onClick={() => navigate("/profile")}
                          icon={<BiUser fontSize={"20px"} />}
                          _hover={{ background: "#f4f4f5" }}
                        >
                          My Account
                        </MenuItem>
                        <MenuItem
                          className="common-userPaneFamily  profile-hover"
                          onClick={() => navigate("/change-password")}
                          icon={<MdOutlineSecurity fontSize={"20px"} />}
                          _hover={{ background: "#f4f4f5" }}
                        >
                          Change Password{" "}
                        </MenuItem>
                        <MenuItem
                          className="common-userPaneFamily  profile-hover"
                          onClick={() => navigate("/order")}
                          icon={<BsClockHistory fontSize={"18px"} />}
                          _hover={{ background: "#f4f4f5" }}
                        >
                          Orders{" "}
                        </MenuItem>
                        {/* </MenuGroup> */}
                        <MenuDivider />
                        <MenuItem
                          className="common-userPaneFamily  profile-hover"
                          onClick={() => handleLogoutclick()}
                          icon={<FiLogOut fontSize={"20px"} />}
                          _hover={{ background: "#f4f4f5" }}
                        >
                          Logout
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </div>
                ) : (
                  <div className="w-100 md:mr-6 leading-4 responsive-icon">
                    <div className="w-100 mb-2 flex justify-center sm:h-6">
                      <BiUser
                        size={25}
                        color="#ed691f"
                        cursor={"pointer"}
                        className="header-icon"
                        onClick={() => navigate("/login")}
                      />
                    </div>
                    <Text
                      fontSize={{
                        base: "0.675rem",
                        sm: "0.675rem",
                        md: "0.875rem",
                        lg: "0.875rem",
                      }}
                      className="icon-name"
                    >
                      LOGIN
                    </Text>
                  </div>
                )}
                <div className="w-100 md:mr-6 leading-4 responsive-icon">
                  <div className="w-100 mb-2 flex justify-center sm:h-6">
                    {/* <img src={wishlistIcon} alt="login" height={24} /> */}

                    <WishlistPage />
                  </div>
                  <Text
                    fontSize={{
                      base: "0.675rem",
                      sm: "0.675rem",
                      md: "0.875rem",
                      lg: "0.875rem",
                    }}
                    className="icon-name"
                  >
                    WISHLIST
                  </Text>
                </div>
                <div className="w-100 md:mr-6 leading-4 responsive-icon">
                  <div className="w-100 mb-2 flex justify-center sm:h-6">
                    {/* <img src={cartIcon} alt="login" height={24} /> */}
                    <CartDrawer />
                  </div>
                  <Text
                    fontSize={{
                      base: "0.675rem",
                      sm: "0.675rem",
                      md: "0.875rem",
                      lg: "0.875rem",
                    }}
                    className="icon-name"
                  >
                    CART
                  </Text>
                </div>
              </div>
            </Flex>
          </Flex>
          <Box
            padding={"8px"}
            display={{ lg: "none" }}
            width={"95%"}
            className="tab-search"
            margin="auto"
          >
            <SearchBar />
          </Box>
        </Box>
        <Box
          marginTop={{ lg: "75px" }}
          minWidth={"30%"}
          width="100%"
          bg={"#ffffff"}
          boxShadow=" 0px 7px 7px -5px rgba(170, 159, 170, 0.2)"
          display={{ base: "none", lg: "block" }}
        >
          <HomeMenu
            headerData={
              headerData ? headerData.navigation : homePageData?.navigation
            }
          />
        </Box>
      </div>
    </>
  );
}

export default Header;

import axiosInstance from '../axios';

export const FETCH_HOME_REQUEST = 'FETCH_HOME_REQUEST';
export const FETCH_HOME_SUCCESS = 'FETCH_HOME_SUCCESS';
export const FETCH_HOME_FAILURE = 'FETCH_HOME_FAILURE';

export const fetchDataRequest = () => ({
  type: FETCH_HOME_REQUEST,
});

// Action creator for the success state
export const fetchDataSuccess = (data) => ({
  type: FETCH_HOME_SUCCESS,
  payload: data,
});

// Action creator for the failure state
export const fetchDataFailure = (error) => ({
  type: FETCH_HOME_FAILURE,
  payload: error,
});

// Action creator for initiating the API call
export const fetchHomeData = () => (dispatch) => {
  dispatch(fetchDataRequest());
  axiosInstance(`/home/`, 'GET', null)
    .then((response) => {
      // Dispatch success action with the received data
      dispatch(fetchDataSuccess(response.data.data));
    })
    .catch((error) => {
      // Dispatch failure action with the error message
      dispatch(fetchDataFailure(error.message));
    });
};

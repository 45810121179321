import React from "react";
import { Box, Container, Grid, Image, Text } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import Slider from "react-slick";

function FooterSlider({ bannerData }) {
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    slidesToShow: 1,
    arrows: false,
  };
  return (
    <div>
      <Box
        width={{ base: "100%", sm: "90%", md: "95%", lg: "100%" }}
        marginTop={"2rem"}
        margin="auto"
      >
        <Box width={{ md: "100%" }} margin={{ md: "auto" }}>
          <Slider {...settings}>
            {bannerData &&
              bannerData.length &&
              bannerData.map((item) => (
                <Box
                  height={{ md: "200px", base: "110px" }}
                  width={"100%"}
                  borderRadius={"8px"}
                  marginTop={"2rem"}
                  padding={"0 20px"}
                >
                  <Image
                    src={`${process.env.REACT_APP_IMAGE_URL}${item?.image_url}`}
                    alt=""
                    width={"100%"}
                    height={"100%"}
                    objectFit={"cover"}
                  />
                </Box>
              ))}
          </Slider>
        </Box>
      </Box>
    </div>
  );
}

export default FooterSlider;

// reducers.js
import { FETCH_HOME_REQUEST, FETCH_HOME_SUCCESS, FETCH_HOME_FAILURE } from '../actions/HomePageActions';

const initialState = {
  loading: false,
  homePageData: [],
  error: '',
};

const HomePageReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_HOME_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_HOME_SUCCESS:
      return {
        loading: false,
        homePageData: action.payload,
        error: '',
      };
    case FETCH_HOME_FAILURE:
      return {
        loading: false,
        homePageData: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export default HomePageReducer;

import { Box, Divider, Flex, Grid, Image, Text } from '@chakra-ui/react';
import React from 'react';
import './footer.css';
import { AiFillFacebook } from 'react-icons/ai';
import { BsInstagram, BsYoutube, BsLinkedin } from 'react-icons/bs';
import { FaXTwitter } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

function Footer() {
  const navigate = useNavigate();
  const { homePageData } = useSelector((state) => state.home);
  return (
    <div>
      <Divider marginTop={10} />
      <Box width={'100%'} background={'white'} padding={6}>
        <Grid
          templateColumns={{
            base: 'repeat(1, 1fr)',
            sm: 'repeat(2, 1fr)',
            md: 'repeat(3, 1fr)',
            lg: 'repeat(4, 1fr)',
          }}
          gap={6}
          marginTop={{ base: 3, sm: 4, md: 6, lg: 7 }}
        >
          <Box>
            <Text className="footer-header" fontSize={{ md: '20px' }}>
              Customer Service
            </Text>
            <ul className="footer-list">
              <li className="footer-content" onClick={() => navigate('/profile')}>
                My Account
              </li>
              <li className="footer-content" onClick={() => navigate('/wishlist')}>
                Wish List
              </li>
              <li className="footer-content" onClick={() => navigate('/order')}>
                My Order
              </li>
            </ul>
          </Box>
          <Box>
            <Text className="footer-header" fontSize={{ md: '20px' }}>
              Policies
            </Text>
            <ul className="footer-list">
              <li className="footer-content" onClick={() => navigate('/policy/refund-exchange')}>
                Refund & Exchange
              </li>
              <li className="footer-content" onClick={() => navigate('/policy/cancellation')}>
                Cancellation
              </li>
              <li className="footer-content" onClick={() => navigate('/policy/terms-condition')}>
                Terms & Conditions
              </li>
              <li className="footer-content" onClick={() => navigate('/policy/privacy-policy')}>
                Privacy Policy
              </li>
            </ul>
          </Box>
          <Box>
            <Text className="footer-header" fontSize={{ md: '20px' }}>
              About canon Quartz
            </Text>
            <ul className="footer-list">
              <li className="footer-content" onClick={() => navigate('/about-us')}>
                About Us
              </li>
              <li className="footer-content" onClick={() => navigate('/contact-us')}>
                Contact Us
              </li>
            </ul>
          </Box>
          <Box>
            <Text className="footer-header" fontSize={{ md: '20px' }}>
              Follow Us With
            </Text>
            <Box
              // border={"1px solid gray"}
              alignItems="center"
            >
              <Flex alignItems="center" margin="auto">
                <Box>
                  <div className="footer-icons">
                    <AiFillFacebook
                      fontSize={'24px'}
                      onMouseOver={({ target }) => (target.style.color = '#ed691f')}
                      onMouseOut={({ target }) => (target.style.color = 'rgb(79, 79, 79)')}
                      onClick={() => {
                        const facebookLink = homePageData?.setting?.facebook;
                        if (facebookLink) {
                          window.open(facebookLink, '_blank');
                        }
                      }}
                    />
                  </div>
                </Box>
                <Box>
                  <div className="footer-icons">
                    <FaXTwitter
                      fontSize={'24px'}
                      onMouseOver={({ target }) => (target.style.color = '#ed691f')}
                      onMouseOut={({ target }) => (target.style.color = 'rgb(79, 79, 79)')}
                      onClick={() => {
                        const twitterLink = homePageData?.setting?.twitter;
                        if (twitterLink) {
                          window.open(twitterLink, '_blank');
                        }
                      }}
                    />
                  </div>
                </Box>
                <Box>
                  <div className="footer-icons">
                    <BsInstagram
                      fontSize={'24px'}
                      onMouseOver={({ target }) => (target.style.color = '#ed691f')}
                      onMouseOut={({ target }) => (target.style.color = 'rgb(79, 79, 79)')}
                      onClick={() => {
                        const instagramLink = homePageData?.setting?.instagram;
                        if (instagramLink) {
                          window.open(instagramLink, '_blank');
                        }
                      }}
                    />
                  </div>
                </Box>
                <Box>
                  <div className="footer-icons">
                    <BsLinkedin
                      fontSize={'24px'}
                      onMouseOver={({ target }) => (target.style.color = '#ed691f')}
                      onMouseOut={({ target }) => (target.style.color = 'rgb(79, 79, 79)')}
                      onClick={() => {
                        const linkedinLink = homePageData?.setting?.linkedin;
                        if (linkedinLink) {
                          window.open(linkedinLink, '_blank');
                        }
                      }}
                    />
                  </div>
                </Box>
              </Flex>
            </Box>
          </Box>
        </Grid>
        <Divider marginTop={5} />
        <Box display={'flex'} justifyContent={'center'} padding={4} className="footer-policy">
          {homePageData && <div dangerouslySetInnerHTML={{ __html: homePageData?.setting?.copyright }} />}
        </Box>
      </Box>
    </div>
  );
}

export default Footer;

import React, { useEffect, useState } from 'react';
import './addCategory.css';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

// import { handleChange } from '../../utils/utils';
import axios from 'axios';
import axiosInstance from '../../axios';
import Notification from '../notification';

function AddCategory({ setOpenDrawer, categoryListAPI, addEdit, id }) {
  const [category, setCategory] = useState({
    category_name: '',
    link: '',
    category_description: '',
    slug: '',
    parent_category_id: null,
    is_active: false,
    is_navbar: false,
  });
  const validationSchema = Yup.object({
    category_name: Yup.string().required('Category Name is required').trim(),
    category_description: Yup.string().required('Category Description is required').trim(),
    slug: Yup.string().required('Slug is required').trim(),
  });

  const [categoryList, setCategoryList] = useState([]);
  const [productImage, setProductImage] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [productImageId, setProductImageId] = useState('');

  const generateSlug = (categoryName) => categoryName && categoryName?.toLowerCase().replace(/[\s\W-]+/g, '-');
  useEffect(() => {
    // product list get by id
    const fetchCategory = async () => {
      const category = await axiosInstance(`/category/parent-category`, 'GET', null);
      if (category) {
        setCategoryList(category.data.data);
      }
    };
    fetchCategory();
  }, []);
  useEffect(() => {
    // product list get by id

    if (addEdit && addEdit === 'edit') {
      const fetchData = async () => {
        const category = await axiosInstance(`/category/parent-category`, 'GET', null);
        if (category) {
          setCategoryList(category.data.data);
        }
        const res = await axiosInstance(`/category/${id}`, 'GET', null);
        if (res.status === 200) {
          if (res.data.data) {
            const data = res.data.data;
            setCategory({
              category_name: data.category_name,
              link: data.link,
              category_description: data.category_description,
              slug: data.slug,
              parent_category_id: data.parent_category_id,
              is_navbar: data.is_navbar,
              is_active: data.is_active,
            });
            if (data.image && data.image !== '') {
              const allproductImages = {
                id: data.category_id,
                image: `${process.env.REACT_APP_IMAGE_URL}${data.image}`,
              };
              // setProductImage([allproductImages]);
              setSelectedImages([allproductImages]);
            }
          }
        }
      };
      fetchData();
    }
  }, []);

  const handleBtnSubmit = async (values) => {
    if (addEdit && addEdit !== 'edit') {
      let imagename;
      if (productImage[0]) {
        const formData = new FormData();
        // Object.values(productImage).forEach((file) => {
        formData.append('image', productImage[0]);
        // });
        const image = await axios.post(`${process.env.REACT_APP_BASE_URL}/common/upload`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        imagename = image.data.data;
      } else {
        imagename = '';
      }
      const response = await axiosInstance(`/category/`, 'POST', { ...values, image: imagename });
      if (response.status === 200) {
        await Notification('success', 'Add Category', response.data.message, 2000);
        setOpenDrawer(false);
        setCategory({
          category_name: '',
          link: '',
          category_description: '',
          slug: '',
          parent_category_id: null,
          is_navbar: false,
          is_active: false,
        });
        setProductImage([]);
        setSelectedImages([]);
        await categoryListAPI();
      } else {
        await Notification('error', 'Add Category', response.data.message, 2000);
      }
    }
  };
  const handleImageUpload = async (event) => {
    const { files } = event.target;
    const element = files[0];
    const imagesArray = element;
    const previewArray = { id: null, image: URL.createObjectURL(element) };
    setProductImage([imagesArray]);
    setSelectedImages([previewArray]);
  };
  const handleOpenDeleteModal = async (index, id) => {
    if (id === null) {
      const updatedImages = selectedImages;
      updatedImages.splice(index, 1);
      setSelectedImages(updatedImages);
      setProductImage(updatedImages);
    } else {
      setOpenDialog(true);
      setProductImageId(id);
    }
  };
  const handleCloseDeleteModal = () => {
    setOpenDialog(false);
  };
  const handleDeleteImage = async () => {
    const updatedImages = [...selectedImages];
    const index = selectedImages.findIndex((item) => item.id === productImageId);
    updatedImages.splice(index, 1);
    setSelectedImages(updatedImages);
    setProductImage(updatedImages);
    const response = await axiosInstance(
      `${process.env.REACT_APP_BASE_URL}/category/image-delete/${productImageId}`,
      'DELETE',
      null
    );
    if (response.status === 200) {
      Notification('success', 'Delete Image', response.data.message, 2000);
      setOpenDialog(false);
      // await userListAPI();
    } else {
      Notification('error', 'Delete Image', response.data.message, 2000);
    }
  };
  // edit api call
  const handleUpdate = async (values) => {
    let imagename = '';
    if (productImage[0]) {
      const formData = new FormData();
      // Object.values(productImage).forEach((file) => {
      formData.append('image', productImage[0]);
      // });
      const image = await axios.post(`${process.env.REACT_APP_BASE_URL}/common/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      imagename = image.data.data;
    }
    let data;
    if (imagename !== '') {
      data = { ...values, image: imagename };
    } else {
      data = values;
    }

    const response = await axiosInstance(`/category/update-category/${id}`, 'PUT', data);
    if (response.status === 200) {
      setOpenDrawer(false);
      setCategory({
        category_name: '',
        link: '',
        category_description: '',
        slug: '',
        parent_category_id: null,
        is_active: false,
      });
      setOpenDrawer(false);
      await categoryListAPI();
      await Notification('success', 'Edit Category', response.data.message, 2000);
    } else {
      await Notification('error', 'Edit Category', response.response.data.message, 2000);
    }
  };
  return (
    <div style={{ overflow: 'hidden' }}>
      <button
        className="fixed focus:outline-none z-50 text-red-500 hover:bg-red-100 hover:text-gray-700 transition-colors duration-150 bg-white shadow-md mr-6 mt-2 right-0 left-auto w-8 h-8 rounded-full block text-center z-30 mr-7 "
        onClick={() => setOpenDrawer(false)}
      >
        <svg
          stroke="currentColor"
          fill="none"
          strokeWidth="2"
          viewBox="0 0 24 24"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="mx-auto"
          height="1em"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
        >
          <line x1="18" y1="6" x2="6" y2="18" />
          <line x1="6" y1="6" x2="18" y2="18" />
        </svg>
      </button>
      <div className="w-full relative p-border-gray-100 bg-gray-50 border-gray-700 bg-white-800 text-black-300 model-title">
        <div>
          {addEdit && addEdit === 'edit' ? (
            <>
              <h4 className="text-xl font-medium">Edit Category</h4>
              <p className="mb-0 text-sm">Edit your category and necessary information from here</p>
            </>
          ) : (
            <>
              <h4 className="text-xl font-medium">Add Category</h4>
              <p className="mb-0 text-sm">Add your category and necessary information from here</p>
            </>
          )}
        </div>
      </div>
      <div className="mt-12 flex flex-col w-full h-full justify-between">
        <div
          className="w-full md:w-7/12 lg:w-8/12 xl:w-8/12 relative bg-white-700 text-gray-200"
          style={{ position: 'relative', overflow: 'hidden', width: '100%', height: '100%' }}
        >
          <div
            style={{
              // position: 'absolute',
              inset: '0px',
              overflow: 'scroll',
              marginRight: '-21px',
              marginBottom: '-21px',
            }}
          >
            <Formik
              enableReinitialize
              initialValues={category}
              validationSchema={validationSchema}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                handleBtnSubmit(values);
                // setSubmitting(true);
                // resetForm();
                // setSubmitting(false);
              }}
            >
              {({ values, errors, touched, handleBlur, handleChange, handleSubmit, isValid, isSubmitting }) => (
                <Form className="block" onSubmit={handleSubmit}>
                  <div className="px-6 pt-12 flex-grow w-full pb-40 max-h-full md:pb-32 lg:pb-32 xl:pb-32 scroll-bar">
                    {/* category Image Upload */}

                    <div className="flex grid-cols-6 gap-3 md:gap-5 xl:gap-6 lg:gap-6 mb-8 formLabel">
                      <label className="block text-sm text-gray-700 dark:text-gray-400 col-span-4 sm:col-span-2 font-medium text-sm">
                        Category Image
                      </label>
                      <div className="file-input col-span-8 sm:col-span-4 formInput">
                        <input
                          type="file"
                          className="block w-full px-3 py-1 text-sm focus:outline-none dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white"
                          onChange={(e) => handleImageUpload(e)}
                          onBlur={handleBlur}
                        />
                        <span className="button">Choose File</span>
                        {/* <label className="label">No file selected</label> */}
                      </div>
                      {/* {!productImage.length && <div className="error-message">Product Image is required</div>} */}
                    </div>
                    <div className="col-span-8 flex images">
                      {selectedImages.length
                        ? selectedImages
                            .filter((i) => i.image !== '')
                            .map((image, index) => (
                              <div className="image-upload" key={index} style={{ position: 'relative' }}>
                                <span onClick={() => handleOpenDeleteModal(index, id)}>
                                  <HighlightOffIcon className="cancel-images" />
                                </span>
                                <div className="set-image">
                                  <img key={index} src={image.image} alt="" height={'65px'} width={'100px'} />
                                </div>
                              </div>
                            ))
                        : ''}
                    </div>
                    <div className="flex grid-cols-6 gap-3 md:gap-5 xl:gap-6 lg:gap-6 mb-6 formLabel">
                      <label className="block text-sm text-gray-700 dark:text-gray-400 col-span-4 sm:col-span-2 font-medium text-sm">
                        Category Name <span className="required-field">*</span>
                      </label>
                      <div className="col-span-8 sm:col-span-4 formInput">
                        <Field
                          className={
                            errors.category_name && touched.category_name
                              ? 'block w-full px-3 py-1 text-sm focus:outline-none dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 text-black dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white input-error'
                              : 'block w-full px-3 py-1 text-sm focus:outline-none dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 text-black dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white'
                          }
                          type="text"
                          name="category_name"
                          placeholder="Category Name"
                          value={values.category_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {touched.category_name && errors.category_name && (
                          <div className="error-message">{errors.category_name}</div>
                        )}
                      </div>
                    </div>
                    <div className="flex grid-cols-6 gap-3 md:gap-5 xl:gap-6 lg:gap-6 mb-6 formLabel">
                      <label className="block text-sm text-gray-700 dark:text-gray-400 col-span-4 sm:col-span-2 font-medium text-sm">
                        Category Link <span className="required-field">*</span>
                      </label>
                      <div className="col-span-8 sm:col-span-4 formInput">
                        <Field
                          className={
                            errors.link && touched.link
                              ? 'block w-full px-3 py-1 text-sm focus:outline-none dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 text-black dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white input-error'
                              : 'block w-full px-3 py-1 text-sm focus:outline-none dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 text-black dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white'
                          }
                          type="text"
                          name="link"
                          placeholder="Category Link"
                          value={values.link}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {touched.link && errors.link && <div className="error-message">{errors.link}</div>}
                      </div>
                    </div>
                    <div className="flex grid-cols-6 gap-3 md:gap-5 xl:gap-6 lg:gap-6 mb-6 formLabel">
                      <label className="block text-sm text-gray-700 dark:text-gray-400 col-span-4 sm:col-span-2 font-medium text-sm">
                        Category Description <span className="required-field">*</span>
                      </label>
                      <div className="col-span-8 sm:col-span-4 formInput">
                        <textarea
                          className={
                            errors.category_description && touched.category_description
                              ? 'block w-full pl-2 pt-2 text-sm dark:text-gray-300 rounded-md focus:outline-none form-textarea focus:border-green-400 border-gray-300 dark:border-gray-600 text-black dark:focus:border-gray-600 dark:bg-gray-700 dark:focus:ring-gray-300 focus:ring focus:ring-green-300 border text-sm focus:outline-none block w-full bg-gray-100 border-transparent focus:bg-white input-error'
                              : 'block w-full text-sm pl-2 pt-2 dark:text-gray-300 rounded-md focus:outline-none text-black form-textarea focus:border-green-400 border-gray-300 dark:border-gray-600 dark:focus:border-gray-600 dark:bg-gray-700 dark:focus:ring-gray-300 focus:ring focus:ring-green-300 border text-sm focus:outline-none block w-full bg-gray-100 border-transparent focus:bg-white'
                          }
                          name="category_description"
                          placeholder="Category details"
                          rows="4"
                          spellCheck="false"
                          type="text"
                          value={values.category_description}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {touched.category_description && errors.category_description && (
                          <div className="error-message">{errors.category_description}</div>
                        )}
                      </div>
                    </div>
                    <div className="flex grid-cols-6 gap-3 md:gap-5 xl:gap-6 lg:gap-6 mb-6 formLabel">
                      <label className="block text-sm text-gray-700 dark:text-gray-400 col-span-4 sm:col-span-2 font-medium text-sm">
                        Category Slug <span className="required-field">*</span>
                      </label>
                      <div className="col-span-8 sm:col-span-4 formInput">
                        <Field
                          className={
                            errors.slug && touched.slug
                              ? 'block w-full px-3 py-1 text-sm focus:outline-none dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring text-black focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white input-error'
                              : 'block w-full px-3 py-1 text-sm focus:outline-none dark:text-gray-300 leading-5 text-black rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white'
                          }
                          type="text"
                          name="slug"
                          placeholder="Category Slug"
                          value={generateSlug(values.slug)}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {touched.slug && errors.slug && <div className="error-message">{errors.slug}</div>}
                      </div>
                    </div>
                    <div className="flex grid-cols-6 gap-3 md:gap-5 xl:gap-6 lg:gap-6 mb-6 formLabel">
                      <label className="block text-sm text-gray-700 dark:text-gray-400 col-span-4 sm:col-span-2 font-medium text-sm">
                        Category Type
                      </label>
                      <div className="col-span-8 sm:col-span-4 formInput">
                        <div className="relative">
                          <svg
                            className="w-2 h-2 absolute top-0 right-0 m-4 pointer-events-none"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 412 232"
                          />
                          <select
                            className="col-span-8 sm:col-span-4 border border-gray-300 rounded-md text-gray-600 h-10 pl-5 pr-10 bg-white hover:border-gray-400 focus:outline-none appearance-none w-full text-sm dark:text-gray-300 rounded-md dark:focus:border-gray-600 dark:bg-gray-700 dark:focus:ring-gray-300 focus:ring focus:ring-purple-300 border text-sm focus:outline-none block w-full bg-gray-100 border-transparent focus:bg-white"
                            value={values.parent_category_id}
                            onChange={handleChange}
                            name="parent_category_id"
                          >
                            <option>Choose a Category</option>
                            {categoryList &&
                              categoryList.length &&
                              categoryList.map((item) => (
                                <option value={item.category_id} key={item.category_id}>
                                  {item.category_name}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="flex grid-cols-6 gap-3 md:gap-5 xl:gap-6 lg:gap-6 mb-6 formLabel">
                      <label className="block text-sm text-gray-700 dark:text-gray-400 col-span-4 sm:col-span-2 font-medium text-sm">
                        Active
                      </label>
                      <div className="col-span-8 sm:col-span-4 formInput">
                        <div className="toggle">
                          <input
                            type="checkbox"
                            name="is_active"
                            id="is_active"
                            checked={values.is_active}
                            onChange={handleChange}
                          />
                          <label htmlFor="is_active">
                            <span className="sr-only">Toggle Switch</span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="flex grid-cols-6 gap-3 md:gap-5 xl:gap-6 lg:gap-6 mb-6 formLabel">
                      <label className="block text-sm text-gray-700 dark:text-gray-400 col-span-4 sm:col-span-2 font-medium text-sm">
                        Navbar
                      </label>
                      <div className="col-span-8 sm:col-span-4 formInput">
                        <div className="toggle">
                          <input
                            type="checkbox"
                            name="is_navbar"
                            id="is_navbar"
                            checked={values.is_navbar}
                            onChange={handleChange}
                          />
                          <label htmlFor="is_navbar">
                            <span className="sr-only">Toggle Switch</span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="px-6 pt-8 fixed bottom-0  right-0 py-4 lg:py-8 px-6 grid gap-4 lg:gap-6 xl:gap-6 md:flex xl:flex bg-gray-50 border-gray-100 border-gray-700 bg-white-800 text-black-300 cancel-btn">
                    <div className="flex-grow-0 md:flex-grow lg:flex-grow xl:flex-grow btn-style">
                      <button
                        className="align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-gray-600 border-gray-200 border text-gray-400 focus:outline-none rounded-lg border border-gray-200 px-4 w-full mr-3 flex items-center justify-center cursor-pointer h-12 bg-gray-200 h-12 bg-white w-full text-red-500 hover:bg-red-50 hover:border-red-100 hover:text-red-600 bg-gray-700 border-white-700 text-gray-500 hover:bg-gray-800 hover:text-red-700"
                        type="button"
                        onClick={() => setOpenDrawer(false)}
                      >
                        Cancel
                      </button>
                    </div>
                    <div className="flex-grow-0 md:flex-grow lg:flex-grow xl:flex-grow btn-style">
                      <button
                        className="align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-white border border-transparent common-background focus:ring focus:ring-green-300 w-full h-12"
                        type="submit"
                        onClick={addEdit && addEdit === 'edit' ? () => handleUpdate(values) : ''}
                      >
                        {addEdit && addEdit === 'edit' ? <span>Edit Category</span> : <span>Add Category</span>}
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          <div
            style={{
              position: 'absolute',
              height: '6px',
              right: '2px',
              bottom: '2px',
              left: '2px',
              borderRadius: '3px',
            }}
          >
            <div
              style={{
                position: 'relative',
                display: 'block',
                height: '100%',
                cursor: 'pointer',
                borderRadius: 'inherit',
                backgroundColor: 'rgba(0, 0, 0, 0.2)',
                width: '0px',
              }}
            />
          </div>
          <Dialog
            open={openDialog}
            keepMounted
            onClose={handleCloseDeleteModal}
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle>{'Delete User'}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                Are you sure you want to delete this image?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDeleteModal}>No</Button>
              <Button onClick={() => handleDeleteImage()}>Yes</Button>
            </DialogActions>
          </Dialog>
          {/* <div
            style={{ position: 'absolute', width: '6px', right: '2px', bottom: '2px', top: '2px', borderRadius: '3px' }}
          >
            <div
              style={{
                position: 'relative',
                display: 'block',
                width: '100%',
                cursor: 'pointer',
                borderradius: 'inherit',
                backgroundColor: 'rgba(0, 0, 0, 0.2)',
                height: '359px',
                transform: 'translateY(0px)',
              }}
            />
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default AddCategory;

import { Card, Container, Stack, Typography } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axiosInstance from '../../axios';

function InvoicePage() {
  const { id } = useParams();
  const [fetchData, setFetchData] = useState({});
  const orderDetails = async () => {
    const order = await axiosInstance(`/order/get-order/${id}`, 'GET');
    setFetchData(order.data.data);
  };
  useEffect(() => {
    orderDetails();
  }, []);
  return (
    <div>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={4}>
          <Typography variant="h4" className="text-lg font-bold text-gray-700 dark:text-gray-300 headers">
            Invoices
          </Typography>
        </Stack>
        <Card className="p-8">
          <div className="bg-white dark:bg-gray-800 mb-4 p-6 lg:p-8 rounded-xl overflow-hidden">
            <div className="">
              <div className="flex lg:flex-row md:flex-row flex-col lg:items-center justify-between pb-4 border-b border-gray-50 dark:border-gray-700 dark:text-gray-300">
                <h1 className="font-bold font-serif text-xl uppercase">
                  Invoice
                  <p className="text-xs mt-1 text-gray-500">
                    Status
                    <span className="pl-2 font-medium text-xs capitalize">
                      {' '}
                      <span className="font-serif">
                        <span
                          className={
                            fetchData?.delivery_current_status  && fetchData?.delivery_current_status !== 'Cancelled'
                              ? 'inline-flex px-2 text-xs font-medium leading-5 rounded-full text-green-500 bg-green-100 dark:bg-green-800 dark:text-green-100'
                              : 'inline-flex px-2 text-xs font-medium leading-5 rounded-full text-red-500 bg-red-100 dark:bg-red-800 dark:text-red-100'
                          }
                        >
                          {fetchData?.delivery_current_status}
                        </span>
                      </span>
                    </span>
                  </p>
                </h1>
              </div>
              <div className="flex lg:flex-row md:flex-row flex-col justify-between pt-4 mt-3">
                <div className="mb-3 md:mb-0 lg:mb-0 flex flex-col">
                  <span className="font-bold font-serif text-sm uppercase text-gray-600 dark:text-gray-500 block">
                    DATE
                  </span>
                  <span className="text-sm text-gray-500 dark:text-gray-400 block">
                    <span>{moment(fetchData?.order_date).format('Do MMM YYYY')}</span>
                  </span>
                </div>
                <div className="mb-3 md:mb-0 lg:mb-0 flex flex-col">
                  <span className="font-bold font-serif text-sm uppercase text-gray-600 dark:text-gray-500 block">
                    INVOICE NO
                  </span>
                  <span className="text-sm text-gray-500 dark:text-gray-400 block">{fetchData?.order_id}</span>
                </div>
                <div className="flex flex-col lg:text-right text-left">
                  <span className="font-bold font-serif text-sm uppercase text-gray-600 dark:text-gray-500 block">
                    INVOICE TO
                  </span>
                  <span className="text-sm text-gray-500 dark:text-gray-400 block">
                    {fetchData?.address} {fetchData?.city}
                    <br />
                    {fetchData?.User?.phone_number || fetchData?.phone_number}
                    <br />
                    {fetchData?.country} {fetchData?.state}, {fetchData?.zip_code}
                  </span>
                </div>
              </div>
            </div>
            <div className="mt-5">
              <div className="w-full overflow-hidden border border-gray-200 dark:border-gray-700 rounded-lg ring-1 ring-black ring-opacity-5 my-8">
                <div className="w-full overflow-x-auto">
                  <table className="w-full whitespace-no-wrap">
                    <thead className="text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b border-gray-200 dark:border-gray-700 bg-gray-100 dark:text-gray-400 dark:bg-gray-800">
                      <tr>
                        <td className="px-4 py-3">SR.</td>
                        <td className="px-4 py-3">Product Title</td>
                        <td className="px-4 py-3 text-center">QUANTITY</td>
                        <td className="px-4 py-3 text-center">ITEM PRICE</td>
                        <td className="px-4 py-3 text-right">AMOUNT</td>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-100 dark:divide-gray-700 dark:bg-gray-800 text-gray-700 dark:text-gray-400 bg-white dark:bg-gray-800 divide-y divide-gray-100 text-serif text-sm ">
                      {fetchData?.OrderItems &&
                        fetchData?.OrderItems.length &&
                        fetchData?.OrderItems.map((item, index) => (
                          <tr className="dark:border-gray-700 dark:text-gray-400">
                            <td className="px-4 py-3 px-6 py-1 whitespace-nowrap font-normal text-gray-500 text-left">
                              {index + 1}
                            </td>
                            <td className="px-4 py-3 px-6 py-1 whitespace-nowrap font-normal text-gray-500">
                              {item?.Product?.name}
                            </td>
                            <td className="px-4 py-3 px-6 py-1 whitespace-nowrap font-bold text-center">
                              {item?.quantity}{' '}
                            </td>
                            <td className="px-4 py-3 px-6 py-1 whitespace-nowrap font-bold text-center">
                              {item?.price}
                            </td>
                            <td className="px-4 py-3 px-6 py-1 whitespace-nowrap text-right font-bold common-textColor dark:text-green-500">
                              {(item?.quantity * item?.price).toFixed(2)}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div
              className="mt-5 border rounded-xl border-gray-100 p-8 py-6 bg-gray-50 dark:bg-gray-900 dark:border-gray-800"
              style={{ borderRadius: 12 }}
            >
              <div className="flex lg:flex-row md:flex-row flex-col justify-between">
                <div className="mb-3 md:mb-0 lg:mb-0  flex flex-col sm:flex-wrap">
                  <span className="mb-1 font-bold font-serif text-sm uppercase text-gray-600 dark:text-gray-500 block common-userPaneFamily">
                    PAYMENT METHOD
                  </span>
                  <span className="text-sm text-gray-500 dark:text-gray-400 font-semibold font-serif block common-userPaneFamily">
                    Card - {fetchData?.payment_method && JSON.parse(fetchData?.payment_method).card.last4}
                  </span>
                  <span className="text-sm text-gray-500 dark:text-gray-400 font-semibold font-serif block common-userPaneFamily">
                    Payment Intent Id: {fetchData?.payment_method && JSON.parse(fetchData?.payment_method).id}
                  </span>
                </div>
                <div className="mb-3 md:mb-0 lg:mb-0  flex flex-col sm:flex-wrap">
                  {fetchData?.discount_code ? (
                    <>
                      <span className="mb-1 font-bold font-serif text-sm uppercase text-gray-600 dark:text-gray-500 block common-userPaneFamily">
                        APPLIED COUPON
                      </span>
                      <span className="text-sm text-gray-500 dark:text-gray-400 font-semibold font-serif block common-userPaneFamily">
                        {fetchData?.discount_code}
                      </span>
                    </>
                  ) : (
                    <span className="mb-1 font-bold font-serif text-sm uppercase text-gray-600 dark:text-gray-500 block common-userPaneFamily">
                      NO APPLIED COUPON
                    </span>
                  )}
                </div>
                {fetchData?.discount_code && (
                  <div className="mb-3 md:mb-0 lg:mb-0  flex flex-col sm:flex-wrap">
                    <span className="mb-1 font-bold font-serif text-sm uppercase text-gray-600 dark:text-gray-500 block common-userPaneFamily">
                      DISCOUNT
                    </span>
                    <span className="text-sm text-gray-500 dark:text-gray-400 font-semibold font-serif block common-userPaneFamily">
                      ₹{fetchData?.discount_amount}
                    </span>
                  </div>
                )}
                <div className="flex flex-col sm:flex-wrap">
                  <span className="mb-1 font-bold font-serif text-sm uppercase text-gray-600 dark:text-gray-500 block common-userPaneFamily">
                    TOTAL AMOUNT
                  </span>
                  <span className="text-xl font-serif font-bold common-textColor dark:text-green-500 block common-userPaneFamily">
                    ₹{fetchData?.main_amount}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </Card>
      </Container>
    </div>
  );
}

export default InvoicePage;

import {
  Box,
  Button,
  CloseButton,
  Flex,
  Image,
  Link,
  Select,
  Stack,
  Text,
  useColorModeValue as mode,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { BsBox2Heart } from "react-icons/bs";
import { PriceTag } from "../cart-page/PriceTag";
import "./wishlistItem.css";
import {
  getWishlist,
  moveWishlistToCart,
  removeWishlist,
} from "../../../actions/wishlistActions";
import { addtocart } from "../../../actions/CartActions";
import { notificationStyle } from "../../product-list/ProductListing";
// import { PriceTag } from './PriceTag';
// import { CartProductMeta } from './CartProductMeta';
import noImage from "../../../assets/noImageAvailable.jpg";

export const WishlistItem = () => {
  const data = useSelector((state) => state.wishlist.wishlist);
  const { homePageData } = useSelector((state) => state.home);
  const { userData } = useSelector((state) => state.user);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (userData?.user_id) {
      dispatch(getWishlist(userData.user_id));
    }
  }, []);
  const handleRemove = (userId, productId, wishlistId) => {
    const data = {
      userId,
      wishlistId,
    };
    dispatch(removeWishlist(data));
    toast.success("Remove Successfully", notificationStyle);
  };

  const handleCart = (userId, productId, quantity, wishlistId) => {
    const data = {
      userId,
      quantity,
      productId,
    };
    const removePayload = {
      userId,
      wishlistId,
    };
    dispatch(moveWishlistToCart(data, removePayload));
    toast.success("Item Added to cart", notificationStyle);
  };
  return (
    <>
      {data && data.length === 0 ? (
        <Box width={"100%"}>
          <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
            <BsBox2Heart fontSize={"100px"} color="#f7f5f4" />
          </Box>
          <Text
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            marginTop={"20px"}
            className="empty-wishlist-main"
          >
            Your Wishlist is Empty
          </Text>
        </Box>
      ) : (
        data &&
        data?.map((item) => (
          <Flex
            direction={{
              base: "column",
              md: "row",
            }}
            justify="space-between"
            align="center"
          >
            <Stack direction="row" spacing="5" width="full">
              {item?.Product?.ProductImages &&
                item?.Product?.ProductImages?.length ? (
                <Image
                  width="120px"
                  height="120px"
                  fit="cover"
                  src={`${process.env.REACT_APP_IMAGE_URL}${item?.Product?.ProductImages[0]?.image_url}`}
                  alt={item?.Product?.name}
                  draggable="false"
                  loading="lazy"
                  cursor={"pointer"}
                  className="common-borderRadius"
                  onClick={() =>
                    navigate(`/product-details/${item.Product.slug}`)
                  }
                />
              ) : (
                <Image
                  width="120px"
                  height="120px"
                  fit="cover"
                  src={noImage}
                  alt={item?.Product?.name}
                  draggable="false"
                  loading="lazy"
                  cursor={"pointer"}
                  className="common-borderRadius"
                  onClick={() =>
                    navigate(`/product-details/${item.Product.slug}`)
                  }
                />
              )}
              <Box pt="4">
                <Stack spacing="0.5">
                  <Text
                    className="common-userPaneFamily wrap-word2"
                    fontSize={"18px"}
                    fontWeight={600}
                    color={"rgb(79, 79, 79)"}
                    onClick={() =>
                      navigate(`/product-details/${item.Product.slug}`)
                    }
                    cursor={"pointer"}
                  >
                    {item?.Product?.name}
                  </Text>
                  <Text
                    fontSize={"16px"}
                    fontWeight={400}
                    color={"rgb(79, 79, 79)"}
                    className="common-userPaneFamily wrap-word2"
                    width={"81%"}
                  >
                    {item?.Product?.short_description}
                  </Text>
                </Stack>
              </Box>
            </Stack>
            <Flex
              width="full"
              justify="space-between"
              display={{
                base: "none",
                md: "flex",
              }}
            >
              <PriceTag price={item?.Product?.price} />
              <Button
                rounded={"none"}
                w={"40%"}
                py={"7"}
                background={'transparent'}
                fontSize={{ base: "15px", md: "15px", sm: "15px", lg: "15px" }}
                className="addcart-btn common-borderRadius"
                textTransform={"uppercase"}
                leftIcon={<AiOutlineShoppingCart size={"20px"} />}
                onClick={() =>
                  handleCart(2, item?.Product.product_id, 1, item?.wishlist_id)
                }
              >
                {homePageData &&
                  homePageData?.setting &&
                  homePageData?.setting?.addToCart
                  ? homePageData?.setting?.addToCart
                  : "Add To Cart"}
              </Button>
              <CloseButton
                onClick={() =>
                  handleRemove(2, item?.Product?.product_id, item?.wishlist_id)
                }
              />
            </Flex>
            <Flex
              mt="4"
              align="center"
              width="full"
              justify="space-between"
              display={{
                base: "flex",
                md: "none",
              }}
            >
              <Link
                fontSize="sm"
                textDecor="underline"
                onClick={() =>
                  handleRemove(2, item?.Product?.product_id, item?.wishlist_id)
                }
              >
                Delete
              </Link>

              <PriceTag price={item?.Product?.price} />
              <Button
                rounded={"none"}
                w={"10%"}
                // py={'7'}
                fontSize={{ base: "15px", md: "15px", sm: "15px", lg: "15px" }}
                className="moveCart-btn common-borderRadius"
                textTransform={"uppercase"}
                leftIcon={<AiOutlineShoppingCart size={"20px"} />}
                onClick={() =>
                  handleCart(2, item?.Product.product_id, 1, item?.wishlist_id)
                }
                background={'transparent'}
              >
                {homePageData &&
                  homePageData?.setting &&
                  homePageData?.setting?.addToCart
                  ? homePageData?.setting?.addToCart
                  : "Add To Cart"}
              </Button>
            </Flex>
          </Flex>
        ))
      )}
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};

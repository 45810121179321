// reducers.js
import {
  FETCH_USER_REQUEST,
  FETCH_USER_SUCCESS,
  FETCH_USER_FAILURE,
  FETCH_TOKEN_SUCCESS,
} from '../actions/UserActions';

const initialState = {
  loading: false,
  userData: {},
  token: '',
  error: '',
};

const UserReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        userData: action.payload,
        error: '',
      };
    case FETCH_TOKEN_SUCCESS:
      return {
        ...state,
        loading: false,
        token: action.payload,
        error: '',
      };
    case FETCH_USER_FAILURE:
      return {
        loading: false,
        userData: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export default UserReducer;

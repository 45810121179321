import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Link,
  Stack,
  Image,
  Textarea,
  Text,
  useColorModeValue as mode,
  Box,
  RadioGroup,
  Radio,
  Divider,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
} from "@chakra-ui/react";
import axios from "axios";
import qs from "qs";
import { useDispatch, useSelector } from "react-redux";
import { FiEdit } from "react-icons/fi";
import "./checkoutForm.css";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  ToastContainer,
  toast,
  Flip,
  cssTransition,
  Bounce,
} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import store from "../../../store";
import { fetchHomeData } from "../../../actions/HomePageActions";
import Header from "../../../components/header";
import {
  checkoutAction,
  handleCheckoutAddressAction,
  orderPlaceAction,
} from "../../../actions/CheckoutActions";
import Footer from "../../../components/footer/Footer";
import { updateUserData } from "../../../actions/UserActions";
import axiosInstance from "../../../axios";
import { fetchDataSuccess } from "../../../actions/OfferActions";
import Loader from "../../../components/loader/Loader";

function CheckoutForm() {
  const [value, setValue] = React.useState("1");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { homePageData } = useSelector((state) => state.home);
  const { userData } = useSelector((state) => state.user);
  const { cart } = useSelector((state) => state.cart);
  const { checkoutData, shippingAddress } = useSelector(
    (state) => state.checkout
  );
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const initialRef = React.useRef(null);
  const navigate = useNavigate();
  const [openModel, setOpenModel] = useState("add");
  const [error, setError] = useState("");
  const [errorMsg, setErrorMsg] = useState(false);
  const finalRef = React.useRef(null);
  const [address, setAddress] = useState({
    first_name: "",
    last_name: "",
    phone_number: "",
    address: "",
    landmark: "",
    city: "",
    state: "",
    country: "",
    zip_code: "",
  });
  const validationSchema = Yup.object({
    first_name: Yup.string().required("First Name is required").trim(),
    last_name: Yup.string().required("Last Name is required").trim(),
    phone_number: Yup.string()
      .required("Phone Number is required")
      .matches(/[0-9]/g, "Please Enter Valid Number")
      .min(10, "Phone Number must be at least 10 numbers")
      .max(10, "Phone Number must be at least 10 numbers")
      .trim(),
    address: Yup.string().required("Address is required").trim(),
    landmark: Yup.string().required("landmark is required").trim(),
    city: Yup.string().required("City is required").trim(),
    state: Yup.string().required("State is required").trim(),
    country: Yup.string().required("Country is required").trim(),
    zip_code: Yup.string().required("Zip Code is required").trim(),
  });

  const getToken = async () => {
    const response = await axios.post(
      "https://apiv2.shiprocket.in/v1/external/auth/login",
      {
        email: 'devamvadher53@gmail.com',
        password: '@Deve5121513',
      }
    );
    if (response.data && response.data.token) {
      return response.data.token;
    }
    return null;
  };
  useEffect(() => {
    if (!homePageData.length) {
      dispatch(fetchHomeData());
    }
    if (shippingAddress) {
      setAddress(shippingAddress);
    }
    if (!cart.length) {
      navigate("/cart");
    }
    return () => {
      // dispatch(checkoutAction({}));
      // dispatch(handleCheckoutAddressAction({}));
      // dispatch(fetchDataSuccess({}));
    };
  }, []);

  useEffect(() => {
    if (
      shippingAddress &&
      shippingAddress.address &&
      shippingAddress.address !== ""
    ) {
      setAddress(shippingAddress);
    }
  }, [shippingAddress]);
  const handleAddressSubmit = (values) => {
    setAddress(values);
    // setOpenModel('add');
    dispatch(handleCheckoutAddressAction(values));
    // setErrorMsg(false);
  };
  const handleChangePassword = () => {
    setOpenModel("change");
    setAddress(shippingAddress);
  };
  function findObjectWithLowestValue(array, property) {
    const lowestObject = array.reduce((lowest, current) => {
      const lowestValue = lowest[property];
      const currentValue = current[property];
      return currentValue < lowestValue ? current : lowest;
    });

    return lowestObject;
  }

  function calculateDimensions(products) {
    // Calculate the total purchase quantity
    const totalQuantity = products.reduce(
      (sum, product) => sum + product.quantity,
      0
    );

    // Define default dimensions
    let weight = 0.25;
    const length = 18;
    const width = 15;
    const height = 7;

    // Adjust dimensions based on the total purchase quantity
    if (totalQuantity === 1) {
      weight = 0.25;
    } else if (totalQuantity === 2) {
      weight = 0.35;
    } else if (totalQuantity > 2) {
      weight = 0.5;
    }

    // Return the dimensions
    return { weight, length, width, height };
  }
  const handlePlaceOrder = async (values) => {
    setErrorMsg(false);
    setLoading(true);
    setAddress(values);
    dispatch(handleCheckoutAddressAction(values));
    const shiprocketToken = await getToken();
    try {
      const dimensions = calculateDimensions(checkoutData.productList);
     
      const config = {
        method: "get",
        url: `https://apiv2.shiprocket.in/v1/external/courier/serviceability/?pickup_postcode=380007&delivery_postcode=${values.zip_code}&cod=0&weight=${dimensions.weight}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${shiprocketToken}`,
        },
        // data: shippingData,
      };

      const shippingPartnerList = await axios.request(config);
      if (
        shippingPartnerList &&
        shippingPartnerList.data &&
        shippingPartnerList.data.status === 200
      ) {
        const findLowShippingPartner = findObjectWithLowestValue(
          shippingPartnerList.data.data.available_courier_companies,
          "rate"
        );

        // Place order code remains mostly the same
        const placeOrder = await axiosInstance("/cart/place-order", "POST", {
          offerData: checkoutData.offerData,
          productList: checkoutData.productList,
          total: checkoutData.total,
          userData,
          shippingAddress: {
            first_name: values.first_name,
            last_name: values.last_name,
            address: values.address,
            zip_code: values.zip_code,
            landmark: values.landmark,
            phone_number: values.phone_number,
            city: values.city,
            state: values.state,
            country: values.country,
            partner_id: findLowShippingPartner.courier_company_id,
            partner_name: findLowShippingPartner.courier_name,
            delivery_charges: findLowShippingPartner.rate,
          },
        });

        if (placeOrder.status === 200) {
          // Handle successful order placement
          const createOrderResponse = await axiosInstance(
            "/cart/order",
            "POST",
            {
              amount: Number(
                (Number(checkoutData.total.mainTotal) * 100).toFixed(2)
              ), // amount in your desired currency's smallest unit (e.g., paise)
              currency: "INR", // currency code (e.g., INR, USD)
            }
          );

          const { id } = createOrderResponse.data.data;
          const options = {
            key: process.env.REACT_APP_RZP_KEY,
            // key: 'rzp_test_6Ygdqjb5tNhfBu',
            amount: Number(
              (Number(checkoutData.total.mainTotal) * 100).toFixed(2)
            ), // amount in your desired currency's smallest unit (e.g., paise)
            currency: "INR", // currency code (e.g., INR, USD)
            name: "Canon Quartz",
            description: "Order Payment",
            order_id: id,
            handler: async (response) => {
              try {
                setLoading(true);
                const verifyPaymentResponse = await axiosInstance(
                  "/cart/verify",
                  "POST",
                  {
                    orderId: response.razorpay_order_id,
                    paymentId: response.razorpay_payment_id,
                    signature: response.razorpay_signature,
                  }
                );
                if (verifyPaymentResponse) {
                  const createOrderResponse = await axiosInstance(
                    `/order/update-order/${placeOrder.data.data.order_id}`,
                    "PUT",
                    {
                      order_number: `CNQT${placeOrder.data.data.order_id}`,
                      payment_intent_id: response.razorpay_payment_id,
                      payment_status: "succeeded",
                      payment_method: JSON.stringify(
                        verifyPaymentResponse.data.data.payment
                      ),
                      productList: checkoutData.productList,
                      total: checkoutData.total,
                      userData,
                      shippingAddress: {
                        first_name: values.first_name,
                        last_name: values.last_name,
                        address: values.address,
                        zip_code: values.zip_code,
                        landmark: values.landmark,
                        phone_number: values.phone_number,
                        city: values.city,
                        state: values.state,
                        country: values.country,
                        partner_id: findLowShippingPartner.id,
                      },
                    }
                  );

                  toast.success("Order Placed Successfully", {
                    position: "top-right",
                    autoClose: 500,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                  });
                  setLoading(false);
                  navigate(`/success/${placeOrder.data.data.order_id}`);
                } else {
                  setLoading(false);
                  toast.error(
                    "Oops! Payment Verification getting failed, Please try again ",
                    {
                      position: "top-right",
                      autoClose: 700,
                      hideProgressBar: true,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light",
                      transition: Bounce,
                    }
                  );
                }

                // Handle successful payment verification
              } catch (error) {
                console.error(error);
                setLoading(false);
                // Handle error in payment verification
              }
            },
            prefill: {
              name: `${userData.first_name} ${userData.last_name}`,
              email: userData.email,
              contact: userData.phone_number,
            },
            theme: {
              color: "#ed691f",
            },
          };
          const rzp1 = new window.Razorpay(options);
          rzp1.open();
        } else {
          setLoading(false);
          toast.error(placeOrder.data.message, {
            position: "top-right",
            autoClose: 700,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });
        }
      } else {
        setError(
          "Oops! This ZIP is not serviceable at this moment, Please try again with another ZIP"
        );
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  return (
    <div>
      <Header headerData={homePageData} />
      <Box height={loading ? "80vh" : ""}>
        <Loader loading={loading} />
        <Formik
          enableReinitialize
          initialValues={address}
          validationSchema={validationSchema}
          onSubmit={(values, { resetForm }) => {
            console.log("error", error);
            handlePlaceOrder(values, resetForm);
            // setSubmitting(true);
            // resetForm();
            // setSubmitting(false);
          }}
          validate={(values) => {
            if (values.zip_code) {
              setError("");
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            isValid,
            dirty,
            isSubmitting,
          }) => (
            <Box
              maxW={{
                base: "3xl",
                lg: "7xl",
              }}
              mx="auto"
              px={{
                base: "4",
                md: "8",
                lg: "12",
              }}
              py={{
                base: "6",
                md: "8",
                lg: "12",
              }}
              opacity={loading ? 0.3 : ""}
              className="form-box"
            >
              {console.log("errorserrors", errors, touched)}
              <Text
                fontSize="2xl"
                fontWeight="extrabold"
                marginBottom={"15px"}
                className="common-userPaneFamily"
              >
                Shipping Address
              </Text>
              {/* {address?.address === undefined || address?.address === '' || openModel === 'change' ? ( */}

              {/* <Stack
                w={'full'}
                marginRight={10}
                spacing={{
                  base: '8',
                  md: '4',
                }}
                flex="2"
              > */}
              <Form onSubmit={handleSubmit}>
                <Stack
                  direction={{
                    base: "column",
                    lg: "row",
                  }}
                  align={{
                    lg: "flex-start",
                  }}
                  spacing={{
                    base: "8",
                    md: "16",
                  }}
                  width={"full"}
                >
                  <Stack
                    w={"full"}
                    marginRight={10}
                    spacing={{
                      base: "8",
                      md: "4",
                    }}
                    flex="2"
                  >
                    <Flex>
                      <FormControl
                        id="firstName"
                        marginRight={"15px"}
                        marginBottom={"10px"}
                      >
                        <FormLabel
                          className="common-userPaneFamily"
                          fontWeight={600}
                          color={"rgb(79, 79, 79)"}
                        >
                          First Name
                        </FormLabel>
                        <Input
                          type="text"
                          name="first_name"
                          placeholder="First Name"
                          value={values.first_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={
                            touched.first_name && errors.first_name
                              ? "common-userPaneFamily input-error"
                              : "common-userPaneFamily"
                          }
                        />
                        {touched.first_name && errors.first_name && (
                          <div className="error-message">
                            {errors.first_name}
                          </div>
                        )}
                      </FormControl>
                      <FormControl id="LastName" marginBottom={"10px"}>
                        <FormLabel
                          className="common-userPaneFamily"
                          fontWeight={600}
                          color={"rgb(79, 79, 79)"}
                        >
                          Last Name
                        </FormLabel>
                        <Input
                          type="text"
                          name="last_name"
                          placeholder="Last Name"
                          value={values.last_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={
                            touched.last_name && errors.last_name
                              ? "common-userPaneFamily input-error"
                              : "common-userPaneFamily"
                          }
                        />
                        {touched.last_name && errors.last_name && (
                          <div className="error-message">
                            {errors.last_name}
                          </div>
                        )}
                      </FormControl>
                    </Flex>
                    <FormControl marginBottom={"10px"}>
                      <FormLabel
                        className="common-userPaneFamily"
                        fontWeight={600}
                        color={"rgb(79, 79, 79)"}
                      >
                        Address
                      </FormLabel>
                      <Textarea
                        type="text"
                        name="address"
                        placeholder="Address"
                        value={values.address}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={
                          touched.address && errors.address
                            ? "common-userPaneFamily input-error"
                            : "common-userPaneFamily"
                        }
                      />
                      {touched.address && errors.address && (
                        <div className="error-message">{errors.address}</div>
                      )}
                    </FormControl>
                    <FormControl id="LastName" marginBottom={"10px"}>
                      <FormLabel
                        className="common-userPaneFamily"
                        fontWeight={600}
                        color={"rgb(79, 79, 79)"}
                      >
                        Landmark
                      </FormLabel>
                      <Input
                        type="text"
                        name="landmark"
                        placeholder="Landmark"
                        value={values.landmark}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={
                          touched.landmark && errors.landmark
                            ? "common-userPaneFamily input-error"
                            : "common-userPaneFamily"
                        }
                      />
                      {touched.landmark && errors.landmark && (
                        <div className="error-message">{errors.landmark}</div>
                      )}
                    </FormControl>

                    <Flex>
                      <FormControl marginRight={"15px"} marginBottom={"10px"}>
                        <FormLabel
                          className="common-userPaneFamily"
                          fontWeight={600}
                          color={"rgb(79, 79, 79)"}
                        >
                          Zip Code
                        </FormLabel>
                        <Input
                          type="text"
                          placeholder="Zip Code"
                          name="zip_code"
                          value={values.zip_code}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={
                            touched.zip_code && errors.zip_code
                              ? "common-userPaneFamily input-error"
                              : "common-userPaneFamily"
                          }
                        />
                        {touched.zip_code && errors.zip_code ? (
                          <div className="error-message">{errors.zip_code}</div>
                        ) : error && error !== "" ? (
                          <div className="error-message">{error}</div>
                        ) : null}
                      </FormControl>
                      <FormControl marginBottom={"10px"}>
                        <FormLabel
                          className="common-userPaneFamily"
                          fontWeight={600}
                          color={"rgb(79, 79, 79)"}
                        >
                          Phone Number
                        </FormLabel>
                        <Input
                          type="text"
                          placeholder="Phone Number"
                          name="phone_number"
                          value={values.phone_number}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={
                            touched.phone_number && errors.phone_number
                              ? "common-userPaneFamily input-error"
                              : "common-userPaneFamily"
                          }
                        />
                        {touched.phone_number && errors.phone_number && (
                          <div className="error-message">
                            {errors.phone_number}
                          </div>
                        )}
                      </FormControl>
                    </Flex>
                    <Flex>
                      <FormControl marginRight={"15px"} marginBottom={"10px"}>
                        <FormLabel
                          className="common-userPaneFamily"
                          fontWeight={600}
                          color={"rgb(79, 79, 79)"}
                        >
                          City
                        </FormLabel>
                        <Input
                          type="text"
                          placeholder="City"
                          name="city"
                          value={values.city}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={
                            touched.city && errors.city
                              ? "common-userPaneFamily input-error"
                              : "common-userPaneFamily"
                          }
                        />
                        {touched.city && errors.city && (
                          <div className="error-message">{errors.city}</div>
                        )}
                      </FormControl>
                      <FormControl marginRight={"15px"} marginBottom={"10px"}>
                        <FormLabel
                          className="common-userPaneFamily"
                          fontWeight={600}
                          color={"rgb(79, 79, 79)"}
                        >
                          State
                        </FormLabel>
                        <Input
                          type="text"
                          placeholder="State"
                          name="state"
                          value={values.state}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={
                            touched.state && errors.state
                              ? "common-userPaneFamily input-error"
                              : "common-userPaneFamily"
                          }
                        />
                        {touched.state && errors.state && (
                          <div className="error-message">{errors.state}</div>
                        )}
                      </FormControl>
                      <FormControl marginBottom={"10px"}>
                        <FormLabel
                          className="common-userPaneFamily"
                          fontWeight={600}
                          color={"rgb(79, 79, 79)"}
                        >
                          Country
                        </FormLabel>
                        <Input
                          type="text"
                          placeholder="Country"
                          name="country"
                          value={values.country}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={
                            touched.country && errors.country
                              ? "common-userPaneFamily input-error"
                              : "common-userPaneFamily"
                          }
                        />
                        {touched.country && errors.country && (
                          <div className="error-message">{errors.country}</div>
                        )}
                      </FormControl>
                    </Flex>
                  </Stack>

                  <Stack
                    spacing="8"
                    borderWidth="1px"
                    rounded="lg"
                    padding="8"
                    width={{ md: "45%", lg: "35%", base: "100%", sm: "100%" }}
                    marginTop={{ base: "10px", sm: "10px" }}
                    height={"-webkit-fit-content"}
                  >
                    <Heading size="md" className="common-userPaneFamily">
                      Order Summary
                    </Heading>
                    <Stack spacing="6">
                      <Flex justify="space-between">
                        <Text
                          fontWeight="medium"
                          className="common-userPaneFamily common-cartPage"
                        >
                          Bag Total
                        </Text>
                        <Text
                          fontWeight="medium"
                          className="common-userPaneFamily common-cartPage"
                        >
                          <span>
                            ₹{checkoutData && checkoutData?.total?.totalAmount}
                          </span>
                        </Text>
                      </Flex>
                      <Flex justify="space-between">
                        <Text
                          fontWeight="medium"
                          className="common-userPaneFamily common-cartPage"
                        >
                          Bag Discount
                        </Text>
                        <Text
                          fontWeight="medium"
                          className="common-userPaneFamily common-cartPage"
                        >
                          <span style={{ color: "#ed691f" }}>
                            - ₹
                            {checkoutData &&
                              Number(
                                checkoutData?.total?.discountAmount
                              ).toFixed(2)}
                          </span>
                        </Text>
                      </Flex>
                      {/* <Flex justify="space-between">
                        <Text fontWeight="medium" className="common-userPaneFamily common-cartPage">
                          Estimated Tax (<span style={{ color: '#ed691f' }}>GST 18%</span>)
                        </Text>
                        <Text fontWeight="medium" className="common-userPaneFamily common-cartPage">
                          <span>₹{checkoutData && checkoutData?.total?.taxAmount}</span>
                        </Text>
                      </Flex> */}
                      {checkoutData &&
                        checkoutData?.offerData?.code &&
                        checkoutData?.offerData?.code !== "" && (
                          <Flex justify="space-between">
                            <Text
                              fontWeight="medium"
                              className="common-userPaneFamily common-cartPage"
                            >
                              Coupon Discount (
                              <span style={{ color: "#ed691f" }}>
                                {checkoutData && checkoutData?.offerData?.code}
                              </span>
                              )
                            </Text>
                            <Text
                              fontWeight="medium"
                              className="common-userPaneFamily common-cartPage"
                            >
                              <span style={{ color: "#ed691f" }}>
                                - ₹
                                {checkoutData &&
                                  checkoutData?.total?.coupenAmount}
                              </span>
                            </Text>
                          </Flex>
                        )}
                      <Flex justify="space-between">
                        <Text
                          fontSize="lg"
                          fontWeight="semibold"
                          className="common-userPaneFamily"
                        >
                          Total
                        </Text>
                        <Text
                          fontSize="lg"
                          fontWeight="semibold"
                          className="common-userPaneFamily"
                        >
                          <span>
                            ₹{checkoutData && checkoutData?.total?.mainTotal}
                          </span>
                        </Text>
                      </Flex>
                      <Button
                        type="submit"
                        rounded={"none"}
                        isDisabled={!cart?.length}
                        className="place-orderBtn"
                      >
                        {homePageData &&
                        homePageData?.setting &&
                        homePageData?.setting?.placeOrder
                          ? homePageData?.setting?.placeOrder
                          : "Place Order"}
                      </Button>
                    </Stack>
                  </Stack>
                </Stack>
              </Form>
              {/* </Stack> */}
            </Box>
          )}
        </Formik>
      </Box>
      <Footer />
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}

export default CheckoutForm;

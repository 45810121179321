import {
  CloseButton,
  Flex,
  Link,
  Select,
  useColorModeValue,
} from "@chakra-ui/react";
import { useState } from "react";
import { PriceTag } from "./PriceTag";
import { CartProductMeta } from "./CartProductMeta";
import noImage from "../../../assets/noImageAvailable.jpg";

export const CartItem = (props) => {
  const {
    name,
    description,
    quantity,
    price,
    handleQuantity,
    handleDeleteItem,
  } = props;
  const [qty, setQty] = useState(1);
  return (
    <Flex
      direction={{
        base: "column",
        md: "row",
      }}
      justify="space-between"
      align="center"
      borderWidth={"1px"}
      padding={{ base: "10px" }}
    >
      <CartProductMeta
        name={props.Product.name}
        product={props.Product}
        image={
          props.Product.ProductImages && props.Product.ProductImages.length
            ? `${process.env.REACT_APP_IMAGE_URL}${props.Product.ProductImages[0].image_url}`
            : noImage
        }
        id={props.product_id}
        slug={props.Product.slug}
      />

      {/* Desktop */}
      <Flex
        width="full"
        justify="space-between"
        display={{
          base: "none",
          md: "flex",
        }}
      >
        {/* <QuantitySelect
          value={quantity}
          onChange={(e) => {
            onChangeQuantity?.(+e.currentTarget.value);
          }}
        /> */}
        <div>
          <div className="js-qty__wrapper common-borderRadius">
            <label
              htmlFor="cart_updates_40516022501443:72da6388b70622a9b6f20eebea824c5a"
              className="hidden-label common-userPaneFamily"
            >
              Quantity
            </label>
            <input
              type="text"
              id="cart_updates_40516022501443:72da6388b70622a9b6f20eebea824c5a"
              name="updates[]"
              className="js-qty__num"
              value={props.quantity}
              min="0"
              pattern="[0-9]*"
              data-id="40516022501443:72da6388b70622a9b6f20eebea824c5a"
            />
            <button
              type="button"
              className="js-qty__adjust js-qty__adjust--minus"
              aria-label="Reduce item quantity by one"
              onClick={() => handleQuantity(props.cart_id, props.quantity - 1)}
              disabled={props.quantity === 1}
            >
              <svg
                aria-hidden="true"
                focusable="false"
                role="presentation"
                className="icon icon-minus"
                viewBox="0 0 20 20"
              >
                <path
                  fill="#444"
                  d="M17.543 11.029H2.1A1.032 1.032 0 0 1 1.071 10c0-.566.463-1.029 1.029-1.029h15.443c.566 0 1.029.463 1.029 1.029 0 .566-.463 1.029-1.029 1.029z"
                />
              </svg>
              <span className="icon__fallback-text" aria-hidden="true">
                −
              </span>
            </button>
            <button
              type="button"
              className="js-qty__adjust js-qty__adjust--plus"
              aria-label="Increase item quantity by one"
              onClick={() => handleQuantity(props.cart_id, props.quantity + 1)}
            >
              <svg
                aria-hidden="true"
                focusable="false"
                role="presentation"
                className="icon icon-plus"
                viewBox="0 0 20 20"
              >
                <path
                  fill="#444"
                  d="M17.409 8.929h-6.695V2.258c0-.566-.506-1.029-1.071-1.029s-1.071.463-1.071 1.029v6.671H1.967C1.401 8.929.938 9.435.938 10s.463 1.071 1.029 1.071h6.605V17.7c0 .566.506 1.029 1.071 1.029s1.071-.463 1.071-1.029v-6.629h6.695c.566 0 1.029-.506 1.029-1.071s-.463-1.071-1.029-1.071z"
                />
              </svg>
              <span className="icon__fallback-text" aria-hidden="true">
                +
              </span>
            </button>
          </div>
        </div>
        <PriceTag
          price={
            props.quantity *
            (Number(props.Product.price) -
              (
                (Number(props.Product.price) * Number(props.Product.discount)) /
                100
              ).toFixed(2))
          }
        />
        <CloseButton
          aria-label={`Delete ${name} from cart`}
          onClick={() => handleDeleteItem(props.cart_id)}
        />
      </Flex>

      {/* Mobile */}
      <Flex
        mt="4"
        align="center"
        width="full"
        justify="space-between"
        display={{
          base: "flex",
          md: "none",
        }}
      >
        <Link
          fontSize="sm"
          textDecor="underline"
          onClick={() => handleDeleteItem(props.cart_id)}
        >
          Delete
        </Link>
        <div>
          <div className="js-qty__wrapper common-borderRadius">
            <label
              htmlFor="cart_updates_40516022501443:72da6388b70622a9b6f20eebea824c5a"
              className="hidden-label common-userPaneFamily"
            >
              Quantity
            </label>
            <input
              type="text"
              id="cart_updates_40516022501443:72da6388b70622a9b6f20eebea824c5a"
              name="updates[]"
              className="js-qty__num"
              value={props.quantity}
              min="0"
              pattern="[0-9]*"
              data-id="40516022501443:72da6388b70622a9b6f20eebea824c5a"
            />
            <button
              type="button"
              className="js-qty__adjust js-qty__adjust--minus"
              aria-label="Reduce item quantity by one"
              onClick={() => handleQuantity(props.cart_id, props.quantity - 1)}
              disabled={props.quantity === 1}
            >
              <svg
                aria-hidden="true"
                focusable="false"
                role="presentation"
                className="icon icon-minus"
                viewBox="0 0 20 20"
              >
                <path
                  fill="#444"
                  d="M17.543 11.029H2.1A1.032 1.032 0 0 1 1.071 10c0-.566.463-1.029 1.029-1.029h15.443c.566 0 1.029.463 1.029 1.029 0 .566-.463 1.029-1.029 1.029z"
                />
              </svg>
              <span className="icon__fallback-text" aria-hidden="true">
                −
              </span>
            </button>
            <button
              type="button"
              className="js-qty__adjust js-qty__adjust--plus"
              aria-label="Increase item quantity by one"
              onClick={() => handleQuantity(props.cart_id, props.quantity + 1)}
            >
              <svg
                aria-hidden="true"
                focusable="false"
                role="presentation"
                className="icon icon-plus"
                viewBox="0 0 20 20"
              >
                <path
                  fill="#444"
                  d="M17.409 8.929h-6.695V2.258c0-.566-.506-1.029-1.071-1.029s-1.071.463-1.071 1.029v6.671H1.967C1.401 8.929.938 9.435.938 10s.463 1.071 1.029 1.071h6.605V17.7c0 .566.506 1.029 1.071 1.029s1.071-.463 1.071-1.029v-6.629h6.695c.566 0 1.029-.506 1.029-1.071s-.463-1.071-1.029-1.071z"
                />
              </svg>
              <span className="icon__fallback-text" aria-hidden="true">
                +
              </span>
            </button>
          </div>
        </div>
        <PriceTag
          price={
            props.quantity *
            (Number(props.Product.price) -
              (
                (Number(props.Product.price) * Number(props.Product.discount)) /
                100
              ).toFixed(2))
          }
        />
      </Flex>
    </Flex>
  );
};

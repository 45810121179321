import React, { useEffect, useState } from "react";
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Button,
  Input,
  useDisclosure,
  Box,
  Image,
  Text,
  Flex,
  Grid,
  GridItem,
  SimpleGrid,
  Badge,
  Stack,
} from "@chakra-ui/react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  AiOutlineHeart,
  AiOutlineCloseCircle,
  AiOutlineShoppingCart,
} from "react-icons/ai";
import "./wishlist.css";
import { BsBox2Heart } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import newArrival1 from "../../assets/newArrival1.webp";
import {
  getWishlist,
  moveWishlistToCart,
  removeWishlist,
} from "../../actions/wishlistActions";
import store from "../../store";
import { notificationStyle } from "../../pages/product-list/ProductListing";
import noImage from "../../assets/noImageAvailable.jpg";

function WishlistPage({ children, ...rest }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { wishlist } = useSelector((state) => state.wishlist);
  const { userData } = useSelector((state) => state.user);
  const { homePageData } = useSelector((state) => state.home);
  const [style, setStyle] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const btnRef = React.useRef();
  useEffect(() => {
    if(userData?.user_id){
      dispatch(getWishlist(userData?.user_id));
    }
  }, []);
  useEffect(() => {
    if(userData?.user_id){
      dispatch(getWishlist(userData?.user_id));
    }
  }, [isOpen]);

  const handleRemove = (userId, productId, wishlistId) => {
    const data = {
      userId,
      wishlistId,
    };
    dispatch(removeWishlist(data));
    toast.success("Remove Successfully", notificationStyle);
  };

  const handleCart = (userId, productId, quantity, wishlistId) => {
    const data = {
      userId,
      quantity,
      productId,
    };
    const removePayload = {
      userId,
      wishlistId,
    };
    setStyle(productId);
    dispatch(moveWishlistToCart(data, removePayload));
    toast.success("Item Added to cart", notificationStyle);
  };
  return (
    <>
      {/* <Button ref={btnRef} colorScheme="teal" onClick={onOpen}>
        Open
      </Button> */}
      <AiOutlineHeart
        ref={btnRef}
        onClick={onOpen}
        size={25}
        color="#ed691f"
        cursor={"pointer"}
        className="header-icon"
      />
      <Drawer
        variant="alwaysOpen"
        {...rest}
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        trapFocus={false}
        closeOnOverlayClick={false}
        blockScrollOnMount={false}
      >
        {/* <DrawerOverlay /> */}
        <DrawerContent maxWidth={"30rem"}>
          <DrawerCloseButton />
          <DrawerHeader
            className="common-userPaneFamily"
            fontWeight="extrabold"
          >
            My Wishlist
          </DrawerHeader>

          <DrawerBody>
            {wishlist && wishlist.length === 0 ? (
              <Box>
                <Box className="empty-boxWishlist">
                  <BsBox2Heart fontSize={"100px"} color="#f7f5f4" />
                </Box>
                <Text className="empty-wishlist">Your Wishlist is Empty</Text>
              </Box>
            ) : (
              wishlist &&
              wishlist?.map((item) => (
                <>
                  {" "}
                  <Box
                    //   boxShadow={' rgba(0, 0, 0, 0.1) 0px 4px 12px'}
                    borderTop={"1px solid lightgray"}
                    borderBottom={"1px solid lightgray"}
                    padding={"10px"}
                    width={{ base: "100%", sm: "100%", md: "100%", lg: "100%" }}
                    background={"white"}
                    display={"flex"}
                    pos={"relative"}
                    className={
                      style === item.product_id
                        ? "wishlist-item removed"
                        : "wishlist-item added"
                    }
                  >
                    <Box
                      position={"relative"}
                      maxWidth={"97px"}
                      className="image-size"
                    >
                      {item?.Product?.ProductImages &&
                      item?.Product?.ProductImages?.length ? (
                        <Image
                          width={{
                            base: "100%",
                            sm: "100%",
                            md: "100%",
                            lg: "100%",
                          }}
                          src={`${process.env.REACT_APP_IMAGE_URL}${item.Product.ProductImages[0].image_url}`}
                          position={"relative"}
                          cursor={"pointer"}
                          onClick={() =>
                            navigate(`/product-details/${item.Product.slug}`)
                          }
                        />
                      ) : (
                        <Image
                          width={{
                            base: "100%",
                            sm: "100%",
                            md: "100%",
                            lg: "100%",
                          }}
                          src={noImage}
                          position={"relative"}
                          // className="common-borderRadius"
                          cursor={"pointer"}
                          onClick={() =>
                            navigate(`/product-details/${item.Product.slug}`)
                          }
                        />
                      )}
                    </Box>
                    <div className="closed-icon">
                      <AiOutlineCloseCircle
                        size={18}
                        onClick={() =>
                          handleRemove(
                            2,
                            item?.Product?.product_id,
                            item?.wishlist_id
                          )
                        }
                      />
                    </div>
                    <SimpleGrid display={"initial"} width="100%">
                      <Text
                        className="watch-details2 wrap-word"
                        paddingLeft={"10px"}
                        onClick={() =>
                          navigate(`/product-details/${item.Product.slug}`)
                        }
                      >
                        {item?.Product?.name}
                      </Text>
                      <Text className="watch-price2" paddingLeft={"10px"}>
                        {item.Product.discount !== null &&
                        item.Product.discount !== "" ? (
                          <>
                            <span className="new-product-item-price">
                              ₹{item.Product.price}
                            </span>
                            <span>₹</span>
                            <span>
                              {Number(item.Product.price) -
                                (
                                  (Number(item.Product.price) *
                                    Number(item.Product.discount)) /
                                  100
                                ).toFixed(2)}
                            </span>
                          </>
                        ) : (
                          <>
                            <span>₹</span>
                            <span>{item.Product.price}</span>
                          </>
                        )}
                        {/* <span>₹</span>
                        {item?.Product?.price} */}
                      </Text>
                      <Button
                        rounded={"none"}
                        w={{ md: "35%", base: "56%" }}
                        ml={3}
                        mt={{ md: "-29px", lg: "-29px" }}
                        float={"right"}
                        //   size={{ base: '29px', md: '20px', sm: '20px', lg: 'lg' }}
                        py={"7"}
                        fontSize={{
                          base: "10px",
                          md: "10px",
                          sm: "10px",
                          lg: "10px",
                        }}
                        //   backgroundColor={'#ed691f'}
                        className="wishlist-cartBtn common-borderRadius"
                        // border={'1px solid #ed691f'}
                        //   color={useColorModeValue('white', 'gray.900')}
                        textTransform={"uppercase"}
                        //   _hover={{
                        //     transform: 'translateY(2px)',
                        //     boxShadow: 'lg',
                        //   }}
                        background={'transparent'}
                        leftIcon={<AiOutlineShoppingCart size={"15px"} />}
                        onClick={() =>
                          handleCart(
                            userData.user_id,
                            item?.Product.product_id,
                            1,
                            item?.wishlist_id
                          )
                        }
                      >
                        {homePageData &&
                        homePageData?.setting &&
                        homePageData?.setting?.addToCart
                          ? homePageData?.setting?.addToCart
                          : "Add To Cart"}
                      </Button>
                    </SimpleGrid>{" "}
                  </Box>
                </>
              ))
            )}
          </DrawerBody>

          <DrawerFooter display={"flex"} justifyContent={"center"}>
            <Button
              className="create-account common-borderRadius"
              isDisabled={wishlist && wishlist.length === 0}
              onClick={() => navigate("/wishlist")}
              background={'transparent'}
            >
              View Wishlist
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
}

export default WishlistPage;

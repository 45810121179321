import axiosInstance from '../axios';
import { cartList } from './CartActions';
import { fetchProductListData } from './ProductListingActions';

export const ADD_TO_WISHLIST_REQUEST = 'ADD_TO_WISHLIST_REQUEST';
export const ADD_TO_WISHLIST_SUCCESS = 'ADD_TO_WISHLIST_SUCCESS';
export const ADD_TO_WISHLIST_FAILURE = 'ADD_TO_WISHLIST_FAILURE';

export const GET_WISHLIST_REQUEST = 'GET_WISHLIST_REQUEST';
export const GET_WISHLIST_SUCCESS = 'GET_WISHLIST_SUCCESS';
export const GET_WISHLIST_FAILURE = 'GET_WISHLIST_FAILURE';

export const REMOVE_WISHLIST_REQUEST = 'REMOVE_WISHLIST_REQUEST';
export const REMOVE_WISHLIST_SUCCESS = 'REMOVE_WISHLIST_SUCCESS';
export const REMOVE_WISHLIST_FAILURE = 'REMOVE_WISHLIST_FAILURE';

export const fetchWishlistRequest = () => ({
  type: ADD_TO_WISHLIST_REQUEST,
});

// Action creator for the success state
export const fetchWishlistSuccess = (data) => ({
  type: ADD_TO_WISHLIST_SUCCESS,
  payload: data,
});

// Action creator for the failure state
export const fetchWishlistFailure = (error) => ({
  type: ADD_TO_WISHLIST_FAILURE,
  payload: error,
});

// export const getWishlistRequest = () => ({
//   type: GET_WISHLIST_REQUEST,
// });

// // Action creator for the success state
// export const getWishlistSuccess = (data) => ({
//   type: GET_WISHLIST_SUCCESS,
//   payload: data,
// });

// // Action creator for the failure state
// export const getWishlistFailure = (error) => ({
//   type: GET_WISHLIST_FAILURE,
//   payload: error,
// });

// export const removeWishlistRequest = () => ({
//   type: REMOVE_WISHLIST_REQUEST,
// });

// // Action creator for the success state
// export const removeWishlistSuccess = (data) => ({
//   type: REMOVE_WISHLIST_SUCCESS,
//   payload: data,
// });

// // Action creator for the failure state
// export const removeWishlistFailure = (error) => ({
//   type: REMOVE_WISHLIST_FAILURE,
//   payload: error,
// });

// Action creator for initiating the API call
export const addWishlist = (payload) => (dispatch) => {
  dispatch(fetchWishlistRequest());
  axiosInstance(`/wishlist/`, 'POST', payload)
    .then(async (response) => {
      const getWishlist = await axiosInstance(`/wishlist/${payload.userId}`, 'GET', null);
      // Dispatch success action with the received data

      // dispatch(fetchProductListData(pagination));
      dispatch(fetchWishlistSuccess(getWishlist.data.data));
    })
    .catch((error) => {
      // Dispatch failure action with the error message
      dispatch(fetchWishlistFailure(error.message));
    });
};

export const getWishlist = (id) => (dispatch) => {
  dispatch(fetchWishlistRequest());
  axiosInstance(`/wishlist/${id}`, 'GET', null)
    .then((response) => {
      // Dispatch success action with the received data
      dispatch(fetchWishlistSuccess(response.data.data));
    })
    .catch((error) => {
      // Dispatch failure action with the error message
      dispatch(fetchWishlistFailure(error.message));
    });
};

export const removeWishlist = (data) => (dispatch) => {
  dispatch(fetchWishlistRequest());
  axiosInstance(`/wishlist/${data.wishlistId}`, 'delete', null)
    .then(async (response) => {
      const getWishlistItems = await axiosInstance(`/wishlist/${data.userId}`, 'get', null);
      // Dispatch success action with the received data
      dispatch(fetchProductListData());
      dispatch(fetchWishlistSuccess(getWishlistItems.data.data));
    })
    .catch((error) => {
      // Dispatch failure action with the error message
      dispatch(fetchWishlistFailure(error.message));
    });
};

export const moveWishlistToCart = (payload, removePayload) => (dispatch) => {
  dispatch(fetchWishlistRequest());
  axiosInstance(`/cart/`, 'Post', payload)
    .then(async (response) => {
      // Dispatch success action with the received data
      const getCartItems = await dispatch(removeWishlist(removePayload));
      // const getCartItems = await axiosInstance(`/wishlist/18`, 'delete', null);
      dispatch(cartList(payload.userId));
      dispatch(fetchWishlistSuccess(getCartItems.data.data));
    })
    .catch((error) => {
      // Dispatch failure action with the error message
      dispatch(fetchWishlistFailure(error.message));
    });
};

import React from 'react';
import 'react-notifications/lib/notifications.css';
import './notification.css';
import { NotificationManager } from 'react-notifications';

function Notification(type = 'success', title = 'Title', msg = 'Successfully', time = 2000, callBack = () => {}) {
  switch (type) {
    case 'info':
      NotificationManager.info(title, msg, time, callBack);
      break;
    case 'success':
      NotificationManager.success(title, msg, time, callBack);
      break;
    case 'warning':
      NotificationManager.warning(title, msg, time, callBack);
      break;
    case 'error':
      NotificationManager.error(title, msg, time, callBack);
      break;
    default:
      return NotificationManager.success(title, msg, time, callBack);
  }
}
export default Notification;

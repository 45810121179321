import { Box, Container, Grid, Heading, Image, Text } from "@chakra-ui/react";
import React from "react";
import first from "../../../assets/About us for website.jpg";
import second from "../../../assets/About us for website 2.jpg";
import third from "../../../assets/About us.jpg";
import fourth from "../../../assets/Business card Gradient Blue.png";
import Header from "../../../components/header";
import "./aboutUs.css";
import Footer from "../../../components/footer/Footer";

function AboutUs() {
  return (
    <div>
      <Header />
      <Box>
        <Container
          maxW={{ md: "85%" }}
          className="main-aboutus"
          paddingTop={{ md: "30px" }}
          paddingBottom={{ md: "30px" }}
        >
          {/* <Heading fontSize="xl" className="common-userPaneFamily" marginBottom={{ md: '20px' }}>
            About Us
          </Heading> */}
          <Heading
            fontSize={{ md: "2xl" }}
            fontWeight="bold"
            textAlign={"center"}
            className="common-userPaneFamily"
          >
            Canon Quartz – CARRYING ON TRADITIONS
          </Heading>
          <Grid
            templateColumns={{
              base: "repeat(1, 1fr)",
              sm: "repeat(2, 1fr)",
              md: "repeat(2, 1fr)",
              lg: "repeat(2, 1fr)",
            }}
            gap={"3rem"}
            marginTop={{ base: 3, sm: 4, md: 6, lg: 7 }}
            marginBottom={{ base: 3, sm: 4, md: 6, lg: 10 }}
          >
            <Box>
              <Text
                fontSize={{ md: "md" }}
                textAlign={"justify"}
                lineHeight={"25px"}
                className="common-userPaneFamily"
              >
                At Canon Quartz, we believe that time is not just a number on a
                digital screen; it is an art form, a timeless tradition that
                connects us to our past and guides us into the future. In an era
                dominated by clumsy smartwatches, we have taken up the mission
                of reviving the glory of analog timepieces and reintroducing
                them to the modern world.
              </Text>
            </Box>
            <Box>
              <Text
                fontSize={{ md: "md" }}
                lineHeight={"25px"}
                textAlign={"justify"}
                className="common-userPaneFamily"
              >
                Established in the year 2000 by two visionary brothers in a
                small space, Canon Quartz has grown to become the leading Indian
                analog wristwatch brand. We are proud to be at the forefront of
                the resurgence of traditional timekeeping in Western India. Our
                watches embody the perfect blend of heritage craftsmanship and
                contemporary design, offering affordable luxury to our
                discerning customers.
              </Text>
            </Box>
          </Grid>
          <Grid
            templateColumns={{
              base: "repeat(1, 1fr)",
              sm: "repeat(2, 1fr)",
              md: "repeat(2, 1fr)",
              lg: "repeat(2, 1fr)",
            }}
            gap={"3rem"}
            height={{ md: "320px" }}
            marginTop={{ base: 3, sm: 4, md: 6, lg: 7 }}
            marginBottom={{ base: 3, sm: 4, md: 6, lg: 10 }}
          >
            <Box>
              <Image src={first} width={"100%"} height={"320px"} />
            </Box>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Text
                fontSize={{ md: "md" }}
                lineHeight={"27px"}
                textAlign={"justify"}
                className="common-userPaneFamily"
              >
                At Canon Quartz, we understand that our customers deserve the
                very best. That's why we have built our brand on the foundation
                of exceptional customer service and a customer-centric approach.
                From the moment you choose a Canon Quartz timepiece, you become
                a part of our extended family. We are committed to providing you
                with a seamless and delightful experience at every step of your
                journey.
              </Text>
            </Box>
          </Grid>
          <Grid
            templateColumns={{
              base: "repeat(1, 1fr)",
              sm: "repeat(2, 1fr)",
              md: "repeat(2, 1fr)",
              lg: "repeat(2, 1fr)",
            }}
            gap={"3rem"}
            height={{ md: "320px" }}
            marginTop={{ base: 3, sm: 4, md: 6, lg: 7 }}
            marginBottom={{ base: 3, sm: 4, md: 6, lg: 10 }}
          >
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              className="third-grid"
            >
              <Text
                fontSize={{ md: "md" }}
                lineHeight={"27px"}
                textAlign={"justify"}
                className="common-userPaneFamily"
              >
                Our watches are a testament to Indian horological excellence.
                Crafted with precision and passion, each Canon Quartz timepiece
                represents a fusion of artistry and technology. We take pride in
                showcasing the rich heritage of Indian watchmaking to the world,
                challenging the dominance of foreign brands. Our dedication to
                quality and innovation has earned us a place of honor in the
                global watch industry.
              </Text>
            </Box>
            <Box>
              <Image src={second} width={"100%"} height={"320px"} />
            </Box>
          </Grid>
          <Grid
            templateColumns={{
              base: "repeat(1, 1fr)",
              sm: "repeat(2, 1fr)",
              md: "repeat(2, 1fr)",
              lg: "repeat(2, 1fr)",
            }}
            gap={"3rem"}
            height={{ md: "320px" }}
            marginTop={{ base: 3, sm: 4, md: 6, lg: 7 }}
            marginBottom={{ base: 3, sm: 4, md: 6, lg: 10 }}
          >
            <Box>
              <Image src={third} width={"100%"} height={"320px"} />
            </Box>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Text
                fontSize={{ md: "md" }}
                lineHeight={"27px"}
                textAlign={"justify"}
                className="common-userPaneFamily"
              >
                At Canon Quartz, we value the time-honored traditions of our
                craft, and we believe in preserving them for generations to
                come. Our team of skilled artisans and watchmakers meticulously
                handcraft each timepiece, ensuring that every detail is perfect.
                From the intricate movements to the exquisite dials and straps,
                our watches are a testament to the artistry and craftsmanship
                that goes into creating a true work of horological art.
              </Text>
            </Box>
          </Grid>
          <Grid
            templateColumns={{
              base: "repeat(1, 1fr)",
              sm: "repeat(2, 1fr)",
              md: "repeat(2, 1fr)",
              lg: "repeat(2, 1fr)",
            }}
            gap={"3rem"}
            height={{ md: "320px" }}
            marginTop={{ base: 3, sm: 4, md: 6, lg: 7 }}
            marginBottom={{ base: 3, sm: 4, md: 6, lg: 10 }}
          >
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              className="fifth-order"
            >
              <Text
                fontSize={{ md: "md" }}
                lineHeight={"27px"}
                textAlign={"justify"}
                className="common-userPaneFamily"
              >
                Join us on this journey as we carry on traditions and elevate
                the Indian analog watch industry to new heights. Discover the
                beauty and elegance of Canon Quartz watches, and experience the
                pride of owning a piece of Indian horological excellence.
              </Text>
            </Box>
            <Box>
              <Image src={fourth} width={"100%"} height={"320px"} />
            </Box>
          </Grid>

          <Text
            className="common-userPaneFamily"
            textAlign={"center"}
            fontSize={{ md: "xl" }}
            fontWeight={"bold"}
          >
            Welcome to Canon Quartz - where time becomes an everlasting work of
            art.
          </Text>
        </Container>
      </Box>
      <Footer />
    </div>
  );
}

export default AboutUs;

import { Box, Spinner } from '@chakra-ui/react';
import React from 'react';
import './loader.css';

function Loader({ loading, children }) {
  return (
    <div>
      {loading ? (
        // <Spinner
        //   position={'absolute'}
        //   top={'50%'}
        //   display={'flex'}
        //   justifyContent={'center'}
        //   alignItems={'center'}
        //   left={'60%'}
        //   thickness="4px"
        //   speed="0.65s"
        //   emptyColor="gray.200"
        //   color="#ed691f"
        //   size="xl"
        // />
        <Box style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <div className="box">
            <div className="loader-38" />
          </div>
        </Box>
      ) : (
        children
      )}
    </div>
  );
}

export default Loader;

import axiosInstance from '../axios';

export const FETCH_SEARCHLIST_REQUEST = 'FETCH_SEARCHLIST_REQUEST';
export const FETCH_SEARCHLIST_SUCCESS = 'FETCH_SEARCHLIST_SUCCESS';
export const FETCH_SEARCHLIST_FAILURE = 'FETCH_SEARCHLIST_FAILURE';

export const fetchSearchListRequest = () => ({
  type: FETCH_SEARCHLIST_REQUEST,
});

// Action creator for the success state
export const fetchSearchListSuccess = (data) => ({
  type: FETCH_SEARCHLIST_SUCCESS,
  payload: data,
});

// Action creator for the failure state
export const fetchSearchListFailure = (error) => ({
  type: FETCH_SEARCHLIST_FAILURE,
  payload: error,
});

// Action creator for initiating the API call
export const fetchSearchListData = (searchInput) => (dispatch) => {
  dispatch(fetchSearchListRequest());
  axiosInstance(`/product/autocomplete?search=${searchInput}`, 'GET', null)
    .then((response) => {
      // Dispatch success action with the received data
      dispatch(fetchSearchListSuccess(response.data.data));
    })
    .catch((error) => {
      // Dispatch failure action with the error message
      dispatch(fetchSearchListFailure(error.message));
    });
};

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { Bounce, toast } from "react-toastify";
import { Box } from "@chakra-ui/react";
import axiosInstance from "../../../axios";
import "./forgotPassword.css";
import mainLogo from "../../../assets/logo/logo.png";
import { CommonImage } from "../common Login Image/CommonImage";
import Header from "../../../components/header";
import Footer from "../../../components/footer/Footer";
import { fetchHomeData } from "../../../actions/HomePageActions";
import Loader from "../../../components/loader/Loader";

function ForgotPasswordPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { homePageData } = useSelector((state) => state.home);
  useEffect(() => {
    if (!homePageData.length) {
      dispatch(fetchHomeData());
    }
  }, []);
  const [loader, setLoader] = useState(false);

  const [forgot, setForgot] = useState({
    identifier: "",
  });
  const emailValidation =
    /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;

  const validationSchema = Yup.object({
    identifier: Yup.string()
      .required("Email is required")
      .email("Please Add Valid Email")
      .matches(emailValidation, "Please Add Valid Email")
      .trim(),
  });

  const handleSubmit = async (values, resetForm) => {
    setLoader(true);
    const response = await axiosInstance(
      "/auth/forgot-password",
      "POST",
      values
    );
    if (response.data.status === 200) {
      setForgot({
        identifier: "",
      });
      setLoader(false);
      toast.success(response.data.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        // style:{ background: '#ed691f', color: '#ffffff' },
        transition: Bounce,
      });
      resetForm();
      // navigate('/verification');
    } else {
      setLoader(false);
      toast.error(response.data.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        // style:{ background: '#ed691f', color: '#ffffff' },
        transition: Bounce,
      });
    }
  };
  return (
    <div>
      <Header headerData={homePageData} />
      <Box height={loader ? "100%" : ""}>
        <Loader loading={loader} />
        <div
          className="flex items-center min-h-screen p-6 bg-gray-50 dark:bg-gray-900 fp-screen"
          style={{ height: "100vh", opacity: loader ? 0.3 : "" }}
        >
          <div className="flex-1 max-w-4xl mx-auto overflow-hidden bg-white rounded-lg shadow-xl dark:bg-gray-800 fp-form">
            <div className="flex flex-col overflow-y-auto md:flex-row h-full">
              <CommonImage />
              <main className="flex justify-center p-6 sm:p-12 md:w-1/2">
                <div className="w-full">
                  <div className="flex items-center justify-center">
                    <img
                      src={
                        homePageData &&
                          homePageData?.setting &&
                          homePageData?.setting.logo
                          ? `${process.env.REACT_APP_IMAGE_URL}${homePageData?.setting.logo}`
                          : mainLogo
                      }
                      alt="mainLogo"
                      style={{ marginTop: -15, marginBottom: 21, height: 75 }}
                      className="fp-logo"
                    />
                  </div>
                  <h1 className="mb-4 text-2xl font-semibold text-gray-700 dark:text-gray-200 common-userPaneFamily fp-title">
                    Forgot Your Password
                  </h1>
                  <Formik
                    enableReinitialize
                    initialValues={forgot}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                      handleSubmit(values, resetForm);
                      // setSubmitting(true);
                      // resetForm();
                      // setSubmitting(false);
                    }}
                  >
                    {({
                      field,
                      values,
                      errors,
                      touched,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                      isValid,
                      isSubmitting,
                    }) => (
                      <Form className="pr-8 pt-6 pl-8 pb-6 mb-4 bg-white rounded fp-padding">
                        <div className="mb-4">
                          <label
                            className="block mb-2 text-sm font-bold text-gray-700 common-userPaneFamily "
                            htmlFor="email"
                          >
                            Email
                          </label>
                          <input
                            className={
                              touched.identifier && errors.identifier
                                ? "w-full px-3 py-2  text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline px-3 py-1 text-sm focus:outline-none dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none  bg-gray-100 dark:bg-white border-transparent focus:bg-white input-error common-userPaneFamily"
                                : "w-full px-3 py-2  text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline px-3 py-1 text-sm focus:outline-none dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none  bg-gray-100 dark:bg-white border-transparent focus:bg-white common-userPaneFamily"
                            }
                            type="text"
                            name="identifier"
                            placeholder="Email"
                            value={values.identifier}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            autocomplete="off"
                          />
                          {touched.identifier && errors.identifier && (
                            <div className="error-message">
                              {errors.identifier}
                            </div>
                          )}
                        </div>
                        <div className="mb-6 text-center">
                          <button
                            className="w-full px-4 py-2 font-bold text-center text-white bg-blue-500 hover:bg-blue-700 focus:outline-none focus:shadow-outline w-3/4 px-4 py-2 font-bold align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-bold focus:outline-none px-4 py-2 text-sm text-white bg-orange-500 border border-transparent active:bg-orange-600 hover:bg-orange-600 focus:ring focus:ring-orange-300 mt-4 h-12 w-3/4 common-userPaneFamily  login-button shadow-md shadow-orange-500/20 hover:shadow-lg hover:shadow-orange-500/40 active:opacity-[0.85] common-borderRadius"
                            type="submit"
                            disabled={!!loader}
                          >
                            Forgot Password
                          </button>
                        </div>
                        <hr className="mb-2.5 border-t" />
                      </Form>
                    )}
                  </Formik>
                  <div className="flex justify-between">
                    <p className="mt-1">
                      <a
                        className="text-base font-medium hover:cursor-pointer  text-color hover:underline common-userPaneFamily"
                        onClick={() => navigate("/login")}
                      >
                        Already have an account? Login!
                      </a>
                    </p>
                    {/* <p className="mt-1">
                    <a
                      className="text-base font-medium hover:cursor-pointer  text-color hover:underline common-userPaneFamily"
                      onClick={() => navigate('/register')}
                    >
                      Create account
                    </a> */}
                    {/* </p> */}
                  </div>
                </div>
              </main>
            </div>
          </div>
        </div>
      </Box>
      <Footer />
      <Footer />
    </div>
  );
}

export default ForgotPasswordPage;

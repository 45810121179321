import { toast } from 'react-toastify';
import axiosInstance from '../axios';
import { notificationStyle } from '../pages/product-list/ProductListing';

export const CHECKOUT_SUCCESS = 'CHECKOUT_SUCCESS';
export const CHECKOUT_REQUEST = 'CHECKOUT_REQUEST';
export const CHECKOUT_FAILURE = 'CHECKOUT_FAILURE';
export const CHECKOUT_APPLY = 'CHECKOUT_APPLY';
export const CHECKOUT_ADDRESS = 'CHECKOUT_ADDRESS';
export const ORDER_PLACE = 'ORDER_PLACE';
export const fetchDataRequest = () => ({
  type: CHECKOUT_REQUEST,
});
export const offerRequest = (offer) => ({
  type: CHECKOUT_APPLY,
  payload: offer,
});
export const orderPlaceAction = (data) => ({
  type: ORDER_PLACE,
  payload: data,
});
export const addAddressAction = (data) => ({
  type: CHECKOUT_ADDRESS,
  payload: data,
});

// Action creator for the success state
export const fetchDataSuccess = (data) => ({
  type: CHECKOUT_SUCCESS,
  payload: data,
});

// Action creator for the failure state
export const fetchDataFailure = (error) => ({
  type: CHECKOUT_FAILURE,
  payload: error,
});

// Action creator for initiating the API call
export const fetchOffersData = (id) => (dispatch) => {
  dispatch(fetchDataRequest());
  axiosInstance(`/order/get-order/${id}`, 'GET', null)
    .then((response) => {
      // Dispatch success action with the received data
      dispatch(orderPlaceAction(response.data.data));
    })
    .catch((error) => {
      // Dispatch failure action with the error message
      dispatch(fetchDataFailure(error.message));
    });
};
export const checkoutAction = (data) => (dispatch) => {
  dispatch(offerRequest(data));
};
export const handleCheckoutAddressAction = (data) => (dispatch) => {
  dispatch(addAddressAction(data));
};

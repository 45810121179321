import { Box, Image, Text } from "@chakra-ui/react";
import React, { Component } from "react";
import Slider from "react-slick";

const HistoryPage = ({ history, setting }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 3000,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    prevArrow: false,
    nextArrow: false,
    arrows: false,
  };
  return (
    <Box
      width={'100%'}
      position={"relative"}
      // margin="auto"
      marginBottom={{ base: "2rem", sm: "3rem", md: "4rem", lg: "2rem" }}
      marginTop={{ base: 0 }}
    >
      {setting && setting?.sectionFour ? (
        <Text className="history-text">{setting.sectionFour}</Text>
      ) : (
        ""
      )}
      <Slider {...settings}>
        {history &&
          history.map((item) => (
            <Box >
              <Image
                // borderRadius={"8px"}
                objectFit={"cover"}
                height={{ md: "76vh", lg: "76vh", base: "360px" }}
                margin={"auto"}
                width={"100%"}
                src={`${process.env.REACT_APP_IMAGE_URL}${item.image_url}`}
              />
            </Box>
          ))}
      </Slider>
    </Box>
  );
};

export default HistoryPage;

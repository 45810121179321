import { ADD_TO_CART_FAILURE,ADD_TO_CART_REQUEST,ADD_TO_CART_SUCCESS } from '../actions/CartActions';

const initialState = {
    loading: false,
    cart: [],
    count: 0,
    error: '',
  };

const CartReducer = (state = initialState, action) => {
    
    switch (action.type) {
        case ADD_TO_CART_REQUEST:
          return {
            ...state,
            loading: true,
          };
        case ADD_TO_CART_SUCCESS:
          return {
            loading: false,
            cart: action.payload,
            error: '',
          };
        case ADD_TO_CART_FAILURE:
          return {
            loading: false,
            cart: [],
            error: action.payload,
          };
        default:
          return state;
      }
}
export default CartReducer;
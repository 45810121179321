import { GET_ORDER_FAILURE, GET_ORDER_SUCCESS, GET_ORDER_REQUEST, GET_ORDER_DETAILS_FAILURE, GET_ORDER_DETAILS_SUCCESS, GET_ORDER_DETAILS_REQUEST } from '../actions/AdminOrderActions';

const initialState = {
  loading: false,
  orderList: [],
  orderDetails: {},
  error: '',
};

const AdminOrderReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ORDER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        orderList: action.payload,
        error: '',
      };
    case GET_ORDER_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case GET_ORDER_DETAILS_REQUEST:
      return {
        ...state,
        loading: false,
        orderDetails: action.payload,
        error: "",
      };
    case GET_ORDER_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        orderDetails: action.payload,
        error: "",
      };
    case GET_ORDER_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        orderDetails: {},
        error: action.payload,
      };
    default:
      return state;
  }
};
export default AdminOrderReducer;

import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Button,
  Popover,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Drawer,
  Pagination,
  FormControlLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Box,
} from '@mui/material';
// components
import AddUser from 'src/components/addUser/AddUser';
import { IOSSwitch } from '../../utils/toggleButton/toggleButton';

import axiosInstance from '../../axios';
import Notification from '../../components/notification';
import TableHeader from '../../sections/@dashboard/user/tableHeader';
import Label from '../../components/label';
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../../sections/@dashboard/user';
// mock
// import USERLIST from '../../_mock/user';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'id', label: 'Id', alignRight: false },
  { id: "'firstName','lastName'", label: 'Name', alignRight: false },
  // { id: 'lastName', label: '', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'address', label: 'Address', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export default function UserPage() {
  const [open, setOpen] = useState(null);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [userListing, setUserListing] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [searchUser, setSearchUser] = useState('');
  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');
  const [tableId, setTableId] = useState(null);

  const [filterName, setFilterName] = useState('');
  const [addEdit, setAddEdit] = useState('add');
  const [pagination, setPagination] = useState({});

  const handleCloseMenu = () => {
    setOpen(null);
  };

  useEffect(() => {
    const fetchData = async () => {
      const res = await axiosInstance(`/user/?search=${searchUser}&page=${page + 1}&limit=${rowsPerPage}`, 'GET', null);
      if (res.status === 200) {
        if (res.data.data.data && res.data.data.data.length)
          setUserListing(
            res.data.data.data.map((data, index) => ({
              id: data.user_id,
              firstName: data.first_name,
              lastName: data.last_name,
              email: data.email,
              address: data.address,
              status: data.status,
            }))
          );
        setCount(res.data.data.pagination.totalPages);
        setPagination(res.data.data.pagination);
        Notification('success', 'User List', res.data.message, 2000);
      } else {
        Notification('error', 'User List', res.data.message, 2000);
      }
    };
    fetchData();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      const res = await axiosInstance(`/user/?search=${searchUser}&page=${page + 1}&limit=${rowsPerPage}`, 'GET', null);
      if (res.status === 200) {
        if (res.data.data.data && res.data.data.data.length)
          setUserListing(
            res.data.data.data.map((data, index) => ({
              id: data.user_id,
              firstName: data.first_name,
              lastName: data.last_name,
              email: data.email,
              address: data.address,
              status: data.status,
            }))
          );
        setCount(res.data.data.pagination.totalPages);
        setPagination(res.data.data.pagination);
        // Notification('success', 'User List', res.data.message, 2000);
      } else {
        // Notification('error', 'User List', res.data.message, 2000);
      }
    };
    fetchData();
  }, [searchUser]);

  const handleOpenMenu = async (event, id) => {
    setOpen(event.currentTarget);
    setTableId(id);
    // setAddEdit("edit")
  };

  const handleChangeRowsPerPage = async (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    const fetchData = async () => {
      const res = await axiosInstance(
        `/user/?search=${searchUser}&page=${page + 1}&limit=${+event.target.value}`,
        'GET',
        null
      );
      if (res.status === 200) {
        if (res.data.data.data && res.data.data.data.length)
          setUserListing(
            res.data.data.data.map((data, index) => ({
              id: data.user_id,
              firstName: data.first_name,
              lastName: data.last_name,
              email: data.email,
              address: data.address,
              status: data.status,
            }))
          );
        setCount(res.data.data.pagination.totalPages);
        setPagination(res.data.data.pagination);
        Notification('success', 'User List', res.data.message, 2000);
      } else {
        Notification('error', 'User List', res.data.message, 2000);
      }
    };
    await fetchData();
  };

  const handleChangePage = async (e, newPage) => {
    setPage(newPage);
    const fetchData = async () => {
      const res = await axiosInstance(
        `/user/?search=${searchUser}&page=${newPage + 1}&limit=${rowsPerPage}`,
        'GET',
        null
      );
      if (res.status === 200) {
        if (res.data.data.data && res.data.data.data.length)
          setUserListing(
            res.data.data.data.map((data, index) => ({
              id: data.user_id,
              firstName: data.first_name,
              lastName: data.last_name,
              email: data.email,
              address: data.address,
              status: data.status,
            }))
          );
        setCount(res.data.data.pagination.totalPages);
        setPagination(res.data.data.pagination);
        Notification('success', 'User List', res.data.message, 2000);
      } else {
        Notification('error', 'User List', res.data.message, 2000);
      }
    };
    await fetchData();
  };

  const handleFilterByName = (event) => {
    // setPage(0);
    setFilterName(event.target.value);
  };
  const handleOpenDeleteModal = () => {
    setOpenDialog(true);
    setOpen(null);
  };
  const handleCloseDeleteModal = () => {
    setOpenDialog(false);
  };
  const updateValue = async (e, id) => {
    const { checked } = e.target;
    const data = userListing.map((val) => {
      if (val.id === id) {
        return {
          ...val,
          status: checked,
        };
      }
      return val;
    });
    setUserListing(data);

    const payload = {
      user_id: id,
      status: checked,
    };
    const response = await axiosInstance(`/user/status`, 'PUT', payload);

    if (response.status === 200) {
      Notification('success', 'Status', response.data.message, 2000);
    } else {
      Notification('error', 'Status', response.data.message, 2000);
    }
  };
  const handleEdit = () => {
    setOpenDrawer(true);
    setOpen(null);
    setAddEdit('edit');
  };
  const userListAPI = async () => {
    const response = await axiosInstance(`/user/?page=${page + 1}&limit=${rowsPerPage}`, 'GET', null);

    if (response.status === 200) {
      if (response.data.data.data.length > 0) {
        setUserListing(
          response.data.data.data.map((data, index) => ({
            id: data.user_id,
            firstName: data.first_name,
            lastName: data.last_name,
            email: data.email,
            address: data.address,
            status: data.status,
          }))
        );
        setCount(response.data.data.pagination.totalPages);
        setPagination(response.data.data.pagination);
      }
    }
  };
  const handleDelete = () => {
    const deleteProductData = async () => {
      const response = await axiosInstance(`/user/${tableId}`, 'DELETE', null);

      if (response.status === 200) {
        Notification('success', 'Delete Product', response.data.message, 2000);
        setOpenDialog(false);
        await userListAPI();
      } else {
        Notification('error', 'Delete Product', response.data.message, 2000);
      }
    };

    deleteProductData();
  };

  const handleAddData = () => {
    setOpenDrawer(true);
    setAddEdit('add');
  };

  const handleSearchUser = (e) => {
    setSearchUser(e.target.value);
  };
  return (
    <>
      <Helmet>
        <title> User </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={4}>
          <Typography variant="h4" className="text-lg font-bold text-gray-700 dark:text-gray-300 headers">
            User
          </Typography>
          <div>
            <button
              className="align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-white  border border-transparent focus:ring focus:ring-purple-300  rounded-md common-shadow common-background"
              type="button"
              onClick={() => handleAddData()}
            >
              <span className="mr-3">
                <svg
                  stroke="currentColor"
                  fill="none"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <line x1="12" y1="5" x2="12" y2="19" />
                  <line x1="5" y1="12" x2="19" y2="12" />
                </svg>
              </span>
              Add User
            </button>
          </div>
        </Stack>
        <Card className="card-style">
          <UserListToolbar
            filterName={filterName}
            onFilterName={handleFilterByName}
            value={searchUser}
            onChange={(e) => handleSearchUser(e)}
          />
        </Card>
        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <TableHeader
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={count}
                  numSelected={selected.length}
                  // onRequestSort={handleRequestSort}
                  // onSelectAllClick={handleSelectAllClick}
                  checkbox={false}
                />
                {userListing.length > 0 ? (
                  <TableBody>
                    {userListing.map((row) => {
                      const { id, firstName, lastName, email, address, status } = row;
                      // const selectedUser = selected.indexOf(name) !== -1;

                      return (
                        <TableRow hover key={id} role="checkbox">
                          {/* <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" spacing={2} justifyContent="center">
                            <Typography variant="subtitle2">{id}</Typography>
                          </Stack>
                        </TableCell> */}

                          <TableCell align="left">{id}</TableCell>
                          <TableCell align="left">{`${firstName} ${lastName}`}</TableCell>

                          <TableCell align="left">{email}</TableCell>
                          <TableCell align="left">{address}</TableCell>

                          <TableCell align="left">
                            <FormControlLabel
                              control={
                                <IOSSwitch sx={{ m: 1 }} checked={status} onChange={(e) => updateValue(e, id)} />
                              }
                            />
                          </TableCell>
                          <TableCell align="right">
                            <IconButton size="large" color="inherit" onClick={(e) => handleOpenMenu(e, id)}>
                              <Iconify icon={'eva:more-vertical-fill'} />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                ) : (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Box
                            className="not-fount-img"
                            component="img"
                            src="/assets/illustrations/illustration_404.svg"
                            sx={{ height: 160, mx: 'auto', my: { xs: 5, sm: 10 } }}
                          />
                          <Typography variant="h4" paragraph className="not-fount-1">
                            No records has been added yet.
                          </Typography>
                          <Typography variant="h6" paragraph className="not-fount-2">
                            Add a new record by simpley clicking the button on top right side.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 15, 25]}
              component="div"
              count={pagination.totalItems}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Scrollbar>
        </Card>
      </Container>
      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem onClick={() => handleEdit()}>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        <MenuItem sx={{ color: 'error.main' }} onClick={handleOpenDeleteModal}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>
      {/* Modal for Delete */}
      <Dialog
        open={openDialog}
        keepMounted
        onClose={handleCloseDeleteModal}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{'Delete User'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to delete user?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteModal}>No</Button>
          <Button onClick={() => handleDelete()}>Yes</Button>
        </DialogActions>
      </Dialog>
      <Drawer anchor={'right'} sx={{"& .MuiPaper-root": {
      width: '50%'
    }}} open={openDrawer} onClose={() => setOpenDrawer(false)} className="drawerStyle">
        <AddUser setOpenDrawer={setOpenDrawer} addEdit={addEdit} id={tableId} userListAPI={userListAPI} />
      </Drawer>
    </>
  );
}

import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Bounce, toast } from "react-toastify";
import email from "../../../assets/Email.png";
import "./verification.css";
import Header from "../../../components/header";
import Footer from "../../../components/footer/Footer";
import store from "../../../store";
import { fetchHomeData } from "../../../actions/HomePageActions";
import axiosInstance from "../../../axios";

function VerificationPage() {
  const { token } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { homePageData } = useSelector((state) => state.home);
  const [verify, setVerify] = useState(false);
  const [error, setError] = useState("");
  useEffect(() => {
    if (!homePageData.length) {
      dispatch(fetchHomeData());
    }
    const emailVerify = async () => {
      const response = await axiosInstance("/auth/verify-email", "POST", {
        token,
      });
      if (response.status === 200) {
        setVerify(true);
      } else {
        setError(response.data.message);
      }
    };
    emailVerify();
  }, []);
  const handleResendVerification = async () => {
    const response = await axiosInstance("/auth/resend-verify-email", "POST", {
      token,
    });
    if (response.status === 200) {
      setVerify(false);
      toast.success(response.data.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        // style:{ background: '#ed691f', color: '#ffffff' },
        transition: Bounce,
      });
    } else {
      setError(response.data.message);
      toast.error(response.data.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        // style:{ background: '#ed691f', color: '#ffffff' },
        transition: Bounce,
      });
    }
  };
  return (
    <>
      <Header headerData={homePageData} />
      <div className="password-card-style">
        <div className="bg-color md:w-3/6 sm:w-full">
          <div className="flex justify-center align-center">
            <Card style={{ borderRadius: 0, width: "100%" }}>
              {verify ? (
                <CardContent>
                  <form>
                    <div className="flex justify-center">
                      <img
                        src={email}
                        alt="email"
                        height={150}
                        width={150}
                        className="email-icon"
                      />
                    </div>
                    <h1 className="text-center mb-4 text-2xl font-bold text-gray-700 dark:text-gray-600 common-userPaneFamily ">
                      Account Verified Successfully
                    </h1>
                    <p className="text-center mb-2 text-s font-medium text-gray-700 dark:text-gray-600 common-userPaneFamily ">
                      Thank you, Your email has been verified. Your account is
                      now active.
                    </p>
                    <p className="text-center mb-4 text-s font-medium text-gray-700 dark:text-gray-200 common-userPaneFamily ">
                      Please use the link below to login to your account.
                    </p>
                    <div className="flex justify-center">
                      <button
                        className="w-1/4 px-4 py-2 font-bold text-white bg-blue-500 hover:bg-blue-700 focus:outline-none focus:shadow-outline px-4 py-2 font-bold align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-bold focus:outline-none px-4 py-2 rounded-lg text-sm text-white bg-orange-500 border border-transparent active:bg-orange-600 hover:bg-orange-600 focus:ring focus:ring-orange-300 mt-4 h-12 common-userPaneFamily
                          login-button shadow-md shadow-orange-500/20 hover:shadow-lg
                           hover:shadow-orange-500/40 active:opacity-[0.85] common-borderRadius"
                        // type="submit"
                        // to="/dashboard"
                        onClick={() => navigate("/login")}
                      >
                        Login
                      </button>
                    </div>
                  </form>
                </CardContent>
              ) : (
                <CardContent>
                  <form>
                    <div className="flex justify-center">
                      <img
                        src={email}
                        alt="email"
                        height={150}
                        width={150}
                        className="email-icon"
                      />
                    </div>
                    <h1 className="text-center mb-4 text-2xl font-bold text-gray-700 dark:text-gray-600 common-userPaneFamily">
                      Email Verification Failed
                    </h1>
                    <p className="text-center mb-2 text-s font-medium text-gray-700 dark:text-gray-600 common-userPaneFamily">
                      We're sorry, but it seems that your email verification has
                      failed. Please try again or contact our support team for
                      assistance.
                    </p>
                    <div className="flex justify-center">
                      <button
                        className="w-1/4 px-4 py-2 font-bold text-white bg-blue-500 hover:bg-blue-700 focus:outline-none 
                        focus:shadow-outline px-4 py-2 font-bold align-bottom inline-flex
                         items-center justify-center cursor-pointer leading-5 transition-colors 
                         duration-150 font-bold focus:outline-none px-4 py-2 rounded-lg text-sm
                          text-white bg-orange-500 border border-transparent active:bg-orange-600
                           hover:bg-orange-600 focus:ring focus:ring-orange-300 mt-4 h-12 
                           common-userPaneFamily login-button shadow-md shadow-orange-500/20 hover:shadow-lg
                            hover:shadow-orange-500/40 active:opacity-[0.85] common-borderRadius resend-veriButton"
                        onClick={() => handleResendVerification()} // Replace handleResendVerification with your actual function to resend verification
                      >
                        Resend Verification
                      </button>
                    </div>
                  </form>
                </CardContent>
              )}
            </Card>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default VerificationPage;

import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Stack, Typography, Card, Container } from "@mui/material";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import "./setting.css";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import axios from "axios";
import Notification from "../../components/notification";
import axiosInstance from "../../axios";

function SettingPage() {
  // url Field Input state
  const [fields, setFields] = useState({
    facebook: "",
    twitter: "",
    linkedin: "",
    instagram: "",
    sectionTwo: "",
    sectionThree: "",
    sectionFour: "",
    sectionSix: "",
    addToCart: "",
    addToWishList: "",
    applyCoupon: "",
    checkout: "",
    placeOrder: "",
    continueShopping: "",
    // sectionSeven: '',
  });

  // textarea input states
  const [policy, setPolicy] = useState("");
  const [condition, setCondition] = useState("");
  const [about, setAbout] = useState("");
  const [contact, setContact] = useState("");
  const [refund, setRefund] = useState("");
  const [cancel, setCancel] = useState("");
  const [copy, setCopy] = useState("");
  const [offerDescription, setofferDescription] = useState("");

  // logo input state
  const [productImage, setProductImage] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);

  const [loginPageBanners, setloginPageBanners] = useState([]);
  const [selectedloginPageBanner, setSelectedloginPageBanner] = useState([]);

  const [selectedFile, setSelectedFile] = useState([]);
  const [previewUrl, setPreviewUrl] = useState([]);

  const [successfullImage, setSuccessfullImage] = useState([]);
  const [prevSuccessfullImage, setPrevSuccessfullImage] = useState([]);

  // url validation
  const validationSchema = Yup.object({
    facebook: Yup.string().matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      "Enter correct url!"
    ),
    twitter: Yup.string().matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      "Enter correct url!"
    ),
    linkedin: Yup.string().matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      "Enter correct url!"
    ),
    instagram: Yup.string().matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      "Enter correct url!"
    ),
  });

  // textarea fonts
  const defaultFonts = [
    "Arial",
    "Comic Sans MS",
    "Courier New",
    "Impact",
    "Georgia",
    "Tahoma",
    "Trebuchet MS",
    "Verdana",
  ];
  const sortedFontOptions = [
    "Logical",
    "Salesforce Sans",
    "Garamond",
    "Sans-Serif",
    "Serif",
    "Times New Roman",
    "Helvetica",
    ...defaultFonts,
  ].sort();
  // Image upload
  const handleImageUpload = async (event, type) => {
    const { files } = event.target;
    const element = files[0];
    const imagesArray = element;
    const previewArray = { id: null, image: URL.createObjectURL(element) };
    if (type === "logo") {
      setProductImage([imagesArray]);
      setSelectedImages([previewArray]);
    } else if (type === "homepageVideo") {
      setSelectedFile([imagesArray]);
      setPreviewUrl([previewArray]);
    } else if (type === "successfullBanner") {
      setSuccessfullImage([imagesArray]);
      setPrevSuccessfullImage([previewArray]);
    } else if (type === "loginPageBanner") {
      setloginPageBanners([imagesArray]);
      setSelectedloginPageBanner([previewArray]);
    }
  };
  // delete image
  const handleOpenDeleteModal = async (index, type) => {
    if (type === "logo") {
      setSelectedImages([]);
      setProductImage([]);
    } else if (type === "successfullBanner") {
      setSuccessfullImage([]);
      setPrevSuccessfullImage([]);
    } else if (type === "loginPageBanner") {
      setSelectedloginPageBanner([]);
      setloginPageBanners([]);
    } else if (type === "homepageVideo") {
      setSelectedFile([]);
      setPreviewUrl([]);
    }
  };
  // handle submit
  const handleSettingSubmit = async (values, resetForm) => {
    let imagename = {
      ...values,
      privacyPolicy: policy,
      termsAndConditions: condition,
      aboutUs: about,
      contactUs: contact,
      refundExchangePolicy: refund,
      cancellation: cancel,
      copyright: copy,
      offerDescription: offerDescription,
    };
    //  debugger
    if (productImage.length) {
      const formData = new FormData();
      // Object.values(productImage).forEach((file) => {
      formData.append("image", productImage[0]);
      // });
      const image = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/common/upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      imagename = { ...imagename, logo: image.data.data };
    } else {
      if (!selectedImages.length) {
        imagename = { ...imagename, logo: null };
      } else {
        imagename = { ...imagename };
      }
    }

    if (selectedFile[0]) {
      const formData = new FormData();
      formData.append("image", selectedFile[0]);

      const video = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/common/upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      imagename = { ...imagename, homepageVideo: video.data.data };
    } else {
      if (!previewUrl.length) {
        imagename = { ...imagename, homepageVideo: null };
      }
    }
    if (successfullImage[0]) {
      const formData = new FormData();
      formData.append("image", successfullImage[0]);

      const image = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/common/upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      imagename = { ...imagename, successBanner: image.data.data };
    } else {
      if (!prevSuccessfullImage.length) {
        imagename = { ...imagename, successBanner: null };
      }
    }
    if (loginPageBanners[0]) {
      const formData = new FormData();
      // Object.values(productImage).forEach((file) => {
      formData.append("image", loginPageBanners[0]);
      // });
      const image = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/common/upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      imagename = { ...imagename, loginPageBanner: image.data.data };
    } else {
      if (!selectedloginPageBanner.length) {
        imagename = { ...imagename, loginPageBanner: null };
      }
    }
    const response = await axiosInstance(`/setting/`, "POST", imagename);
    if (response.status === 200) {
      await Notification("success", "Add Setting", response.data.message, 2000);
      await fetchData();
    } else {
      await Notification("error", "Add Setting", response.data.message, 2000);
    }
  };

  const fetchData = async () => {
    const res = await axiosInstance(`/setting/`, "GET", null);
    if (res.status === 200) {
      setPolicy(res.data.privacyPolicy);
      setCondition(res.data.termsAndConditions);
      setAbout(res.data.aboutUs);
      setContact(res.data.contactUs);
      setRefund(res.data.refundExchangePolicy);
      setCancel(res.data.cancellation);
      setCopy(res.data.copyright);
      setofferDescription(res.data.offerDescription);
      setFields({
        facebook: res.data.facebook,
        twitter: res.data.twitter,
        linkedin: res.data.linkedin,
        instagram: res.data.instagram,
        sectionTwo: res.data.sectionTwo,
        sectionThree: res.data.sectionThree,
        sectionFour: res.data.sectionFour,
        sectionSix: res.data.sectionSix,
        addToCart: res.data.addToCart,
        addToWishList: res.data.addToWishList,
        applyCoupon: res.data.applyCoupon,
        checkout: res.data.checkout,
        placeOrder: res.data.placeOrder,
        continueShopping: res.data.continueShopping,
        homepageVideoLink: res.data.homepageVideoLink,
        // sectionSeven: res.data.sectionSeven,
      });
      if (res.data.logo && res.data.logo !== "") {
        const allproductImages = {
          id: res.data.id,
          image: `${process.env.REACT_APP_IMAGE_URL}${res.data.logo}`,
        };
        setProductImage([]);
        setSelectedImages([allproductImages]);
      }
      if (res.data.loginPageBanner && res.data.loginPageBanner !== "") {
        const allproductImages = {
          id: res.data.id,
          image: `${process.env.REACT_APP_IMAGE_URL}${res.data.loginPageBanner}`,
        };
        setloginPageBanners([]);
        setSelectedloginPageBanner([allproductImages]);
      }
      if (res.data.homepageVideo && res.data.homepageVideo !== "") {
        const allproductImages = {
          id: res.data.id,
          image: `${process.env.REACT_APP_IMAGE_URL}${res.data.homepageVideo}`,
        };
        setSelectedFile([]);
        setPreviewUrl([allproductImages]);
      }
      if (res.data.successBanner && res.data.successBanner !== "") {
        const allproductImages = {
          id: res.data.id,
          image: `${process.env.REACT_APP_IMAGE_URL}${res.data.successBanner}`,
        };
        setSuccessfullImage([]);
        setPrevSuccessfullImage([allproductImages]);
      }
      Notification("success", "Setting", res.data.message, 2000);
    } else {
      Notification("error", "Setting", res.data.message, 2000);
    }
  };
  // get list api
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <>
      <Helmet>
        <title> Settings </title>
      </Helmet>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={4}
        >
          <Typography
            variant="h4"
            className="text-lg font-bold text-gray-700 dark:text-gray-300 headers"
          >
            Settings
          </Typography>
        </Stack>
        {/* here need to add code for all fields */}
        <Card className="form">
          <Formik
            enableReinitialize
            initialValues={fields}
            // validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              handleSettingSubmit(values, resetForm);
              // setSubmitting(true);
              // resetForm();
              // setSubmitting(false);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
              isValid,
              isSubmitting,
            }) => (
              <Form onSubmit={handleSubmit}>
                <div className="inline grid-cols-6 gap-3 md:gap-5 xl:gap-6 lg:gap-6 mb-6 formLabel">
                  <label className="block font-bold text-gray-700 dark:text-gray-400 col-span-4 sm:col-span-2  font-bold mb-3">
                    Logo
                  </label>
                  <div className="file-input col-span-8 sm:col-span-4 mb-6 formInput">
                    <input
                      type="file"
                      accept="image/*"
                      className="block w-full px-3 py-1 text-sm focus:outline-none dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white"
                      onChange={(e) => handleImageUpload(e, "logo")}
                    />
                    <span className="button">Choose File</span>
                  </div>
                </div>
                <div
                  className="col-span-8 flex images"
                  style={{ marginTop: 5 }}
                >
                  {selectedImages.length
                    ? selectedImages
                        .filter((i) => i.image !== "")
                        .map((image, index) => (
                          <div
                            className="image-upload"
                            key={index}
                            style={{ position: "relative" }}
                          >
                            <span
                              onClick={() =>
                                handleOpenDeleteModal(index, "logo")
                              }
                            >
                              <HighlightOffIcon className="cancel-images" />
                            </span>
                            <div className="set-image">
                              <img
                                key={index}
                                src={image.image}
                                alt=""
                                height={"65px"}
                                width={"100px"}
                              />
                            </div>
                          </div>
                        ))
                    : ""}
                </div>

                <div className="inline grid-cols-6 gap-3 md:gap-5 xl:gap-6 lg:gap-6 mb-6 formLabel">
                  <label className="block font-bold text-gray-700 dark:text-gray-400 col-span-4 sm:col-span-2  font-bold mb-3">
                    Login Page Banner
                  </label>
                  <div className="file-input col-span-8 sm:col-span-4 mb-6 formInput">
                    <input
                      type="file"
                      accept="image/*"
                      className="block w-full px-3 py-1 text-sm focus:outline-none dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white"
                      onChange={(e) => handleImageUpload(e, "loginPageBanner")}
                    />
                    <span className="button">Choose File</span>
                  </div>
                </div>
                <div
                  className="col-span-8 flex images"
                  style={{ marginTop: 5 }}
                >
                  {selectedloginPageBanner.length
                    ? selectedloginPageBanner
                        .filter((i) => i.image !== "")
                        .map((image, index) => (
                          <div
                            className="image-upload"
                            key={index}
                            style={{ position: "relative" }}
                          >
                            <span
                              onClick={() =>
                                handleOpenDeleteModal(index, "loginPageBanner")
                              }
                            >
                              <HighlightOffIcon className="cancel-images" />
                            </span>
                            <div className="set-image">
                              <img
                                key={index}
                                src={image.image}
                                alt=""
                                height={"65px"}
                                width={"100px"}
                              />
                            </div>
                          </div>
                        ))
                    : ""}
                </div>

                <div className="inline grid-cols-6 gap-3 md:gap-5 xl:gap-6 lg:gap-6 mb-6 formLabel">
                  <label className="block font-bold text-gray-700 dark:text-gray-400 col-span-4 sm:col-span-2 font-bold mb-3">
                    Privacy Policy
                  </label>
                  <div className="col-span-8 sm:col-span-4 mb-6">
                    <SunEditor
                      name="privacyPolicy"
                      setContents={policy}
                      onChange={setPolicy}
                      setOptions={{
                        buttonList: [
                          ["undo", "redo"],
                          ["font", "fontSize"],
                          // ['paragraphStyle', 'blockquote'],
                          [
                            "bold",
                            "underline",
                            "italic",
                            "strike",
                            "subscript",
                            "superscript",
                          ],
                          ["fontColor", "hiliteColor"],
                          ["align", "list", "lineHeight"],
                          ["outdent", "indent"],

                          ["table", "horizontalRule", "link", "image", "video"],
                          // ['math'] //You must add the 'katex' library at options to use the 'math' plugin.
                          // ['imageGallery'], // You must add the "imageGalleryUrl".
                          // ["fullScreen", "showBlocks", "codeView"],
                          ["preview", "print"],
                          ["removeFormat"],

                          // ['save', 'template'],
                          // '/', Line break
                        ], // Or Array of button list, eg. [['font', 'align'], ['image']]
                        defaultTag: "div",
                        minHeight: "300px",
                        height: "230px",
                        showPathLabel: false,
                        font: sortedFontOptions,
                      }}
                      height="230px"
                      className="desc-input"
                    />
                    {/* {touched.description && errors.description && <div className="error-message">{errors.description}</div>} */}
                  </div>
                </div>
                <div className="inline grid-cols-6 gap-3 md:gap-5 xl:gap-6 lg:gap-6 mb-6 formLabel">
                  <label className="block font-bold text-gray-700 dark:text-gray-400 col-span-4 sm:col-span-2 font-bold mb-3">
                    Terms & Conditions
                  </label>
                  <div className="col-span-8 sm:col-span-4 mb-6">
                    <SunEditor
                      name="termsAndConditions"
                      setContents={condition}
                      onChange={setCondition}
                      setOptions={{
                        buttonList: [
                          ["undo", "redo"],
                          ["font", "fontSize"],
                          // ['paragraphStyle', 'blockquote'],
                          [
                            "bold",
                            "underline",
                            "italic",
                            "strike",
                            "subscript",
                            "superscript",
                          ],
                          ["fontColor", "hiliteColor"],
                          ["align", "list", "lineHeight"],
                          ["outdent", "indent"],

                          ["table", "horizontalRule", "link", "image", "video"],
                          // ['math'] //You must add the 'katex' library at options to use the 'math' plugin.
                          // ['imageGallery'], // You must add the "imageGalleryUrl".
                          // ["fullScreen", "showBlocks", "codeView"],
                          ["preview", "print"],
                          ["removeFormat"],

                          // ['save', 'template'],
                          // '/', Line break
                        ], // Or Array of button list, eg. [['font', 'align'], ['image']]
                        defaultTag: "div",
                        minHeight: "300px",
                        height: "230px",
                        showPathLabel: false,
                        font: sortedFontOptions,
                      }}
                      height="230px"
                      className="desc-input"
                    />
                    {/* {touched.description && errors.description && <div className="error-message">{errors.description}</div>} */}
                  </div>
                </div>
                {/* <div className="inline grid-cols-6 gap-3 md:gap-5 xl:gap-6 lg:gap-6 mb-6 formLabel">
                  <label className="block font-bold text-gray-700 dark:text-gray-400 col-span-4 sm:col-span-2 font-bold mb-3">
                    About Us
                  </label>
                  <div className="col-span-8 sm:col-span-4 mb-6">
                    <SunEditor
                      name="termsAndConditions"
                      setContents={about}
                      onChange={setAbout}
                      setOptions={{
                        buttonList: [
                          ['undo', 'redo'],
                          ['font', 'fontSize'],
                          // ['paragraphStyle', 'blockquote'],
                          ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
                          ['fontColor', 'hiliteColor'],
                          ['align', 'list', 'lineHeight'],
                          ['outdent', 'indent'],

                          ['table', 'horizontalRule', 'link', 'image', 'video'],
                          // ['math'] //You must add the 'katex' library at options to use the 'math' plugin.
                          // ['imageGallery'], // You must add the "imageGalleryUrl".
                          // ["fullScreen", "showBlocks", "codeView"],
                          ['preview', 'print'],
                          ['removeFormat'],

                          // ['save', 'template'],
                          // '/', Line break
                        ], // Or Array of button list, eg. [['font', 'align'], ['image']]
                        defaultTag: 'div',
                        minHeight: '300px',
                        height: '230px',
                        showPathLabel: false,
                        font: sortedFontOptions,
                      }}
                      height="230px"
                      className="desc-input"
                    />
                  </div>
                </div> */}
                <div className="inline grid-cols-6 gap-3 md:gap-5 xl:gap-6 lg:gap-6 mb-6 formLabel">
                  <label className="block font-bold text-gray-700 dark:text-gray-400 col-span-4 sm:col-span-2 font-bold mb-3">
                    Contact Us
                  </label>
                  <div className="col-span-8 sm:col-span-4 mb-6">
                    <SunEditor
                      name="termsAndConditions"
                      setContents={contact}
                      onChange={setContact}
                      setOptions={{
                        buttonList: [
                          ["undo", "redo"],
                          ["font", "fontSize"],
                          // ['paragraphStyle', 'blockquote'],
                          [
                            "bold",
                            "underline",
                            "italic",
                            "strike",
                            "subscript",
                            "superscript",
                          ],
                          ["fontColor", "hiliteColor"],
                          ["align", "list", "lineHeight"],
                          ["outdent", "indent"],

                          ["table", "horizontalRule", "link", "image", "video"],
                          // ['math'] //You must add the 'katex' library at options to use the 'math' plugin.
                          // ['imageGallery'], // You must add the "imageGalleryUrl".
                          // ["fullScreen", "showBlocks", "codeView"],
                          ["preview", "print"],
                          ["removeFormat"],

                          // ['save', 'template'],
                          // '/', Line break
                        ], // Or Array of button list, eg. [['font', 'align'], ['image']]
                        defaultTag: "div",
                        minHeight: "300px",
                        height: "230px",
                        showPathLabel: false,
                        font: sortedFontOptions,
                      }}
                      height="230px"
                      className="desc-input"
                    />
                    {/* {touched.description && errors.description && <div className="error-message">{errors.description}</div>} */}
                  </div>
                </div>
                <div className="inline grid-cols-6 gap-3 md:gap-5 xl:gap-6 lg:gap-6 mb-6 formLabel">
                  <label className="block font-bold text-gray-700 dark:text-gray-400 col-span-4 sm:col-span-2 font-bold mb-3">
                    Refund Exchange Policy
                  </label>
                  <div className="col-span-8 sm:col-span-4 mb-6">
                    <SunEditor
                      name="termsAndConditions"
                      setContents={refund}
                      onChange={setRefund}
                      setOptions={{
                        buttonList: [
                          ["undo", "redo"],
                          ["font", "fontSize"],
                          // ['paragraphStyle', 'blockquote'],
                          [
                            "bold",
                            "underline",
                            "italic",
                            "strike",
                            "subscript",
                            "superscript",
                          ],
                          ["fontColor", "hiliteColor"],
                          ["align", "list", "lineHeight"],
                          ["outdent", "indent"],

                          ["table", "horizontalRule", "link", "image", "video"],
                          // ['math'] //You must add the 'katex' library at options to use the 'math' plugin.
                          // ['imageGallery'], // You must add the "imageGalleryUrl".
                          // ["fullScreen", "showBlocks", "codeView"],
                          ["preview", "print"],
                          ["removeFormat"],

                          // ['save', 'template'],
                          // '/', Line break
                        ], // Or Array of button list, eg. [['font', 'align'], ['image']]
                        defaultTag: "div",
                        minHeight: "300px",
                        height: "230px",
                        showPathLabel: false,
                        font: sortedFontOptions,
                      }}
                      height="230px"
                      className="desc-input"
                    />
                    {/* {touched.description && errors.description && <div className="error-message">{errors.description}</div>} */}
                  </div>
                </div>
                <div className="inline grid-cols-6 gap-3 md:gap-5 xl:gap-6 lg:gap-6 mb-6 formLabel">
                  <label className="block font-bold text-gray-700 dark:text-gray-400 col-span-4 sm:col-span-2 font-bold mb-3">
                    Cancellation
                  </label>
                  <div className="col-span-8 sm:col-span-4 mb-6">
                    <SunEditor
                      name="termsAndConditions"
                      setContents={cancel}
                      onChange={setCancel}
                      setOptions={{
                        buttonList: [
                          ["undo", "redo"],
                          ["font", "fontSize"],
                          // ['paragraphStyle', 'blockquote'],
                          [
                            "bold",
                            "underline",
                            "italic",
                            "strike",
                            "subscript",
                            "superscript",
                          ],
                          ["fontColor", "hiliteColor"],
                          ["align", "list", "lineHeight"],
                          ["outdent", "indent"],

                          ["table", "horizontalRule", "link", "image", "video"],
                          // ['math'] //You must add the 'katex' library at options to use the 'math' plugin.
                          // ['imageGallery'], // You must add the "imageGalleryUrl".
                          // ["fullScreen", "showBlocks", "codeView"],
                          ["preview", "print"],
                          ["removeFormat"],

                          // ['save', 'template'],
                          // '/', Line break
                        ], // Or Array of button list, eg. [['font', 'align'], ['image']]
                        defaultTag: "div",
                        minHeight: "300px",
                        height: "230px",
                        showPathLabel: false,
                        font: sortedFontOptions,
                      }}
                      height="230px"
                      className="desc-input"
                    />
                    {/* {touched.description && errors.description && <div className="error-message">{errors.description}</div>} */}
                  </div>
                </div>
                <div className="inline grid-cols-6 gap-3 md:gap-5 xl:gap-6 lg:gap-6 mb-6 formLabel">
                  <label className="block font-bold text-gray-700 dark:text-gray-400 col-span-4 sm:col-span-2 font-bold mb-3">
                    CopyRight
                  </label>
                  <div className="col-span-8 sm:col-span-4 mb-6">
                    <SunEditor
                      name="termsAndConditions"
                      setContents={copy}
                      onChange={setCopy}
                      setOptions={{
                        buttonList: [
                          ["undo", "redo"],
                          ["font", "fontSize"],
                          // ['paragraphStyle', 'blockquote'],
                          [
                            "bold",
                            "underline",
                            "italic",
                            "strike",
                            "subscript",
                            "superscript",
                          ],
                          ["fontColor", "hiliteColor"],
                          ["align", "list", "lineHeight"],
                          ["outdent", "indent"],

                          ["table", "horizontalRule", "link", "image", "video"],
                          // ['math'] //You must add the 'katex' library at options to use the 'math' plugin.
                          // ['imageGallery'], // You must add the "imageGalleryUrl".
                          // ["fullScreen", "showBlocks", "codeView"],
                          ["preview", "print"],
                          ["removeFormat"],

                          // ['save', 'template'],
                          // '/', Line break
                        ], // Or Array of button list, eg. [['font', 'align'], ['image']]
                        defaultTag: "div",
                        minHeight: "300px",
                        height: "230px",
                        showPathLabel: false,
                        font: sortedFontOptions,
                      }}
                      height="230px"
                      className="desc-input"
                    />
                    {/* {touched.description && errors.description && <div className="error-message">{errors.description}</div>} */}
                  </div>
                </div>
                <div className="inline grid-cols-6 gap-3 md:gap-5 xl:gap-6 lg:gap-6 mb-6 formLabel">
                  <label className="block font-bold text-gray-700 dark:text-gray-400 col-span-4 sm:col-span-2 font-bold mb-3">
                    Facebook URL
                  </label>
                  <div className="col-span-8 sm:col-span-4 mb-6 formInput">
                    <input
                      className={
                        errors.facebook && touched.facebook
                          ? "block w-full px-3 py-1 text-sm focus:outline-none text-black dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white input-error "
                          : "block w-full px-3 py-1 text-sm focus:outline-none text-black dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white "
                      }
                      id="url"
                      type="url"
                      name="facebook"
                      placeholder="Enter Facebook URL..."
                      value={values.facebook}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.facebook && errors.facebook && (
                      <div className="error-message">{errors.facebook}</div>
                    )}
                  </div>
                </div>
                <div className="inline grid-cols-6 gap-3 md:gap-5 xl:gap-6 lg:gap-6 mb-6 formLabel">
                  <label className="block font-bold text-gray-700 dark:text-gray-400 col-span-4 sm:col-span-2 font-bold mb-3">
                    Twitter URL
                  </label>
                  <div className="col-span-8 sm:col-span-4 mb-6 formInput">
                    <input
                      className={
                        errors.twitter && touched.twitter
                          ? "block w-full px-3 py-1 text-sm focus:outline-none text-black dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring  dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white input-error"
                          : "block w-full px-3 py-1 text-sm focus:outline-none text-black dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring  dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white"
                      }
                      type="url"
                      name="twitter"
                      placeholder="Enter Twitter URL..."
                      value={values.twitter}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.twitter && errors.twitter && (
                      <div className="error-message">{errors.twitter}</div>
                    )}
                  </div>
                </div>
                <div className="inline grid-cols-6 gap-3 md:gap-5 xl:gap-6 lg:gap-6 mb-6 formLabel">
                  <label className="block font-bold text-gray-700 dark:text-gray-400 col-span-4 sm:col-span-2 font-bold mb-3">
                    Linkedin URL
                  </label>
                  <div className="col-span-8 sm:col-span-4 mb-6 formInput">
                    <input
                      className={
                        errors.linkedin && touched.linkedin
                          ? "block w-full px-3 py-1 text-sm focus:outline-none text-black dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring  dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white input-error"
                          : "block w-full px-3 py-1 text-sm focus:outline-none text-black dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring  dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white"
                      }
                      type="url"
                      name="linkedin"
                      placeholder="Enter Linkedin URL..."
                      value={values.linkedin}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.linkedin && errors.linkedin && (
                      <div className="error-message">{errors.linkedin}</div>
                    )}
                  </div>
                </div>
                <div className="inline grid-cols-6 gap-3 md:gap-5 xl:gap-6 lg:gap-6 mb-6 formLabel">
                  <label className="block font-bold text-gray-700 dark:text-gray-400 col-span-4 sm:col-span-2 font-bold mb-3">
                    Instagram URL
                  </label>
                  <div className="col-span-8 sm:col-span-4 mb-6 formInput">
                    <input
                      className={
                        errors.instagram && touched.instagram
                          ? "block w-full px-3 py-1 text-sm focus:outline-none text-black dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white input-error "
                          : "block w-full px-3 py-1 text-sm focus:outline-none text-black dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white "
                      }
                      type="url"
                      name="instagram"
                      placeholder="Enter Instagram URL..."
                      value={values.instagram}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.instagram && errors.instagram && (
                      <div className="error-message">{errors.instagram}</div>
                    )}
                  </div>
                </div>
                <div className="inline grid-cols-6 gap-3 md:gap-5 xl:gap-6 lg:gap-6 mb-6 formLabel">
                  <label className="block font-bold text-gray-700 dark:text-gray-400 col-span-4 sm:col-span-2 font-bold mb-3">
                    Section Two title
                  </label>
                  <div className="col-span-8 sm:col-span-4 mb-6 formInput">
                    <input
                      className={
                        errors.sectionTwo && touched.sectionTwo
                          ? "block w-full px-3 py-1 text-sm focus:outline-none text-black dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white input-error "
                          : "block w-full px-3 py-1 text-sm focus:outline-none text-black dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white "
                      }
                      type="text"
                      name="sectionTwo"
                      placeholder="Enter Title"
                      value={values.sectionTwo}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.sectionTwo && errors.sectionTwo && (
                      <div className="error-message">{errors.sectionTwo}</div>
                    )}
                  </div>
                </div>
                <div className="inline grid-cols-6 gap-3 md:gap-5 xl:gap-6 lg:gap-6 mb-6 formLabel">
                  <label className="block font-bold text-gray-700 dark:text-gray-400 col-span-4 sm:col-span-2 font-bold mb-3">
                    Section Three title
                  </label>
                  <div className="col-span-8 sm:col-span-4 mb-6 formInput">
                    <input
                      className={
                        errors.sectionThree && touched.sectionThree
                          ? "block w-full px-3 py-1 text-sm focus:outline-none text-black dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white input-error "
                          : "block w-full px-3 py-1 text-sm focus:outline-none text-black dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white "
                      }
                      type="text"
                      name="sectionThree"
                      placeholder="Enter Title"
                      value={values.sectionThree}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.sectionThree && errors.sectionThree && (
                      <div className="error-message">{errors.sectionThree}</div>
                    )}
                  </div>
                </div>
                <div className="inline grid-cols-6 gap-3 md:gap-5 xl:gap-6 lg:gap-6 mb-6 formLabel">
                  <label className="block font-bold text-gray-700 dark:text-gray-400 col-span-4 sm:col-span-2 font-bold mb-3">
                    Section Four title
                  </label>
                  <div className="col-span-8 sm:col-span-4 mb-6 formInput">
                    <input
                      className={
                        errors.sectionFour && touched.sectionFour
                          ? "block w-full px-3 py-1 text-sm focus:outline-none text-black dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white input-error "
                          : "block w-full px-3 py-1 text-sm focus:outline-none text-black dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white "
                      }
                      type="text"
                      name="sectionFour"
                      placeholder="Enter Title"
                      value={values.sectionFour}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.sectionFour && errors.sectionFour && (
                      <div className="error-message">{errors.sectionFour}</div>
                    )}
                  </div>
                </div>
                <div className="inline grid-cols-6 gap-3 md:gap-5 xl:gap-6 lg:gap-6 mb-6 formLabel">
                  <label className="block font-bold text-gray-700 dark:text-gray-400 col-span-4 sm:col-span-2 font-bold mb-3">
                    Section Six title
                  </label>
                  <div className="col-span-8 sm:col-span-4 mb-6 formInput">
                    <input
                      className={
                        errors.sectionSix && touched.sectionSix
                          ? "block w-full px-3 py-1 text-sm focus:outline-none text-black dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white input-error "
                          : "block w-full px-3 py-1 text-sm focus:outline-none text-black dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white "
                      }
                      type="text"
                      name="sectionSix"
                      placeholder="Enter title"
                      value={values.sectionSix}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.sectionSix && errors.sectionSix && (
                      <div className="error-message">{errors.sectionSix}</div>
                    )}
                  </div>
                </div>
                {/* <div className="inline grid-cols-6 gap-3 md:gap-5 xl:gap-6 lg:gap-6 mb-6 formLabel">
                  <label className="block font-bold text-gray-700 dark:text-gray-400 col-span-4 sm:col-span-2 font-bold mb-3">
                    Section seven title
                  </label>
                  <div className="col-span-8 sm:col-span-4 mb-6 formInput">
                    <input
                      className={
                        errors.sectionSeven && touched.sectionSeven
                          ? 'block w-full px-3 py-1 text-sm focus:outline-none text-black dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white input-error '
                          : 'block w-full px-3 py-1 text-sm focus:outline-none text-black dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white '
                      }
                      type="text"
                      name="sectionSeven"
                      placeholder="Enter title"
                      value={values.sectionSeven}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.sectionSeven && errors.sectionSeven && (
                      <div className="error-message">{errors.sectionSeven}</div>
                    )}
                  </div>
                </div> */}
                <div className="inline grid-cols-6 gap-3 md:gap-5 xl:gap-6 lg:gap-6 mb-6 formLabel">
                  <label className="block font-bold text-gray-700 dark:text-gray-400 col-span-4 sm:col-span-2 font-bold mb-3">
                    Add to Cart
                  </label>
                  <div className="col-span-8 sm:col-span-4 mb-6 formInput">
                    <input
                      className={
                        errors.addToCart && touched.addToCart
                          ? "block w-full px-3 py-1 text-sm focus:outline-none text-black dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white input-error "
                          : "block w-full px-3 py-1 text-sm focus:outline-none text-black dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white "
                      }
                      type="text"
                      name="addToCart"
                      placeholder="Enter title"
                      value={values.addToCart}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.addToCart && errors.addToCart && (
                      <div className="error-message">{errors.addToCart}</div>
                    )}
                  </div>
                </div>
                <div className="inline grid-cols-6 gap-3 md:gap-5 xl:gap-6 lg:gap-6 mb-6 formLabel">
                  <label className="block font-bold text-gray-700 dark:text-gray-400 col-span-4 sm:col-span-2 font-bold mb-3">
                    Add to Wishlist
                  </label>
                  <div className="col-span-8 sm:col-span-4 mb-6 formInput">
                    <input
                      className={
                        errors.addToWishList && touched.addToWishList
                          ? "block w-full px-3 py-1 text-sm focus:outline-none text-black dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white input-error "
                          : "block w-full px-3 py-1 text-sm focus:outline-none text-black dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white "
                      }
                      type="text"
                      name="addToWishList"
                      placeholder="Enter title"
                      value={values.addToWishList}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.addToWishList && errors.addToWishList && (
                      <div className="error-message">
                        {errors.addToWishList}
                      </div>
                    )}
                  </div>
                </div>
                <div className="inline grid-cols-6 gap-3 md:gap-5 xl:gap-6 lg:gap-6 mb-6 formLabel">
                  <label className="block font-bold text-gray-700 dark:text-gray-400 col-span-4 sm:col-span-2 font-bold mb-3">
                    Apply Coupon
                  </label>
                  <div className="col-span-8 sm:col-span-4 mb-6 formInput">
                    <input
                      className={
                        errors.applyCoupon && touched.applyCoupon
                          ? "block w-full px-3 py-1 text-sm focus:outline-none text-black dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white input-error "
                          : "block w-full px-3 py-1 text-sm focus:outline-none text-black dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white "
                      }
                      type="text"
                      name="applyCoupon"
                      placeholder="Enter title"
                      value={values.applyCoupon}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.applyCoupon && errors.applyCoupon && (
                      <div className="error-message">{errors.applyCoupon}</div>
                    )}
                  </div>
                </div>
                <div className="inline grid-cols-6 gap-3 md:gap-5 xl:gap-6 lg:gap-6 mb-6 formLabel">
                  <label className="block font-bold text-gray-700 dark:text-gray-400 col-span-4 sm:col-span-2 font-bold mb-3">
                    Checkout
                  </label>
                  <div className="col-span-8 sm:col-span-4 mb-6 formInput">
                    <input
                      className={
                        errors.checkout && touched.checkout
                          ? "block w-full px-3 py-1 text-sm focus:outline-none text-black dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white input-error "
                          : "block w-full px-3 py-1 text-sm focus:outline-none text-black dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white "
                      }
                      type="text"
                      name="checkout"
                      placeholder="Enter title"
                      value={values.checkout}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.checkout && errors.checkout && (
                      <div className="error-message">{errors.checkout}</div>
                    )}
                  </div>
                </div>
                <div className="inline grid-cols-6 gap-3 md:gap-5 xl:gap-6 lg:gap-6 mb-6 formLabel">
                  <label className="block font-bold text-gray-700 dark:text-gray-400 col-span-4 sm:col-span-2 font-bold mb-3">
                    Place Order
                  </label>
                  <div className="col-span-8 sm:col-span-4 mb-6 formInput">
                    <input
                      className={
                        errors.placeOrder && touched.placeOrder
                          ? "block w-full px-3 py-1 text-sm focus:outline-none text-black dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white input-error "
                          : "block w-full px-3 py-1 text-sm focus:outline-none text-black dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white "
                      }
                      type="text"
                      name="placeOrder"
                      placeholder="Enter title"
                      value={values.placeOrder}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.placeOrder && errors.placeOrder && (
                      <div className="error-message">{errors.placeOrder}</div>
                    )}
                  </div>
                </div>
                <div className="inline grid-cols-6 gap-3 md:gap-5 xl:gap-6 lg:gap-6 mb-6 formLabel">
                  <label className="block font-bold text-gray-700 dark:text-gray-400 col-span-4 sm:col-span-2 font-bold mb-3">
                    Continue Shopping
                  </label>
                  <div className="col-span-8 sm:col-span-4 mb-6 formInput">
                    <input
                      className={
                        errors.continueShopping && touched.continueShopping
                          ? "block w-full px-3 py-1 text-sm focus:outline-none text-black dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white input-error "
                          : "block w-full px-3 py-1 text-sm focus:outline-none text-black dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white "
                      }
                      type="text"
                      name="continueShopping"
                      placeholder="Enter title"
                      value={values.continueShopping}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.continueShopping && errors.continueShopping && (
                      <div className="error-message">
                        {errors.continueShopping}
                      </div>
                    )}
                  </div>
                </div>
                <Card
                  style={{
                    padding: "20px",
                    boxShadow: "0 2px 16px 4px rgba(40, 44, 63, 0.07)",
                    marginBottom: 20,
                  }}
                >
                  {/* <div className="inline grid-cols-6 gap-3 md:gap-5 xl:gap-6 lg:gap-6 mb-6 formLabel">
                    <label className="block font-bold text-gray-700 dark:text-gray-400 col-span-4 sm:col-span-2 font-bold mb-3">
                      Video Title
                    </label>
                    <div className="col-span-8 sm:col-span-4 mb-6 formInput">
                      <input
                        className={
                          errors.homepageVideoTitle && touched.homepageVideoTitle
                            ? 'block w-full px-3 py-1 text-sm focus:outline-none text-black dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white input-error '
                            : 'block w-full px-3 py-1 text-sm focus:outline-none text-black dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white '
                        }
                        type="text"
                        name="homepageVideoTitle"
                        placeholder="Enter Title"
                        value={values.homepageVideoTitle}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {touched.homepageVideoTitle && errors.homepageVideoTitle && (
                        <div className="error-message">{errors.homepageVideoTitle}</div>
                      )}
                    </div>
                  </div> */}
                  <div className="inline grid-cols-6 gap-3 md:gap-5 xl:gap-6 lg:gap-6 mb-6 formLabel">
                    <label className="block font-bold text-gray-700 dark:text-gray-400 col-span-4 sm:col-span-2 font-bold mb-3">
                      Video Url
                    </label>
                    <div className="col-span-8 sm:col-span-4 mb-6 formInput">
                      <input
                        className={
                          errors.homepageVideoLink && touched.homepageVideoLink
                            ? "block w-full px-3 py-1 text-sm focus:outline-none text-black dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white input-error "
                            : "block w-full px-3 py-1 text-sm focus:outline-none text-black dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white "
                        }
                        type="text"
                        name="homepageVideoLink"
                        placeholder="Enter Url"
                        value={values.homepageVideoLink}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {touched.homepageVideoLink &&
                        errors.homepageVideoLink && (
                          <div className="error-message">
                            {errors.homepageVideoLink}
                          </div>
                        )}
                    </div>
                  </div>
                  <div className="inline grid-cols-6 gap-3 md:gap-5 xl:gap-6 lg:gap-6 mb-6 formLabel">
                    <label className="block font-bold text-gray-700 dark:text-gray-400 col-span-4 sm:col-span-2 font-bold mb-3">
                      Upload Video
                    </label>
                    <div className="col-span-8 sm:col-span-4 formInput">
                      <input
                        type="file"
                        className="block w-full px-3 py-1 text-sm focus:outline-none dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white"
                        // onChange={handleFileChange}
                        onChange={(e) => handleImageUpload(e, "homepageVideo")}
                        // name="homepageVideo"
                        accept="video/*"
                      />
                      {/* {previewUrl[0] && (
                        <video
                          autoPlay
                          controls
                          src={previewUrl[0]}
                          style={{ width: '50%', height: '50%', marginTop: '10px', backgroundColor: 'transparent' }}
                        />
                      )} */}
                      {previewUrl.length
                        ? previewUrl
                            .filter((i) => i.image !== "")
                            .map((image, index) => (
                              <div
                                className="image-upload"
                                key={index}
                                style={{ position: "relative" }}
                              >
                                <span
                                  onClick={() =>
                                    handleOpenDeleteModal(
                                      index,
                                      "homepageVideo"
                                    )
                                  }
                                >
                                  <HighlightOffIcon className="cancel-images" />
                                </span>
                                <div className="set-image">
                                  <video
                                    controls
                                    key={index}
                                    src={image.image}
                                    alt=""
                                    height={"50%"}
                                    width={"50%"}
                                  />
                                </div>
                              </div>
                            ))
                        : ""}
                    </div>
                  </div>
                </Card>
        
                 
                  <div className="inline grid-cols-6 gap-3 md:gap-5 xl:gap-6 lg:gap-6 mb-6 formLabel">
                    <label className="block font-bold text-gray-700 dark:text-gray-400 col-span-4 sm:col-span-2 font-bold mb-3">
                      Offer description
                    </label>
                    <SunEditor
                      name="offerDescription"
                      setContents={offerDescription}
                      onChange={setofferDescription}
                      setOptions={{
                        buttonList: [
                          ["undo", "redo"],
                          ["font", "fontSize"],
                          // ['paragraphStyle', 'blockquote'],
                          [
                            "bold",
                            "underline",
                            "italic",
                            "strike",
                            "subscript",
                            "superscript",
                          ],
                          ["fontColor", "hiliteColor"],
                          ["align", "list", "lineHeight"],
                          ["outdent", "indent"],

                          ["table", "horizontalRule", "link", "image", "video"],
                          // ['math'] //You must add the 'katex' library at options to use the 'math' plugin.
                          // ['imageGallery'], // You must add the "imageGalleryUrl".
                          // ["fullScreen", "showBlocks", "codeView"],
                          ["preview", "print"],
                          ["removeFormat"],

                          // ['save', 'template'],
                          // '/', Line break
                        ], // Or Array of button list, eg. [['font', 'align'], ['image']]
                        defaultTag: "div",
                        minHeight: "300px",
                        height: "230px",
                        showPathLabel: false,
                        font: sortedFontOptions,
                      }}
                      height="230px"
                      className="desc-input"
                    />
                  </div>
                 
                  
              
                <div className="inline grid-cols-6 gap-3 md:gap-5 xl:gap-6 lg:gap-6 mb-6 formLabel">
                  <label className="block font-bold text-gray-700 dark:text-gray-400 col-span-4 sm:col-span-2  font-bold mb-3">
                    Successfull Page Banner
                  </label>
                  <div className="file-input col-span-8 sm:col-span-4 mb-6 formInput">
                    <input
                      type="file"
                      accept="image/*"
                      className="block w-full px-3 py-1 text-sm focus:outline-none dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white"
                      onChange={(e) =>
                        handleImageUpload(e, "successfullBanner")
                      }
                    />
                    <span className="button">Choose File</span>
                  </div>
                </div>
                <div
                  className="col-span-8 flex images"
                  style={{ marginTop: 5 }}
                >
                  {prevSuccessfullImage.length
                    ? prevSuccessfullImage
                        .filter((i) => i.image !== "")
                        .map((image, index) => (
                          <div
                            className="image-upload"
                            key={index}
                            style={{ position: "relative" }}
                          >
                            <span
                              onClick={() =>
                                handleOpenDeleteModal(
                                  index,
                                  "successfullBanner"
                                )
                              }
                            >
                              <HighlightOffIcon className="cancel-images" />
                            </span>
                            <div className="set-image">
                              <img
                                key={index}
                                src={image.image}
                                alt=""
                                height={"65px"}
                                width={"100px"}
                              />
                            </div>
                          </div>
                        ))
                    : ""}
                </div>
                <div className="button-container">
                  <button type="submit" name="submit" className="submit-button">
                    SUBMIT
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </Card>
      </Container>
    </>
  );
}

export default SettingPage;

import { useEffect, useState } from "react";
import {
  Badge,
  Box,
  Button,
  Circle,
  Flex,
  Grid,
  Icon,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Tooltip,
  useBreakpointValue,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { FiShoppingCart } from "react-icons/fi";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  ToastContainer,
  toast,
  Flip,
  cssTransition,
  Bounce,
} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header";
import "./productListing.css";
import ProductSidebar from "../../components/productList-sidebar/ProductSidebar";
import newArrival1 from "../../assets/newArrival1.webp";
import newArrival2 from "../../assets/newArrival2.webp";
import newArrival3 from "../../assets/newArrival3.webp";
import newArrival4 from "../../assets/newArrival4.webp";
import newArrival5 from "../../assets/newArrival5.webp";
import {
  fetchProductById,
  fetchProductListData,
} from "../../actions/ProductListingActions";
import { fetchFilterOptions } from "../../actions/productFilterActions";
import { addtocart, cartList } from "../../actions/CartActions";
import store from "../../store";
import Loader from "../../components/loader/Loader";
import {
  addWishlist,
  getWishlist,
  removeWishlist,
} from "../../actions/wishlistActions";
import { fetchHomeData } from "../../actions/HomePageActions";
import { fetchUserData } from "../../actions/UserActions";
import ReactPaginate from "react-paginate";
import LoginModal from "../../components/login-modal/LoginModal";
import axiosInstance from "../../axios";
import noImage from "../../assets/noImageAvailable.jpg";

export const notificationStyle = {
  position: "top-right",
  autoClose: 1000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "light",
  transition: Bounce,
};

export default function ProductListing() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const productData = useSelector((state) => state.productList);
  const { wishlist } = useSelector((state) => state.wishlist);
  const { homePageData } = useSelector((state) => state.home);
  const { userData, token } = useSelector((state) => state.user);
  const [product, setProduct] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const handleClick = (id) => {
    navigate(`/product-details/${id}`);
  };
  const { section } = useParams();
  useEffect(() => {
    if (!homePageData.length) {
      dispatch(fetchHomeData());
    }
    if(userData?.user_id){
      dispatch(cartList(userData?.user_id));
      dispatch(getWishlist(userData?.user_id));
    }
    if (location.pathname.includes("product-section")) {
      axiosInstance(`/home-page/home-page-section-2-by-title/${section}`, "Get")
        .then((response) => {
          // Dispatch success action with the received data
          dispatch(
            fetchProductListData({
              product_id: JSON.parse(response.data.data.products) || [],
            })
          );
        })
        .catch((error) => {
          // Dispatch failure action with the error message
        });
    }
    dispatch(fetchFilterOptions());
    // return () => {
    //   setProduct([]);
    // };
  }, []);
  // useEffect(() => {
  //   if (homePageData?.navigation?.length && slug) {
  //     const matchedRecordIds = [];
  //     homePageData.navigation.forEach((record) => {
  //       if (record.slug === slug) {
  //         matchedRecordIds.push(record.category_id);
  //         if (record.childCategories && record.childCategories.length > 0) {
  //           record.childCategories.forEach((child) => {
  //             matchedRecordIds.push(child.category_id);
  //           });
  //         }
  //       } else if (record.childCategories && record.childCategories.length > 0) {
  //         const childMatch = record.childCategories.find((child) => child.slug === slug);
  //         if (childMatch) {
  //           matchedRecordIds.push(childMatch.category_id);
  //         }
  //       }
  //     });
  //     dispatch(fetchProductListData({ category: matchedRecordIds }));
  //   }
  // }, [slug, homePageData.navigation]);

  useEffect(() => {
    if (
      productData?.ProductList?.data &&
      productData?.ProductList?.data?.length
    ) {
      const newData = productData.ProductList.data.map((item) => {
        const isExist =
          wishlist && wishlist.find((i) => i.product_id === item.product_id);
        return {
          ...item,
          isWishlist: !!isExist,
        };
      });
      setProduct(newData);
    } else {
      setProduct([]);
    }
  }, [productData?.ProductList?.data, wishlist]);

  const handleCart = (userId, productId, quantity) => {
    if (token) {
      const data = {
        userId,
        quantity,
        productId,
      };
      dispatch(addtocart(data));

      toast.success("Item Added to cart", notificationStyle);
    } else {
      onOpen();
    }
  };

  const handleAddWishlist = (userId, productId) => {
    if (token) {
      const data = {
        userId,
        productId,
      };
      dispatch(addWishlist(data));
      toast.success("Item Added to wishlist", notificationStyle);
    } else {
      onOpen();
    }
  };
  const handleRemove = (userId, productId, wishlistId) => {
    const data = {
      userId,
      wishlistId,
    };
    dispatch(removeWishlist(data));
    toast.success("Remove Successfully", notificationStyle);
  };
  // const handlePageClick = (selected) => {
  //   const data = {
  //     page: selected?.selected + 1,
  //     limit: productData?.ProductList?.pagination?.itemsPerPage,
  //   };
  //   dispatch(fetchProductListData(data));
  // };
  return (
    <div>
      <Header headerData={homePageData} />
      {/* <ProductSidebar> */}
      <Loader loading={productData?.loading}>
        <Grid
          templateColumns={{
            base: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
            lg: "repeat(4, 1fr)",
          }}
          gap={6}
          className="productList"
          width={"100%"}
          marginTop={{ base: 3, sm: 4, md: 6, lg: 4 }}
        >
          {product && product ? (
            product.map((item, i) => {
              const findwishlist = wishlist?.find(
                (i) => i.product_id === item.product_id
              );
              return (
                <Box
                  key={i}
                  // boxShadow={' rgba(0, 0, 0, 0.1) 0px 4px 12px'}
                  width={{ base: "70%", sm: "80%", md: "100%", lg: "100%" }}
                  background={"white"}
                  className="boxshadow"
                >
                  <Box position={"relative"}>
                    {item?.ProductImages && item?.ProductImages?.length ? (
                      <Image
                        width={{
                          base: "100%",
                          sm: "100%",
                          md: "100%",
                          lg: "100%",
                        }}
                        src={`${process.env.REACT_APP_IMAGE_URL}${item.ProductImages[0].image_url}`}
                        position={"relative"}
                        className="common-borderRadius"
                        cursor={"pointer"}
                        onClick={() => handleClick(item.slug)}
                      />
                    ) : (
                      <Image
                        width={{
                          base: "100%",
                          sm: "100%",
                          md: "100%",
                          lg: "100%",
                        }}
                        src={noImage}
                        position={"relative"}
                        className="common-borderRadius"
                        cursor={"pointer"}
                        onClick={() => handleClick(item.slug)}
                      />
                    )}
                    {item.isWishlist ? (
                      <span className="wishlist-icon2">
                        <AiOutlineHeart
                          size={25}
                          color="#ed691f"
                          cursor={"pointer"}
                          className="product-wishlist"
                          onClick={() =>
                            handleRemove(
                              userData.user_id,
                              item.product_id,
                              findwishlist.wishlist_id
                            )
                          }
                        />
                        <AiFillHeart
                          className="product-fillWishlist"
                          size={25}
                          cursor={"pointer"}
                        />
                      </span>
                    ) : (
                      <span className="wishlist-icon">
                        <AiOutlineHeart
                          size={25}
                          color="#ed691f"
                          cursor={"pointer"}
                          className="product-wishlist"
                        />
                        <AiFillHeart
                          className="product-fillWishlist"
                          size={25}
                          cursor={"pointer"}
                          onClick={() =>
                            handleAddWishlist(
                              userData.user_id,
                              item?.product_id
                            )
                          }
                        />
                      </span>
                    )}
                  </Box>
                  <Text className="watch-details" paddingLeft={"5px"}>
                    {item.name}
                  </Text>
                  <Flex justifyContent={"space-between"} alignItems={"center"}>
                    {item.discount !== null && item.discount !== "" ? (
                      <>
                        <Text
                          className="discount-priceText"
                          paddingLeft={"5px"}
                        >
                          <Grid>
                            <span>
                              ₹
                              {Number(item.price) -
                                (
                                  (Number(item.price) * Number(item.discount)) /
                                  100
                                ).toFixed(2)}
                            </span>
                            <span className="new-product-item-price">
                              ₹{Number(item.price).toFixed(2)}
                            </span>
                          </Grid>
                        </Text>
                      </>
                    ) : (
                      <>
                        <Text className="watch-price" paddingLeft={"5px"}>
                          <span>₹</span>
                          <span>{item.price}</span>
                        </Text>
                      </>
                    )}
                    <Button
                      className="cart-btn"
                      size="sm"
                      onClick={() =>
                        handleCart(userData?.user_id, item.product_id, 1)
                      }
                      background={'transparent'}
                    >
                      {homePageData &&
                      homePageData?.setting &&
                      homePageData?.setting?.addToCart
                        ? homePageData?.setting?.addToCart
                        : "Add To Cart"}
                    </Button>
                  </Flex>
                </Box>
                // <Box borderRadius={'20px'} key={i}>
                //   <Image borderRadius={'20px'} width={'93%'} margin="auto" src={item} />
                //   <Text className="watch-details">
                //     Titan Evoke with large with 1.43" AMOLED Display 1000 Nits Brightness, SingleSync, IP68 Water Resistance
                //   </Text>
                // </Box>
              );
            })
          ) : (
            <Box
              // boxShadow={' rgba(0, 0, 0, 0.1) 0px 4px 12px'}
              width={{ base: "70%", sm: "80%", md: "100%", lg: "100%" }}
              background={"white"}
              className="boxshadow"
            >
              <Text fontSize={"3xl"}>No Data Found</Text>
            </Box>
          )}
        </Grid>
      </Loader>
      {/* </ProductSidebar> */}
      {/* <ReactPaginate
      breakLabel="..."
      nextLabel=">"
      onPageChange={handlePageClick}
      pageRangeDisplayed={5}
      marginPagesDisplayed={2}
      pageCount={productData?.ProductList?.pagination?.totalPages}
      disableInitialCallback
      previousLabel="<"
      containerClassName={'pagination justify-content-center'}
      previousLinkClassName={'pagination__link'}
      nextLinkClassName={'pagination__link'}
      disabledClassName={'pagination__link--disabled'}
      activeClassName={'pagination__link--active'}
    /> */}
      <Footer />
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <LoginModal isOpen={isOpen} onClose={onClose} />
    </div>
  );
}

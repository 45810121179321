import {
  GET_ORDER_LIST_FAILURE,
  GET_ORDER_LIST_REQUEST,
  GET_ORDER_LIST_SUCCESS,
  GET_ORDER_LIST_ID_SUCCESS,
} from '../actions/UserOrderActions';

const initialState = {
  loading: false,
  orderHistory: [],
  orderById: {},
  error: '',
};

const UserOrderReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ORDER_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ORDER_LIST_SUCCESS:
      return {
        loading: false,
        orderHistory: action.payload,
        error: '',
      };
    case GET_ORDER_LIST_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        orderById: action.payload,
        error: '',
      };
    case GET_ORDER_LIST_FAILURE:
      return {
        loading: false,
        orderHistory: [],
        error: action.payload,
      };
    default:
      return state;
  }
};
export default UserOrderReducer;

import React, { useEffect, useState } from "react";
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerContent,
  DrawerCloseButton,
  Button,
  useDisclosure,
  Box,
  Image,
  Text,
  SimpleGrid,
  DrawerFooter,
  Badge,
  Grid,
} from "@chakra-ui/react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import {
  AiOutlineHeart,
  AiOutlineCloseCircle,
  AiOutlineShoppingCart,
} from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import newArrival1 from "../../assets/newArrival1.webp";
import "./cartDrawer.css";
import {
  cartList,
  deleteCart,
  handleCartQuantity,
} from "../../actions/CartActions";
import { notificationStyle } from "../../pages/product-list/ProductListing";
import noImage from "../../assets/noImageAvailable.jpg";

function CartDrawer({ children, ...rest }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDispatch();
  const { cart } = useSelector((state) => state.cart);
  const { userData } = useSelector((state) => state.user);
  const [style, setStyle] = useState();
  const navigate = useNavigate();
  const btnRef = React.useRef();
  const [subTotal, setSubTotal] = useState(0);
  useEffect(() => {
    if(userData?.user_id){

      dispatch(cartList(userData?.user_id));
    }
  }, []);
  useEffect(() => {
    if (cart && cart.length) {
      const findTotal = cart.reduce((acc, obj) => {
        if (
          obj.Product.discount &&
          obj.Product.discount !== "" &&
          obj.Product.discount !== null
        ) {
          acc +=
            obj.quantity *
            (Number(obj.Product.price) -
              (
                (Number(obj.Product.price) * Number(obj.Product.discount)) /
                100
              ).toFixed(2));
        } else {
          acc += obj.quantity * Number(obj.Product.price);
        }
        return acc;
      }, 0);
      setSubTotal(findTotal);
    } else {
      setSubTotal(0);
    }
  }, [cart]);
  const handleQuantity = async (cartId, quantity) => {
    dispatch(
      handleCartQuantity({ cartId, userId: userData.user_id, quantity })
    );
  };
  const handleDeleteCart = (cartId) => {
    toast.success("Remove Successfully", notificationStyle);
    dispatch(deleteCart({ cartId, userId: userData.user_id }));
  };
  const handleContinueShopping = () => {
    navigate("/product");
    onClose();
  };
  useEffect(() => {}, [cart]);
  return (
    <>
      {/* <Button ref={btnRef} colorScheme="teal" onClick={onOpen}>
        Open
      </Button> */}
      <AiOutlineShoppingCart
        ref={btnRef}
        onClick={onOpen}
        size={25}
        color="#ed691f"
        cursor={"pointer"}
        className="header-icon"
      />
      <Badge
        background={"transparent"}
        // width={'20px'}
        // height={'18px'}
        // marginLeft={'-7px'}
        color={"#ed691f"}
        fontSize={"13px"}
        // borderRadius={'50%'}
        textAlign={"center"}
      >
        {cart?.length}
      </Badge>
      <Drawer
        variant="alwaysOpen"
        {...rest}
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        trapFocus={false}
        closeOnOverlayClick={false}
        blockScrollOnMount={false}
      >
        {/* <DrawerOverlay /> */}
        <DrawerContent maxWidth={"30rem"}>
          <DrawerCloseButton />
          <DrawerHeader
            className="common-userPaneFamily"
            fontWeight="extrabold"
          >
            Shopping Cart
          </DrawerHeader>

          <DrawerBody>
            { cart && cart.length === 0 ? (
              <Box>
                <Box
                  position={"absolute"}
                  top={"25%"}
                  left={{ md: "38%", base: "32%" }}
                  className="empty-cartDrawer"
                >
                  <AiOutlineShoppingCart fontSize={"100px"} color="#f7f5f4" />
                </Box>
                <Text className="empty-cart">Your Cart is Empty</Text>
                <Button
                  className="continue-shoppingbtn"
                  onClick={() => handleContinueShopping()}
                >
                  Continue Shopping
                </Button>
              </Box>
            ) : (
              cart &&
              cart.length &&
              cart.map((item) => (
                <Box
                  //   boxShadow={' rgba(0, 0, 0, 0.1) 0px 4px 12px'}
                  borderTop={"1px solid lightgray"}
                  borderBottom={"1px solid lightgray"}
                  padding={"10px"}
                  width={{ base: "100%", sm: "100%", md: "100%", lg: "100%" }}
                  background={"white"}
                  display={"flex"}
                  pos={"relative"}
                  key={item.cart_id}
                  className={
                    style === item.product_id
                      ? "wishlist-item removed"
                      : "wishlist-item added"
                  }
                >
                  <Box
                    position={"relative"}
                    maxWidth={"97px"}
                    className="image-size"
                  >
                    {item?.Product?.ProductImages &&
                    item?.Product?.ProductImages?.length ? (
                      <Image
                        width={{
                          base: "100%",
                          sm: "100%",
                          md: "100%",
                          lg: "100%",
                        }}
                        src={`${process.env.REACT_APP_IMAGE_URL}${item.Product.ProductImages[0].image_url}`}
                        position={"relative"}
                        cursor={"pointer"}
                        onClick={() =>
                          navigate(`/product-details/${item.Product.slug}`)
                        }
                      />
                    ) : (
                      <Image
                        width={{
                          base: "100%",
                          sm: "100%",
                          md: "100%",
                          lg: "100%",
                        }}
                        src={noImage}
                        position={"relative"}
                        // className="common-borderRadius"
                        cursor={"pointer"}
                        onClick={() =>
                          navigate(`/product-details/${item.Product.slug}`)
                        }
                      />
                    )}
                  </Box>
                  <div className="closed-icon">
                    <AiOutlineCloseCircle
                      size={18}
                      onClick={() => handleDeleteCart(item?.cart_id)}
                    />
                  </div>
                  <SimpleGrid display={"initial"} width={"100%"}>
                    <Text
                      className="watch-details3 wrap-word"
                      paddingLeft={"10px"}
                      onClick={() =>
                        navigate(`/product-details/${item.Product.slug}`)
                      }
                    >
                      {item.Product.name}
                    </Text>
                    <Text className="watch-price3" paddingLeft={"10px"}>
                      {item.Product.discount !== null &&
                      item.Product.discount !== "" ? (
                        <>
                          <span className="new-product-item-price">
                            ₹{item.Product.price}
                          </span>
                          <span>₹</span>
                          <span>
                            {Number(item.Product.price) -
                              (
                                (Number(item.Product.price) *
                                  Number(item.Product.discount)) /
                                100
                              ).toFixed(2)}
                          </span>
                        </>
                      ) : (
                        <>
                          <span>₹</span>
                          <span>{item.Product.price}</span>
                        </>
                      )}
                    </Text>
                    <div>
                      <div className="js-qty__wrapper common-borderRadius">
                        <label
                          htmlFor="cart_updates_40516022501443:72da6388b70622a9b6f20eebea824c5a"
                          className="hidden-label common-userPaneFamily"
                        >
                          Quantity
                        </label>
                        <input
                          type="text"
                          id="cart_updates_40516022501443:72da6388b70622a9b6f20eebea824c5a"
                          name="updates[]"
                          className="js-qty__num"
                          value={item.quantity}
                          min="0"
                          pattern="[0-9]*"
                          data-id="40516022501443:72da6388b70622a9b6f20eebea824c5a"
                        />
                        <button
                          type="button"
                          className="js-qty__adjust js-qty__adjust--minus"
                          aria-label="Reduce item quantity by one"
                          onClick={() =>
                            handleQuantity(item.cart_id, item.quantity - 1)
                          }
                          disabled={item.quantity === 1}
                        >
                          <svg
                            aria-hidden="true"
                            focusable="false"
                            role="presentation"
                            className="icon icon-minus"
                            viewBox="0 0 20 20"
                          >
                            <path
                              fill="#444"
                              d="M17.543 11.029H2.1A1.032 1.032 0 0 1 1.071 10c0-.566.463-1.029 1.029-1.029h15.443c.566 0 1.029.463 1.029 1.029 0 .566-.463 1.029-1.029 1.029z"
                            />
                          </svg>
                          <span
                            className="icon__fallback-text"
                            aria-hidden="true"
                          >
                            −
                          </span>
                        </button>
                        <button
                          type="button"
                          className="js-qty__adjust js-qty__adjust--plus"
                          aria-label="Increase item quantity by one"
                          onClick={() =>
                            handleQuantity(item.cart_id, item.quantity + 1)
                          }
                        >
                          <svg
                            aria-hidden="true"
                            focusable="false"
                            role="presentation"
                            className="icon icon-plus"
                            viewBox="0 0 20 20"
                          >
                            <path
                              fill="#444"
                              d="M17.409 8.929h-6.695V2.258c0-.566-.506-1.029-1.071-1.029s-1.071.463-1.071 1.029v6.671H1.967C1.401 8.929.938 9.435.938 10s.463 1.071 1.029 1.071h6.605V17.7c0 .566.506 1.029 1.071 1.029s1.071-.463 1.071-1.029v-6.629h6.695c.566 0 1.029-.506 1.029-1.071s-.463-1.071-1.029-1.071z"
                            />
                          </svg>
                          <span
                            className="icon__fallback-text"
                            aria-hidden="true"
                          >
                            +
                          </span>
                        </button>
                      </div>
                    </div>
                  </SimpleGrid>
                </Box>
              ))
            )}
          </DrawerBody>

          <DrawerFooter display={"initial"}>
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              className="footer-total"
            >
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Text fontSize={"14px"} className="common-userPaneFamily">
                  SubTotal:
                </Text>
                <Text
                  fontWeight={"600"}
                  className="common-userPaneFamily"
                  marginLeft={2}
                >
                  {" "}
                  <span>₹</span>
                  {subTotal.toFixed(2)}
                </Text>
              </Box>
              <Button
                className="cart-footerBtn common-borderRadius"
                isDisabled={cart && cart.length === 0}
                onClick={() => navigate("/cart")}
                background={'transparent'}
              >
                View Cart
              </Button>
            </Box>
            {/* </Grid> */}
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
}

export default CartDrawer;

// reducers.js
import { ADD_TO_WISHLIST_REQUEST, ADD_TO_WISHLIST_SUCCESS, ADD_TO_WISHLIST_FAILURE } from '../actions/wishlistActions';

const initialState = {
  loading: false,
  wishlist: [],
  error: '',
};

const WishlistReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TO_WISHLIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ADD_TO_WISHLIST_SUCCESS:
      return {
        loading: false,
        wishlist: action.payload,
        error: '',
      };
    case ADD_TO_WISHLIST_FAILURE:
      return {
        loading: false,
        wishlist: [],
        error: action.payload,
      };
    // case GET_WISHLIST_REQUEST:
    //   return {
    //     ...state,
    //     loading: true,
    //   };
    // case GET_WISHLIST_SUCCESS:
    //   return {
    //     loading: false,
    //     data: action.payload,
    //     error: '',
    //   };
    // case GET_WISHLIST_FAILURE:
    //   return {
    //     loading: false,
    //     data: [],
    //     error: action.payload,
    //   };
    default:
      return state;
  }
};

export default WishlistReducer;

import { Box, Card, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router';
import Header from '../../../components/header';
import { fetchHomeData } from '../../../actions/HomePageActions';
import Footer from '../../../components/footer/Footer';

function Policy() {
  const { homePageData } = useSelector((state) => state.home);
  const dispatch = useDispatch();
  const location = useLocation();
  const [pageData, setPageData] = useState();
  useEffect(() => {
    if (!homePageData.length) {
      dispatch(fetchHomeData());
    }
  }, []);
  useEffect(() => {
    if (homePageData && location.pathname.includes('/terms-condition')) {
      setPageData(homePageData?.setting?.termsAndConditions);
    } else if (homePageData && location.pathname.includes('/privacy-policy')) {
      setPageData(homePageData?.setting?.privacyPolicy);
    } else if (homePageData && location.pathname.includes('/contact-us')) {
      setPageData(homePageData?.setting?.contactUs);
    } else if (homePageData && location.pathname.includes('/refund-exchange')) {
      setPageData(homePageData?.setting?.refundExchangePolicy);
    } else if (homePageData && location.pathname.includes('/cancellation')) {
      setPageData(homePageData?.setting?.cancellation);
    } else if (homePageData && location.pathname.includes('/copy-right')) {
      setPageData(homePageData?.setting?.copyright);
    }
  }, [homePageData, location.pathname]);
  return (
    <div>
      <Header headerData={homePageData} />
      <Box
        // maxW={{
        //   base: '3xl',
        //   lg: '7xl',
        // }}
        // mx="auto"

        px={{
          base: '4',
          md: '8',
          lg: '12',
        }}
        py={{
          base: '6',
          md: '8',
          lg: '12',
        }}
        className="form-box"
        display={'flex'}
        justifyContent={'center'}
      >
        <Card
          width={{ md: '100%', lg: '100%' }}
          boxShadow={' rgba(0, 0, 0, 0.1) 0px 4px 12px'}
          background={'white'}
          padding={{ md: 10, lg: 10, base: 4, sm: 4 }}
        >
          <Text
            className="common-userPaneFamily"
            color={'rgb(99, 99, 99)'}
            fontSize={{ base: '15px', sm: '15px', lg: '17px' }}
          >
            {pageData && <div dangerouslySetInnerHTML={{ __html: pageData }} />}
          </Text>
        </Card>
      </Box>
      <Footer />
    </div>
  );
}

export default Policy;

import React, { useEffect, useState } from 'react';
import './addUser.css';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import axiosInstance from '../../axios';
import Notification from '../notification';

function AddUser({ setOpenDrawer, addEdit, id, userListAPI }) {
  const [user, setUser] = useState({
    first_name: '',
    last_name: '',
    email: '',
    username: '',
    password: '',
    confirm_password: '',
    phone_number: '',
    address: '',
    city: '',
    state: '',
    country: '',
    zip_code: '',
    role: 'user',
  });
  useEffect(() => {
    if (addEdit && addEdit === 'edit') {
      const fetchData = async () => {
        const res = await axiosInstance(`/user/${id}`, 'GET', null);
        if (res.status === 200) {
          if (res.data.data) {
            const data = res.data.data;

            setUser({
              first_name: data.first_name,
              last_name: data.last_name,
              email: data.email,
              username: data.username,
              phone_number: data.phone_number,
              address: data.address,
              city: data.city,
              state: data.state,
              country: data.country,
              zip_code: data.zip_code,
            });
          }
        }
      };
      fetchData();
    }
  }, []);
  const validationSchema = Yup.object({
    first_name: Yup.string().required('First Name is required').trim(),
    last_name: Yup.string().required('Last Name is required').trim(),
    email: Yup.string().required('Email is required').email('Please Add Valid Email').trim(),
    username: Yup.string().required('User Name is required').trim(),
    password: Yup.string().required('Password is required').min(6, 'Password must be at least 6 characters.').trim(),
    confirm_password: Yup.string()
      .required('Confirm Password is required')
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .trim(),
    phone_number: Yup.string()
      .required('Phone Number is required')
      .matches(/[0-9]/g, 'Please Enter Valid Number')
      .min(10, 'Phone Number must be at least 10 numbers')
      .max(10, 'Phone Number must be at least 10 numbers')
      .trim(),
    address: Yup.string().required('Address is required').trim(),
    city: Yup.string().required('City is required').trim(),
    state: Yup.string().required('State is required').trim(),
    country: Yup.string().required('Country is required').trim(),
    zip_code: Yup.string()
      .required('Zip Code is required')
      .min(6, 'Zip Code must be at least 6 numbers')
      .max(6, 'Zip Code must be at least 6 numbers')
      .matches(/[0-9]/g, 'Please Enter Valid Number')
      .trim(),
  });

  const handleUserSubmit = async (values, resetForm) => {
    const response = await axiosInstance(`/auth/register`, 'POST', values);
    if (response.status === 200) {
      setOpenDrawer(false);
      setUser({
        first_name: '',
        last_name: '',
        email: '',
        username: '',
        password: '',
        confirm_password: '',
        phone_number: '',
        address: '',
        city: '',
        state: '',
        country: '',
        zip_code: '',
        role: 'user',
      });
      setOpenDrawer(false);
      await userListAPI();
      await Notification('success', 'Add User', response.data.message, 2000);
    } else {
      await Notification('error', 'Add User', response.data.message, 2000);
    }
  };
  const handleUpdate = async (values) => {
    const allUpdatedData = { ...values, user_id: id };
    delete allUpdatedData.username;
    delete allUpdatedData.email;

    const response = await axiosInstance(`/user/update-profile`, 'PUT', allUpdatedData);
    if (response.status === 200) {
      setOpenDrawer(false);
      setUser({
        first_name: '',
        last_name: '',
        phone_number: '',
        address: '',
        city: '',
        state: '',
        country: '',
        zip_code: '',
        role: 'user',
      });
      setOpenDrawer(false);
      await userListAPI();
      await Notification('success', 'Add User', response.data.message, 2000);
    } else {
      await Notification('error', 'Add User', response.data.message, 2000);
    }
  };
  return (
    <div>
      <button
        className="fixed focus:outline-none z-50 text-red-500 hover:bg-red-100 hover:text-gray-700 transition-colors duration-150 bg-white shadow-md mr-6 mt-2 right-0 left-auto w-8 h-8 rounded-full block text-center z-40 mr-7"
        onClick={() => setOpenDrawer(false)}
      >
        <svg
          stroke="currentColor"
          fill="none"
          strokeWidth="2"
          viewBox="0 0 24 24"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="mx-auto"
          height="1em"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
        >
          <line x1="18" y1="6" x2="6" y2="18" />
          <line x1="6" y1="6" x2="18" y2="18" />
        </svg>
      </button>
      <div className="w-full relative p-6 border-gray-100 bg-gray-50 border-gray-700 bg-white-800 text-black-300 model-title">
        <div>
          {addEdit && addEdit === 'edit' ? (
            <h4 className="text-xl font-medium">Edit User</h4>
          ) : (
            <h4 className="text-xl font-medium">Add User</h4>
          )}
        </div>
      </div>
      <div className="mt-12 flex flex-col w-full h-full justify-between">
        <div
          className="w-full md:w-7/12 lg:w-8/12 xl:w-8/12 relative bg-white-700 text-black-200"
          style={{ position: 'relative', overflow: 'hidden', width: '100%', height: '100%' }}
        >
          <div
            style={{
              // position: 'absolute',
              inset: '0px',
              overflow: 'scroll',
              marginRight: '-21px',
              marginBottom: '-21px',
            }}
          >
            <Formik
              enableReinitialize
              initialValues={user}
              validationSchema={validationSchema}
              onSubmit={(values, { resetForm }) => {
                handleUserSubmit(values, resetForm);
                // setSubmitting(true);
                // resetForm();
                // setSubmitting(false);
              }}
            >
              {({ values, errors, touched, handleBlur, handleChange, handleSubmit, isValid, isSubmitting }) => (
                <Form className="block" onSubmit={handleSubmit}>
                  <div className="px-6 pt-8 flex-grow w-full h-full max-h-full pb-40 md:pb-32 lg:pb-32 xl:pb-32">
                    <div className="flex grid-cols-6 gap-3 md:gap-5 xl:gap-6 lg:gap-6 mb-6 formLabel">
                      <label className="block text-sm text-gray-700 dark:text-gray-400 col-span-4 sm:col-span-2 font-medium text-sm">
                        First Name <span className="required-field">*</span>
                      </label>
                      <div className="col-span-8 sm:col-span-4 formInput">
                        <input
                          className={
                            errors.first_name && touched.first_name
                              ? 'block w-full px-3 py-1 text-sm focus:outline-none dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white input-error'
                              : 'block w-full px-3 py-1 text-sm focus:outline-none dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white'
                          }
                          type="text"
                          name="first_name"
                          placeholder="First Name"
                          value={values.first_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {touched.first_name && errors.first_name && (
                          <div className="error-message">{errors.first_name}</div>
                        )}
                      </div>
                    </div>
                    <div className="flex grid-cols-6 gap-3 md:gap-5 xl:gap-6 lg:gap-6 mb-6 formLabel">
                      <label className="block text-sm text-gray-700 dark:text-gray-400 col-span-4 sm:col-span-2 font-medium text-sm">
                        Last Name <span className="required-field">*</span>
                      </label>
                      <div className="col-span-8 sm:col-span-4 formInput">
                        <input
                          className={
                            errors.last_name && touched.last_name
                              ? 'block w-full px-3 py-1 text-sm focus:outline-none dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white input-error'
                              : 'block w-full px-3 py-1 text-sm focus:outline-none dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white'
                          }
                          type="text"
                          name="last_name"
                          placeholder="Last Name"
                          value={values.last_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {touched.last_name && errors.last_name && (
                          <div className="error-message">{errors.last_name}</div>
                        )}
                      </div>
                    </div>
                    <div className="flex grid-cols-6 gap-3 md:gap-5 xl:gap-6 lg:gap-6 mb-6 formLabel">
                      <label className="block text-sm text-gray-700 dark:text-gray-400 col-span-4 sm:col-span-2 font-medium text-sm">
                        User Name<span className="required-field">*</span>
                      </label>
                      <div className="col-span-8 sm:col-span-4 formInput">
                        <input
                          className={
                            errors.username && touched.username
                              ? 'block w-full px-3 py-1 text-sm focus:outline-none dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white input-error'
                              : 'block w-full px-3 py-1 text-sm focus:outline-none dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white'
                          }
                          type="text"
                          name="username"
                          placeholder="User Name"
                          value={values.username}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={
                            addEdit && addEdit === 'edit'
                              ? { cursor: 'not-allowed', color: 'lightgray' }
                              : { cursor: 'text' }
                          }
                          disabled={!!(addEdit && addEdit === 'edit')}
                        />
                        {touched.username && errors.username && <div className="error-message">{errors.username}</div>}
                      </div>
                    </div>
                    <div className="flex grid-cols-6 gap-3 md:gap-5 xl:gap-6 lg:gap-6 mb-6 formLabel">
                      <label className="block text-sm text-gray-700 dark:text-gray-400 col-span-4 sm:col-span-2 font-medium text-sm">
                        Email <span className="required-field">*</span>
                      </label>
                      <div className="col-span-8 sm:col-span-4 formInput">
                        <input
                          className={
                            errors.email && touched.email
                              ? 'block w-full px-3 py-1 text-sm focus:outline-none dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white input-error'
                              : 'block w-full px-3 py-1 text-sm focus:outline-none dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white'
                          }
                          type="text"
                          name="email"
                          placeholder="Email"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={
                            addEdit && addEdit === 'edit'
                              ? { cursor: 'not-allowed', color: 'lightgray' }
                              : { cursor: 'text' }
                          }
                          disabled={!!(addEdit && addEdit === 'edit')}
                        />
                        {touched.email && errors.email && <div className="error-message">{errors.email}</div>}
                      </div>
                    </div>
                    {addEdit && addEdit !== 'edit' ? (
                      <>
                        <div className="flex grid-cols-6 gap-3 md:gap-5 xl:gap-6 lg:gap-6 mb-6 formLabel">
                          <label className="block text-sm text-gray-700 dark:text-gray-400 col-span-4 sm:col-span-2 font-medium text-sm">
                            Password <span className="required-field">*</span>
                          </label>
                          <div className="col-span-8 sm:col-span-4 formInput">
                            <input
                              className={
                                errors.password && touched.password
                                  ? 'block w-full px-3 py-1 text-sm focus:outline-none dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white input-error'
                                  : 'block w-full px-3 py-1 text-sm focus:outline-none dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white'
                              }
                              type="text"
                              name="password"
                              placeholder="Password"
                              value={values.password}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {touched.password && errors.password && (
                              <div className="error-message">{errors.password}</div>
                            )}
                          </div>
                        </div>
                        <div className="flex grid-cols-6 gap-3 md:gap-5 xl:gap-6 lg:gap-6 mb-6 formLabel">
                          <label className="block text-sm text-gray-700 dark:text-gray-400 col-span-4 sm:col-span-2 font-medium text-sm">
                            Confirm Password <span className="required-field">*</span>
                          </label>
                          <div className="col-span-8 sm:col-span-4 formInput">
                            <input
                              className={
                                errors.confirm_password && touched.confirm_password
                                  ? 'block w-full px-3 py-1 text-sm focus:outline-none dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white input-error'
                                  : 'block w-full px-3 py-1 text-sm focus:outline-none dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white'
                              }
                              type="text"
                              name="confirm_password"
                              placeholder="Confirm Password"
                              value={values.confirm_password}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {touched.confirm_password && errors.confirm_password && (
                              <div className="error-message">{errors.confirm_password}</div>
                            )}
                          </div>
                        </div>
                      </>
                    ) : (
                      ''
                    )}
                    <div className="flex grid-cols-6 gap-3 md:gap-5 xl:gap-6 lg:gap-6 mb-6 formLabel">
                      <label className="block text-sm text-gray-700 dark:text-gray-400 col-span-4 sm:col-span-2 font-medium text-sm">
                        Phone Number <span className="required-field">*</span>
                      </label>
                      <div className="col-span-8 sm:col-span-4 formInput">
                        <input
                          className={
                            errors.phone_number && touched.phone_number
                              ? 'block w-full px-3 py-1 text-sm focus:outline-none dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white input-error'
                              : 'block w-full px-3 py-1 text-sm focus:outline-none dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white'
                          }
                          type="text"
                          onWheel={(e) => e.target.blur()}
                          name="phone_number"
                          placeholder="Phone Number"
                          value={values.phone_number}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {touched.phone_number && errors.phone_number && (
                          <div className="error-message">{errors.phone_number}</div>
                        )}
                      </div>
                    </div>
                    <div className="flex grid-cols-6 gap-3 md:gap-5 xl:gap-6 lg:gap-6 mb-6 formLabel">
                      <label className="block text-sm text-gray-700 dark:text-gray-400 col-span-4 sm:col-span-2 font-medium text-sm">
                        Address <span className="required-field">*</span>
                      </label>
                      <div className="col-span-8 sm:col-span-4 formInput">
                        <input
                          className={
                            errors.address && touched.address
                              ? 'block w-full px-3 py-1 text-sm focus:outline-none dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white input-error'
                              : 'block w-full px-3 py-1 text-sm focus:outline-none dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white'
                          }
                          type="text"
                          name="address"
                          placeholder="Address"
                          value={values.address}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {touched.address && errors.address && <div className="error-message">{errors.address}</div>}
                      </div>
                    </div>
                    <div className="flex grid-cols-6 gap-3 md:gap-5 xl:gap-6 lg:gap-6 mb-6 formLabel">
                      <label className="block text-sm text-gray-700 dark:text-gray-400 col-span-4 sm:col-span-2 font-medium text-sm">
                        City <span className="required-field">*</span>
                      </label>
                      <div className="col-span-8 sm:col-span-4 formInput">
                        <input
                          className={
                            errors.city && touched.city
                              ? 'block w-full px-3 py-1 text-sm focus:outline-none dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white input-error'
                              : 'block w-full px-3 py-1 text-sm focus:outline-none dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white'
                          }
                          type="text"
                          name="city"
                          placeholder="City"
                          value={values.city}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {touched.city && errors.city && <div className="error-message">{errors.city}</div>}
                      </div>
                    </div>
                    <div className="flex grid-cols-6 gap-3 md:gap-5 xl:gap-6 lg:gap-6 mb-6 formLabel">
                      <label className="block text-sm text-gray-700 dark:text-gray-400 col-span-4 sm:col-span-2 font-medium text-sm">
                        State <span className="required-field">*</span>
                      </label>
                      <div className="col-span-8 sm:col-span-4 formInput">
                        <input
                          className={
                            errors.state && touched.state
                              ? 'block w-full px-3 py-1 text-sm focus:outline-none dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white input-error'
                              : 'block w-full px-3 py-1 text-sm focus:outline-none dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white'
                          }
                          type="text"
                          name="state"
                          placeholder="State"
                          value={values.state}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {touched.state && errors.state && <div className="error-message">{errors.state}</div>}
                      </div>
                    </div>
                    <div className="flex grid-cols-6 gap-3 md:gap-5 xl:gap-6 lg:gap-6 mb-6 formLabel">
                      <label className="block text-sm text-gray-700 dark:text-gray-400 col-span-4 sm:col-span-2 font-medium text-sm">
                        Country <span className="required-field">*</span>
                      </label>
                      <div className="col-span-8 sm:col-span-4 formInput">
                        <input
                          className={
                            errors.country && touched.country
                              ? 'block w-full px-3 py-1 text-sm focus:outline-none dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white input-error'
                              : 'block w-full px-3 py-1 text-sm focus:outline-none dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white'
                          }
                          type="text"
                          name="country"
                          placeholder="Country"
                          value={values.country}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {touched.country && errors.country && <div className="error-message">{errors.country}</div>}
                      </div>
                    </div>
                    <div className="flex grid-cols-3 gap-3 md:gap-5 xl:gap-6 lg:gap-6 mb-6 formLabel">
                      <label className="block text-sm text-gray-700 dark:text-gray-400 col-span-4 sm:col-span-2 font-medium text-sm">
                        Zip Code <span className="required-field">*</span>
                      </label>
                      <div className="col-span-8 sm:col-span-4 formInput">
                        <input
                          className={
                            touched.zip_code && errors.zip_code
                              ? 'block w-full px-3 py-1 text-sm focus:outline-none dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white input-error'
                              : 'block w-full px-3 py-1 text-sm focus:outline-none dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white'
                          }
                          type="text"
                          onWheel={(e) => e.target.blur()}
                          name="zip_code"
                          placeholder="Zip Code"
                          value={values.zip_code}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {touched.zip_code && errors.zip_code && <div className="error-message">{errors.zip_code}</div>}
                      </div>
                    </div>
                    <div className="px-6 pt-8 fixed bottom-0  right-0 py-4 lg:py-8 px-6 grid gap-4 lg:gap-6 xl:gap-6 md:flex xl:flex bg-gray-50 border-gray-100 border-gray-700 bg-white-800 text-black-300 cancel-btn ">
                      <div className="flex-grow-0 md:flex-grow lg:flex-grow xl:flex-grow btn-style">
                        <button
                          className="align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-gray-600 border-white-200 border text-gray-400 focus:outline-none rounded-lg border border-gray-200 px-4  mr-3 flex items-center justify-center cursor-pointer w-full h-12 bg-gray-200 h-12 bg-white  text-red-500 hover:bg-red-50 hover:border-red-100 hover:text-red-600 bg-gray-700 border-white-700 text-gray-500 hover:bg-gray-800 hover:text-red-700"
                          type="button"
                          onClick={() => setOpenDrawer(false)}
                        >
                          Cancel
                        </button>
                      </div>
                      <div className="flex-grow-0 md:flex-grow lg:flex-grow xl:flex-grow btn-style">
                        <button
                          className="align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-white w-full border border-transparent common-background focus:ring focus:ring-green-300  h-12"
                          type="submit"
                          onClick={addEdit && addEdit === 'edit' ? () => handleUpdate(values) : ''}
                        >
                          {addEdit && addEdit !== 'edit' ? <span>Add User</span> : <span>Edit User</span>}
                        </button>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          <div
            style={{
              position: 'absolute',
              height: '6px',
              right: '2px',
              bottom: '2px',
              left: '2px',
              borderRadius: '3px',
            }}
          >
            <div
              style={{
                position: 'relative',
                display: 'block',
                height: '100%',
                cursor: 'pointer',
                borderRadius: 'inherit',
                backgroundColor: 'rgba(0, 0, 0, 0.2)',
                width: '0px',
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddUser;

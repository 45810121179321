import axiosInstance from '../axios';

export const FETCH_PRODUCTLIST_REQUEST = 'FETCH_PRODUCTLIST_REQUEST';
export const FETCH_PRODUCTLIST_SUCCESS = 'FETCH_PRODUCTLIST_SUCCESS';
export const FETCH_PRODUCTLIST_FAILURE = 'FETCH_PRODUCTLIST_FAILURE';

export const FETCH_PRODUCTLIST_ID_REQUEST = 'FETCH_PRODUCTLIST_ID_REQUEST';
export const FETCH_PRODUCTLIST_ID_SUCCESS = 'FETCH_PRODUCTLIST_ID_SUCCESS';
export const FETCH_PRODUCTLIST_ID_FAILURE = 'FETCH_PRODUCTLIST_ID_FAILURE';

export const fetchProductDataRequest = () => ({
  type: FETCH_PRODUCTLIST_REQUEST,
});

// Action creator for the success state
export const fetchProductDataSuccess = (data) => ({
  type: FETCH_PRODUCTLIST_SUCCESS,
  payload: data,
});

// Action creator for the failure state
export const fetchProductDataFailure = (error) => ({
  type: FETCH_PRODUCTLIST_FAILURE,
  payload: error,
});

export const fetchProductByIdRequest = () => ({
  type: FETCH_PRODUCTLIST_ID_REQUEST,
});

// Action creator for the success state
export const fetchProductByIdSuccess = (data) => ({
  type: FETCH_PRODUCTLIST_ID_SUCCESS,
  payload: data,
});

// Action creator for the failure state
export const fetchProductByIdFailure = (error) => ({
  type: FETCH_PRODUCTLIST_ID_FAILURE,
  payload: error,
});

// Action creator for initiating the API call
export const fetchProductListData = (filters) => (dispatch) => {
  dispatch(fetchProductDataRequest());
  axiosInstance(`/product/fetch-products`, 'Post', filters)
    .then((response) => {
      // Dispatch success action with the received data
      dispatch(fetchProductDataSuccess(response.data.data));
    })
    .catch((error) => {
      // Dispatch failure action with the error message
      dispatch(fetchProductDataFailure(error.message));
    });
};

export const fetchProductById = (slug) => (dispatch) => {
  dispatch(fetchProductByIdRequest());
  axiosInstance(`/product/product-detail/${slug}`, 'GET', null)
    .then((response) => {
      // Dispatch success action with the received data
      dispatch(fetchProductByIdSuccess(response.data.data));
    })
    .catch((error) => {
      // Dispatch failure action with the error message
      dispatch(fetchProductByIdFailure(error.message));
    });
};

import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import axiosInstance from '../../axios/index';

const PrivateRoute = ({ auth: { isAuthenticated }, children }) => {
  const [isVerify, setIsVerify] = useState(false);
  const [loadingComplete, setLoadingComplete] = useState(false);
  useEffect(() => {
    const verifyToken = async () => {
      const token = localStorage.getItem('token');
      const response = await axiosInstance(`/auth/verify-token?token=${token}`, 'POST', {});
      if (response && response.status === 200) {
        setIsVerify(true);
      }
      setLoadingComplete(true);
    };
    verifyToken();
  }, []);

  if (loadingComplete) {
    return isVerify ? children : <Navigate to="/admin/login" />;
  }
  return loadingComplete && !isVerify ? <Navigate to="/admin/login" /> : <div>...loading</div>;
};

export default PrivateRoute;

// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'dashboard',
    path: '/admin/dashboard',
    icon: icon('ic_analytics'),
  },
  {
    title: 'user',
    path: '/admin/user',
    icon: icon('ic_user'),
  },
  {
    title: 'product',
    path: '/admin/products',
    icon: icon('ic_cart'),
  },
  {
    title: 'category',
    path: '/admin/category',
    icon: icon('ic_category'),
  },
  {
    title: 'orders',
    path: '/admin/orders',
    icon: icon('ic_order'),
  },
  {
    title: 'coupons',
    path: '/admin/coupons',
    icon: icon('ic_coupon'),
  },
  {
    title: 'banner',
    path: '/admin/banner',
    icon: icon('ic_banner'),
  },
  {
    title: 'setting',
    path: '/admin/setting',
    icon: icon('ic_setting'),
  },
  {
    title: 'Card Section',
    path: '/admin/section',
    icon: icon('ic_setting'),
  },
  {
    title: 'home product selection',
    path: '/admin/home-product',
    icon: icon('ic_setting'),
  },
  // {
  //   title: 'blog',
  //   path: '/dashboard/blog',
  //   icon: icon('ic_blog'),
  // },
  // {
  //   title: 'login',
  //   path: '/login',
  //   icon: icon('ic_lock'),
  // },
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: icon('ic_disabled'),
  // },
];

export default navConfig;

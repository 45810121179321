import React, { useEffect, useState } from "react";
import "./register.css";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Bounce, ToastContainer, toast } from "react-toastify";
import { Box } from "@chakra-ui/react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../../axios";
import mainLogo from "../../../assets/logo/logo.png";
import { CommonImage } from "../common Login Image/CommonImage";
import Header from "../../../components/header";
import Footer from "../../../components/footer/Footer";
import { fetchHomeData } from "../../../actions/HomePageActions";
import store from "../../../store";
import Loader from "../../../components/loader/Loader";

function RegisterPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { homePageData } = useSelector((state) => state.home);
  useEffect(() => {
    if (!homePageData.length) {
      dispatch(fetchHomeData());
    }
  }, []);
  const [register, setRegister] = useState({
    first_name: "",
    last_name: "",
    email: "",
    username: "",
    password: "",
    confirm_password: "",
    phone_number: "",
    address: null,
    city: null,
    state: null,
    country: null,
    zip_code: null,
    role: "user",
  });
  const [loader, setLoader] = useState(false);
  const [visiblity, setVisiblity] = useState(false);
  const [confirmVisiblity, setConfirmVisiblity] = useState(false);
  // validation schema
  const validationSchema = Yup.object({
    first_name: Yup.string().required("First Name is required").trim(),
    last_name: Yup.string().required("Last Name is required").trim(),
    email: Yup.string()
      .required("Email is required")
      .email("Please Add Valid Email")
      .trim(),
    username: Yup.string().required("User Name is required").trim(),
    password: Yup.string()
      .required("Password is required")
      .min(6, "Password must be at least 6 characters.")
      .trim(),
    confirm_password: Yup.string()
      .required("Confirm Password is required")
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .trim(),
    phone_number: Yup.string()
      .required("Phone Number is required")
      .matches(/[0-9]/g, "Please Enter Valid Number")
      .min(10, "Phone Number must be at least 10 numbers")
      .max(10, "Phone Number must be at least 10 numbers")
      .trim(),
  });

  const handleUserSubmit = async (values, resetForm) => {
    setLoader(true);
    const response = await axiosInstance(`/auth/register`, "POST", values);
    if (response.data.status === 200) {
      setRegister({
        first_name: "",
        last_name: "",
        email: "",
        username: "",
        password: "",
        confirm_password: "",
        phone_number: "",
        address: null,
        city: null,
        state: null,
        country: null,
        zip_code: null,
        role: "user",
      });
      setLoader(false);
      resetForm();
      toast.success(
        "Thank you for registering! Please check your email for verification.",
        {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          // style:{ background: '#ed691f', color: '#ffffff' },
          transition: Bounce,
        }
      );
      setTimeout(() => {
        navigate("/login");
      }, 2000);
    } else {
      setLoader(false);
      toast.error(response.data.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        // style:{ background: '#ed691f', color: '#ffffff', fill: "white" },
        transition: Bounce,
      });
    }
  };
  return (
    <div>
      <Header headerData={homePageData} />
      <Box height={loader ? "100%" : ""}>
        <Loader loading={loader} />
        <div
          className="flex items-center min-h-screen p-6 bg-gray-50 dark:bg-gray-900 register-screen"
          style={{ height: "122vh", opacity: loader ? 0.3 : "" }}
        >
          <div className="flex-1 h-full max-w-5xl mx-auto  bg-white rounded-lg shadow-xl dark:bg-gray-800 reg-form">
            <div className="flex flex-col md:flex-row h-full">
              <CommonImage />
              <main className="flex justify-center p-6  md:w-1/2 form-width">
                <div className="w-full pt-5 pl-1">
                  <div className="flex items-center justify-center">
                    <img
                      src={
                        homePageData &&
                          homePageData?.setting &&
                          homePageData?.setting.logo
                          ? `${process.env.REACT_APP_IMAGE_URL}${homePageData?.setting.logo}`
                          : mainLogo
                      }
                      alt="mainLogo"
                      style={{ marginTop: -15, marginBottom: 21, height: 75 }}
                      className="reg-logo"
                    />
                  </div>
                  <h1 className="mb-6 text-2xl font-semibold text-gray-700 dark:text-gray-600 common-userPaneFamily  flex justify-center">
                    Create an Account
                  </h1>
                  <Formik
                    enableReinitialize
                    initialValues={register}
                    validationSchema={validationSchema}
                    validateOnBlur
                    onSubmit={(values, { resetForm }) => {
                      handleUserSubmit(values, resetForm);
                      // setSubmitting(true);
                      // resetForm();
                      // setSubmitting(false);
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                      isValid,
                      isSubmitting,
                    }) => (
                      <Form
                        className="pr-8 pt-6 pl-8 pb-6 mb-4 bg-white rounded reg-form2"
                        onSubmit={handleSubmit}
                      >
                        <div className="mb-4 md:flex md:mb-0 md:justify-between w-full">
                          <div className="mb-4 md:mr-2 md:mb-0 md:w-1/2">
                            <label
                              className="block mb-2 text-sm font-bold text-gray-700 common-userPaneFamily "
                              htmlFor="firstName"
                            >
                              First Name{" "}
                              <span className="required-field">*</span>
                            </label>
                            <input
                              className={
                                touched.first_name && errors.first_name
                                  ? "w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline px-3 py-1 text-sm focus:outline-none dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none  bg-gray-100 dark:bg-white border-transparent focus:bg-white input-error common-userPaneFamily "
                                  : "w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline px-3 py-1 text-sm focus:outline-none dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none  bg-gray-100 dark:bg-white border-transparent focus:bg-white- common-userPaneFamily "
                              }
                              type="text"
                              name="first_name"
                              placeholder="First Name"
                              value={values.first_name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {touched.first_name && errors.first_name && (
                              <div className="error-message">
                                {errors.first_name}
                              </div>
                            )}
                          </div>
                          <div className="mb-4 md:ml-2 md:w-1/2">
                            <label
                              className="block mb-2 text-sm font-bold text-gray-700 common-userPaneFamily "
                              htmlFor="lastName"
                            >
                              Last Name{" "}
                              <span className="required-field">*</span>
                            </label>
                            <input
                              className={
                                touched.last_name && errors.last_name
                                  ? "w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline px-3 py-1 text-sm focus:outline-none dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none  bg-gray-100 dark:bg-white border-transparent focus:bg-white input-error common-userPaneFamily "
                                  : "w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline px-3 py-1 text-sm focus:outline-none dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none  bg-gray-100 dark:bg-white border-transparent focus:bg-white common-userPaneFamily "
                              }
                              type="text"
                              name="last_name"
                              placeholder="Last Name"
                              value={values.last_name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {touched.last_name && errors.last_name && (
                              <div className="error-message">
                                {errors.last_name}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="mb-4 md:flex md:mb-0 md:justify-between w-full">
                          <div className="mb-4 md:mr-2 md:mb-0 md:w-1/2">
                            <label
                              className="block mb-2 text-sm font-bold text-gray-700 common-userPaneFamily "
                              htmlFor="password"
                            >
                              User Name{" "}
                              <span className="required-field">*</span>
                            </label>
                            <input
                              className={
                                touched.username && errors.username
                                  ? "w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline px-3 py-1 text-sm focus:outline-none dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none  bg-gray-100 dark:bg-white border-transparent focus:bg-white input-error common-userPaneFamily"
                                  : "w-full px-3 py-2  text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline px-3 py-1 text-sm focus:outline-none dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none  bg-gray-100 dark:bg-white border-transparent focus:bg-white common-userPaneFamily"
                              }
                              type="text"
                              name="username"
                              placeholder="User Name"
                              value={values.username}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {touched.username && errors.username && (
                              <div className="error-message">
                                {errors.username}
                              </div>
                            )}
                          </div>
                          <div className="mb-4 md:ml-2 md:w-1/2">
                            <label
                              className="block mb-2 text-sm font-bold text-gray-700 common-userPaneFamily "
                              htmlFor="c_password"
                            >
                              Phone Number{" "}
                              <span className="required-field">*</span>
                            </label>
                            <input
                              className={
                                touched.phone_number && errors.phone_number
                                  ? "w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline px-3 py-1 text-sm focus:outline-none dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none  bg-gray-100 dark:bg-white border-transparent focus:bg-white input-error common-userPaneFamily"
                                  : "w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline px-3 py-1 text-sm focus:outline-none dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none  bg-gray-100 dark:bg-white border-transparent focus:bg-white common-userPaneFamily"
                              }
                              type="number"
                              onWheel={(e) => e.target.blur()}
                              name="phone_number"
                              placeholder="Phone Number"
                              value={values.phone_number}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {touched.phone_number && errors.phone_number && (
                              <div className="error-message">
                                {errors.phone_number}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="mb-4">
                          <label
                            className="block mb-2 text-sm font-bold text-gray-700 common-userPaneFamily "
                            htmlFor="email"
                          >
                            Email <span className="required-field">*</span>
                          </label>
                          <input
                            className={
                              touched.email && errors.email
                                ? "w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline px-3 py-1 text-sm focus:outline-none dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none  bg-gray-100 dark:bg-white border-transparent focus:bg-white input-error common-userPaneFamily"
                                : "w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline px-3 py-1 text-sm focus:outline-none dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none  bg-gray-100 dark:bg-white border-transparent focus:bg-white common-userPaneFamily"
                            }
                            type="text"
                            name="email"
                            placeholder="Email"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {touched.email && errors.email && (
                            <div className="error-message">{errors.email}</div>
                          )}
                        </div>
                        <div className="mb-4 md:flex md:mb-0 md:justify-between w-full">
                          <div className="mb-4 md:mr-2 md:mb-0 md:w-1/2">
                            <label
                              className="block mb-2 text-sm font-bold text-gray-700 common-userPaneFamily "
                              htmlFor="password"
                            >
                              Password <span className="required-field">*</span>
                            </label>
                            <div className="inputWrapper">
                              <input
                                className={
                                  touched.password && errors.password
                                    ? "w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline px-3 py-1 text-sm focus:outline-none dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none  bg-gray-100 dark:bg-white border-transparent focus:bg-white input-error common-userPaneFamily"
                                    : "w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline px-3 py-1 text-sm focus:outline-none dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none  bg-gray-100 dark:bg-white border-transparent focus:bg-white common-userPaneFamily"
                                }
                                type={visiblity ? "text" : "password"}
                                name="password"
                                placeholder="Password"
                                value={values.password}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <div
                                className="showHidePassword"
                                style={{ left: "86%" }}
                                onClick={() => setVisiblity(!visiblity)}
                              >
                                {!visiblity ? (
                                  <VisibilityOffIcon />
                                ) : (
                                  <VisibilityIcon />
                                )}
                              </div>
                            </div>
                            {touched.password && errors.password && (
                              <div className="error-message">
                                {errors.password}
                              </div>
                            )}
                          </div>
                          <div className="mb-4 md:ml-2 md:w-1/2">
                            <label
                              className="block mb-2 text-sm font-bold text-gray-700 common-userPaneFamily "
                              htmlFor="c_password"
                            >
                              Confirm Password{" "}
                              <span className="required-field">*</span>
                            </label>
                            <div className="inputWrapper">
                              <input
                                className={
                                  touched.confirm_password &&
                                    errors.confirm_password
                                    ? "w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline px-3 py-1 text-sm focus:outline-none dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none  bg-gray-100 dark:bg-white border-transparent focus:bg-white input-error common-userPaneFamily"
                                    : "w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline px-3 py-1 text-sm focus:outline-none dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none  bg-gray-100 dark:bg-white border-transparent focus:bg-white common-userPaneFamily"
                                }
                                type={confirmVisiblity ? "text" : "password"}
                                name="confirm_password"
                                placeholder="Confirm Password"
                                value={values.confirm_password}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <div
                                className="showHidePassword"
                                style={{ left: "86%" }}
                                onClick={() =>
                                  setConfirmVisiblity(!confirmVisiblity)
                                }
                              >
                                {!confirmVisiblity ? (
                                  <VisibilityOffIcon />
                                ) : (
                                  <VisibilityIcon />
                                )}
                              </div>
                            </div>
                            {touched.confirm_password &&
                              errors.confirm_password && (
                                <div className="error-message">
                                  {errors.confirm_password}
                                </div>
                              )}
                          </div>
                        </div>
                        {/* <div className="mb-4 md:mb-0">
                    <label className="block mb-2 text-sm font-bold text-gray-700 common-userPaneFamily ">Address</label>
                    <input
                      className="w-full px-3 py-2 mb-3 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline px-3 py-1 text-sm focus:outline-none dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none  bg-gray-100 dark:bg-white border-transparent focus:bg-white"
                      type="text"
                      name="address"
                      placeholder="Address"
                    />
                  </div> */}
                        {/* <div className="mb-4 md:flex md:mb-0 md:justify-between w-full">
                    <div className="mb-4 md:mr-2 md:mb-0 md:w-1/2">
                      <label className="block mb-2 text-sm font-bold text-gray-700 common-userPaneFamily ">City</label>
                      <input
                        className="w-full px-3 py-2 mb-3 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline px-3 py-1 text-sm focus:outline-none dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none  bg-gray-100 dark:bg-white border-transparent focus:bg-white"
                        type="text"
                        name="city"
                        placeholder="City"
                      />
                    </div>
                    <div className="md:ml-2 md:w-1/2">
                      <label className="block mb-2 text-sm font-bold text-gray-700 common-userPaneFamily ">State</label>
                      <input
                        className="w-full px-3 py-2 mb-3 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline px-3 py-1 text-sm focus:outline-none dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none  bg-gray-100 dark:bg-white border-transparent focus:bg-white"
                        type="text"
                        name="state"
                        placeholder="State"
                      />
                    </div>
                  </div>
                  <div className="mb-4 md:flex md:mb-0 md:justify-between w-full">
                    <div className="mb-4 md:mr-2 md:mb-0 md:w-1/2">
                      <label className="block mb-2 text-sm font-bold text-gray-700 common-userPaneFamily ">Country</label>
                      <input
                        className="w-full px-3 py-2 mb-3 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline px-3 py-1 text-sm focus:outline-none dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none  bg-gray-100 dark:bg-white border-transparent focus:bg-white"
                        type="text"
                        name="country"
                        placeholder="Country"
                      />
                    </div>
                    <div className="md:ml-2 md:w-1/2">
                      <label className="block mb-2 text-sm font-bold text-gray-700 common-userPaneFamily ">Zip Code</label>
                      <input
                        className="w-full px-3 py-2 mb-3 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline px-3 py-1 text-sm focus:outline-none dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none  bg-gray-100 dark:bg-white border-transparent focus:bg-white"
                        type="number"
                        name="zip_code"
                        placeholder="Zip Code"
                      />
                    </div>
                  </div> */}
                        <div className="mb-6 text-center">
                          <button
                            className="w-full px-4 py-2 font-bold text-white bg-blue-500 hover:bg-blue-700 focus:outline-none focus:shadow-outline w-3/4 px-4 py-2 font-bold align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-bold focus:outline-none px-4 py-2 rounded-lg text-sm text-white bg-orange-500 border border-transparent active:bg-orange-600 hover:bg-orange-600 focus:ring focus:ring-orange-300 mt-4 h-12 w-3/4 common-userPaneFamily  login-button shadow-md shadow-orange-500/20 hover:shadow-lg hover:shadow-orange-500/40 active:opacity-[0.85] common-borderRadius"
                            type="submit"
                            disabled={!!loader}
                          >
                            Register Account
                          </button>
                        </div>
                        <hr className="mb-2.5 border-t" />
                      </Form>
                    )}
                  </Formik>
                  <div className="flex justify-end mr-2.5">
                    <p className="mt-1">
                      <a
                        className="text-base font-medium text-color hover:cursor-pointer hover:underline common-userPaneFamily"
                        onClick={() => navigate("/login")}
                      >
                        Already have an account? Login!
                      </a>
                    </p>
                  </div>
                </div>
              </main>
            </div>
          </div>
        </div>
      </Box>
      <Footer />
    </div>
  );
}

export default RegisterPage;

import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import './products.css';
import '../dashboard/dashboard.css';
import {
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  Card,
  Container,
  TablePagination,
  Popover,
  MenuItem,
  Drawer,
  FormControlLabel,
  Switch,
  styled,
  Pagination,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Box,
} from '@mui/material';
import { filter } from 'lodash';
import Scrollbar from 'src/components/scrollbar/Scrollbar';
import Iconify from 'src/components/iconify/Iconify';
import Product from 'src/components/addProducts/AddProduct';
import { useNavigate } from 'react-router-dom';
import { IOSSwitch } from 'src/utils/toggleButton/toggleButton';
import Notification from 'src/components/notification';
// mock
import TableHeader from '../../sections/@dashboard/user/tableHeader';
import axiosInstance from '../../axios';
import noImages from '../../assets/noImageAvailable.jpg';

// ----------------------------------------------------------------------

export default function ProductsPage() {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [open, setOpen] = useState(null);
  const [productListing, setProductListing] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [tableId, setTableId] = useState(null);
  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [orderBy, setOrderBy] = useState('name');
  const [filters, setFilters] = useState({
    search: '',
    category: '',
  });
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [pagination, setPagination] = useState({});
  const [addEdit, setAddEdit] = useState('add');
  // get product list

  const productListAPI = async () => {
  
    let query = `/product/?category=${filters.category}&page=${page + 1}&limit=${rowsPerPage}`
    if(filters.search && filters.search !== ""){
      query += `&search=${filters.search}`;
    }
    const response = await axiosInstance(
      query,
      'GET',
      null
    );
    if (response.status === 200) {
      if (response.data.data.data.length > 0) {
        setProductListing(
          response.data.data.data.map((data, index) => ({
            id: data.product_id,
            image: data.ProductImages ? data.ProductImages[0]?.image_url : '',
            name: data.name,
            category: data.Category.category_name,
            amount: data.price,
            stock: data.quantity,
            is_active: data.is_active,
            discount: data.discount,
          }))
        );
        setCount(response.data.data.pagination.totalItems);
        setPagination(response.data.data.pagination);
      }
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      let query = `/product/?category=${filters.category}&page=${page + 1}&limit=${rowsPerPage}`
      if(filters.search && filters.search !== ""){
        query += `&search=${filters.search}`;
      }
      const response = await axiosInstance(
        query,
        'GET',
        null
      );

      if (response.status === 200) {
        if (response.data.data.data.length > 0) {
          setProductListing(
            response.data.data.data.map((data, index) => ({
              id: data.product_id,
              image: data.ProductImages ? data.ProductImages[0]?.image_url : '',
              name: data.name,
              category: data.Category.category_name,
              amount: data.price,
              stock: data.quantity,
              is_active: data.is_active,
              discount: data.discount,
            }))
          );
          setCount(response.data.data.pagination.totalItems);
          setPagination(response.data.data.pagination);
        } else {
          setProductListing([]);
          setCount(response.data.data.pagination.totalItems);
          setPagination(response.data.data.pagination);
        }
        Notification('success', 'Category List', response.data.message, 2000);
      } else {
        Notification('error', 'Category List', response.data.message, 2000);
      }
      const category = await axiosInstance(`/category/`, 'GET', null);
      if (category.status === 200) {
        setCategoryList(category.data.data);
      } else {
        Notification('error', 'Category List', category.data.message, 2000);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      let query = `/product/?category=${filters.category}&page=${page + 1}&limit=${rowsPerPage}`
      if(filters.search && filters.search !== ""){
        query += `&search=${filters.search}`;
      }
      const response = await axiosInstance(
        query,
        'GET',
        null
      );

      if (response.status === 200) {
        if (response.data.data.data.length > 0) {
          setProductListing(
            response.data.data.data.map((data, index) => ({
              id: data.product_id,
              image: data.ProductImages ? data.ProductImages[0]?.image_url : '',
              name: data.name,
              category: data.Category.category_name,
              amount: data.price,
              stock: data.quantity,
              is_active: data.is_active,
              discount: data.discount,
            }))
          );
          setCount(response.data.data.pagination.totalItems);
          setPagination(response.data.data.pagination);
        } else {
          setProductListing([]);
          setCount(response.data.data.pagination.totalItems);
          setPagination(response.data.data.pagination);
        }
      }
    };

    fetchData();
  }, [filters]);

  const handleChangeRowsPerPage = async (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    const fetchData = async () => {
    
      let query = `/product/?category=${filters.category}&page=${page + 1}&limit=${+event.target.value}`
      if(filters.search && filters.search !== ""){
        query += `&search=${filters.search}`;
      }
      const response = await axiosInstance(
        query,
        'GET',
        null
      );
      if (response.status === 200) {
        if (response.data.data.data.length > 0) {
          setProductListing(
            response.data.data.data.map((data, index) => ({
              id: data.product_id,
              image: data.ProductImages ? data.ProductImages[0]?.image_url : '',
              name: data.name,
              category: data.Category.category_name,
              amount: data.price,
              stock: data.quantity,
              is_active: data.is_active,
              discount: data.discount,
            }))
          );
          setCount(response.data.data.pagination.totalItems);
          setPagination(response.data.data.pagination);
        }
        Notification('success', 'Category List', response.data.message, 2000);
      } else {
        Notification('error', 'Category List', response.data.message, 2000);
      }
      const category = await axiosInstance(`/category/`, 'GET', null);
      if (category.status === 200) {
        setCategoryList(category.data.data);
      } else {
        Notification('error', 'Category List', category.data.message, 2000);
      }
    };

    await fetchData();
  };

  const handleChangePage = async (e, newPage) => {
    setPage(newPage);
    const fetchData = async () => {
    
      let query = `/product/?category=${filters.category}&page=${newPage + 1}&limit=${rowsPerPage}`
      if(filters.search && filters.search !== ""){
        query += `&search=${filters.search}`;
      }
      const response = await axiosInstance(
        query,
        'GET',
        null
      );
      if (response.status === 200) {
        if (response.data.data.data.length > 0) {
          setProductListing(
            response.data.data.data.map((data, index) => ({
              id: data.product_id,
              image: data.ProductImages ? data.ProductImages[0]?.image_url : '',
              name: data.name,
              category: data.Category.category_name,
              amount: data.price,
              stock: data.quantity,
              is_active: data.is_active,
              discount: data.discount,
            }))
          );
          setCount(response.data.data.pagination.totalItems);
          setPagination(response.data.data.pagination);
        }
        Notification('success', 'Category List', response.data.message, 2000);
      } else {
        Notification('error', 'Category List', response.data.message, 2000);
      }
      const category = await axiosInstance(`/category/`, 'GET', null);
      if (category.status === 200) {
        setCategoryList(category.data.data);
      } else {
        Notification('error', 'Category List', category.data.message, 2000);
      }
    };

    await fetchData();
  };

  const handleFilter = (e) => {
    const { name, value } = e.target;
    setFilters({ ...filters, [name]: value });
  };

  const updateValue = async (e, id) => {
    const { checked } = e.target;
    const data = productListing.map((val) => {
      if (val.id === id) {
        return {
          ...val,
          is_active: checked,
        };
      }
      return val;
    });
    setProductListing(data);

    const fetchData = async () => {
      const payload = {
        product_id: id,
        is_active: checked,
      };
      const response = await axiosInstance(`/product/status`, 'POST', payload);

      if (response.status === 200) {
        Notification('success', 'Status', response.data.message, 2000);
      } else {
        Notification('error', 'Status', response.data.message, 2000);
      }
    };

    fetchData();
  };

  // Table Columns

  const handleOpenMenu = (event, id) => {
    setOpen(event.currentTarget);
    setTableId(id);
    // setAddEdit("edit")
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };
  const handleEditProduct = (e) => {
    setOpenDrawer(true);
    setOpen(null);
    setAddEdit('edit');
  };
  const handleOpenDeleteModal = () => {
    setOpenDialog(true);
    setOpen(null);
  };
  const handleCloseDeleteModal = () => {
    setOpenDialog(false);
  };
  const handleDeleteProduct = () => {
    const deleteProductData = async () => {
      const response = await axiosInstance(`/product/${tableId}`, 'DELETE', null);

      if (response.status === 200) {
        Notification('success', 'Delete Product', response.data.message, 2000);
        setOpenDialog(false);
        await productListAPI();
      } else {
        Notification('error', 'Delete Product', response.data.message, 2000);
      }
    };

    deleteProductData();
  };

  const handleAddData = () => {
    setOpenDrawer(true);
    setAddEdit('add');
  };
  const TABLE_HEAD = [
    { id: 'id', label: 'Id', alignRight: false },
    { id: 'image', label: 'Product Image', alignRight: false },
    { id: 'name', label: 'Product Name', alignRight: false },
    { id: 'category', label: 'Category', alignRight: false },
    { id: 'amount', label: 'Price', alignRight: false },
    { id: 'stock', label: 'Stock', alignRight: false },
    // { id: 'status', label: 'Status', alignRight: false },
    { id: 'discount', label: 'Discount', alignRight: false },
    // { id: 'details', label: 'Details', alignRight: false },
    { id: 'is_active', label: 'Status', alignRight: false },
    { id: '' },
  ];

  return (
    <>
      <Helmet>
        <title> Products </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={4}>
          <Typography variant="h4" className="text-lg font-bold text-gray-700 dark:text-gray-300 headers">
            Products
          </Typography>
          <div>
            <button
              className="align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-white border border-transparent common-background focus:ring focus:ring-purple-300  rounded-md common-shadow"
              type="button"
              onClick={() => handleAddData()}
            >
              <span className="mr-3">
                <svg
                  stroke="currentColor"
                  fill="none"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <line x1="12" y1="5" x2="12" y2="19" />
                  <line x1="5" y1="12" x2="19" y2="12" />
                </svg>
              </span>
              Add Product
            </button>
          </div>
        </Stack>
        {/* Add Product */}
        <Card className="card-style mb-0.5">
          <div className="pt-0.2">
            <form className="px-3 py-3 grid gap-4 lg:gap-6 xl:gap-6 md:flex xl:flex">
              <div className="flex-grow-0 md:flex-grow lg:flex-grow xl:flex-grow">
                <input
                  className="block w-full px-3 py-1 text-sm focus:outline-none dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 border-transparent focus:bg-white"
                  type="search"
                  name="search"
                  placeholder="Search by product name"
                  value={filters.search}
                  onChange={(e) => handleFilter(e)}
                />
              </div>
              <div className="flex-grow-0 md:flex-grow lg:flex-grow xl:flex-grow">
                <select
                  className="block w-full px-2 py-1 text-sm dark:text-gray-300 focus:outline-none rounded-md form-select focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:shadow-none focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 leading-5 border h-10 text-sm focus:outline-none block w-full bg-gray-100 border-transparent focus:bg-white"
                  name="category"
                  onChange={(e) => handleFilter(e)}
                >
                  <option value={'all'} hidden="">
                    Choose a Category
                  </option>
                  {categoryList.map((item) => (
                    <option value={item.category_id} key={item.category_id}>
                      {item.category_name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="flex-grow-0 md:flex-grow lg:flex-grow xl:flex-grow">
                <select className="block w-full px-2 py-1 text-sm dark:text-gray-300 focus:outline-none rounded-md form-select focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:shadow-none focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 leading-5 border h-10 text-sm focus:outline-none block w-full bg-gray-100 border-transparent focus:bg-white">
                  <option value="All" hidden="">
                    Price
                  </option>
                  <option value="Low">Low to High</option>
                  <option value="High">High to Low</option>
                </select>
              </div>
            </form>
          </div>
        </Card>
        {/* Prroduct Table */}

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <TableHeader
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={count}
                  numSelected={selected.length}
                  // onRequestSort={handleRequestSort}
                  checkbox={false}
                />
                {productListing.length > 0 ? (
                  <TableBody>
                    {productListing.map((row) => {
                      const { id, image, name, category, amount, stock, is_active, discount, details, published } = row;
                      return (
                        <TableRow hover key={id} role="checkbox">
                          <TableCell align="left">{id}</TableCell>
                          {image ? (
                            <TableCell align="left">
                              <div
                                style={{
                                  height: '100px',
                                  width: '100px',
                                }}
                              >
                                <img src={`${process.env.REACT_APP_IMAGE_URL}${image}`} alt="" />
                              </div>
                            </TableCell>
                          ) : (
                            <TableCell align="left">
                              <div
                                style={{
                                  height: '100px',
                                  width: '100px',
                                }}
                              >
                                <img src={noImages} alt="" />
                              </div>
                            </TableCell>
                          )}
                          <TableCell align="left">{name}</TableCell>

                          <TableCell align="left">{category}</TableCell>
                          <TableCell align="left">{amount}</TableCell>
                          <TableCell align="left">{stock}</TableCell>
                          <TableCell align="left">{discount}</TableCell>
                          <TableCell align="left">
                            <FormControlLabel
                              control={
                                <IOSSwitch sx={{ m: 1 }} checked={is_active} onChange={(e) => updateValue(e, id)} />
                              }
                            />
                          </TableCell>

                          <TableCell align="right">
                            <IconButton size="large" color="inherit" onClick={(e) => handleOpenMenu(e, id)}>
                              <Iconify icon={'eva:more-vertical-fill'} />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                ) : (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Box
                            className="not-fount-img"
                            component="img"
                            src="/assets/illustrations/illustration_404.svg"
                            sx={{ height: 160, mx: 'auto', my: { xs: 5, sm: 10 } }}
                          />
                          <Typography variant="h4" paragraph className="not-fount-1">
                            No records has been added yet.
                          </Typography>
                          <Typography variant="h6" paragraph className="not-fount-2">
                            Add a new record by simpley clicking the button on top right side.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
            {productListing.length > 0 ? (
              <TablePagination
                rowsPerPageOptions={[2, 3, 4, 5, 10, 15, 25]}
                component="div"
                count={pagination.totalItems}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            ) : (
              ''
            )}
          </Scrollbar>
        </Card>
      </Container>
      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem onClick={(e) => handleEditProduct(e)}>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        <MenuItem sx={{ color: 'error.main' }} onClick={handleOpenDeleteModal}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>

      {/* Modal for Delete */}
      <Dialog
        open={openDialog}
        keepMounted
        onClose={handleCloseDeleteModal}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{'Logout'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to delete product?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteModal}>No</Button>
          <Button onClick={() => handleDeleteProduct()}>Yes</Button>
        </DialogActions>
      </Dialog>
      {/* Add Product Form */}
      <Drawer anchor={'right'} sx={{"& .MuiPaper-root": {
      width: '50%'
    }}} open={openDrawer} onClose={() => setOpenDrawer(false)} className="drawerStyle">
        <Product setOpenDrawer={setOpenDrawer} addEdit={addEdit} productListAPI={productListAPI} tableId={tableId} />
      </Drawer>
    </>
  );
}

import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Formik, Form, Field } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import "./resetPassword.css";
import { Bounce, ToastContainer, toast } from "react-toastify";
import { Box } from "@chakra-ui/react";
import Loader from "../../../components/loader/Loader";
import mainLogo from "../../../assets/logo/logo.png";
import { CommonImage } from "../common Login Image/CommonImage";
import axiosInstance from "../../../axios";
import Header from "../../../components/header";
import Footer from "../../../components/footer/Footer";
import store from "../../../store";
import { fetchHomeData } from "../../../actions/HomePageActions";

function ResetPassword() {
  const { token } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { homePageData } = useSelector((state) => state.home);
  useEffect(() => {
    if (!homePageData.length) {
      dispatch(fetchHomeData());
    }
  }, []);
  const [reset, setReset] = useState({
    resetToken: token,
    newPassword: "",
    // confirm_password: '',
  });
  const [loader, setLoader] = useState(false);
  const [visiblity, setVisiblity] = useState(false);
  const validationSchema = Yup.object({
    newPassword: Yup.string()
      .required("Password is required")
      .min(6, "Password must be at least 6 characters.")
      .trim(),
    // confirm_password: Yup.string()
    //   .required('Confirm Password is required')
    //   .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
    //   .trim(),
  });
  const handleSubmit = async (values, resetForm) => {
    setLoader(true);
    const response = await axiosInstance(
      "/auth/reset-password",
      "POST",
      values
    );
    if (response.data.status === 200) {
      setLoader(false);

      toast.success(response.data.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        // style:{ background: '#ed691f', color: '#ffffff' },
        transition: Bounce,
      });
      setTimeout(() => {
        setReset({
          resetToken: "",
          newPassword: "",
          // confirm_password: '',
        });
        resetForm();
        navigate("/login");
      }, 2000);
    } else {
      setLoader(false);
      toast.error(response.data.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        // style:{ background: '#ed691f', color: '#ffffff', fill: "white" },
        transition: Bounce,
      });
    }
  };
  return (
    <div>
      <Header headerData={homePageData} />
      <Box height={loader ? "100%" : ""}>
        <Loader loading={loader} />
        <div
          className="flex items-center p-6 bg-gray-50 dark:bg-gray-900"
          style={{ opacity: loader ? 0.3 : "" }}
        >
          <div className="flex-1 max-w-4xl mx-auto overflow-hidden bg-white rounded-lg shadow-xl dark:bg-gray-800 reset-form">
            <div className="flex flex-col overflow-y-auto md:flex-row h-full">
              <CommonImage />
              <main className="flex justify-center p-6 sm:p-12 md:w-1/2">
                <div className="w-full">
                  <div className="flex items-center justify-center">
                    <img
                      src={
                        homePageData &&
                          homePageData?.setting &&
                          homePageData?.setting.logo
                          ? `${process.env.REACT_APP_IMAGE_URL}${homePageData?.setting.logo}`
                          : mainLogo
                      }
                      alt="mainLogo"
                      className="reset-logo"
                      style={{ marginTop: -15, marginBottom: 21, height: 75 }}
                    />
                  </div>
                  <h1 className="mb-4 text-2xl font-semibold text-gray-700 dark:text-gray-200 common-userPaneFamily ">
                    Reset Password
                  </h1>
                  <Formik
                    enableReinitialize
                    initialValues={reset}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                      handleSubmit(values);
                      // setSubmitting(true);
                      // resetForm();
                      // setSubmitting(false);
                    }}
                  >
                    {({
                      field,
                      values,
                      errors,
                      touched,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                      isValid,
                      isSubmitting,
                    }) => (
                      <Form
                        className="pr-7 pt-4 pl-7 pb-4 mb-4 bg-white rounded"
                        onSubmit={handleSubmit}
                      >
                        <div className="mb-3">
                          <label className="block mb-2 text-sm text-gray-700 dark:text-gray-400 col-span-4 sm:col-span-2 font-bold text-sm common-userPaneFamily  ">
                            New Password
                          </label>
                          <div className="inputWrapper">
                            <input
                              className={
                                touched.newPassword && errors.newPassword
                                  ? "block w-full px-3 py-1 text-sm focus:outline-none dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-12 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white input-error common-userPaneFamily"
                                  : "block w-full px-3 py-1 text-sm focus:outline-none dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-12 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white common-userPaneFamily"
                              }
                              type={visiblity ? "text" : "password"}
                              name="newPassword"
                              placeholder="New Password"
                              value={values.newPassword}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              autocomplete="off"
                            />
                            <div
                              className="showHidePassword"
                              onClick={() => setVisiblity(!visiblity)}
                            >
                              {!visiblity ? (
                                <VisibilityOffIcon />
                              ) : (
                                <VisibilityIcon />
                              )}
                            </div>
                          </div>
                          {touched.newPassword && errors.newPassword && (
                            <div className="error-message">
                              {errors.newPassword}
                            </div>
                          )}
                          {/* <label className="block text-sm text-gray-700 dark:text-gray-400 col-span-4 sm:col-span-2 font-bold text-sm common-userPaneFamily  ">
                          Cofirm Password
                        </label>
                        <input
                          className={
                            touched.confirm_password && errors.confirm_password
                              ? 'mb-4 block w-full px-3 py-1 text-sm focus:outline-none dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-12 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white input-error'
                              : 'mb-4 block w-full px-3 py-1 text-sm focus:outline-none dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-12 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white'
                          }
                          type="password"
                          name="confirm_password"
                          placeholder="Confirm Password"
                          value={values.confirm_password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          autocomplete="off"
                        />
                        {touched.confirm_password && errors.confirm_password && (
                          <div className="error-message">{errors.confirm_password}</div>
                        )} */}
                        </div>
                        <div className="mb-6 text-center">
                          <button
                            className="w-full px-4 py-2 font-bold text-center text-white bg-blue-500 hover:bg-blue-700 focus:outline-none focus:shadow-outline w-3/4 px-4 py-2 font-bold align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-bold focus:outline-none px-4 py-2 rounded-lg text-sm text-white bg-orange-500 border border-transparent active:bg-orange-600 hover:bg-orange-600 focus:ring focus:ring-orange-300 mt-4 h-12 w-3/4 common-userPaneFamily  login-button shadow-md shadow-orange-500/20 hover:shadow-lg hover:shadow-orange-500/40 active:opacity-[0.85] common-borderRadius"
                            type="submit"
                            disabled={!!loader}
                          >
                            Reset Password
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                  <div className="flex justify-between">
                    <p className="mt-1">
                      <a
                        className="text-base font-medium hover:cursor-pointer  text-color hover:underline common-userPaneFamily"
                        onClick={() => navigate("/login")}
                      >
                        Already have an account? Login!
                      </a>
                    </p>
                  </div>
                </div>
              </main>
            </div>
          </div>
        </div>
      </Box>
      <Footer />
    </div>
  );
}

export default ResetPassword;

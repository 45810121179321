import { Box, Flex, Grid, Image, Text } from "@chakra-ui/react";
import React, { Component, useEffect, useState } from "react";
import Slider from "react-slick";
import "./newArrival.css";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import { useNavigate, useNavigationType } from "react-router-dom";

const NewArrival = ({ cardData, setting }) => {
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(false);

  function SampleNextArrow({ onClick }) {
    return (
      <Box
        fontSize={{ base: "20px", sm: "25px", md: "30px", lg: "30px" }}
        padding={{
          base: "2px 2px",
          sm: "3px 5px",
          md: "4px 8px",
          lg: "11px 12px",
        }}
        // borderRadius="50%"
        color="black"
        // boxShadow="0 0 5px 3px #ccc"
        display="flex"
        width={{ base: "20px", sm: "30px", md: "40px", lg: "54px" }}
        position="absolute"
        top="45%"
        right={{ md: "-29px", base: "-18px" }}
        // backgroundColor="white"
        className="arrow arrow-right"
        onClick={onClick}
        cursor={"pointer"}
      >
        <IoIosArrowForward />
      </Box>
    );
  }
  function SamplePrevArrow({ onClick }) {
    return (
      <Box
        fontSize={{ base: "20px", sm: "25px", md: "30px", lg: "30px" }}
        padding={{
          base: "2px 2px",
          sm: "3px 5px",
          md: "4px 8px",
          lg: "11px 12px",
        }}
        // borderRadius="50%"
        color="black"
        // boxShadow="0 0 5px 3px #ccc"
        // border={'1px solid lightgray'}
        display="flex"
        width={{ base: "20px", sm: "30px", md: "40px", lg: "54px" }}
        position="absolute"
        top="45%"
        left={{ md: "-40px", base: "-18px" }}
        // backgroundColor="white"
        cursor={"pointer"}
        className="arrow arrow-left"
        zIndex={1}
        onClick={onClick}
      >
        <IoIosArrowBack />
      </Box>
    );
  }
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 4,
    // slidesToScroll: 2,
    arrows: true,
    initialSlide: 0,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          nextArrow: false,
          prevArrow: false,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          nextArrow: false,
          prevArrow: false
          // slidesToScroll: 2,
          // initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false
        },
      },
    ],
  };
  useEffect(() => {
    // Function to check if the screen width is below a certain size (e.g., 768px for mobile)
    const checkIsMobile = () => {
      const mobileSize = 768;
      const isMobileView = window.innerWidth <= mobileSize;
      setIsMobile(isMobileView);
    };

    // Listen for window resize events and update the state accordingly
    window.addEventListener("resize", checkIsMobile);

    // Initial check when the component mounts
    checkIsMobile();

    // Clean up the event listener to prevent memory leaks
    return () => {
      window.removeEventListener("resize", checkIsMobile);
    };
  }, []);
  return (
    <Box
      width={{ base: "100%", sm: "100%", md: "100%", lg: "100%" }}
      marginBottom={{ base: "2rem", sm: "3rem", md: "4rem", lg: "2rem" }}
      padding={"0 20px"}
    // margin="auto"
    // className="tab-watchSlider"
    >
      {setting && setting?.sectionTwo ? (
        <Text className="history-text">{setting.sectionTwo}</Text>
      ) : (
        ""
      )}
      {isMobile ? (
        <Slider {...settings}>
          {cardData &&
            cardData.map((item) => {
              return (
                <a
                  href={
                    item?.section_type && item?.section_type === "category"
                      ? item.link_url
                      : `${process.env.REACT_APP_URL}/product-section/${item.title}`
                  }
                >
                  <div className="butFrame">
                    <>
                      <Image
                        className="image-hover-effect"
                        src={`${process.env.REACT_APP_IMAGE_URL}${item.image_url}`}
                        alt=""
                        style={{ height: "100%" }}
                      // onClick={() =>
                      //   navigate(
                      //     item?.section_type && item?.section_type === "category"
                      //       ? item.link_url
                      //       : `${process.env.REACT_APP_URL}/product-section/${item.title}`
                      //   )
                      // }
                      />
                      <div className="butTextWrap2">
                        <a
                          href={
                            item?.section_type &&
                              item?.section_type === "category"
                              ? item.link_url
                              : `${process.env.REACT_APP_URL}/product-section/${item.title}`
                          }
                        >
                          <p
                            style={{
                              fontSize: "1.2rem",
                              borderBottom: "3px solid #ed691f",
                              margin: "auto",
                              width: "max-content",
                            }}
                          >
                            {item.title}
                          </p>
                          <p
                            className="content-text"
                            style={{
                              fontSize: "1rem",
                              textAlign: "center",
                              marginTop: "3rem",
                            }}
                          >
                            {item.description}
                          </p>
                        </a>
                      </div>
                    </>
                  </div>
                </a>
              );
            })}
        </Slider>
      ) : (
        <Grid
          templateColumns={{
            base: "repeat(2, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(4, 1fr)",
            lg: `repeat(${cardData.length < 4 ? cardData.length : 4}, 1fr)`,
          }}
          gap={{ md: 6, base: "13px" }}
          className="tab-listing"
          marginTop={{ base: 3, sm: 4, md: 6, lg: 7 }}
        >
          {cardData &&
            cardData.map((item) => (
              <a
                href={
                  item?.section_type && item?.section_type === "category"
                    ? item.link_url
                    : `${process.env.REACT_APP_URL}/product-section/${item.title}`
                }
              >
                <div className="butFrame common-borderRadius">
                  <Image
                    className="image-hover-effect"
                    src={`${process.env.REACT_APP_IMAGE_URL}${item.image_url}`}
                    alt=""
                    style={{ height: "100%" }}
                  // onClick={() =>
                  //   navigate(
                  //     item?.section_type && item?.section_type === "category"
                  //       ? item.link_url
                  //       : `${process.env.REACT_APP_URL}/product-section/${item.title}`
                  //   )
                  // }
                  />
                  <div className="butTextWrap">
                    <a
                      href={
                        item?.section_type && item?.section_type === "category"
                          ? item.link_url
                          : `${process.env.REACT_APP_URL}/product-section/${item.title}`
                      }
                    >
                      <p
                        style={{
                          fontSize: "1.2rem",
                          borderBottom: "3px solid #ed691f",
                          margin: "auto",
                          width: "max-content",
                        }}
                      >
                        {item.title}
                      </p>
                      <p
                        className="content-text"
                        style={{
                          fontSize: "1rem",
                          textAlign: "center",
                          marginTop: "3rem",
                        }}
                      >
                        {item.description}
                      </p>
                    </a>
                  </div>
                </div>
              </a>
            ))}
        </Grid>
      )}
    </Box>
  );
};
export default NewArrival;

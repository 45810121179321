// layouts
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import DashboardLayout from "./layouts/dashboard";

import SimpleLayout from "./layouts/simple";
//
import UserPage from "./pages/users/UserPage";
import Page404 from "./pages/Page404";
import DashboardAppPage from "./pages/dashboard/DashboardAppPage";
import ProductsPage from "./pages/products/ProductsPage";
import Login from "./pages/login/Login";
import CategoryPage from "./pages/category/Category";
import OrdersPage from "./pages/orders/OrdersPage";
import CouponPage from "./pages/coupons/CouponPage";
import InvoicePage from "./pages/invoicepage/InvoicePage";
import ProductDetails from "./pages/products/ProductDetails";
import SettingPage from "./pages/setting/SettingPage";
import PrivateRoute from "./router/PrivateRoute";
import BannerPage from "./pages/banner/BannerPage";
import UserLoginPage from "./pages/user panel/login/UserLoginPage";
import RegisterPage from "./pages/user panel/register/RegisterPage";
import ForgotPasswordPage from "./pages/user panel/forgotPassword/ForgotPasswordPage";
import ChangePasswordPage from "./pages/user panel/change password/ChangePasswordPage";
import ResetPassword from "./pages/user panel/reset password/ResetPassword";
import VerificationPage from "./pages/user panel/verification/VerificationPage";
import UserHomePage from "./pages/user panel/home page/UserHomePage";
import ProductListing from "./pages/product-list/ProductListing";
import SectionProductListing from "./pages/product-list/SectionProductListing";
import DetailsPage from "./pages/user panel/ProductDetailsPage/DetailsPage";
import ProductSelectionPage from "./pages/home-productSelection/ProductSelectionPage";
import HomePageSection from "./pages/homepagesections/HomePageSections";
import CartPage from "./pages/user panel/cart-page/CartPage";
import WishlistPage from "./pages/user panel/wishlist-page/WishlistPage";
import CheckoutForm from "./pages/user panel/checkout-form/CheckoutForm";
import ProfilePage from "./pages/user panel/profile-page/ProfilePage";
import SuccessPage from "./pages/user panel/success-page/SuccessPage";
import FailPage from "./pages/user panel/fail-page/FailPage";
import Layout from "./utils/Layout";
import Policy from "./pages/user panel/policies/Policy";
import UserPrivateRoute from "./router/UserPrivateRoute";
import OrderHistory from "./pages/user panel/order-history/OrderHistory";
import OrderDetails from "./pages/user panel/order-details/OrderDetails";
import AboutUs from "./pages/user panel/policies/AboutUs";
import ContactUs from "./pages/user panel/policies/contactUs";

// ----------------------------------------------------------------------

export function Routers() {
  return (
    <Routes>
      <Route path="/admin" element={<DashboardLayout />}>
        <Route index element={<Navigate to="/admin/dashboard" />} />
        <Route
          path="dashboard"
          element={
            <PrivateRoute auth={{ isAuthenticated: true }}>
              <DashboardAppPage />
            </PrivateRoute>
          }
        />
        <Route
          path="user"
          element={
            <PrivateRoute auth={{ isAuthenticated: true }}>
              <UserPage />
            </PrivateRoute>
          }
        />
        {/* <Route path="blog" element={<PrivateRoute component={BlogPage} />} /> */}

        <Route
          path="products"
          element={
            <PrivateRoute auth={{ isAuthenticated: true }}>
              <ProductsPage />
            </PrivateRoute>
          }
        />
        <Route
          path="category"
          element={
            <PrivateRoute auth={{ isAuthenticated: true }}>
              <CategoryPage />
            </PrivateRoute>
          }
        />
        <Route
          path="orders"
          element={
            <PrivateRoute auth={{ isAuthenticated: true }}>
              <OrdersPage />
            </PrivateRoute>
          }
        />
        <Route
          path="coupons"
          element={
            <PrivateRoute auth={{ isAuthenticated: true }}>
              <CouponPage />
            </PrivateRoute>
          }
        />
        <Route
          path="setting"
          element={
            <PrivateRoute auth={{ isAuthenticated: true }}>
              <SettingPage />
            </PrivateRoute>
          }
        />
        <Route
          path="invoice/:id"
          element={
            <PrivateRoute auth={{ isAuthenticated: true }}>
              <InvoicePage />
            </PrivateRoute>
          }
        />
        <Route
          path="banner"
          element={
            <PrivateRoute auth={{ isAuthenticated: true }}>
              <BannerPage />
            </PrivateRoute>
          }
        />
        <Route
          path="home-product"
          element={
            <PrivateRoute auth={{ isAuthenticated: true }}>
              <ProductSelectionPage />
            </PrivateRoute>
          }
        />
        <Route
          path="section"
          element={
            <PrivateRoute auth={{ isAuthenticated: true }}>
              <HomePageSection />
            </PrivateRoute>
          }
        />
        <Route
          path="productDetails/:id"
          element={
            <PrivateRoute auth={{ isAuthenticated: true }}>
              <ProductDetails />
            </PrivateRoute>
          }
        />
      </Route>
      <Route path="/home" element={<UserHomePage />} />
      <Route
        exact
        path="/"
        element={
          <ChakraProvider>
            <UserHomePage />
          </ChakraProvider>
        }
      />
      <Route
        path="/product"
        element={
          <ChakraProvider>
            <ProductListing />
          </ChakraProvider>
        }
      />
      <Route
        path="/product/:slug"
        element={
          <ChakraProvider>
            <ProductListing />
          </ChakraProvider>
        }
      />
      <Route
        path="/product-section/:section"
        element={
          <ChakraProvider>
            <SectionProductListing />
          </ChakraProvider>
        }
      />
      <Route
        exact
        path="/product-details/:slug"
        element={
          <ChakraProvider>
            <DetailsPage />
          </ChakraProvider>
        }
      />
      <Route
        exact
        path="/cart"
        element={
          <ChakraProvider>
            <UserPrivateRoute>
              <CartPage />
            </UserPrivateRoute>
          </ChakraProvider>
        }
      />
      <Route
        exact
        path="/wishlist"
        element={
          <ChakraProvider>
            <UserPrivateRoute>
              <WishlistPage />
            </UserPrivateRoute>
          </ChakraProvider>
        }
      />
      <Route
        exact
        path="/checkout"
        element={
          <ChakraProvider>
            <UserPrivateRoute>
              <CheckoutForm />
            </UserPrivateRoute>
          </ChakraProvider>
        }
      />
      <Route
        exact
        path="/order"
        element={
          <ChakraProvider>
            <UserPrivateRoute>
              <OrderHistory />
            </UserPrivateRoute>
          </ChakraProvider>
        }
      />
      <Route
        exact
        path="/order/:id"
        element={
          <ChakraProvider>
            <UserPrivateRoute>
              <OrderDetails />
            </UserPrivateRoute>
          </ChakraProvider>
        }
      />
      <Route
        exact
        path="/profile"
        element={
          <ChakraProvider>
            <UserPrivateRoute>
              <ProfilePage />
            </UserPrivateRoute>
          </ChakraProvider>
        }
      />
      <Route
        exact
        path="/success/:orderId"
        element={
          <ChakraProvider>
            <UserPrivateRoute>
              <SuccessPage />
            </UserPrivateRoute>
          </ChakraProvider>
        }
      />
      <Route
        exact
        path="/fail"
        element={
          <ChakraProvider>
            <UserPrivateRoute>
              <FailPage />
            </UserPrivateRoute>
          </ChakraProvider>
        }
      />

      <Route path="/admin/login" element={<Login />} />
      <Route
        path="/login"
        element={
          <ChakraProvider>
            <UserLoginPage />
          </ChakraProvider>
        }
      />
      <Route
        path="/register"
        element={
          <ChakraProvider>
            <RegisterPage />
          </ChakraProvider>
        }
      />
      <Route
        path="/forgot-password"
        element={
          <ChakraProvider>
            <ForgotPasswordPage />
          </ChakraProvider>
        }
      />
      <Route
        path="/change-password"
        element={
          <ChakraProvider>
            <UserPrivateRoute>
              <ChangePasswordPage />
            </UserPrivateRoute>
          </ChakraProvider>
        }
      />
      <Route
        path="/reset-password/:token"
        element={
          <ChakraProvider>
            <ResetPassword />
          </ChakraProvider>
        }
      />
      <Route
        path="/verification/:token"
        element={
          <ChakraProvider>
            <VerificationPage />
          </ChakraProvider>
        }
      />
      <Route
        path="/policy/:policy"
        element={
          <ChakraProvider>
            <Policy />
          </ChakraProvider>
        }
      />
      <Route
        path="/about-us"
        element={
          <ChakraProvider>
            <AboutUs />
          </ChakraProvider>
        }
      />
      <Route
        path="/contact-us"
        element={
          <ChakraProvider>
            <ContactUs />
          </ChakraProvider>
        }
      />

      <Route element={<SimpleLayout />}>
        {/* <Route index element={<Navigate to="/admin/dashboard" />} /> */}
        <Route path="404" element={<Page404 />} />
        <Route path="*" element={<Navigate to="/404" />} />
      </Route>

      <Route path="*" element={<Navigate to="/404" replace />} />
    </Routes>
  );
}

import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Image,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import Logo from "../../assets/logo/logo.png";
//   import LogoImage from "../../Asssets/LogoI.png";

function SideBar({ headerData }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  // let { isAuth, afterLoginUser } = useSelector((state) => state.AuthReducer);
  // const toast = useToast();
  // const dispatch = useDispatch();
  return (
    <>
      <Button
        onClick={onOpen}
        // colorScheme="yellow"
        color={"#ed691f"}
        background={'transparent'}
        // size="md"
        border={"1px solid #ed691f"}
        _hover={{ bg: "white" }}
        marginLeft={"15px"}
        padding={0}
      >
        <GiHamburgerMenu size={"15px"} />
      </Button>

      <Drawer onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton color={"orange.600"} fontSize="2xl" />
          {/* <DrawerHeader width="28%">
            <Image src={Logo} />
          </DrawerHeader> */}
          <DrawerBody>
            <DrawerCloseButton />
            <Box p="1rem">
              <Image
                src={Logo}
                alt="logo"
                width="6rem"
                margin="auto"
                onClick={() => navigate("/")}
                height={{ base: "4rem", md: "100%" }}
              />
            </Box>

            <Flex
              justify="center"
              pl="1rem"
              gap="5"
              flexDir={"column"}
              mx="2rem"
              mt="2rem"
            >
              {headerData &&
                headerData.length &&
                headerData.map((item) => (
                  <Link to={`/product/${item.slug}`} onClick={onClose}>
                    <Text
                      textAlign={"center"}
                      className="menu-styles"
                      transition="0.5s ease"
                      _hover={{
                        background: "common-background",
                        // borderBottomWidth: '4px',
                        // borderBottomColor: '#f89f17',
                        // color: 'orange.600',
                        // fontSize: '1.7rem',
                        // webkitTransform: 'scale(1.04)',
                        // msTransform: 'scale(1.02)',
                        // transform: 'scale(1.02)',
                        // transition: ' 0.5s ease',
                      }}
                    >
                      {item.category_name}
                    </Text>
                  </Link>
                ))}
              <Link to="/cart">
                <Text
                  textAlign={"center"}
                  className="menu-styles"
                  transition="0.5s ease"
                  _hover={{
                    background: "common-background",
                    // borderBottomWidth: '4px',
                    // borderBottomColor: '#f89f17',
                    // color: 'orange.600',
                    // fontSize: '1.7rem',
                    // webkitTransform: 'scale(1.04)',
                    // msTransform: 'scale(1.02)',
                    // transform: 'scale(1.02)',
                    // transition: ' 0.5s ease',
                  }}
                >
                  Your Cart
                </Text>
              </Link>
              <Link to="/profile">
                <Text
                  textAlign={"center"}
                  className="menu-styles"
                  transition="0.5s ease"
                  _hover={{
                    background: "common-background",
                    // borderBottomWidth: '4px',
                    // borderBottomColor: '#f89f17',
                    // color: 'orange.600',
                    // fontSize: '1.7rem',
                    // webkitTransform: 'scale(1.04)',
                    // msTransform: 'scale(1.02)',
                    // transform: 'scale(1.02)',
                    // transition: ' 0.5s ease',
                  }}
                >
                  Profile
                </Text>
              </Link>
              <Flex justify={"center"} gap={3}>
                {/* {isAuth ? (
                  <Link to="/">
                    <Button
                      px="2rem"
                      colorScheme={'yellow'}
                      _hover={{ bg: 'pink' }}
                      onClick={() => {
                        dispatch(logout);
                        toast({
                          title: 'User Logout Successfully.',
                          description: 'Come Back Again Soon',
                          status: 'success',
                          duration: 2000,
                          isClosable: true,
                          position: 'top',
                        });
                      }}
                    >
                      Logout
                    </Button>
                  </Link>
                ) : ( */}
                <Link to="/login">
                  <Button
                    textAlign={"center"}
                    className="menu-styles"
                    transition="0.5s ease"
                    fontWeight={"inherit"}
                    _hover={{
                      background: "common-background",
                      // borderBottomWidth: '4px',
                      // borderBottomColor: '#f89f17',
                      // color: 'orange.600',
                      // fontSize: '1.7rem',
                      // webkitTransform: 'scale(1.04)',
                      // msTransform: 'scale(1.02)',
                      // transform: 'scale(1.02)',
                      // transition: ' 0.5s ease',
                    }}
                  >
                    Login
                  </Button>
                </Link>
                {/* )} */}
              </Flex>
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}
export default SideBar;

import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Heading,
  Input,
  Stack,
  Text,
  Textarea,
} from "@chakra-ui/react";
import Header from "../../../components/header";
import "./profile.css";
import store from "../../../store";
import { useDispatch, useSelector } from "react-redux";
import { fetchHomeData } from "../../../actions/HomePageActions";
import { updateUserData } from "../../../actions/UserActions";
import Footer from "../../../components/footer/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { notificationStyle } from "../../product-list/ProductListing";

function ProfilePage() {
  const [update, setUpdate] = useState("profile");
  const dispatch = useDispatch();
  const { homePageData } = useSelector((state) => state.home);
  const { userData } = useSelector((state) => state.user);
  const { checkoutData } = useSelector((state) => state.checkout);
  const [userUpdate, setUserUpdate] = useState({
    user_id: userData?.user_id,
    first_name:
      userData?.first_name || checkoutData?.shippingAddress?.first_name,
    last_name: userData?.last_name || checkoutData?.shippingAddress?.last_name,
    phone_number:
      userData?.phone_number || checkoutData?.shippingAddress?.phone_number,
    address: userData?.address || checkoutData?.shippingAddress?.address,
    city: userData?.city || checkoutData?.shippingAddress?.city,
    state: userData?.state || checkoutData?.shippingAddress?.state,
    country: userData?.country || checkoutData?.shippingAddress?.country,
    zip_code: userData?.zip_code || checkoutData?.shippingAddress?.zip_code,
  });
  useEffect(() => {
    if (!homePageData.length) {
      dispatch(fetchHomeData());
    }
  }, []);
  const handleEditBtn = () => {
    setUpdate("update");
  };
  const handleUpdateChange = (e) => {
    const { name, value } = e.target;
    setUserUpdate({
      ...userUpdate,
      [name]: value,
    });
  };

  const handleUpdatedData = () => {
    dispatch(updateUserData(userUpdate));
    setUpdate("profile");
    toast.success("Update Profile Successfully", notificationStyle);
  };
  return (
    <div>
      <Header headerData={homePageData} />
      <Box
        // maxW={{
        //   base: '3xl',
        //   lg: '7xl',
        // }}
        // mx="auto"

        px={{
          base: "4",
          md: "8",
          lg: "12",
        }}
        py={{
          base: "6",
          md: "8",
          lg: "12",
        }}
        className="form-box"
        display={"flex"}
        justifyContent={"center"}
      >
        <Card
          width={{ md: "60%", lg: "60%" }}
          className="profile-box"
          boxShadow={" rgba(0, 0, 0, 0.1) 0px 4px 12px"}
          background={"white"}
          padding={{ md: 10, lg: 10, base: 4, sm: 4 }}
        >
          <Stack w={"full"} marginRight={10}>
            <Heading
              fontSize={"2xl"}
              marginBottom={8}
              fontWeight="extrabold"
              className="common-userPaneFamily"
            >
              Profile Details
            </Heading>
            <Grid
              templateColumns={"repeat(2, 1fr)"}
              gap={{ md: 6 }}
              marginBottom={"5px"}
            >
              <Text className="common-userPaneFamily common-cartPage">
                First Name
              </Text>
              {update === "update" ? (
                <Input
                  type="text"
                  placeholder="First Name"
                  name="first_name"
                  value={userUpdate.first_name}
                  onChange={(e) => handleUpdateChange(e)}
                  className="common-userPaneFamily"
                />
              ) : (
                <Text
                  className="common-userPaneFamily"
                  color={"rgb(79, 79, 79)"}
                >
                  {(userData && userData?.first_name) ||
                    (checkoutData &&
                      checkoutData?.shippingAddress &&
                      checkoutData?.shippingAddress?.first_name)}
                </Text>
              )}
            </Grid>
            <Grid
              templateColumns={"repeat(2, 1fr)"}
              gap={{ md: 6 }}
              marginBottom={"5px"}
            >
              <Text className="common-userPaneFamily common-cartPage">
                Last Name
              </Text>
              {update === "update" ? (
                <Input
                  type="text"
                  name="last_name"
                  placeholder="Last Name"
                  value={userUpdate.last_name}
                  onChange={(e) => handleUpdateChange(e)}
                  className="common-userPaneFamily"
                />
              ) : (
                <Text
                  className="common-userPaneFamily"
                  color={"rgb(79, 79, 79)"}
                >
                  {(userData && userData?.last_name) ||
                    (checkoutData &&
                      checkoutData?.shippingAddress &&
                      checkoutData?.shippingAddress?.last_name)}
                </Text>
              )}
            </Grid>
            <Grid
              templateColumns={"repeat(2, 1fr)"}
              gap={{ md: 6 }}
              marginBottom={"5px"}
            >
              <Text className="common-userPaneFamily common-cartPage">
                Email
              </Text>
              {update === "update" ? (
                <Input
                  type="text"
                  name="email"
                  disabled
                  value={userData?.email}
                  onChange={(e) => handleUpdateChange(e)}
                  placeholder="Email"
                  className="common-userPaneFamily"
                />
              ) : (
                <Text
                  className="common-userPaneFamily"
                  color={"rgb(79, 79, 79)"}
                >
                  {userData && userData?.email}
                </Text>
              )}
            </Grid>
            <Grid
              templateColumns={"repeat(2, 1fr)"}
              gap={{ md: 6 }}
              marginBottom={"5px"}
            >
              <Text className="common-userPaneFamily common-cartPage">
                User Name
              </Text>
              {update === "update" ? (
                <Input
                  type="text"
                  placeholder="User Name"
                  name="username"
                  disabled
                  value={userData?.username}
                  onChange={(e) => handleUpdateChange(e)}
                  className="common-userPaneFamily"
                />
              ) : (
                <Text
                  className="common-userPaneFamily"
                  color={"rgb(79, 79, 79)"}
                >
                  {userData && userData?.username}
                </Text>
              )}
            </Grid>
            <Grid
              templateColumns={"repeat(2, 1fr)"}
              gap={{ md: 6 }}
              marginBottom={"5px"}
            >
              <Text className="common-userPaneFamily common-cartPage">
                Phone Number
              </Text>
              {update === "update" ? (
                <Input
                  type="text"
                  placeholder="Phone Number"
                  name="phone_number"
                  value={userUpdate.phone_number}
                  onChange={(e) => handleUpdateChange(e)}
                  className="common-userPaneFamily"
                />
              ) : (
                <Text
                  className="common-userPaneFamily"
                  color={"rgb(79, 79, 79)"}
                >
                  {(userData && userData?.phone_number) ||
                  (checkoutData &&
                    checkoutData?.shippingAddress &&
                    checkoutData?.shippingAddress?.phone_number)
                    ? userData?.phone_number ||
                      checkoutData?.shippingAddress?.phone_number
                    : ""}
                </Text>
              )}
            </Grid>
            <Grid
              templateColumns={"repeat(2, 1fr)"}
              gap={{ md: 6 }}
              marginBottom={"5px"}
            >
              <Text className="common-userPaneFamily common-cartPage">
                Address
              </Text>
              {update === "update" ? (
                <Textarea
                  type="text"
                  placeholder="Address"
                  name="address"
                  value={userUpdate.address}
                  onChange={(e) => handleUpdateChange(e)}
                  className="common-userPaneFamily address-textArea"
                />
              ) : (
                <Text
                  className="common-userPaneFamily"
                  color={"rgb(79, 79, 79)"}
                >
                  {(userData && userData?.address) ||
                  (checkoutData &&
                    checkoutData?.shippingAddress &&
                    checkoutData?.shippingAddress?.address)
                    ? userData?.address ||
                      checkoutData?.shippingAddress?.address
                    : ""}
                </Text>
              )}
            </Grid>
            <Grid
              templateColumns={"repeat(2, 1fr)"}
              gap={{ md: 6 }}
              marginBottom={"5px"}
            >
              <Text className="common-userPaneFamily common-cartPage">
                City
              </Text>
              {update === "update" ? (
                <Input
                  type="text"
                  placeholder="City"
                  name="city"
                  value={userUpdate.city}
                  onChange={(e) => handleUpdateChange(e)}
                  className="common-userPaneFamily"
                />
              ) : (
                <Text
                  className="common-userPaneFamily"
                  color={"rgb(79, 79, 79)"}
                >
                  {(userData && userData?.city) ||
                  (checkoutData &&
                    checkoutData?.shippingAddress &&
                    checkoutData?.shippingAddress?.city)
                    ? userData?.city || checkoutData?.shippingAddress?.city
                    : ""}
                </Text>
              )}
            </Grid>
            <Grid
              templateColumns={"repeat(2, 1fr)"}
              gap={{ md: 6 }}
              marginBottom={"5px"}
            >
              <Text className="common-userPaneFamily common-cartPage">
                State
              </Text>
              {update === "update" ? (
                <Input
                  type="text"
                  placeholder="State"
                  name="state"
                  value={userUpdate.state}
                  onChange={(e) => handleUpdateChange(e)}
                  className="common-userPaneFamily"
                />
              ) : (
                <Text
                  className="common-userPaneFamily"
                  color={"rgb(79, 79, 79)"}
                >
                  {(userData && userData?.state) ||
                  (checkoutData &&
                    checkoutData?.shippingAddress &&
                    checkoutData?.shippingAddress?.state)
                    ? userData?.state || checkoutData?.shippingAddress?.state
                    : ""}
                </Text>
              )}
            </Grid>
            <Grid
              templateColumns={"repeat(2, 1fr)"}
              gap={{ md: 6 }}
              marginBottom={"5px"}
            >
              <Text className="common-userPaneFamily common-cartPage">
                Country
              </Text>
              {update === "update" ? (
                <Input
                  type="text"
                  placeholder="Country"
                  name="country"
                  value={userUpdate.country}
                  onChange={(e) => handleUpdateChange(e)}
                  className="common-userPaneFamily"
                />
              ) : (
                <Text
                  className="common-userPaneFamily"
                  color={"rgb(79, 79, 79)"}
                >
                  {(userData && userData?.country) ||
                  (checkoutData &&
                    checkoutData?.shippingAddress &&
                    checkoutData?.shippingAddress?.country)
                    ? userData?.country ||
                      checkoutData?.shippingAddress?.country
                    : ""}
                </Text>
              )}
            </Grid>
            <Grid
              templateColumns={"repeat(2, 1fr)"}
              gap={{ md: 6 }}
              marginBottom={"5px"}
            >
              <Text className="common-userPaneFamily common-cartPage">
                Zip Code
              </Text>
              {update === "update" ? (
                <Input
                  type="text"
                  placeholder="Zip Code"
                  name="zip_code"
                  value={userUpdate.zip_code}
                  onChange={(e) => handleUpdateChange(e)}
                  className="common-userPaneFamily"
                />
              ) : (
                <Text
                  className="common-userPaneFamily"
                  color={"rgb(79, 79, 79)"}
                >
                  {(userData && userData?.zip_code) ||
                  (checkoutData &&
                    checkoutData?.shippingAddress &&
                    checkoutData?.shippingAddress?.zip_code)
                    ? userData?.zip_code ||
                      checkoutData?.shippingAddress?.zip_code
                    : ""}
                </Text>
              )}
            </Grid>
            <Box display={"flex"} justifyContent={"center"} marginTop={10}>
              {update === "update" ? (
                <Button
                  rounded={"none"}
                  className="update-profile"
                  onClick={() => handleUpdatedData()}
                >
                  Submit
                </Button>
              ) : (
                <Button
                  rounded={"none"}
                  className="update-profile"
                  onClick={() => handleEditBtn()}
                >
                  Update
                </Button>
              )}
            </Box>
          </Stack>
        </Card>
      </Box>
      <Footer />
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}

export default ProfilePage;

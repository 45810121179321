import { OFFERS_APPLY, OFFERS_FAILURE,OFFERS_REQUEST,OFFERS_SUCCESS } from '../actions/OfferActions';

const initialState = {
    loading: false,
    offers: [],
    selectedOffer: {},
    error: '',
  };

const CartReducer = (state = initialState, action) => {
    
    switch (action.type) {
        case OFFERS_REQUEST:
          return {
            ...state,
            loading: true,
          };
        case OFFERS_SUCCESS:
          return {
            ...state,
            loading: false,
            offers: action.payload,
            error: '',
          };
        case OFFERS_APPLY:
          return {
            ...state,
            selectedOffer: action.payload,
          };
        case OFFERS_FAILURE:
          return {
            ...state,
            loading: false,
            offers: [],
            selectedOffer:{},
            error: action.payload,
          };
        default:
          return state;
      }
}
export default CartReducer;
import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import '../products/products.css';
import '../dashboard/dashboard.css';
import {
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  Card,
  Container,
  FormControlLabel,
  Button,
  Box,
  Checkbox,
} from '@mui/material';

// @mui
// components
import Scrollbar from 'src/components/scrollbar/Scrollbar';

import { IOSSwitch } from 'src/utils/toggleButton/toggleButton';
import Notification from 'src/components/notification';
// mock
import TableHeader from '../../sections/@dashboard/user/tableHeader';
import axiosInstance from '../../axios';
import noImages from '../../assets/noImageAvailable.jpg';

// ----------------------------------------------------------------------

export default function ProductSelectionPage() {
  const [productListing, setProductListing] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [title, setTitle] = useState('');
  const [selected, setSelected] = useState([]);
  console.log('selected', selected);
  const [filters, setFilters] = useState({
    search: '',
    category: '',
  });

  const getSelectedData = async () => {
    const response = await axiosInstance(`/home-page/home-page-section-product`, 'GET', null);
    if (response.status === 200) {
      console.log('response', response.data);
      if (response.data && response.data.data) {
        const productList = JSON.parse(response.data.data.productList);
        console.log('productList', productList);
        setTitle(response.data?.data?.title);
        setSelected(productList);
      }
      Notification('success', 'Category List', response.data.message, 2000);
    } else {
      Notification('error', 'Category List', response.data.message, 2000);
    }
  };
  const fetchData = async () => {
    const response = await axiosInstance(
      `/product/all-product-list/?search=${filters.search}&category=${filters.category}`,
      'GET',
      null
    );

    if (response.status === 200) {
      if (response.data.data.length > 0) {
        setProductListing(
          response.data.data.map((data, index) => ({
            id: data.product_id,
            image: data.ProductImages ? data.ProductImages[0]?.image_url : '',
            name: data.name,
            category: data.Category.category_name,
            amount: data.price,
            stock: data.quantity,
            is_active: data.is_active,
            discount: data.discount,
          }))
        );
      } else {
        setProductListing([]);
      }
      // Notification('success', 'Category List', response.data.message, 2000);
    } else {
      Notification('error', 'Category List', response.data.message, 2000);
    }
    const category = await axiosInstance(`/category/`, 'GET', null);
    if (category.status === 200) {
      setCategoryList(category.data.data);
    } else {
      Notification('error', 'Category List', category.data.message, 2000);
    }
  };
  // get product list

  useEffect(() => {
    getSelectedData();
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const response = await axiosInstance(
        `/product/all-product-list/?search=${filters.search}&category=${filters.category}`,
        'GET',
        null
      );

      if (response.status === 200) {
        if (response.data.data.length > 0) {
          setProductListing(
            response.data.data.map((data, index) => ({
              id: data.product_id,
              image: data.ProductImages ? data.ProductImages[0]?.image_url : '',
              name: data.name,
              category: data.Category.category_name,
              amount: data.price,
              stock: data.quantity,
              is_active: data.is_active,
              discount: data.discount,
            }))
          );
        } else {
          setProductListing([]);
        }
        // Notification('success', 'Category List', response.data.message, 2000);
      } else {
        // Notification('error', 'Category List', response.data.message, 2000);
      }
    };

    fetchData();
  }, [filters]);

  const handleFilter = (e) => {
    const { name, value } = e.target;
    setFilters({ ...filters, [name]: value });
  };

  const updateValue = async (e, id) => {
    const { checked } = e.target;
    const data = productListing.map((val) => {
      if (val.id === id) {
        return {
          ...val,
          is_active: checked,
        };
      }
      return val;
    });
    setProductListing(data);

    const fetchData = async () => {
      const payload = {
        product_id: id,
        is_active: checked,
      };
      const response = await axiosInstance(`/product/status`, 'POST', payload);

      if (response.status === 200) {
        Notification('success', 'Status', response.data.message, 2000);
      } else {
        Notification('error', 'Status', response.data.message, 2000);
      }
    };

    fetchData();
  };

  // Table Columns

  const TABLE_HEAD = [
    { id: '' },
    { id: 'id', label: 'Id', alignRight: false },
    { id: 'image', label: 'Product Image', alignRight: false },
    { id: 'name', label: 'Product Name', alignRight: false },
    { id: 'category', label: 'Category', alignRight: false },
    { id: 'amount', label: 'Price', alignRight: false },
    { id: 'stock', label: 'Stock', alignRight: false },
    // { id: 'status', label: 'Status', alignRight: false },
    { id: 'discount', label: 'Discount', alignRight: false },
    // { id: 'details', label: 'Details', alignRight: false },
    { id: 'is_active', label: 'Status', alignRight: false },
    // { id: '' },
  ];
  const isSelected = (id) => selected.indexOf(id) !== -1;

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const handleSubmit = async () => {
    const payload = { title: title, productList: JSON.stringify(selected) };
    const response = await axiosInstance(`/home-page/home-page-section-product-list`, 'POST', payload);

    if (response.status === 200) {
      Notification('success', 'Status', response.data.message, 2000);
    } else {
      Notification('error', 'Status', response.data.message, 2000);
    }
  };
  return (
    <>
      <Helmet>
        <title> Products Selection</title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={4}>
          <Typography variant="h4" className="text-lg font-bold text-gray-700 dark:text-gray-300 headers">
            Select Products
          </Typography>
        </Stack>

        <div className="flex grid-cols-6 gap-3 md:gap-5 xl:gap-6 lg:gap-6 mb-6 formLabel">
          <label className="block text-sm text-gray-700 dark:text-gray-400 col-span-4 sm:col-span-2 font-medium text-sm">
            Title
          </label>
          <div className="col-span-8 sm:col-span-4 formInput">
            <input
              className={
                'block w-full px-3 py-1 text-sm focus:outline-none text-black dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white'
              }
              type="text"
              name="title"
              placeholder="Type Title..."
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              // onBlur={handleBlur}
            />
            {/* {touched.name && errors.name && <div className="error-message">{errors.name}</div>} */}
          </div>
        </div>

        {/* Prroduct Table */}
        {/* Add Product */}
        <label className="block text-sm text-gray-700 dark:text-gray-400 col-span-4 sm:col-span-2 font-medium text-sm">
          Select Products
        </label>
        <div
          className="grid-cols-6 gap-3 md:gap-5 xl:gap-6 lg:gap-6 mb-6 formLabel"
          style={{ height: '500px', overflowY: 'scroll', width: 'calc(100% - 120px)', float: 'right' }}
        >
          <Card className="card-style mb-0.5">
            <div className="pt-0.2">
              <form className="px-3 py-3 grid gap-4 lg:gap-6 xl:gap-6 md:flex xl:flex">
                <div className="flex-grow-0 md:flex-grow lg:flex-grow xl:flex-grow">
                  <input
                    className="block w-full px-3 py-1 text-sm focus:outline-none dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 border-transparent focus:bg-white"
                    type="search"
                    name="search"
                    placeholder="Search by product name"
                    value={filters.search}
                    onChange={(e) => handleFilter(e)}
                  />
                </div>
                <div className="flex-grow-0 md:flex-grow lg:flex-grow xl:flex-grow">
                  <select
                    className="block w-full px-2 py-1 text-sm dark:text-gray-300 focus:outline-none rounded-md form-select focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:shadow-none focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 leading-5 border h-10 text-sm focus:outline-none block w-full bg-gray-100 border-transparent focus:bg-white"
                    name="category"
                    onChange={(e) => handleFilter(e)}
                  >
                    <option value="" hidden="">
                      Choose a Category
                    </option>
                    {categoryList.map((item) => (
                      <option value={item.category_id} key={item.category_id}>
                        {item.category_name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="flex-grow-0 md:flex-grow lg:flex-grow xl:flex-grow">
                  <select className="block w-full px-2 py-1 text-sm dark:text-gray-300 focus:outline-none rounded-md form-select focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:shadow-none focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 leading-5 border h-10 text-sm focus:outline-none block w-full bg-gray-100 border-transparent focus:bg-white">
                    <option value="All" hidden="">
                      Price
                    </option>
                    <option value="Low">Low to High</option>
                    <option value="High">High to Low</option>
                  </select>
                </div>
              </form>
            </div>
          </Card>

          <Card>
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <TableHeader
                    // order={order}
                    // orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    // rowCount={count}
                    numSelected={selected.length}
                    // onRequestSort={handleRequestSort}
                    checkbox={false}
                  />

                  {productListing.length > 0 ? (
                    <TableBody>
                      {/* filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) */}
                      {productListing.map((row) => {
                        const { id, image, name, category, amount, stock, is_active, discount, details, published } =
                          row;
                        const isItemSelected = isSelected(id);
                        // const selectedUser = selected.indexOf(name) !== -1;
                        return (
                          <TableRow
                            hover
                            key={id}
                            role="checkbox"
                            onClick={(event) => handleClick(event, id)}
                            selected={isItemSelected}
                            aria-checked={isItemSelected}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                color="primary"
                                checked={isItemSelected}
                                inputProps={{
                                  'aria-labelledby': id,
                                }}
                              />
                            </TableCell>
                            <TableCell align="left">{id}</TableCell>
                            {image ? (
                              <TableCell align="left">
                                <div
                                  style={{
                                    height: '100px',
                                    width: '100px',
                                  }}
                                >
                                  <img src={`${process.env.REACT_APP_IMAGE_URL}${image}`} alt="" />
                                </div>
                              </TableCell>
                            ) : (
                              <TableCell align="left">
                                <div
                                  style={{
                                    height: '100px',
                                    width: '100px',
                                  }}
                                >
                                  <img src={noImages} alt="" />
                                </div>
                              </TableCell>
                            )}
                            <TableCell align="left">{name}</TableCell>

                            <TableCell align="left">{category}</TableCell>
                            <TableCell align="left">{amount}</TableCell>
                            <TableCell align="left">{stock}</TableCell>
                            <TableCell align="left">{discount}</TableCell>
                            {/* <TableCell
                                align="left"
                                style={{ height: 15 }}
                                onClick={() => navigate(`/admin/productDetails/${id}`)}
                              >
                                {details}
                              </TableCell> */}
                            <TableCell align="left">
                              <FormControlLabel
                                control={
                                  <IOSSwitch sx={{ m: 1 }} checked={is_active} onChange={(e) => updateValue(e, id)} />
                                }
                              />
                            </TableCell>

                            {/* <TableCell align="right">
                                <IconButton size="large" color="inherit" onClick={(e) => handleOpenMenu(e, id)}>
                                  <Iconify icon={'eva:more-vertical-fill'} />
                                </IconButton>
                              </TableCell> */}
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  ) : (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                          <Paper
                            sx={{
                              textAlign: 'center',
                            }}
                          >
                            <Box
                              className="not-fount-img"
                              component="img"
                              src="/assets/illustrations/illustration_404.svg"
                              sx={{ height: 160, mx: 'auto', my: { xs: 5, sm: 10 } }}
                            />
                            <Typography variant="h4" paragraph className="not-fount-1">
                              No records has been added yet.
                            </Typography>
                            {/* <Typography variant="h6" paragraph className="not-fount-2">
                                Add a new record by simpley clicking the button on top right side.
                              </Typography> */}
                          </Paper>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
              {/* {productListing.length > 0 ? (
                  <TablePagination
                    rowsPerPageOptions={[2, 3, 4, 5, 10, 15, 25]}
                    component="div"
                    count={pagination.totalItems}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                ) : (
                  ''
                )} */}
            </Scrollbar>
          </Card>
        </div>
        <div className="flex-grow-0 md:flex-grow lg:flex-grow xl:flex-grow btn-style">
          <button
            className="align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-white  border border-transparent common-background focus:ring focus:ring-green-300 w-1/2 h-12"
            type="submit"
            onClick={() => handleSubmit()}
          >
            <span>Submit</span>
          </button>
        </div>
      </Container>
      {/* <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem onClick={(e) => handleEditProduct(e)}>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        <MenuItem sx={{ color: 'error.main' }} onClick={handleOpenDeleteModal}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover> */}

      {/* Modal for Delete */}
      {/* <Dialog
        open={openDialog}
        keepMounted
        onClose={handleCloseDeleteModal}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{'Logout'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to delete product?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteModal}>No</Button>
          <Button onClick={() => handleDeleteProduct()}>Yes</Button>
        </DialogActions>
      </Dialog> */}

      {/* Add Product Form */}

      {/* <Drawer anchor={'right'} open={openDrawer} sx={{"& .MuiPaper-root": {
      width: '50%'
    }}} onClose={() => setOpenDrawer(false)} className="drawerStyle">
        <Product setOpenDrawer={setOpenDrawer} addEdit={addEdit} productListAPI={productListAPI} tableId={tableId} />
      </Drawer> */}
    </>
  );
}

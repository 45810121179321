import React from 'react';
import { useSelector } from 'react-redux';
import loginImage from '../../../assets/user-login.jpg';

export function CommonImage() {
  const { homePageData } = useSelector((state) => state.home);
  return (
    <div className="md:h-auto md:w-1/2">
      <img
        aria-hidden="true"
        className="object-cover w-full h-full dark:hidden"
        src={homePageData && `${process.env.REACT_APP_IMAGE_URL}${homePageData?.setting?.loginPageBanner}`}
        alt="Office"
      />
      {/* <img
        aria-hidden="true"
        className="hidden object-cover w-full h-full dark:block"
        src="/static/media/login-office-dark.c7786a89.jpeg"
        alt="Office"
      /> */}
    </div>
  );
}

import { Box, Image, Stack, Text } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import failIcon from '../../../assets/fail-59.svg';
import Header from '../../../components/header';
import Footer from '../../../components/footer/Footer';
import store from '../../../store';
import { fetchHomeData } from '../../../actions/HomePageActions';

function FailPage() {
  const dispatch = useDispatch();
  const { homePageData } = store.getState().home;
  useEffect(() => {
    if (!homePageData.length) {
      dispatch(fetchHomeData());
    }
  }, []);
  return (
    <div>
      <Header headerData={homePageData} />
      <Box textAlign={'center'}>
        <Box display={'flex'} justifyContent={'center'} padding={10}>
          <Stack height={{ md: '150px', base: '120px' }} width={{ md: '150px', base: '120px' }}>
            <Image src={failIcon} />
          </Stack>
        </Box>
        <Text className="confirmation-text" marginTop={4}>
          Your payment was not successfully processed. Please contact our customer support.
        </Text>
      </Box>
      <Footer />
    </div>
  );
}

export default FailPage;

import { configureStore } from '@reduxjs/toolkit';

import { applyMiddleware, combineReducers, compose } from 'redux';
import thunk from 'redux-thunk'; // Thunk middleware for handling asynchronous actions
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import HomePageReducer from './reducers/HomePageReducer';
import ProductListReducer from './reducers/ProductListReducer';
import FilterOptionReducer from './reducers/FilterOptionReducer';
import CartReducer from './reducers/CartReducer';
import WishlistReducer from './reducers/WishlistReducer';
import UserReducer from './reducers/UserReducer';
import SearchReducer from './reducers/SearchReducer';
import ProductByIdReducer from './reducers/ProductByIdReducer';
import OfferReducer from './reducers/OfferReducer';
import CheckoutReducer from './reducers/CheckoutReducer';
import AdminOrderReducer from './reducers/AdminOrderReducer';
import UserOrderReducer from './reducers/UserOrderReducer';

const storeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistConfig = {
  key: 'root', // the key in local storage where your persisted state will be saved
  storage, // the storage engine to use
};

const rootReducer = combineReducers({
  home: HomePageReducer,
  productList: ProductListReducer,
  filter: FilterOptionReducer,
  cart: CartReducer,
  offer: OfferReducer,
  wishlist: WishlistReducer,
  user: UserReducer,
  search: SearchReducer,
  productById: ProductByIdReducer,
  checkout: CheckoutReducer,
  adminOrder: AdminOrderReducer,
  userOrder: UserOrderReducer,
});
const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = configureStore(
  {
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }),
  },
  storeEnhancers(applyMiddleware(thunk))
);
export const persistor = persistStore(store);
// const store = configureStore({ reducer: rootReducer }, applyMiddleware(thunk));

export default store;

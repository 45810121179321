import { Button, Card, CardActions, CardContent, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import './changePassword.css';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useNavigate } from 'react-router-dom';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import * as Yup from 'yup';
import Header from '../../../components/header';
import Footer from '../../../components/footer/Footer';
import store from '../../../store';
import { fetchHomeData } from '../../../actions/HomePageActions';
import axiosInstance from '../../../axios';

function ChangePasswordPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { homePageData } = useSelector((state) => state.home);
  const [password, setPassword] = useState({
    oldPassword: '',
    newPassword: '',
  });
  const [visiblity, setVisiblity] = useState(false);
  const [confirmVisiblity, setConfirmVisiblity] = useState(false);
  useEffect(() => {
    if (!homePageData.length) {
      dispatch(fetchHomeData());
    }
  }, []);
  const validationSchema = Yup.object({
    oldPassword: Yup.string()
      .required('Old Password is required')
      .min(6, 'Password must be at least 6 characters.')
      .trim(),
    newPassword: Yup.string()
      .required('New Password is required')
      // .oneOf([Yup.ref('password'), null], 'Passwords not match')
      .trim(),
  });
  const handleUserSubmit = async (values, resetForm) => {
    const response = await axiosInstance(`/auth/change-password`, 'POST', values);
    if (response.data.status === 200) {
      setPassword({});
      navigate('/login');
      resetForm();
    }
  };
  return (
    <>
      <Header headerData={homePageData} />
      <div className="password-card-style">
        <div className="bg-color md:w-2/6 sm:w-full">
          <div className="flex justify-center align-center">
            <Card style={{ borderRadius: 0, width: '100%' }}>
              <CardContent>
                <h1 className="mb-6 text-2xl font-semibold text-gray-700 dark:text-gray-600 common-userPaneFamily ">
                  Change Password
                </h1>
                <Formik
                  enableReinitialize
                  initialValues={password}
                  validationSchema={validationSchema}
                  onSubmit={(values, { resetForm }) => {
                    handleUserSubmit(values, resetForm);
                    // setSubmitting(true);
                    // resetForm();
                    // setSubmitting(false);
                  }}
                >
                  {({ values, errors, touched, handleBlur, handleChange, handleSubmit, isValid, isSubmitting }) => (
                    <Form>
                      {/* <label className="block text-sm text-gray-700 dark:text-gray-400 col-span-4 sm:col-span-2 font-bold text-sm common-userPaneFamily ">
                    Your Email
                  </label>
                  <input
                    className="mb-4 block w-full px-3 py-1 text-sm focus:outline-none dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-12 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white common-userPaneFamily"
                    type="email"
                    name="email"
                    placeholder="Email"
                  /> */}
                      <label className="block text-sm text-gray-700 dark:text-gray-400 col-span-4 sm:col-span-2 font-bold text-sm common-userPaneFamily  ">
                        Old Password <span className="required-field">*</span>
                      </label>
                      <div className="inputWrapper2">
                        <input
                          className={
                            touched.oldPassword && errors.oldPassword
                              ? 'mb-4 block w-full px-3 py-1 text-sm focus:outline-none dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-12 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white- common-userPaneFamily input-error'
                              : 'mb-4 block w-full px-3 py-1 text-sm focus:outline-none dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-12 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white- common-userPaneFamily'
                          }
                          type={confirmVisiblity ? 'text' : 'password'}
                          name="oldPassword"
                          placeholder="Old Password"
                          value={values.oldPassword}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <div
                          className="showHidePassword"
                          style={{ top: '18%', left: '91%' }}
                          onClick={() => setConfirmVisiblity(!confirmVisiblity)}
                        >
                          {!confirmVisiblity ? <VisibilityOffIcon /> : <VisibilityIcon />}
                        </div>
                        {touched.oldPassword && errors.oldPassword && (
                          <div className="error-message" style={{ marginTop: '-12px', marginBottom: '10px' }}>
                            {errors.oldPassword}
                          </div>
                        )}
                      </div>
                      <label className="block text-sm text-gray-700 dark:text-gray-400 col-span-4 sm:col-span-2 font-bold text-sm common-userPaneFamily  ">
                        New Password <span className="required-field">*</span>
                      </label>
                      <div className="inputWrapper2">
                        <input
                          className={
                            touched.newPassword && errors.newPassword
                              ? 'mb-4 block w-full px-3 py-1 text-sm focus:outline-none dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-12 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white common-userPaneFamily input-error'
                              : 'mb-4 block w-full px-3 py-1 text-sm focus:outline-none dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-12 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white common-userPaneFamily'
                          }
                          type={visiblity ? 'text' : 'password'}
                          name="newPassword"
                          placeholder="New Password"
                          value={values.newPassword}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <div
                          className="showHidePassword"
                          style={{ top: '18%', left: '91%' }}
                          onClick={() => setVisiblity(!visiblity)}
                        >
                          {!visiblity ? <VisibilityOffIcon /> : <VisibilityIcon />}
                        </div>
                        {touched.newPassword && errors.newPassword && (
                          <div className="error-message" style={{ marginTop: '-12px', marginBottom: '10px' }}>
                            {errors.newPassword}
                          </div>
                        )}
                      </div>
                      <div className="flex justify-center">
                        <button
                          className="w-full px-4 py-2 font-bold text-white bg-blue-500  hover:bg-blue-700 focus:outline-none focus:shadow-outline w-3/4 px-4 py-2 font-bold align-bottom flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-bold focus:outline-none px-4 py-2 rounded-lg text-sm text-white bg-orange-500 border border-transparent active:bg-orange-600 hover:bg-orange-600 focus:ring focus:ring-orange-300 mt-4 h-12 w-3/4 common-userPaneFamily  login-button shadow-md shadow-orange-500/20 hover:shadow-lg hover:shadow-orange-500/40 active:opacity-[0.85]  common-borderRadius"
                          type="submit"
                        >
                          Change Password
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default ChangePasswordPage;

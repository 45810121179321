import {
  Box,
  Button,
  Card,
  Flex,
  Grid,
  Image,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React, { Component, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  IoIosArrowBack,
  IoIosArrowForward,
  IoMdThermometer,
} from "react-icons/io";
import Slider from "react-slick";
import noImage from "../../../assets/noImageAvailable.jpg";
import { useDispatch, useSelector } from "react-redux";
import { addtocart } from "../../../actions/CartActions";
import { ToastContainer, toast } from "react-toastify";
import LoginModal from "../../../components/login-modal/LoginModal";
import { notificationStyle } from "../../../pages/product-list/ProductListing";

function SampleNextArrow({ onClick }) {
  return (
    <Box
      fontSize={{ base: "20px", sm: "25px", md: "30px", lg: "30px" }}
      padding={{
        base: "2px 2px",
        sm: "3px 5px",
        md: "4px 8px",
        lg: "11px 12px",
      }}
      // borderRadius="50%"
      color="black"
      // boxShadow="0 0 5px 3px #ccc"
      display="flex"
      width={{ base: "20px", sm: "30px", md: "40px", lg: "54px" }}
      position="absolute"
      top="33%"
      right={"-26px"}
      // backgroundColor="white"
      className="arrow arrow-right"
      onClick={onClick}
      cursor={"pointer"}
    >
      <IoIosArrowForward />
    </Box>
  );
}
function SamplePrevArrow({ onClick }) {
  return (
    <Box
      fontSize={{ base: "20px", sm: "25px", md: "30px", lg: "30px" }}
      padding={{
        base: "2px 2px",
        sm: "3px 5px",
        md: "4px 8px",
        lg: "11px 12px",
      }}
      // borderRadius="50%"
      color="black"
      // boxShadow="0 0 5px 3px #ccc"
      // border={'1px solid lightgray'}
      display="flex"
      width={{ base: "20px", sm: "30px", md: "40px", lg: "54px" }}
      position="absolute"
      top="33%"
      left={"-26px"}
      // backgroundColor="white"
      cursor={"pointer"}
      className="arrow arrow-left"
      zIndex={1}
      onClick={onClick}
    >
      <IoIosArrowBack />
    </Box>
  );
}
const NewProduct = ({ sliderData }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { homePageData } = useSelector((state) => state.home);
  const { userData, token } = useSelector((state) => state.user);

  const handleClick = (id) => {
    navigate(`/product-details/${id}`);
  };
  const handleCart = (userId, productId, quantity) => {
    if (token) {
      const data = {
        userId,
        quantity,
        productId,
      };
      dispatch(addtocart(data));

      toast.success("Item Added to cart", notificationStyle);
    } else {
      onOpen();
    }
  };
  const [settings, setSettings] = useState({
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 2,
    initialSlide: 0,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  });

  useEffect(() => {
    if (sliderData && sliderData.length) {
      setSettings({
        ...settings,
        slidesToShow:
          sliderData && !!sliderData?.length && sliderData?.length >= 4
            ? 4
            : sliderData?.length,
      });
    }
  }, [sliderData && sliderData.length]);

  return (
    <>
      <Box
        marginTop={{ base: 6, sm: 7, md: 10, lg: 20 }}
        marginBottom={{ base: "2rem", sm: "3rem", md: "4rem", lg: "4rem" }}
        // height={{ md: "70vh" }}
        width={
          sliderData && sliderData.length && sliderData.length < 4 ? "70%" : ""
        }
        margin={
          sliderData && sliderData.length && sliderData.length < 4 ? "auto" : ""
        }
      >
        <Slider {...settings} style={{ height: "100%" }} height="100%">
          {sliderData &&
            sliderData.length &&
            sliderData?.map((item, i) => (
              <Box key={i} className="slider-boxShadow" height={"100%"}>
                {item?.ProductImages && item?.ProductImages?.length ? (
                  <Image
                    src={`${process.env.REACT_APP_IMAGE_URL}${item.ProductImages[0].image_url}`} // 291 * 291
                    width={"90%"}
                    margin="auto"
                    cursor={"pointer"}
                    className="watchImage-effect"
                    onClick={() => handleClick(item.slug)}
                  />
                ) : (
                  <Image
                    src={noImage}
                    width={"90%"}
                    margin="auto"
                    cursor={"pointer"}
                    className="watchImage-effect"
                    onClick={() => handleClick(item.slug)}
                  />
                )}

                <Text
                  className="watch-details"
                  onClick={() => navigate(`/product-details/${item.slug}`)}
                >
                  {item.name}
                </Text>
                <Flex
                  display={{ base: "grid", sm: "grid", md: "flex" }}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  {item.discount !== null && item.discount !== "" ? (
                    <>
                      <Text
                        className="discount-priceText"
                        paddingLeft={"5px"}
                        onClick={() => handleClick(item.slug)}
                      >
                        <Grid>
                          <span>
                            ₹
                            {(
                              Number(item.price) -
                              (Number(item.price) * Number(item.discount)) / 100
                            ).toFixed(2)}
                          </span>
                          <span className="new-product-item-price">
                            ₹{Number(item.price).toFixed(2)}
                          </span>
                        </Grid>
                      </Text>
                    </>
                  ) : (
                    <>
                      <Text
                        className="watch-price"
                        paddingLeft={"5px"}
                        onClick={() => handleClick(item.slug)}
                      >
                        <span>₹</span>
                        <span>{item.price}</span>
                      </Text>
                    </>
                  )}
                  <Button
                    className="cart-btn"
                    size="sm"
                    background={'transparent'}
                    onClick={() =>
                      handleCart(userData?.user_id, item.product_id, 1)
                    }
                  >
                    {homePageData &&
                      homePageData?.setting &&
                      homePageData?.setting?.addToCart
                      ? homePageData?.setting?.addToCart
                      : "Add To Cart"}
                  </Button>
                </Flex>
              </Box>
            ))}
        </Slider>
      </Box>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <LoginModal isOpen={isOpen} onClose={onClose} />
    </>
  );
};

export default NewProduct;

import React, { useEffect, useState } from 'react';
import './addProduct.css';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
// import { axiosInstance } from '../../axios';
import axios from 'axios';
import SunEditor from 'suneditor-react';
import Notification from '../notification';
import axiosInstance from '../../axios';
import 'suneditor/dist/css/suneditor.min.css';

function Product({ setOpenDrawer, addEdit, productListAPI, tableId }) {
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
  const Navigate = useNavigate();
  const [products, setProducts] = useState({
    name: '',
    slug: '',
    // description: '',
    short_description: '',
    price: null,
    discount: null,
    is_new: false,
    is_best: false,
    discounted_price: null,
    quantity: null,
    category_id: null,
    bandsize: null,
    colour: null,
    displaytype: null,
    dialcolour: null,
    casematerial: null,
    casediameter: null,
    bezelmaterial: null,
    bezelfunction: null,
    bandwidth: null,
    bandmaterial: null,
    bandcolour: null,
    movementtype: null,
    warrantytype: null,
    warrantydescription: null,
    caseshape: null,
    gender: null,
    specification_image: '',
  });
  const validationSchema = Yup.object({
    name: Yup.string().required('Product Name is required').trim(),
    slug: Yup.string().required('Product Slug is required').trim(),
    // description: Yup.string().required('Product Description is required').trim(),
    short_description: Yup.string().required('Short Description is required').trim(),
    price: Yup.string().required('Product Price is required').trim(),
    category_id: Yup.string().required('Category is required').trim(),
    discount: Yup.number().min(1).max(100).nullable(),
    quantity: Yup.string().required('Product Quantity is required').trim(),
  });
  const [categoryList, setCategoryList] = useState([]);
  const [productImage, setProductImage] = useState([]);
  const [productImageId, setProductImageId] = useState('');
  const [selectedImages, setSelectedImages] = useState([]);
  const [specificationImage, setSpecificationImage] = useState([]);
  const [prevSpecificationImage, setPrevSpecificationImage] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [descriptionData, setDescriptionData] = useState('');

  // handle image upload
  const handleImageUpload = async (event) => {
    const { files } = event.target;
    const imagesArray = [];
    const previewArray = [];

    for (let i = 0; i < files.length; i += 1) {
      const element = files[i];
      imagesArray.push(element);
      previewArray.push({ id: null, image: URL.createObjectURL(element), is_video: element.type.startsWith('video/') });
    }
    setProductImage([...productImage, ...imagesArray]);
    setSelectedImages([...selectedImages, ...previewArray]);
  };

  const handleSpecImageUpload = async (event) => {
    const { files } = event.target;
    const element = files[0];
    const imagesArray = element;
    const previewArray = { id: null, specification_image: URL.createObjectURL(element) };
    setSpecificationImage([imagesArray]);
    setPrevSpecificationImage([previewArray]);
  };
  const generateSlug = (productName) => productName.toLowerCase().replace(/[\s\W-]+/g, '-');

  const handleProductSubmit = async (values, resetForm) => {
    if (addEdit && addEdit !== 'edit') {
      let imagename;
      if (specificationImage[0]) {
        const formData = new FormData();
        // Object.values(specificationImage).forEach((file) => {
        formData.append('image', specificationImage[0]);
        // });
        const image = await axios.post(`${process.env.REACT_APP_BASE_URL}/common/upload`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        imagename = image.data.data;
      } else {
        imagename = '';
      }
      if (productImage.length) {
        if (descriptionData) {
          const response = await axiosInstance(`/product/`, 'POST', {
            ...values,
            description: descriptionData,
            specification_image: imagename,
            slug: generateSlug(values.slug),
          });
          if (response.status === 200) {
            const formData = new FormData();
            Object.values(productImage).forEach((file) => {
              formData.append('images', file);
            });
            await axios.post(
              `${process.env.REACT_APP_BASE_URL}/product/upload/${response.data.data.product_id}`,
              formData,
              {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              }
            );
            setProducts({
              name: '',
              slug: '',
              description: '',
              short_description: '',
              price: null,
              discount: null,
              is_new: false,
              is_best: false,
              discounted_price: null,
              quantity: null,
              category_id: null,
              bandsize: null,
              colour: null,
              displaytype: null,
              dialcolour: null,
              casematerial: null,
              casediameter: null,
              bezelmaterial: null,
              bezelfunction: null,
              bandwidth: null,
              bandmaterial: null,
              bandcolour: null,
              movementtype: null,
              warrantytype: null,
              warrantydescription: null,
              caseshape: null,
              gender: null,
              specification_image: '',
            });
            setDescriptionData('');
            setProductImage([]);
            setSelectedImages([]);
            setSpecificationImage([]);
            setPrevSpecificationImage([]);
            setOpenDrawer(false);
            await Notification('success', 'Add Product', response.data.message, 2000);
            await productListAPI();
          } else {
            await Notification('error', 'Add Product', response.data.message, 2000);
          }
        } else {
          await Notification('error', 'Edit Product', 'Product Description is required', 2000);
        }
      } else {
        await Notification('error', 'Edit Product', 'Product Image is required', 2000);
      }
    }
  };
  const handleOpenDeleteModal = async (index, id) => {
    if (id === null) {
      const updatedImages = [...selectedImages];
      updatedImages.splice(index, 1);
      setSelectedImages(updatedImages);
      setProductImage(updatedImages);
    } else {
      setOpenDialog(true);
      setProductImageId(id);
    }
  };
  const handleOpenspecDeleteModal = async (index, name) => {
    // if (addEdit && addEdit === 'edit') {
    const updatedImages = [...specificationImage];
    updatedImages.splice(index, 1);
    setSpecificationImage(updatedImages);
    setPrevSpecificationImage(updatedImages);
    // } else {
    //   const updatedImages = [...specificationImage];
    //   updatedImages.splice(index, 1);
    //   setSpecificationImage(updatedImages);
    //   setPrevSpecificationImage(updatedImages);
    // }
  };
  const handleCloseDeleteModal = () => {
    setOpenDialog(false);
  };
  const handleDeleteImage = async () => {
    const updatedImages = [...selectedImages];
    const index = selectedImages.findIndex((item) => item.id === productImageId);
    updatedImages.splice(index, 1);
    setSelectedImages(updatedImages);
    setProductImage(updatedImages);
    const response = await axiosInstance(
      `${process.env.REACT_APP_BASE_URL}/product/image-delete/${productImageId}`,
      'DELETE',
      null
    );
    if (response.status === 200) {
      Notification('success', 'Delete Image', response.data.message, 2000);
      setOpenDialog(false);
      // await userListAPI();
    } else {
      Notification('error', 'Delete Image', response.data.message, 2000);
    }
  };
  // get category list
  useEffect(() => {
    // product list get by id
    if (addEdit && addEdit === 'edit') {
      const fetchData = async () => {
        const res = await axiosInstance(`/product/${tableId}`, 'GET', null);
        if (res.status === 200) {
          if (res.data.data) {
            const data = res.data.data.product;
            const allproductImages = data.ProductImages.map((item) => ({
              id: item.product_image_id,
              is_video: item.is_video,
              image: `${process.env.REACT_APP_IMAGE_URL}${item.image_url}`,
            }));
            if (data.specification_image && data.specification_image !== '') {
              const specificationImagesData = {
                id: data.product_id,
                specification_image: `${process.env.REACT_APP_IMAGE_URL}${data.specification_image}`,
              };
              // setSpecificationImage([specificationImages]);
              setPrevSpecificationImage([specificationImagesData]);
            }
            setProducts({
              name: data.name,
              slug: data.slug,
              // description: data.description,
              short_description: data.short_description,
              price: data.price,
              discount: data.discount,
              is_new: data.is_new,
              is_best: data.is_best,
              discounted_price: data.discounted_price,
              quantity: data.quantity,
              bandsize: data.bandsize,
              category_id: data.category_id,
              colour: data.colour,
              displaytype: data.displaytype,
              dialcolour: data.dialcolour,
              casematerial: data.casematerial,
              casediameter: data.casediameter,
              bezelmaterial: data.bezelmaterial,
              bezelfunction: data.bezelfunction,
              bandwidth: data.bandwidth,
              bandmaterial: data.bandmaterial,
              bandcolour: data.bandcolour,
              movementtype: data.movementtype,
              warrantytype: data.warrantytype,
              warrantydescription: data.warrantydescription,
              caseshape: data.caseshape,
              gender: data.gender,
            });
            setDescriptionData(data.description);
            setProductImage(...productImage, allproductImages);
            setSelectedImages(...selectedImages, allproductImages);
          }
        }
      };
      fetchData();
    }
    // Category list
    const fetchData = async () => {
      const res = await axiosInstance(`/category/`, 'GET', null);
      if (res.status === 200) {
        setCategoryList(res.data.data);
        // Notification('success', 'Category List', res.data.message, 2000);
      } else {
        // Notification('error', 'Category List', res.data.message, 2000);
      }
    };
    fetchData();
  }, []);

  const handleUpdate = async (values) => {
    let imagename;
    if (specificationImage[0]) {
      const formData = new FormData();
      // Object.values(specificationImage).forEach((file) => {
      formData.append('image', specificationImage[0]);
      // });
      const image = await axios.post(`${process.env.REACT_APP_BASE_URL}/common/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      imagename = image.data.data;
    }

    if (productImage.length) {
      const response = await axiosInstance(`/product/${tableId}`, 'PUT', {
        ...values,
        description: descriptionData,
        specification_image: imagename,
        slug: generateSlug(values.slug),
      });
      if (response.status === 200) {
        const formData = new FormData();
        Object.values(productImage).forEach((file) => {
          formData.append('images', file);
        });
        await axios.post(`${process.env.REACT_APP_BASE_URL}/product/upload/${tableId}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        setOpenDrawer(false);
        setProducts({
          name: '',
          slug: '',
          // description: '',
          short_description: '',
          price: null,
          discount: null,
          is_new: false,
          is_best: false,
          discounted_price: null,
          quantity: null,
          category_id: null,
          bandsize: null,
          colour: null,
          displaytype: null,
          dialcolour: null,
          casematerial: null,
          casediameter: null,
          bezelmaterial: null,
          bezelfunction: null,
          bandwidth: null,
          bandmaterial: null,
          bandcolour: null,
          movementtype: null,
          warrantytype: null,
          warrantydescription: null,
          caseshape: null,
          gender: null,
        });
        setDescriptionData('');
        setOpenDrawer(false);
        setProductImage([]);
        setSelectedImages([]);
        setSpecificationImage([]);
        setPrevSpecificationImage([]);
        await productListAPI();
        await Notification('success', 'Edit Product', response.data.message, 2000);
      } else {
        await Notification('error', 'Edit Product', response.data.message, 2000);
      }
    } else {
      await Notification('error', 'Edit Product', 'Product Image is required', 2000);
    }
  };

  const defaultFonts = [
    'Arial',
    'Comic Sans MS',
    'Courier New',
    'Impact',
    'Georgia',
    'Tahoma',
    'Trebuchet MS',
    'Verdana',
  ];
  const sortedFontOptions = [
    'Logical',
    'Salesforce Sans',
    'Garamond',
    'Sans-Serif',
    'Serif',
    'Times New Roman',
    'Helvetica',
    ...defaultFonts,
  ].sort();
  return (
    <div>
      <button
        className="fixed focus:outline-none z-50 text-red-500 hover:bg-red-100 hover:text-gray-700 transition-colors duration-150 bg-white shadow-md mr-6 mt-2 right-0 left-auto w-8 h-8 rounded-full block text-center z-30 mr-7 "
        onClick={() => setOpenDrawer(false)}
      >
        <svg
          stroke="currentColor"
          fill="none"
          strokeWidth="2"
          viewBox="0 0 24 24"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="mx-auto"
          height="1em"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
        >
          <line x1="18" y1="6" x2="6" y2="18" />
          <line x1="6" y1="6" x2="18" y2="18" />
        </svg>
      </button>
      <div className="w-full relative p-border-gray-100 bg-gray-50 border-gray-700 bg-white-800 text-black-300 model-title">
        <div>
          {addEdit && addEdit === 'edit' ? (
            <>
              <h4 className="text-xl font-medium">Edit Product</h4>
              <p className="mb-0 text-sm">Edit your product and necessary information from here</p>
            </>
          ) : (
            <>
              <h4 className="text-xl font-medium">Add Product</h4>
              <p className="mb-0 text-sm">Add your product and necessary information from here</p>
            </>
          )}
        </div>
      </div>
      <div className="mt-12 flex flex-col w-full h-full justify-between">
        <div
          className="w-full md:w-7/12 lg:w-8/12 xl:w-8/12 relative bg-white-700 text-gray-200"
          style={{ position: 'relative', overflow: 'hidden', width: '100%', height: '100%' }}
        >
          <div
            style={{
              // position: 'absolute',
              inset: '0px',
              overflow: 'scroll',
              marginRight: '-21px',
              marginBottom: '-21px',
            }}
          >
            <Formik
              enableReinitialize
              initialValues={products}
              validationSchema={validationSchema}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                handleProductSubmit(values, resetForm);
                // resetForm();
                // setSubmitting(false);
              }}
            >
              {({ values, errors, touched, handleBlur, handleChange, handleSubmit, isValid, isSubmitting }) => (
                <Form className="block" onSubmit={handleSubmit}>
                  <div className="px-6 pt-8 flex-grow w-full h-full max-h-full pb-40 md:pb-32 lg:pb-32 xl:pb-32">
                    <div className="flex grid-cols-6 gap-3 md:gap-5 xl:gap-6 lg:gap-6 mb-8 formLabel">
                      <label className="block text-sm text-gray-700 dark:text-gray-400 col-span-4 sm:col-span-2 font-medium text-sm">
                        Product Image <span className="required-field">*</span>
                      </label>
                      <div className="file-input col-span-8 sm:col-span-4 formInput">
                        <input
                          type="file"
                          multiple
                          className="block w-full px-3 py-1 text-sm focus:outline-none dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white"
                          onChange={(e) => handleImageUpload(e)}
                          // onBlur={handleBlur}
                        />
                        <span className="button">Choose File</span>
                        {/* <label className="label">No file selected</label> */}
                      </div>
                      {/* {!productImage.length && <div className="error-message">Product Image is required</div>} */}
                    </div>
                    <div className="col-span-8 flex images">
                      {selectedImages && selectedImages.length
                        ? selectedImages.map((image, index) => (
                            <div className="image-upload">
                              <span onClick={() => handleOpenDeleteModal(index, image.id)}>
                                <HighlightOffIcon className="cancel-images" />
                              </span>
                              <div className="set-image">
                                {!image.is_video ? (
                                  <img key={index} src={image.image} alt="" height={'65px'} width={'100px'} />
                                ) : (
                                  <video key={index} controls height={'65px'} width={'100px'}>
                                    <source src={image.image} type="video/mp4" />
                                    <track kind="captions" src={image.image} srcLang="en" label="English" />
                                    Your browser does not support the video tag.
                                  </video>
                                )}
                              </div>
                            </div>
                          ))
                        : ''}
                    </div>
                    <div className="flex grid-cols-6 gap-3 md:gap-5 xl:gap-6 lg:gap-6 mb-8 formLabel">
                      <label className="block text-sm text-gray-700 dark:text-gray-400 col-span-4 sm:col-span-2 font-medium text-sm">
                        Specifiactaion Image
                      </label>
                      <div className="file-input col-span-8 sm:col-span-4 formInput">
                        <input
                          type="file"
                          className="block w-full px-3 py-1 text-sm focus:outline-none dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white"
                          onChange={(e) => handleSpecImageUpload(e)}
                          // onBlur={handleBlur}
                        />
                        <span className="button">Choose File</span>
                      </div>
                    </div>
                    <div className="col-span-8 flex images">
                      {prevSpecificationImage.length
                        ? prevSpecificationImage
                            .filter((i) => i.specification_image !== '')
                            .map((image, index) => (
                              <div className="image-upload" key={index} style={{ position: 'relative' }}>
                                <span onClick={() => handleOpenspecDeleteModal(index, image.specification_image)}>
                                  <HighlightOffIcon className="cancel-images" />
                                </span>
                                <div className="set-image">
                                  <img
                                    key={index}
                                    src={image.specification_image}
                                    alt=""
                                    height={'65px'}
                                    width={'100px'}
                                  />
                                </div>
                              </div>
                            ))
                        : ''}
                    </div>
                    <div className="flex grid-cols-6 gap-3 md:gap-5 xl:gap-6 lg:gap-6 mb-6 formLabel">
                      <label className="block text-sm text-gray-700 dark:text-gray-400 col-span-4 sm:col-span-2 font-medium text-sm">
                        Product Name <span className="required-field">*</span>
                      </label>
                      <div className="col-span-8 sm:col-span-4 formInput">
                        <input
                          className={
                            errors.name && touched.name
                              ? 'block w-full px-3 py-1 text-sm focus:outline-none text-black dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white input-error'
                              : 'block w-full px-3 py-1 text-sm focus:outline-none text-black dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white'
                          }
                          type="text"
                          name="name"
                          placeholder="Product Name"
                          value={values.name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {touched.name && errors.name && <div className="error-message">{errors.name}</div>}
                      </div>
                    </div>
                    <div className="flex grid-cols-6 gap-3 md:gap-5 xl:gap-6 lg:gap-6 mb-6 formLabel">
                      <label className="block text-sm text-gray-700 dark:text-gray-400 col-span-4 sm:col-span-2 font-medium text-sm">
                        Product Slug <span className="required-field">*</span>
                      </label>
                      <div className="col-span-8 sm:col-span-4 formInput">
                        <input
                          className={
                            errors.slug && touched.slug
                              ? 'block w-full px-3 py-1 text-sm focus:outline-none dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-black text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white input-error'
                              : 'block w-full px-3 py-1 text-sm focus:outline-none dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 text-black dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white'
                          }
                          type="text"
                          name="slug"
                          placeholder="Product Slug"
                          value={generateSlug(values.slug)}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {touched.slug && errors.slug && <div className="error-message">{errors.slug}</div>}
                      </div>
                    </div>
                    <div className="flex grid-cols-6 gap-3 md:gap-5 xl:gap-6 lg:gap-6 mb-6 formLabel">
                      <label className="block text-sm text-gray-700 dark:text-gray-400 col-span-4 sm:col-span-2 font-medium text-sm">
                        Product Description <span className="required-field">*</span>
                      </label>
                      <div className="col-span-8 sm:col-span-4 formInput">
                        <SunEditor
                          name="description"
                          height="100%"
                          setContents={descriptionData}
                          // value={values.description}
                          onChange={setDescriptionData}
                          onBlur={handleBlur}
                          setOptions={{
                            height: '100%',
                            buttonList: [
                              ['undo', 'redo'],
                              ['font', 'fontSize'],
                              // ['paragraphStyle', 'blockquote'],
                              ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
                              ['fontColor', 'hiliteColor'],
                              ['align', 'list', 'lineHeight'],
                              ['outdent', 'indent'],

                              ['table', 'horizontalRule', 'link', 'image', 'video'],
                              // ['math'] //You must add the 'katex' library at options to use the 'math' plugin.
                              // ['imageGallery'], // You must add the "imageGalleryUrl".
                              // ["fullScreen", "showBlocks", "codeView"],
                              ['preview', 'print'],
                              ['removeFormat'],

                              // ['save', 'template'],
                              // '/', Line break
                            ], // Or Array of button list, eg. [['font', 'align'], ['image']]
                            defaultTag: 'div',
                            minHeight: '300px',
                            showPathLabel: false,
                            font: sortedFontOptions,
                          }}
                          className="desc-input"
                        />
                        {touched.description && errors.description && (
                          <div className="error-message">{errors.description}</div>
                        )}
                      </div>
                    </div>

                    <div className="flex grid-cols-6 gap-3 md:gap-5 xl:gap-6 lg:gap-6 mb-6 formLabel">
                      <label className="block text-sm text-gray-700 dark:text-gray-400 col-span-4 sm:col-span-2 font-medium text-sm">
                        Product Short Description<span className="required-field">*</span>
                      </label>
                      <div className="col-span-8 sm:col-span-4 formInput">
                        <input
                          className={
                            errors.short_description && touched.short_description
                              ? 'block w-full px-3 py-1 text-sm text-black focus:outline-none dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white input-error'
                              : 'block w-full px-3 py-1 text-sm text-black focus:outline-none dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white'
                          }
                          type="text"
                          name="short_description"
                          placeholder="Short Description"
                          value={values.short_description}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {touched.short_description && errors.short_description && (
                          <div className="error-message">{errors.short_description}</div>
                        )}
                      </div>
                    </div>

                    <div className="flex grid-cols-6 gap-3 md:gap-5 xl:gap-6 lg:gap-6 mb-6 formLabel">
                      <label className="block text-sm text-gray-700 dark:text-gray-400 col-span-4 sm:col-span-2 font-medium text-sm">
                        Product Price <span className="required-field">*</span>
                      </label>
                      <div className="col-span-8 sm:col-span-4 formInput">
                        <input
                          className={
                            errors.price && touched.price
                              ? 'block w-full px-3 py-1 text-sm text-black focus:outline-none dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white input-error'
                              : 'block w-full px-3 py-1 text-sm text-black focus:outline-none dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white'
                          }
                          type="number"
                          onWheel={(e) => e.target.blur()}
                          name="price"
                          placeholder="Price"
                          value={values.price}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {touched.price && errors.price && <div className="error-message">{errors.price}</div>}
                      </div>
                    </div>
                    <div className="flex grid-cols-6 gap-3 md:gap-5 xl:gap-6 lg:gap-6 mb-6 formLabel">
                      <label className="block text-sm text-gray-700 dark:text-gray-400 col-span-4 sm:col-span-2 font-medium text-sm">
                        Discount
                      </label>
                      <div className="col-span-8 sm:col-span-4 formInput">
                        <input
                          className={
                            touched.discount && errors.discount
                              ? 'block w-full px-3 py-1 text-sm text-black focus:outline-none dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white input-error'
                              : 'block w-full px-3 py-1 text-sm text-black focus:outline-none dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white'
                          }
                          type="number"
                          onWheel={(e) => e.target.blur()}
                          name="discount"
                          placeholder="discount"
                          value={values.discount}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {touched.discount && errors.discount && <div className="error-message">{errors.discount}</div>}
                      </div>
                    </div>
                    <div className="flex grid-cols-6 gap-3 md:gap-5 xl:gap-6 lg:gap-6 mb-6 formLabel">
                      <label className="block text-sm text-gray-700 dark:text-gray-400 col-span-4 sm:col-span-2 font-medium text-sm">
                        Discount Price
                      </label>
                      <div className="col-span-8 sm:col-span-4 formInput">
                        <input
                          className="block w-full px-3 py-1 text-sm text-black focus:outline-none dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white"
                          type="number"
                          onWheel={(e) => e.target.blur()}
                          name="discounted_price"
                          placeholder="discount price"
                          value={values.price - (values.price * values.discount) / 100}
                          disabled
                          // onChange={handleChange}
                          // onBlur={handleBlur}
                        />
                      </div>
                    </div>
                    <div className="flex grid-cols-6 gap-3 md:gap-5 xl:gap-6 lg:gap-6 mb-6 formLabel">
                      <label className="block text-sm text-gray-700 dark:text-gray-400 col-span-4 sm:col-span-2 font-medium text-sm">
                        Category Type <span className="required-field">*</span>
                      </label>
                      <div className="col-span-8 sm:col-span-4 formInput">
                        <div className="relative">
                          <svg
                            className="w-2 h-2 absolute top-0 right-0 m-4 pointer-events-none"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 412 232"
                          />
                          <select
                            className={
                              errors.category_id && touched.category_id
                                ? 'col-span-8 sm:col-span-4 border text-black border-gray-300 rounded-md  h-10 pl-5 pr-10 bg-gray-100 hover:border-gray-400 focus:outline-none appearance-none w-full text-sm dark:text-gray-300 rounded-md dark:focus:border-gray-600 dark:bg-gray-700 dark:focus:ring-gray-300 focus:ring focus:ring-green-300 border text-sm focus:outline-none block w-full bg-gray-100 border-transparent focus:bg-white input-error'
                                : 'col-span-8 sm:col-span-4 border text-black border-gray-300 rounded-md  h-10 pl-5 pr-10 bg-gray-100 hover:border-gray-400 focus:outline-none appearance-none w-full text-sm dark:text-gray-300 rounded-md dark:focus:border-gray-600 dark:bg-gray-700 dark:focus:ring-gray-300 focus:ring focus:ring-green-300 border text-sm focus:outline-none block w-full bg-gray-100 border-transparent focus:bg-white'
                            }
                            name="category_id"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.category_id}
                          >
                            <option>Choose a Category</option>
                            {categoryList.map((item) => (
                              <option value={item.category_id} key={item.category_id}>
                                {item.category_name}
                              </option>
                            ))}
                          </select>
                          {touched.category_id && errors.category_id && (
                            <div className="error-message">{errors.category_id}</div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="flex grid-cols-6 gap-3 md:gap-5 xl:gap-6 lg:gap-6 mb-6 formLabel">
                      <label className="block text-sm text-gray-700 dark:text-gray-400 col-span-4 sm:col-span-2 font-medium text-sm">
                        Quantity <span className="required-field">*</span>
                      </label>
                      <div className="col-span-8 sm:col-span-4 formInput">
                        <input
                          className={
                            errors.price && touched.price
                              ? 'block w-full px-3 py-1 text-sm text-black focus:outline-none dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white input-error'
                              : 'block w-full px-3 py-1 text-sm text-black focus:outline-none dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white'
                          }
                          type="number"
                          onWheel={(e) => e.target.blur()}
                          name="quantity"
                          placeholder="Quantity"
                          value={values.quantity}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {touched.quantity && errors.quantity && <div className="error-message">{errors.quantity}</div>}
                      </div>
                    </div>
                    <div>
                      <label className="block text-sm text-gray-700 dark:text-gray-400 col-span-4 sm:col-span-2 font-medium text-md">
                        Product Specification
                      </label>
                      <div className="flex flex-wrap -mx-3 mb-6 mt-6">
                        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                          <label
                            className="block text-sm text-gray-700 dark:text-gray-400 col-span-4 sm:col-span-2 font-medium text-sm"
                            htmlFor="grid-first-name"
                          >
                            Gender
                          </label>
                          <select
                            className={
                              'col-span-8 sm:col-span-4 border text-black border-gray-300 rounded-md  h-10 pl-5 pr-10 bg-gray-100 hover:border-gray-400 focus:outline-none appearance-none w-full text-sm dark:text-gray-300 rounded-md dark:focus:border-gray-600 dark:bg-gray-700 dark:focus:ring-gray-300 focus:ring focus:ring-green-300 border text-sm focus:outline-none block w-full bg-gray-100 border-transparent focus:bg-white'
                            }
                            name="gender"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.gender}
                          >
                            <option>Choose a Gender</option>
                            <option value="Couple">Couple</option>
                            <option value="Women">Women</option>
                            <option value="Men">Men</option>
                            <option value="Boys">Boys</option>
                            <option value="Men & Women">Men & Women</option>
                            <option value="Girls">Girls</option>
                            <option value="Boys & Girls">Boys & Girls</option>
                          </select>
                        </div>
                        <div className="w-full md:w-1/2 px-3">
                          <label
                            className="block text-sm text-gray-700 dark:text-gray-400 col-span-4 sm:col-span-2 font-medium text-sm"
                            htmlFor="grid-last-name"
                          >
                            Case Shape
                          </label>
                          <select
                            className={
                              'col-span-8 sm:col-span-4 border text-black border-gray-300 rounded-md  h-10 pl-5 pr-10 bg-gray-100 hover:border-gray-400 focus:outline-none appearance-none w-full text-sm dark:text-gray-300 rounded-md dark:focus:border-gray-600 dark:bg-gray-700 dark:focus:ring-gray-300 focus:ring focus:ring-green-300 border text-sm focus:outline-none block w-full bg-gray-100 border-transparent focus:bg-white'
                            }
                            name="caseshape"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.caseshape}
                          >
                            <option>Choose a Case Shape</option>
                            <option value="Round">Round</option>
                            <option value="Square">Square</option>
                            <option value="Oval">Oval</option>
                            <option value="Rectangle">Rectangle</option>
                            <option value="Tonneau">Tonneau</option>
                            <option value="Cushion">Cushion</option>
                            <option value="Avantgarde">Avantgarde</option>
                            <option value="Carage">Carage</option>
                            <option value="Tank">Tank</option>
                            <option value="Asymmetrical">Asymmetrical</option>
                            <option value="Carre">Carre</option>
                          </select>
                        </div>
                      </div>
                      <div className="flex flex-wrap -mx-3 mb-6 mt-6">
                        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                          <label
                            className="block text-sm text-gray-700 dark:text-gray-400 col-span-4 sm:col-span-2 font-medium text-sm"
                            htmlFor="grid-first-name"
                          >
                            Warranty Description
                          </label>
                          <input
                            className="block w-full px-3 py-1 text-sm text-black focus:outline-none dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white"
                            id="grid-first-name"
                            type="text"
                            name="warrantydescription"
                            placeholder="Warranty Description"
                            value={values.warrantydescription}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="w-full md:w-1/2 px-3">
                          <label
                            className="block text-sm text-gray-700 dark:text-gray-400 col-span-4 sm:col-span-2 font-medium text-sm"
                            htmlFor="grid-last-name"
                          >
                            Warranty Type
                          </label>
                          <input
                            className="block w-full px-3 py-1 text-sm text-black focus:outline-none dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white"
                            id="grid-last-name"
                            type="text"
                            name="warrantytype"
                            placeholder="Warranty Type"
                            value={values.warrantytype}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="flex flex-wrap -mx-3 mb-8 mt-6">
                        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                          <label
                            className="block text-sm text-gray-700 dark:text-gray-400 col-span-4 sm:col-span-2 font-medium text-sm"
                            htmlFor="grid-first-name"
                          >
                            Movement Type
                          </label>
                          <input
                            className="block w-full px-3 py-1 text-sm text-black focus:outline-none dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white"
                            id="grid-first-name"
                            type="text"
                            name="movementtype"
                            placeholder="Movement Type"
                            value={values.movementtype}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="w-full md:w-1/2 px-3">
                          <label
                            className="block text-sm text-gray-700 dark:text-gray-400 col-span-4 sm:col-span-2 font-medium text-sm"
                            htmlFor="grid-last-name"
                          >
                            band Colour
                          </label>
                          <input
                            className="block w-full px-3 py-1 text-sm text-black focus:outline-none dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white"
                            id="grid-last-name"
                            type="text"
                            name="bandcolour"
                            placeholder="Band Colour"
                            value={values.bandcolour}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="flex flex-wrap -mx-3 mb-8 mt-6">
                        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                          <label
                            className="block text-sm text-gray-700 dark:text-gray-400 col-span-4 sm:col-span-2 font-medium text-sm"
                            htmlFor="grid-first-name"
                          >
                            Band Material
                          </label>
                          <select
                            className={
                              'col-span-8 sm:col-span-4 border text-black border-gray-300 rounded-md  h-10 pl-5 pr-10 bg-gray-100 hover:border-gray-400 focus:outline-none appearance-none w-full text-sm dark:text-gray-300 rounded-md dark:focus:border-gray-600 dark:bg-gray-700 dark:focus:ring-gray-300 focus:ring focus:ring-green-300 border text-sm focus:outline-none block w-full bg-gray-100 border-transparent focus:bg-white'
                            }
                            name="bandmaterial"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.bandmaterial}
                          >
                            <option>Choose a Band Material</option>
                            <option value="Leather">Leather</option>
                            <option value="Metal">Metal</option>
                            <option value="Stainless Steel">Stainless Steel</option>
                          </select>
                        </div>
                        <div className="w-full md:w-1/2 px-3">
                          <label
                            className="block text-sm text-gray-700 dark:text-gray-400 col-span-4 sm:col-span-2 font-medium text-sm"
                            htmlFor="grid-last-name"
                          >
                            band Width
                          </label>
                          <input
                            className="block w-full px-3 py-1 text-sm text-black focus:outline-none dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white"
                            id="grid-last-name"
                            type="text"
                            name="bandwidth"
                            placeholder="Band Width"
                            value={values.bandwidth}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="flex flex-wrap -mx-3 mb-8 mt-6">
                        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                          <label
                            className="block text-sm text-gray-700 dark:text-gray-400 col-span-4 sm:col-span-2 font-medium text-sm"
                            htmlFor="grid-first-name"
                          >
                            Bezel Function
                          </label>
                          <select
                            className={
                              'col-span-8 sm:col-span-4 border text-black border-gray-300 rounded-md  h-10 pl-5 pr-10 bg-gray-100 hover:border-gray-400 focus:outline-none appearance-none w-full text-sm dark:text-gray-300 rounded-md dark:focus:border-gray-600 dark:bg-gray-700 dark:focus:ring-gray-300 focus:ring focus:ring-green-300 border text-sm focus:outline-none block w-full bg-gray-100 border-transparent focus:bg-white'
                            }
                            name="bezelfunction"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.bezelfunction}
                          >
                            <option>Choose a Bezel Function</option>
                            <option value="Slide Rule">Slide Rule</option>
                          </select>
                        </div>
                        <div className="w-full md:w-1/2 px-3">
                          <label
                            className="block text-sm text-gray-700 dark:text-gray-400 col-span-4 sm:col-span-2 font-medium text-sm"
                            htmlFor="grid-last-name"
                          >
                            Bezel Material
                          </label>
                          <select
                            className={
                              'col-span-8 sm:col-span-4 border text-black border-gray-300 rounded-md  h-10 pl-5 pr-10 bg-gray-100 hover:border-gray-400 focus:outline-none appearance-none w-full text-sm dark:text-gray-300 rounded-md dark:focus:border-gray-600 dark:bg-gray-700 dark:focus:ring-gray-300 focus:ring focus:ring-green-300 border text-sm focus:outline-none block w-full bg-gray-100 border-transparent focus:bg-white'
                            }
                            name="bezelmaterial"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.bezelmaterial}
                          >
                            <option>Choose a Bezel Material</option>
                            <option value="Stainless Steel">Stainless Steel</option>
                          </select>
                        </div>
                      </div>

                      <div className="flex flex-wrap -mx-3 mb-8 mt-6">
                        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                          <label
                            className="block text-sm text-gray-700 dark:text-gray-400 col-span-4 sm:col-span-2 font-medium text-sm"
                            htmlFor="grid-first-name"
                          >
                            Case Diameter
                          </label>
                          <select
                            className={
                              'col-span-8 sm:col-span-4 border text-black border-gray-300 rounded-md  h-10 pl-5 pr-10 bg-gray-100 hover:border-gray-400 focus:outline-none appearance-none w-full text-sm dark:text-gray-300 rounded-md dark:focus:border-gray-600 dark:bg-gray-700 dark:focus:ring-gray-300 focus:ring focus:ring-green-300 border text-sm focus:outline-none block w-full bg-gray-100 border-transparent focus:bg-white'
                            }
                            name="casediameter"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.casediameter}
                          >
                            <option>Choose a Case Diameter</option>
                            <option value="46 mm">46 mm</option>
                            <option value="42 mm">42 mm</option>
                            <option value="40 mm">40 mm</option>
                            <option value="38 mm">38 mm</option>
                            <option value="36 mm">36 mm</option>
                            <option value="34 mm">34 mm</option>
                            <option value="32 mm">32 mm</option>
                            <option value="30 mm">30 mm</option>
                            <option value="28 mm">28 mm</option>
                            <option value="26 mm">26 mm</option>
                            <option value="24 mm">24 mm</option>
                            <option value="22 mm">22 mm</option>
                          </select>
                        </div>
                        <div className="w-full md:w-1/2 px-3">
                          <label
                            className="block text-sm text-gray-700 dark:text-gray-400 col-span-4 sm:col-span-2 font-medium text-sm"
                            htmlFor="grid-last-name"
                          >
                            Case Material
                          </label>
                          <select
                            className={
                              'col-span-8 sm:col-span-4 border text-black border-gray-300 rounded-md  h-10 pl-5 pr-10 bg-gray-100 hover:border-gray-400 focus:outline-none appearance-none w-full text-sm dark:text-gray-300 rounded-md dark:focus:border-gray-600 dark:bg-gray-700 dark:focus:ring-gray-300 focus:ring focus:ring-green-300 border text-sm focus:outline-none block w-full bg-gray-100 border-transparent focus:bg-white'
                            }
                            name="casematerial"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.casematerial}
                          >
                            <option>Choose a Case Material</option>
                            <option value="Brass">Brass</option>
                          </select>
                        </div>
                      </div>

                      <div className="flex flex-wrap -mx-3 mb-8 mt-6">
                        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                          <label
                            className="block text-sm text-gray-700 dark:text-gray-400 col-span-4 sm:col-span-2 font-medium text-sm"
                            htmlFor="grid-first-name"
                          >
                            Dial Colour
                          </label>
                          <input
                            className="block w-full px-3 py-1 text-sm text-black focus:outline-none dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white"
                            id="grid-first-name"
                            type="text"
                            name="dialcolour"
                            placeholder="Dial Colour"
                            value={values.dialcolour}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="w-full md:w-1/2 px-3">
                          <label
                            className="block text-sm text-gray-700 dark:text-gray-400 col-span-4 sm:col-span-2 font-medium text-sm"
                            htmlFor="grid-last-name"
                          >
                            Display Type
                          </label>
                          <select
                            className={
                              'col-span-8 sm:col-span-4 border text-black border-gray-300 rounded-md  h-10 pl-5 pr-10 bg-gray-100 hover:border-gray-400 focus:outline-none appearance-none w-full text-sm dark:text-gray-300 rounded-md dark:focus:border-gray-600 dark:bg-gray-700 dark:focus:ring-gray-300 focus:ring focus:ring-green-300 border text-sm focus:outline-none block w-full bg-gray-100 border-transparent focus:bg-white'
                            }
                            name="displaytype"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.displaytype}
                          >
                            <option>Choose a Display Type</option>
                            <option value="Analog">Analog</option>
                          </select>
                        </div>
                      </div>

                      <div className="flex flex-wrap -mx-3 mb-8 mt-6">
                        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                          <label
                            className="block text-sm text-gray-700 dark:text-gray-400 col-span-4 sm:col-span-2 font-medium text-sm"
                            htmlFor="grid-first-name"
                          >
                            Colour
                          </label>
                          <input
                            className="block w-full px-3 py-1 text-sm text-black focus:outline-none dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white"
                            id="grid-first-name"
                            type="text"
                            name="colour"
                            placeholder="Colour"
                            value={values.colour}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="w-full md:w-1/2 px-3">
                          <label
                            className="block text-sm text-gray-700 dark:text-gray-400 col-span-4 sm:col-span-2 font-medium text-sm"
                            htmlFor="grid-last-name"
                          >
                            Band Size
                          </label>
                          <select
                            className={
                              'col-span-8 sm:col-span-4 border text-black border-gray-300 rounded-md  h-10 pl-5 pr-10 bg-gray-100 hover:border-gray-400 focus:outline-none appearance-none w-full text-sm dark:text-gray-300 rounded-md dark:focus:border-gray-600 dark:bg-gray-700 dark:focus:ring-gray-300 focus:ring focus:ring-green-300 border text-sm focus:outline-none block w-full bg-gray-100 border-transparent focus:bg-white'
                            }
                            name="bandsize"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.bandsize}
                          >
                            <option>Choose a Band Size</option>
                            <option value="20 mm">20 mm</option>
                            <option value="22 mm">22 mm</option>
                            <option value="24 mm">24 mm</option>
                            <option value="26 mm">26 mm</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    {/* <div className="flex flex-wrap -mx-3 mb-8 mt-6">
                      <div className="flex justify-between align-center w-full md:w-1/2 px-3 mb-6 md:mb-0">
                        <label className="block text-sm text-gray-700 dark:text-gray-400 col-span-4 sm:col-span-2 font-medium text-sm">
                          New Arrival
                        </label>
                        <div className="col-span-8 sm:col-span-4 formInput">
                          <div className="toggle">
                            <input
                              type="checkbox"
                              name="is_new"
                              id="is_new"
                              checked={values.is_new}
                              onChange={handleChange}
                            />
                            <label htmlFor="is_new">
                              <span className="sr-only">Toggle Switch</span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="flex justify-between align-center w-full md:w-1/2 px-3">
                        <label className="block text-sm text-gray-700 dark:text-gray-400 col-span-4 sm:col-span-2 font-medium text-sm">
                          Best Seller
                        </label>
                        <div className="col-span-8 sm:col-span-4 formInput">
                          <div className="toggle">
                            <input
                              type="checkbox"
                              name="is_best"
                              id="is_best"
                              checked={values.is_best}
                              onChange={handleChange}
                            />
                            <label htmlFor="is_best">
                              <span className="sr-only">Toggle Switch</span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                  <div className="px-6 pt-8 fixed bottom-0  right-0 py-4 lg:py-8 px-6 grid gap-4 lg:gap-6 xl:gap-6 md:flex xl:flex bg-gray-50 border-gray-100 border-gray-700 bg-white-800 text-black-300 cancel-btn">
                    <div className="flex-grow-0 md:flex-grow lg:flex-grow xl:flex-grow btn-style">
                      <button
                        className="align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-gray-600 border-gray-200 border text-gray-400 focus:outline-none rounded-lg border border-gray-200 px-4 w-full mr-3 flex items-center justify-center cursor-pointer h-12 bg-gray-200 h-12 bg-white w-full text-red-500 hover:bg-red-50 hover:border-red-100 hover:text-red-600 bg-gray-700 border-white-700 text-gray-500 hover:bg-gray-800 hover:text-red-700"
                        type="button"
                        onClick={() => setOpenDrawer(false)}
                      >
                        Cancel
                      </button>{' '}
                    </div>
                    <div className="flex-grow-0 md:flex-grow lg:flex-grow xl:flex-grow btn-style">
                      <button
                        className="align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-white  border border-transparent common-background focus:ring focus:ring-green-300 w-full h-12"
                        type="submit"
                        onClick={addEdit && addEdit === 'edit' ? () => handleUpdate(values) : ''}
                      >
                        {addEdit && addEdit === 'edit' ? <span>Edit Product</span> : <span>Add Product</span>}
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          <div
            style={{
              position: 'absolute',
              height: '6px',
              right: '2px',
              bottom: '2px',
              left: '2px',
              borderRadius: '3px',
            }}
          >
            <div
              style={{
                position: 'relative',
                display: 'block',
                height: '100%',
                cursor: 'pointer',
                borderRadius: 'inherit',
                backgroundColor: 'rgba(0, 0, 0, 0.2)',
                width: '0px',
              }}
            />
          </div>

          <Dialog
            open={openDialog}
            keepMounted
            onClose={handleCloseDeleteModal}
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle>{'Delete User'}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                Are you sure you want to delete this image?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDeleteModal}>No</Button>
              <Button onClick={() => handleDeleteImage()}>Yes</Button>
            </DialogActions>
          </Dialog>
          {/* <div
            style={{ position: 'absolute', width: '6px', right: '2px', bottom: '2px', top: '2px', borderRadius: '3px' }}
          >
            <div
              style={{
                position: 'relative',
                display: 'block',
                width: '100%',
                cursor: 'pointer',
                borderradius: 'inherit',
                backgroundColor: 'rgba(0, 0, 0, 0.2)',
                height: '359px',
                transform: 'translateY(0px)',
              }}
            />
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default Product;

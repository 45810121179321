import {
  CHECKOUT_ADDRESS,
  CHECKOUT_APPLY,
  CHECKOUT_FAILURE,
  CHECKOUT_REQUEST,
  CHECKOUT_SUCCESS,
  ORDER_PLACE,
} from '../actions/CheckoutActions';

const initialState = {
  loading: false,
  checkoutData: {},
  shippingAddress: {},
  placeOrderData: {},
  error: '',
};

const CheckoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHECKOUT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CHECKOUT_SUCCESS:
      return {
        ...state,
        loading: false,
        checkoutData: action.payload,
        error: '',
      };
    case CHECKOUT_APPLY:
      return {
        ...state,
        loading: true,
        checkoutData: action.payload,
      };
    case ORDER_PLACE:
      return {
        ...state,
        loading: false,
        placeOrderData: action.payload,
      };
    case CHECKOUT_ADDRESS:
      return {
        ...state,
        loading: false,
        shippingAddress: action.payload,
      };
    case CHECKOUT_FAILURE:
      return {
        ...state,
        loading: false,
        checkoutData: {},
        error: action.payload,
      };
    default:
      return state;
  }
};
export default CheckoutReducer;

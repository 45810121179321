import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import axiosInstance from '../axios';
import { notificationStyle } from '../pages/product-list/ProductListing';
import { offerRequest } from './OfferActions';
import { checkoutAction, fetchDataSuccess, handleCheckoutAddressAction, orderPlaceAction } from './CheckoutActions';

export const FETCH_TOKEN_SUCCESS = 'FETCH_TOKEN_SUCCESS';
export const FETCH_USER_REQUEST = 'FETCH_USER_REQUEST';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE';

export const fetchUserRequest = () => ({
  type: FETCH_USER_REQUEST,
});

// Action creator for the success state
export const fetchUserSuccess = (data) => ({
  type: FETCH_USER_SUCCESS,
  payload: data,
});
export const fetchTokenSuccess = (data) => ({
  type: FETCH_TOKEN_SUCCESS,
  payload: data,
});

// Action creator for the failure state
export const fetchUserFailure = (error) => ({
  type: FETCH_USER_FAILURE,
  payload: error,
});

// Action creator for initiating the API call
export const fetchUserData = (payload) => async (dispatch) => {
  dispatch(fetchUserRequest());
  const response = await axiosInstance(`/auth/login`, 'POST', payload);
  if (response?.data.status === 200) {
    dispatch(fetchUserSuccess(response.data.data));
    dispatch(fetchTokenSuccess(response.data?.data?.access_token));
  } else {
    dispatch(fetchUserFailure(response.data.message));
    toast.error(response?.data?.message, notificationStyle);
  }
};

export const updateUserData = (payload) => (dispatch) => {
  dispatch(fetchUserRequest());
  axiosInstance(`/user/update-profile`, 'PUT', payload)
    .then((response) => {
      // Dispatch success action with the received data
      dispatch(fetchUserSuccess(response.data.data));
    })
    .catch((error) => {
      // Dispatch failure action with the error message
      dispatch(fetchUserFailure(error.message));
    });
};

export const handleLogout = () => (dispatch) => {
  dispatch(fetchTokenSuccess(""));
  dispatch(fetchUserSuccess({}));
  dispatch(offerRequest({}));
  dispatch(orderPlaceAction({}));
  dispatch(checkoutAction({}));
  dispatch(handleCheckoutAddressAction({}));
  dispatch(fetchDataSuccess({}));
};

import React from "react";
import { Box, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import loopVideo from "../../assets/videoSection.mp4";
import "./videoSection.css";

function VideoSection({ video, setting }) {
  const navigate = useNavigate();
  return (
    <div>
      <Box
        width={'100%'}
        // marginTop={{ md: '4rem' }}
        className="video-sec common-borderRadius"
        margin="auto"
        padding={"0 20px"}
        marginBottom={{ base: "1rem", sm: "3rem", md: "4rem", lg: "2rem" }}
      >
        {setting && setting?.sectionThree ? (
          <Text className="history-text">{setting.sectionThree}</Text>
        ) : (
          ""
        )}
        <a href={video?.homepageVideoLink}>
          <video
            className="loop-video"
            id="video-player"
            src={`${process.env.REACT_APP_IMAGE_URL}${video?.homepageVideo}`}
            type="video/mp4"
            height="auto"
            width="auto"
            preload="none"
            autoPlay
            muted
            loop
          />
        </a>
      </Box>
    </div>
  );
}

export default VideoSection;

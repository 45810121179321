import axiosInstance from '../axios';

export const OFFERS_SUCCESS = 'OFFERS_SUCCESS';
export const OFFERS_REQUEST = 'OFFERS_REQUEST';
export const OFFERS_FAILURE = 'OFFERS_FAILURE';
export const OFFERS_APPLY = 'OFFERS_APPLY';

export const fetchDataRequest = () => ({
  type: OFFERS_REQUEST,
});
export const offerRequest = (offer) => ({
  type: OFFERS_APPLY,
  payload: offer,
});

// Action creator for the success state
export const fetchDataSuccess = (data) => ({
  type: OFFERS_SUCCESS,
  payload: data,
});

// Action creator for the failure state
export const fetchDataFailure = (error) => ({
  type: OFFERS_FAILURE,
  payload: error,
});

// Action creator for initiating the API call
export const fetchOffersData = (total) => (dispatch) => {
  dispatch(fetchDataRequest());
  axiosInstance(`/offer/active-offers/${total}`, 'GET', null)
    .then((response) => {
      // Dispatch success action with the received data
      dispatch(fetchDataSuccess(response.data.data));
    })
    .catch((error) => {
      // Dispatch failure action with the error message
      dispatch(fetchDataFailure(error.message));
    });
};
export const handleApplyOffer = (offer) => (dispatch) => {
  dispatch(offerRequest(offer));
};

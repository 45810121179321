import React, { useState } from "react";
import "./login.css";
import { useNavigate } from "react-router-dom";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import Iconify from "src/components/iconify/Iconify";

import Notification from "src/components/notification";
import axios from "axios";
import axiosInstance from "../../axios";
import mainLogo from "../../assets/logo/logo.png";

const Login = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [cred, setCred] = useState({
    identifier: "",
    password: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setCred({ ...cred, [name]: value });
  };
  const handleSubmit = async () => {
    // const response = await axios.post('http://localhost:3033/canon/api/auth/login', cred);
    const response = await axiosInstance("/auth/auth-login", "POST", cred);
    if (response.status === 200) {
      localStorage.setItem("token", response.data.data.access_token);
      await Notification("success", "Login", response.data.message, 2000);
      navigate("/admin/dashboard");
    } else {
      await Notification("error", "Login", response.data.message, 2000);
    }
  };
  return (
    <div>
      <section className="bg-gray-200 background">
        <div className="w-full lg:w-4/12 px-4 mx-auto pt-6 login-card">
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-slate-300 border-0">
            <div className="rounded-t mb-0 px-6 py-6">
              <div className="btn-wrapper text-center">
                <img
                  src={mainLogo}
                  alt="logo"
                  style={{ width: "26%", height: "65px" }}
                />
              </div>
              <hr className="mt-6 border-b-1 border-blueGray-300" />
            </div>
            <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
              <form className="p-3">
                <div className="relative w-full mb-3 pb-2">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2  email-label"
                    htmlFor="emailInput"
                  >
                    Email / UserName
                    <input
                      type="text"
                      className="border-0 h-11 p-2 placeholder-slate-300 text-blueGray-300 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 email-label email-field"
                      placeholder="Email"
                      name="identifier"
                      id="emailInput"
                      value={cred.identifier}
                      onChange={(e) => handleChange(e)}
                    />
                  </label>
                </div>
                <div className="relative w-full mb-3 pb-2">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Password
                    <input
                      className="border-0 h-11 p-2 placeholder-slate-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full h-11 ease-linear transition-all duration-150 email-label"
                      name="password"
                      placeholder="Password"
                      type={showPassword ? "text" : "password"}
                      // style={{ backgroundColor: 'rgb(232, 240, 254)', height: '45px' }}
                      // InputProps={{
                      //   endAdornment: (
                      //     <InputAdornment position="end" className="placeholder-slate-300">
                      //       <IconButton
                      //         className="placeholder-blueGray-300"
                      //         onClick={() => setShowPassword(!showPassword)}
                      //         edge="end"
                      //       >
                      //         <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                      //       </IconButton>
                      //     </InputAdornment>
                      //   ),
                      // }}
                      value={cred.password}
                      onChange={(e) => handleChange(e)}
                    />
                  </label>
                </div>
                <div className="text-center mt-6">
                  <button
                    className="text-white text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150 login-btn"
                    type="button"
                    onClick={() => handleSubmit()}
                  >
                    {" "}
                    Login{" "}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* <footer className="relative pt-8 pb-6 mt-2">
    <div className="container mx-auto px-2">
      <div className="flex flex-wrap items-center md:justify-between justify-center">
        <div className="w-full md:w-6/12 px-4 mx-auto text-center">
          <div className="text-sm text-blueGray-500 font-semibold py-1">
            Made with <a href="https://www.creative-tim.com/product/notus-js" className="text-blueGray-500 hover:text-gray-800" target="_blank">Notus JS</a> by <a href="https://www.creative-tim.com" className="text-blueGray-500 hover:text-blueGray-800" target="_blank"> Creative Tim</a>.
          </div>
        </div>
      </div>
    </div>
  </footer> */}
      </section>
    </div>
  );
};

export default Login;

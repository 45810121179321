import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
// import './products.css';
import '../dashboard/dashboard.css';
import './orders.css';
import {
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  Card,
  Container,
  TablePagination,
  Popover,
  MenuItem,
  Drawer,
  Tooltip,
} from '@mui/material';
import { UserListHead } from 'src/sections/@dashboard/user';
// @mui
// components
import Scrollbar from 'src/components/scrollbar/Scrollbar';
import Iconify from 'src/components/iconify/Iconify';
import Product from 'src/components/addProducts/AddProduct';
import { useNavigate } from 'react-router-dom';
import { getOrderList } from 'src/actions/AdminOrderActions';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { MuiThemeProvider } from '@material-ui/core';
import LoupeIcon from '@mui/icons-material/Loupe';

// ----------------------------------------------------------------------

export default function OrdersPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { orderList } = useSelector((state) => state.adminOrder);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [open, setOpen] = useState(null);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [orderData, setOrderData] = useState([]);
  const [pagination, setPagination] = useState({});

  const [selected, setSelected] = useState([]);
  const [filterName, setFilterName] = useState('');

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    dispatch(getOrderList({ page: newPage + 1, limit: rowsPerPage }));
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    dispatch(getOrderList({ page: page + 1, limit: +event.target.value }));
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const TABLE_HEAD = [
    { id: 'id', label: 'ORDER ID', alignRight: false },
    { id: 'orderTime', label: 'ORDER TIME', alignRight: false },
    { id: 'customerName', label: 'CUSTOMER NAME', alignRight: false },
    { id: 'paymentStatus', label: 'PAYMENT STATUS', alignRight: false },
    { id: 'deliveryCurrentStatus', label: 'DELIVERY STATUS', alignRight: false },
    { id: 'amount', label: 'ORDER AMOUNT', alignRight: false },
    { id: '', label: 'INVOICE', alignRight: false },
  ];

  useEffect(() => {
    dispatch(getOrderList({ page: page + 1, limit: rowsPerPage }));
  }, []);

  useEffect(() => {
    if (orderList?.data?.length) {
      const data =
        orderList &&
        orderList.data.length &&
        orderList?.data?.map((item) => ({
          id: item?.OrderItems[0]?.order_id,
          orderTime: item.OrderItems[0]?.createdAt,
          customerName: item.User?.username,
          paymentStatus: item.payment_status,
          deliveryCurrentStatus: item.delivery_current_status,
          amount: item.total_amount,
        }));
      setOrderData(data);
      setCount(orderList?.pagination?.totalItems);
      setPagination(orderList?.pagination);
    }
  }, [orderList]);

  return (
    <>
      <Helmet>
        <title> Orders </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={4}>
          <Typography variant="h4" className="text-lg font-bold text-gray-700 dark:text-gray-300 headers">
            Orders
          </Typography>
        </Stack>
        {/* Filter Order */}
        {/* <Card className="card-style mb-0.5">
          <div className="pt-0.2">
            <form className="py-3 px-3 lg:gap-6 xl:gap-6 md:flex xl:flex grid grid-cols-4 gap-4">
              <div className="flex-grow-0 md:flex-grow lg:flex-grow xl:flex-grow product-filter">
                <input
                  className="block w-full px-3 py-1 text-sm focus:outline-none dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 border-transparent focus:bg-white"
                  type="search"
                  name="search"
                  placeholder="Search by product name"
                />
                <button type="submit" className="absolute right-0 top-0 mt-5 mr-1" />
              </div>
              <div className="flex-grow-0 md:flex-grow lg:flex-grow xl:flex-grow product-filter">
                <select className="block w-full px-2 py-1 text-sm dark:text-gray-300 focus:outline-none rounded-md form-select focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:shadow-none focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 leading-5 border h-10 text-sm focus:outline-none block w-full bg-gray-100 border-transparent focus:bg-white">
                  <option value="All" hidden="">
                    Status
                  </option>
                  <option value="Delivered">Delivered</option>
                  <option value="Pending">Pending</option>
                  <option value="Processing">Processing</option>
                  <option value="Cancel">Cancel</option>
                </select>
              </div>
              <div className="flex-grow-0 md:flex-grow lg:flex-grow xl:flex-grow product-filter">
                <select className="block w-full px-2 py-1 text-sm dark:text-gray-300 focus:outline-none rounded-md form-select focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:shadow-none focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 leading-5 border h-10 text-sm focus:outline-none block w-full bg-gray-100 border-transparent focus:bg-white">
                  <option value="All" hidden="">
                    Order Limits
                  </option>
                  <option value="5days">Last 5 days orders</option>
                  <option value="7days">Last 7 days orders</option>
                  <option value="15days">Last 15 days orders</option>
                  <option value="30days">Last 30 days orders</option>
                </select>
              </div>
            </form>
          </div>
        </Card> */}

        {/* Order Table */}

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={orderData}
                  // orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={orderData?.length}
                  numSelected={selected?.length}
                  // onRequestSort={handleRequestSort}
                  checkbox={false}
                />
                <TableBody>
                  {orderData &&
                    orderData.map((row) => {
                      const { id, productName, customerName, paymentStatus, amount } = row;
                      // const selectedUser = selected.indexOf(name) !== -1;
                      return (
                        <TableRow hover key={row.id} tabIndex={0}>
                          <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              {/* <Avatar alt={name} src={avatarUrl} /> */}
                              <Typography variant="subtitle2" noWrap marginLeft={'17px'}>
                                {row.id}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">{moment(row.orderTime).format('Do MMM YYYY hh:mm')}</TableCell>
                          <TableCell align="left">{row.customerName}</TableCell>
                          <TableCell align="left">
                            <div
                              className={
                                row.paymentStatus === 'succeeded'
                                ? 'inline-flex px-2 text-xs font-medium leading-5 rounded-full text-green-500 bg-green-100 dark:bg-green-800 dark:text-green-100 padding-style w-2/5 flex justify-center'
                                : 'inline-flex px-2 text-xs font-medium leading-5 rounded-full text-red-500 bg-red-100 dark:text-red-100 dark:bg-red-800 padding-style w-2/5 flex justify-center'
                            }
                            >
                              {row.paymentStatus && row.paymentStatus !== "" ? row.paymentStatus : "Failed"}
                            </div>
                          </TableCell>
                          <TableCell align="left">{row.deliveryCurrentStatus}</TableCell>
                          <TableCell align="left">₹{row.amount}</TableCell>
                          <TableCell align="left">
                            <MuiThemeProvider>
                              <Tooltip title="View Invoice" placement="bottom">
                                <LoupeIcon sx={{ height: 15 }} onClick={() => navigate(`/admin/invoice/${row.id}`)} />
                              </Tooltip>
                            </MuiThemeProvider>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>

                {orderData && orderData.length <= 0 && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Opps! No Record found
                          </Typography>

                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              component="div"
              count={orderList?.pagination?.totalItems}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Scrollbar>
        </Card>
        <Popover
          open={Boolean(open)}
          anchorEl={open}
          onClose={handleCloseMenu}
          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          PaperProps={{
            sx: {
              p: 1,
              width: 140,
              '& .MuiMenuItem-root': {
                px: 1,
                typography: 'body2',
                borderRadius: 0.75,
              },
            },
          }}
        >
          <MenuItem>
            <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
            Edit
          </MenuItem>

          <MenuItem sx={{ color: 'error.main' }}>
            <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
            Delete
          </MenuItem>
        </Popover>
      </Container>

      {/* Add Product Form */}
      <Drawer anchor={'right'} sx={{"& .MuiPaper-root": {
      width: '50%'
    }}} open={openDrawer} onClose={() => setOpenDrawer(false)} className="drawerStyle">
        <Product setOpenDrawer={setOpenDrawer} />
      </Drawer>
    </>
  );
}

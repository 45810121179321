import {
  Box,
  Button,
  Checkbox,
  Flex,
  Grid,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Stack,
  Text,
  useColorModeValue as mode,
  useDisclosure,
} from "@chakra-ui/react";
import { FaArrowRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { RiCoupon2Line, RiCoupon3Line } from "react-icons/ri";
import moment from "moment";
import { useEffect, useState } from "react";
import { formatPrice } from "./PriceTag";
import "./cartPage.css";
import { offerRequest } from "../../../actions/OfferActions";
import { checkoutAction } from "../../../actions/CheckoutActions";
import { BsFillExclamationCircleFill } from "react-icons/bs";
// const OrderSummaryItem = (props) => {
//   const { label, value, children } = props;
//   return (
//     <Flex justify="space-between" fontSize="sm">
//       <Text fontWeight="medium" color={mode('gray.600', 'gray.400')} className="common-userPaneFamily">
//         {label}
//       </Text>
//       {value ? <Text fontWeight="medium">{value}</Text> : children}
//     </Flex>
//   );
// };

export const CartOrderSummary = ({ cart }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDispatch();
  const { offers, selectedOffer } = useSelector((state) => state.offer);
  const { homePageData } = useSelector((state) => state.home);
  const [total, setTotal] = useState({});
  const [apply, setApply] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    const findTotal = cart.reduce((acc, obj) => {
      acc += obj.quantity * Number(obj.Product.price);
      return acc;
    }, 0);

    const findDiscountedTotal = cart.reduce((acc, obj) => {
      if (
        obj.Product.discount &&
        obj.Product.discount !== "" &&
        obj.Product.discount !== null
      ) {
        acc +=
          obj.quantity *
          (
            (Number(obj.Product.price) * Number(obj.Product.discount)) /
            100
          ).toFixed(2);
      }
      return acc;
    }, 0);

    const taxCalc = Number(
      (((findTotal - findDiscountedTotal) * 18) / 100).toFixed(2)
    );
    let mainTotal = (findTotal - findDiscountedTotal).toFixed(2);
    if (selectedOffer && selectedOffer.discountAmount) {
      mainTotal = (
        findTotal -
        findDiscountedTotal -
        Number(selectedOffer.discountAmount) 
      ).toFixed(2);
      setTotal({
        totalAmount: findTotal,
        discountAmount: findDiscountedTotal,
        coupenAmount: Number(selectedOffer.discountAmount),
        taxAmount: 0,
        mainTotal: Number(mainTotal),
      });
    } else {
      setTotal({
        totalAmount: findTotal,
        discountAmount: findDiscountedTotal,
        coupenAmount: 0,
        taxAmount: 0,
        mainTotal: Number(mainTotal),
      });
    }
  }, [cart, selectedOffer]);

  useEffect(() => {
    if (offers && offers.length) {
      setApply(offers && offers.length ? `${offers[0].offer_id}` : "");
    }
  }, [offers]);
  const handleApplyOffer = (id) => {
    if (offers && offers.length) {
      const data = offers.find((i) => Number(i.offer_id) === Number(id));
      if (data.isApplicable) {
        dispatch(offerRequest(data));
      }
    }
    onClose();
  };

  const handleCheckout = async () => {
    const checkoutData = {
      productList: cart,
      total,
      offerData: selectedOffer,
    };
    dispatch(checkoutAction(checkoutData));
    navigate("/checkout");
  };

  const handleApplyCoupon = (e) => {
    // const { value } = e.target;
    setApply(e);
  };
  return (
    <>
      <Stack spacing="8" padding="4" width="full">
        {selectedOffer && selectedOffer?.offer_id && selectedOffer?.offer_id ? (
          <>
            <Heading
              size="md"
              className="common-userPaneFamily"
              float={"left"}
              width={"100%"}
              marginTop={"-6px"}
            >
              Coupons
            </Heading>
            <Flex justifyContent={"space-between"}>
              <Grid justifyContent={"space-between"}>
                <Text className="common-userPaneFamily" fontWeight={"bold"}>
                  1 coupon applied
                </Text>
                <Text
                  className="common-userPaneFamily"
                  fontSize={"15px"}
                  color={"#ed691f"}
                >
                  You Saved additional{" "}
                  <span style={{ fontWeight: "bold" }}>
                    ₹{selectedOffer.discountAmount}
                  </span>
                </Text>
              </Grid>
              <Button onClick={onOpen} className="view-coupons">
                Edit
              </Button>
            </Flex>
          </>
        ) : (
          <>
            <Flex justifyContent={"space-between"}>
              <Heading size="md" className="common-userPaneFamily">
                Coupons
              </Heading>
              <Button
                onClick={onOpen}
                className="view-coupons"
                isDisabled={!cart.length}
              >
                {homePageData &&
                homePageData?.setting &&
                homePageData?.setting?.applyCoupon
                  ? homePageData?.setting?.applyCoupon
                  : "Apply Coupon"}
              </Button>
            </Flex>
          </>
        )}
      </Stack>
      {/* <InputGroup paddingBottom="4" borderRadius={0} rounded={'none'}>
        <Input type="tel" borderRadius={0} placeholder="Coupon Code" />
        <Button rounded={'none'} className="apply-coupon">
          Apply
        </Button>
      </InputGroup> */}
      <Stack
        spacing="8"
        borderWidth="1px"
        rounded="lg"
        padding="8"
        width="full"
      >
        <Heading size="md" className="common-userPaneFamily">
          Order Summary
        </Heading>

        <Stack spacing="6">
          <Flex justify="space-between">
            <Text
              fontWeight="medium"
              className="common-userPaneFamily common-cartPage"
            >
              Bag Total
            </Text>
            <Text
              fontWeight="medium"
              className="common-userPaneFamily common-cartPage"
            >
              <span>₹{total.totalAmount}</span>
            </Text>
          </Flex>
          <Flex justify="space-between">
            <Text
              fontWeight="medium"
              className="common-userPaneFamily common-cartPage"
            >
              Bag Discount
            </Text>
            <Text
              fontWeight="medium"
              className="common-userPaneFamily common-cartPage"
            >
              <span style={{ color: "#ed691f" }}>
                - ₹{Number(total.discountAmount).toFixed(2)}
              </span>
            </Text>
          </Flex>
          {/* <Flex justify="space-between">
            <Text
              fontWeight="medium"
              className="common-userPaneFamily common-cartPage"
            >
              Estimated Tax (<span style={{ color: "#ed691f" }}>GST 18%</span>)
            </Text>
            <Text
              fontWeight="medium"
              className="common-userPaneFamily common-cartPage"
            >
              <span>₹{total.taxAmount}</span>
            </Text>
          </Flex> */}
          <Flex justify="space-between">
            <Text
              fontWeight="medium"
              className="common-userPaneFamily common-cartPage"
            >
              Coupon Discount{" "}
              {selectedOffer && selectedOffer?.code ? (
                <span style={{ color: "#ed691f", fontSize: "12px" }}>
                  ({selectedOffer && selectedOffer?.code})
                </span>
              ) : (
                ""
              )}
            </Text>
            <Text
              fontWeight="medium"
              className="common-userPaneFamily common-cartPage"
            >
              <span style={{ color: "#ed691f" }}>- ₹{total.coupenAmount}</span>
            </Text>
          </Flex>
          <Flex justify="space-between">
            <Text
              fontSize="lg"
              fontWeight="semibold"
              className="common-userPaneFamily"
            >
              Total
            </Text>
            <Text
              fontSize="lg"
              fontWeight="semibold"
              className="common-userPaneFamily"
            >
              <span>₹{total.mainTotal}</span>
            </Text>
          </Flex>
        </Stack>
        <Flex justifyContent={"center"}>
          <Button
            rounded={"none"}
            className="checkout-btn"
            textTransform={"uppercase"}
            size="lg"
            fontSize="md"
            rightIcon={<FaArrowRight />}
            onClick={() => handleCheckout()}
            isDisabled={!cart.length}
            background={'transparent'}
          >
            {homePageData &&
            homePageData?.setting &&
            homePageData?.setting?.checkout
              ? homePageData?.setting?.checkout
              : "Checkout"}
          </Button>
        </Flex>
        <Modal
          isCentered
          onClose={onClose}
          isOpen={isOpen}
          motionPreset="slideInBottom"
        >
          <ModalOverlay />
          <ModalContent className="coupon-modal">
            <ModalHeader>Coupons</ModalHeader>
            <ModalCloseButton />
            <ModalBody
              maxH={"400px"}
              overflowY={offers && offers.length > 2 ? "scroll" : "unset" }
              padding={0}
              backgroundColor={"#f4f4f5"}
            >
              <Stack>
                <RadioGroup
                  defaultValue={
                    offers && offers.length ? `${offers[0].offer_id}` : ""
                  }
                  onChange={(val) => handleApplyCoupon(val)}
                  value={`${apply}`}
                >
                  {offers && offers.length ? (
                    offers.map((item, i) => (
                      <Box
                        key={i}
                        padding={4}
                        background={"white"}
                        marginBottom={"15px"}
                        marginTop={"15px"}
                      >
                        {/* <Checkbox colorScheme="orange" alignItems={'start'} marginRight={4}> */}
                        <Radio
                          alignItems={"start"}
                          marginRight={4}
                          // checked={`${item.offer_id}`}
                          isDisabled={!item.isApplicable}
                          colorScheme="orange"
                          value={`${item.offer_id}`}
                        >
                          <Box marginLeft={4}>
                            <Text className="offer-name">{item.code}</Text>
                            <Grid
                              justifyContent={"space-between"}
                              alignItems={"çenter"}
                            >
                              <Text className="offer-saveAmmount">
                                Save <span>₹</span>
                                {item.discountAmount}
                              </Text>
                              <Text
                                className="offer-desc"
                                width={{ md: "100%", lg: "100%" }}
                              >
                                {item.name}
                              </Text>
                              <Text
                                className="offer-desc"
                                width={{ md: "100%", lg: "100%" }}
                              >
                                Expires on:{" "}
                                {moment(item.expiry_date).format("Do MMM YYYY")}
                              </Text>
                              {/* <Button
                          className="offer-apply"
                          isDisabled={!item.isApplicable}
                          onClick={() => handleApplyOffer(item)}
                        >
                          Apply
                        </Button> */}
                            </Grid>
                          </Box>
                          {/* </Checkbox> */}
                        </Radio>
                      </Box>
                    ))
                  ) : (
                    <Box display={"grid"} placeItems={"center"} padding={20}>
                      <BsFillExclamationCircleFill
                        color="rgb(219 219 219)"
                        fontSize={"100px"}
                      />
                      <Text
                        fontSize={{ md: "2xl" }}
                        marginTop={4}
                        fontWeight="bold"
                        textAlign={"center"}
                        color={"#5e5e5e"}
                      >
                        No Coupons Available
                      </Text>
                    </Box>
                  )}
                </RadioGroup>
              </Stack>
            </ModalBody>
            <ModalFooter width={"100%"} padding={0}>
            {offers && offers.length ? (
              
              <Flex padding={4} width={"100%"} justifyContent={"space-between"} alignItems={"center"}>
                <Text className="common-userPaneFamily" fontWeight={600} fontSize={{base: "14px"}}>
                  Maximum Savings:
                  <span>
                    {" "}
                    ₹
                    {apply && offers && offers.length
                      ? offers.find((i) => Number(i.offer_id) === Number(apply))
                          .discountAmount
                      : 0}
                  </span>
                </Text>
                <Button
                  className="offer-apply"
                  onClick={() => handleApplyOffer(apply)}
                >
                  Apply
                </Button>
              </Flex>)
              : ""}
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Stack>
    </>
  );
};

import {
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  Heading,
  Image,
  SimpleGrid,
  Stack,
  Text,
  useColorModeValue as mode,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Bounce, toast } from "react-toastify";
import successIcon from "../../../assets/success.svg";
import "./successPage.css";
import WatchSlider from "../../../components/watch-slider/WatchSlider";
import NewArrival1 from "../../../assets/newArrival1.webp";
import Header from "../../../components/header";
import Footer from "../../../components/footer/Footer";
import store from "../../../store";
import cardIcon from "../../../assets/card.svg";
import { fetchHomeData } from "../../../actions/HomePageActions";
import {
  checkoutAction,
  fetchOffersData,
  handleCheckoutAddressAction,
  orderPlaceAction,
} from "../../../actions/CheckoutActions";
import {
  fetchDataSuccess,
  handleApplyOffer,
} from "../../../actions/OfferActions";
import banner from "../../../assets/SliderImage1.jpg";
import NewProduct from "../ProductDetailsPage/NewProduct";
import axiosInstance from "../../../axios";
import Loader from "../../../components/loader/Loader";
import noImage from "../../../assets/noImageAvailable.jpg";

export const handleCancleOrder = async (orderId, setLoader) => {
  setLoader(true);
  const cancelOrder = await axiosInstance(
    `/cart/cancle-order/${orderId}`,
    "POST"
  );
  if (cancelOrder.data.status === 200) {
    setLoader(false);
    toast.success(cancelOrder.data.message, {
      position: "top-right",
      autoClose: 500,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
    });
  } else {
    setLoader(false);
    toast.error(cancelOrder.data.message, {
      position: "top-right",
      autoClose: 1500,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
    });
  }
};

function SuccessPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { orderId } = useParams();
  const { homePageData } = useSelector((state) => state.home);
  const { productById, loading } = useSelector((state) => state.productById);
  const { placeOrderData } = useSelector((state) => state.checkout);
  const [orderData, setOrderData] = useState({});
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    if (!homePageData.length) {
      dispatch(fetchHomeData());
    }
    dispatch(fetchOffersData(orderId));
    dispatch(handleApplyOffer({}));
    return () => {
      dispatch(orderPlaceAction({}));
      dispatch(checkoutAction({}));
      dispatch(handleCheckoutAddressAction({}));
      dispatch(fetchDataSuccess({}));
    };
  }, []);

  useEffect(() => {
    if (placeOrderData?.order_id > 0) {
      const dataNew = {
        orderId: placeOrderData.order_number,
        shippingAddress: {
          address: placeOrderData.address,
          city: placeOrderData.city,
          country: placeOrderData.country,
          state: placeOrderData.state,
          zip_code: placeOrderData.zip_code,
          first_name: placeOrderData.first_name,
          last_name: placeOrderData.last_name,
          phone_number: placeOrderData.phone_number,
        },
        paymentInfo: {
          payment: JSON.parse(placeOrderData.payment_method),
        },
        orderSummary: placeOrderData.OrderItems,
        totalAmount: placeOrderData.total_amount,
        mainTotal: placeOrderData.main_amount,
        taxTotal: placeOrderData.tax_amount,
      };
      if (placeOrderData.discount_code && placeOrderData.discount_code !== "") {
        dataNew.discount = {
          discountAmount: placeOrderData.discount_amount,
          discountCode: placeOrderData.discount_code,
        };
      }
      setOrderData(dataNew);
    }
  }, [placeOrderData]);
  return (
    <div>
      <Header headerData={homePageData} />
      <Box height={loader ? "100%" : ""}>
        <Loader loading={loader} />
        <div style={{ opacity: loader ? 0.3 : "" }}>
          <Box textAlign={"center"} className="succes-main">
            {homePageData && homePageData?.setting ? (
              <Box height={"220px"} width={"100%"} paddingInline={"30px"}>
                <Image
                  src={`${process.env.REACT_APP_IMAGE_URL}${homePageData?.setting?.successBanner}`}
                  height={"100%"}
                  width={"100%"}
                />{" "}
                {/* 1298  * 220 */}
              </Box>
            ) : (
              ""
            )}
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              padding={{ md: 10, base: 4 }}
            >
              <Stack
                height={{ md: "70px", base: "50px" }}
                width={{ md: "70px", base: "50px" }}
              >
                <Image src={successIcon} />
              </Stack>
              <Grid>
                <Text
                  className="purchase-text"
                  textAlign={"left"}
                  marginLeft={{ md: 10, base: 4 }}
                >
                  Thank you for your purchase
                </Text>
                <Text
                  className="confirmation-text"
                  textAlign={"left"}
                  marginLeft={{ md: 10, base: 4 }}
                >
                  Your order id is: #{orderData.orderId}
                </Text>
              </Grid>
            </Box>
            {/* <Text className="confirmation-text" paddingLeft={{ base: 3 }} paddingRight={{ base: 3 }}>
          You Will receive an order confirmation email with details of your order
        </Text> */}
          </Box>
          <Divider marginTop={4} />
          <Box
            display={{ md: "flex", base: "block", sm: "block" }}
            justifyContent={"start"}
          >
            <Grid
              templateColumns={{
                md: "repeat(2, 1fr)",
                lg: "repeat(2, 1fr)",
                base: "repeat(1, 1fr)",
                sm: "repeat(1, 1fr)",
              }}
              width={{ md: "65%", base: "100%" }}
              height={"fit-content"}
              borderWidth="1px"
              margin={{ md: "20px" }}
              className="common-borderRadius"
            >
              <Stack
                spacing="8"
                //   borderWidth="1px"
                rounded="lg"
                padding={{ md: 8, base: 4 }}
                // width={{ md: '45%', lg: '32%', base: '100%', sm: '100%' }}
                marginTop={{ base: "10px", sm: "10px" }}
                height={"-webkit-fit-content"}
              >
                <Heading
                  size={{ md: "lg", base: "15px" }}
                  fontWeight="semibold"
                  fontSize={"1.5rem"}
                  className="common-userPaneFamily"
                >
                  Shipping Address
                </Heading>
                <Stack spacing="6">
                  <Text
                    fontSize="19px"
                    display={"grid"}
                    className="common-userPaneFamily shipping-add"
                  >
                    {orderData?.shippingAddress?.address}
                    <span>
                      {orderData?.shippingAddress?.city},
                      {orderData?.shippingAddress?.state},{" "}
                      {orderData?.shippingAddress?.zip_code}
                    </span>
                    <span>{orderData?.shippingAddress?.country}</span>
                    <span>Ph.{orderData?.shippingAddress?.phone_number}</span>
                  </Text>
                </Stack>
              </Stack>
              <Stack
                spacing="8"
                //   borderWidth="1px"
                rounded="lg"
                padding={{ md: 8, base: 4 }}
                // width={{ md: '45%', lg: '32%', base: '100%', sm: '100%' }}
                marginTop={{ base: "10px", sm: "10px" }}
                height={"-webkit-fit-content"}
              >
                <Heading
                  size="lg"
                  fontWeight="semibold"
                  fontSize={"1.5rem"}
                  className="common-userPaneFamily"
                >
                  Payment Information
                </Heading>
                <Stack spacing="6">
                  <Text
                    fontSize="19px"
                    display={"grid"}
                    className="common-userPaneFamily shipping-add"
                  >
                    <Flex alignItems={"center"}>
                      <Image src={cardIcon} height={"50px"} width={"50px"} />{" "}
                      <span style={{ marginLeft: "10px" }}>
                        *****{orderData?.paymentInfo?.payment?.card?.last4}
                      </span>
                    </Flex>
                    {/* <span>Paris, 750008</span> */}
                    {/* <span>France</span>
              <span>Ph.90928-92901</span> */}
                  </Text>
                </Stack>
              </Stack>
            </Grid>
            <Stack
              spacing="8"
              borderLeft={"1px solid lightgray"}
              rounded="none"
              padding={{ md: 8, base: 4 }}
              width={{ md: "45%", lg: "35%", base: "100%", sm: "100%" }}
              marginTop={{ base: "10px", sm: "10px" }}
              height={"-webkit-fit-content"}
            >
              <Heading
                size="lg"
                fontWeight="semibold"
                fontSize={"1.5rem"}
                className="common-userPaneFamily"
              >
                Order Summary
              </Heading>
              <Stack>
                {orderData?.orderSummary &&
                  orderData?.orderSummary.length &&
                  orderData?.orderSummary.map((item, index) => (
                    <Flex
                      key={index}
                      borderWidth={"1px"}
                      className="common-borderRadius"
                      padding={"8px"}
                    >
                      <Box
                        position={"relative"}
                        maxWidth={"81px"}
                        className="image-size"
                      >
                        {item?.Product &&
                        item?.Product?.ProductImages &&
                        item?.Product?.ProductImages.length ? (
                          <Image
                            width={{
                              base: "100%",
                              sm: "100%",
                              md: "100%",
                              lg: "100%",
                            }}
                            src={`${process.env.REACT_APP_IMAGE_URL}${item?.Product?.ProductImages[0]?.image_url}`}
                            position={"relative"}
                            cursor={"pointer"}
                            onClick={() =>
                              navigate(`/product-details/${item.Product.slug}`)
                            }
                          />
                        ) : (
                          <Image
                            width={{
                              base: "100%",
                              sm: "100%",
                              md: "100%",
                              lg: "100%",
                            }}
                            src={noImage}
                            position={"relative"}
                            cursor={"pointer"}
                            onClick={() =>
                              navigate(`/product-details/${item.Product.slug}`)
                            }
                          />
                        )}
                      </Box>
                      <SimpleGrid display={"initial"} width={"100%"}>
                        <Text
                          className="common-userPaneFamily wrap-word2"
                          fontSize={"15px"}
                          marginBottom={"20px"}
                          fontWeight={600}
                          color={"rgb(79, 79, 79)"}
                          paddingLeft={"10px"}
                          maxWidth="100%"
                          onClick={() =>
                            navigate(`/product-details/${item.Product.slug}`)
                          }
                        >
                          {item.Product.name}
                        </Text>
                        <Text className="watch-price4" paddingLeft={"10px"}>
                          <span>₹</span>
                          {item.Product?.price}
                        </Text>
                        <Text className="qty-text" paddingLeft={"10px"}>
                          <span>Qty:</span> {item.quantity}
                        </Text>
                      </SimpleGrid>
                    </Flex>
                  ))}
              </Stack>
              <Stack spacing="6">
                <Stack spacing={{ md: "6", base: "3" }}>
                  <Flex justify="space-between">
                    <Text
                      fontWeight="medium"
                      className="common-userPaneFamily common-cartPage"
                    >
                      Bag Total
                    </Text>
                    <Text
                      fontWeight="medium"
                      className="common-userPaneFamily common-cartPage"
                    >
                      <span>₹</span> {orderData.totalAmount}
                    </Text>
                  </Flex>
                  {orderData &&
                    orderData?.discount?.discountCode &&
                    orderData?.discount?.discountCode !== "" && (
                      <Flex justify="space-between">
                        <Text
                          fontWeight="medium"
                          className="common-userPaneFamily common-cartPage"
                        >
                          Coupon Discount (
                          <span style={{ color: "#ed691f" }}>
                            {orderData && orderData?.discount?.discountCode}
                          </span>
                          )
                        </Text>
                        <Text
                          fontWeight="medium"
                          className="common-userPaneFamily common-cartPage"
                        >
                          <span style={{ color: "#ed691f" }}>
                            - ₹
                            {orderData && orderData?.discount?.discountAmount}
                          </span>
                        </Text>
                      </Flex>
                    )}
                  {/* <Flex justify="space-between">
                    <Text
                      fontWeight="medium"
                      className="common-userPaneFamily common-cartPage"
                    >
                      Estimated Tax (
                      <span style={{ color: "#ed691f" }}>GST 18%</span>)
                    </Text>
                    <Text
                      fontWeight="medium"
                      className="common-userPaneFamily common-cartPage"
                    >
                      <span>₹{orderData && orderData.taxTotal}</span>
                    </Text>
                  </Flex> */}
                  <Divider />
                  <Flex justify="space-between">
                    <Text
                      fontSize="lg"
                      fontWeight="semibold"
                      className="common-userPaneFamily"
                    >
                      Total
                    </Text>
                    <Text
                      fontSize="lg"
                      fontWeight="semibold"
                      className="common-userPaneFamily"
                    >
                      <span>₹{orderData.mainTotal}</span>
                    </Text>
                  </Flex>
                </Stack>
              </Stack>
            </Stack>
          </Box>
          <Box className="continue-box">
            <Button
              // display={'flex'}
              // justifyContent={'center'}
              onClick={() => handleCancleOrder(orderId, setLoader)}
              className="continue-shoppingBtn"
              disabled={!!loader}
            >
              Cancel Order
            </Button>
            <Button
              // display={'flex'}
              // justifyContent={'center'}
              onClick={() => navigate("/product")}
              className="continue-shoppingBtn"
              disabled={!!loader}
            >
              {homePageData &&
              homePageData?.setting &&
              homePageData?.setting?.continueShopping
                ? homePageData?.setting?.continueShopping
                : "Continue Shopping"}
            </Button>
          </Box>
          {productById && productById?.productsList ? (
            <Box
              width={{ base: "90%", sm: "90%", md: "90%", lg: "95%" }}
              margin="auto"
              marginBottom={{ md: "8rem" }}
            >
              <NewProduct sliderData={productById?.productsList} />
            </Box>
          ) : (
            ""
          )}
        </div>
      </Box>
      <Footer />
    </div>
  );
}

export default SuccessPage;

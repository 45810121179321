import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  Divider,
  Flex,
  Grid,
  Image,
  Text,
} from "@chakra-ui/react";
import Footer from "../../../components/footer/Footer";
import Header from "../../../components/header";
import "./orderHistory.css";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserOrderList,
  getUserOrderListById,
} from "../../../actions/UserOrderActions";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import Loader from "../../../components/loader/Loader";
import { AiOutlineShoppingCart } from "react-icons/ai";
import noImage from "../../../assets/noImageAvailable.jpg";

function OrderHistory() {
  const { userData } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { orderHistory, loading } = useSelector((state) => state.userOrder);

  useEffect(() => {
    const data = {
      page: 1,
      limit: 5,
    };
    dispatch(getUserOrderList(userData?.user_id, data));
  }, []);

  const handleOrderDetails = (id) => {
    navigate(`/order/${id}`);
  };

  const handlePageClick = (selected) => {
    const data = {
      page: selected?.selected + 1,
      limit: 5,
    };
    dispatch(getUserOrderList(userData?.user_id, data));
  };

  return (
    <div>
      <Header />
      <Box textAlign={"center"} marginTop={"15px"} className="orders-details">
        <Text
          fontSize="2xl"
          fontWeight="extrabold"
          marginBottom={"15px"}
          className="common-userPaneFamily"
        >
          Order History
        </Text>
      </Box>
      <Box height={loading ? "70vh" : ""}>
        <Loader loading={loading}>
          <div style={{ opacity: loading ? 0.3 : 1 }}>
            <Box>
              {orderHistory && orderHistory.orders?.length ? (
                orderHistory.orders?.map((item) => (
                  <Box
                    display={"flex"}
                    marginTop={10}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <Box
                      borderWidth={"1px"}
                      borderRadius={"6px"}
                      width={{ md: "50%" }}
                      className="box-width"
                      padding={8}
                    >
                      <Grid>
                        <Flex justifyContent={"space-between"}>
                          <Grid fontSize={{ base: "13px", sm: "13px" }}>
                            <Text fontWeight={"500"}>Order Number</Text>
                            <Text fontWeight={"500"}>{item?.order_id}</Text>
                          </Grid>
                          <Grid fontSize={{ base: "13px", sm: "13px" }}>
                            <Text fontWeight={600}>Total Amount</Text>
                            <Text fontWeight={600}>{item?.main_amount}</Text>
                          </Grid>
                          <Button
                            className="view-orderDetails"
                            onClick={() => handleOrderDetails(item?.order_id)}
                          >
                            View Order
                          </Button>
                        </Flex>
                      </Grid>
                      <Divider paddingTop={2} paddingBottom={2} />
                      {item?.OrderItems?.length &&
                        item?.OrderItems.map((value) => (
                          <Box>
                            <Flex
                              paddingTop={"25px"}
                              gap={{ md: "30px", base: "10px", sm: "0px" }}
                              borderBottomWidth={
                                item?.OrderItems?.length > 1 ? "1px" : ""
                              }
                              //   alignItems={'center'}
                            >
                              <Box width={"100px"}>
                                {value?.Product?.ProductImages &&
                                value?.Product?.ProductImages.length ? (
                                  <Image
                                    src={`${process.env.REACT_APP_IMAGE_URL}${value?.Product?.ProductImages[0]?.image_url}`}
                                  />
                                ) : (
                                  <Image src={noImage} />
                                )}
                              </Box>
                              <Box width={"100%"} padding={4}>
                                <Flex justifyContent={"space-between"}>
                                  <Text
                                    className="common-userPaneFamily"
                                    lineHeight={1.1}
                                    fontWeight={600}
                                    fontSize={{
                                      base: "13px",
                                      sm: "13px",
                                      lg: "sm",
                                    }}
                                    color={"rgb(79,79,79)"}
                                  >
                                    {value?.Product?.name}
                                  </Text>
                                  <Text fontSize={{ base: "13px", sm: "13px" }}>
                                    ₹{value?.Product?.price}
                                  </Text>
                                </Flex>
                                <Box marginTop={"3px"}>
                                  <Text
                                    fontSize={{
                                      base: "13px",
                                      sm: "13px",
                                      lg: "14px",
                                    }}
                                    color={"rgb(99, 99, 99)"}
                                    className="common-userPaneFamily"
                                  >
                                    {value?.Product?.Category?.category_name}
                                  </Text>
                                </Box>
                              </Box>
                            </Flex>
                          </Box>
                        ))}
                    </Box>
                  </Box>
                ))
              ) : (
                <Box>
                  <Box display={"flex"} justifyContent={"center"}>
                    <AiOutlineShoppingCart fontSize={"100px"} color="#f7f5f4" />
                  </Box>
                  <Text className="empty-cart2">Your Order List is Empty</Text>
                  <Box marginBottom={"75px"}>
                    <Button
                      className="continue-shoppingbtn3"
                      onClick={() => navigate("/product")}
                    >
                      Continue Shopping
                    </Button>
                  </Box>
                </Box>
              )}
            </Box>
            {orderHistory && orderHistory.orders?.length ? (
              <Box
                height={{ md: "50px" }}
                display={"flex"}
                justifyContent={"center"}
                marginTop={"40px"}
                alignItems={"center"}
              >
                <ReactPaginate
                  breakLabel="..."
                  nextLabel=">"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  marginPagesDisplayed={2}
                  pageCount={orderHistory?.totalPages}
                  disableInitialCallback
                  previousLabel="<"
                  containerClassName={"pagination2 justify-content-center"}
                  previousLinkClassName={"pagination2__link"}
                  nextLinkClassName={"pagination2__link"}
                  disabledClassName={"pagination2__link--disabled"}
                  activeClassName={"pagination2__link--active"}
                />
              </Box>
            ) : (
              ""
            )}
          </div>
        </Loader>
      </Box>
      <Footer />
    </div>
  );
}

export default OrderHistory;

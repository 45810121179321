import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Flex,
  Grid,
  Heading,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Select,
  SimpleGrid,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { BsSearch } from "react-icons/bs";
import "./searchBar.css";
import noImage from "../../assets/noImageAvailable.jpg";
import { useDispatch, useSelector } from "react-redux";
import { fetchSearchListData } from "../../actions/SearchListAction";
import { fetchProductListData } from "../../actions/ProductListingActions";
import { fetchFilterOptions } from "../../actions/productFilterActions";

function SearchBar() {
  const { searchList } = useSelector((state) => state.search);
  const dispatch = useDispatch();
  const [searchInput, setSearchInput] = useState("");
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  // useEffect(() => {

  // }, [searchList]);
  const productListRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      console.log('productListRef', productListRef)
      if (productListRef.current && !productListRef.current.contains(event.target)) {
        setOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setOpen, open]);
  useEffect(() => {
    if (searchInput.length >= 3) {
      setOpen(true);
      dispatch(fetchSearchListData(searchInput));
    }
  }, [searchInput.length]);

  const handleKeyDown = (e) => {
    if (e.code === "Enter") {
      navigate(`/product?search=${searchInput}`);
      setOpen(false);
    }
  };
  const handleProductClick = (slug) => {
    navigate(`/product-details/${slug}`)
    setOpen(false);
    setSearchInput("")
  }

  return (
    <Box borderRadius={"md"} pos="relative" width={"100%"} ref={productListRef}>
      <Menu width="100%" height={"420px"} className="menu-listStyle">
        {/* <MenuButton
          as={Button}
          role={Input}
          background={'none'}
          width={'100%'}
          padding={0}
          marginTop={'5px'}
          fontWeight={'initial'}
          _active={{ background: 'none' }}
          _hover={{ background: 'none' }}
        > */}
        <InputGroup aria-label="Options">
          <InputLeftElement
            style={{
              top: "50%",
              transform: "translateY(-50%)",
              marginLeft: "10px",
            }}
            children={<BsSearch color="gray.300" />}
            // style={{ top: '6px' }}
          />
          <Input
            type="text"
            outline="none"
            placeholder="What are you looking for?"
            backgroundColor={"#ffffff"}
            className="common-userPaneFamily"
            autocomplete="given-name"
            height={50}
            _focus={{
              boxShadow: "none",
              border: "1px solid #f89f17",
              outline: "none",
            }}
            // onBlur={() => handleBlur()}
            aria-label="Options"
            name="search"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            onKeyUp={handleKeyDown}
          />
        </InputGroup>
        {/* </MenuButton> */}
        {open ? (
          // <MenuList>
          <Stack width="100%">
            <Flex
              // templateColumns={{
              //   base: 'repeat(1, 1fr)',
              //   sm: 'repeat(1, 1fr)',
              //   md: 'repeat(2, 1fr)',
              //   lg: 'repeat(2, 1fr)',
              // }}
              height={"80vh"} 
              overflow={'hidden'}
              padding={8}
              className="productList-res"
              width={"100%"}
              marginTop={{ base: 3, sm: 4, md: 0, lg: 0 }}
              alignItems={"baseline"}
              pos={"absolute"}
              background={"white"}
              border={"1px solid #f4f4f5"}
              boxShadow={"rgba(0, 0, 0, 0.1) 0px 4px 12px"}
            >
              <Stack
                className="common-userPaneFamily search-suggetions"
                width={"40%"}
              >
                <Box width={"100%"}>
                  <Heading
                    marginBottom={4}
                    fontWeight={"bold"}
                    className="common-userPaneFamily"
                    fontSize={"2xl"}
                  >
                    Suggestions
                  </Heading>
                  {searchList &&
                    searchList?.matchingCategories &&
                    searchList?.matchingCategories.map((item) => (
                      <Text
                        className="suggestion-text"
                        cursor={"pointer"}
                        onClick={() => navigate(`/product/${item.slug}`)}
                      >
                        {item?.category_name}
                      </Text>
                    ))}
                </Box>
              </Stack>
              <Stack
                className="common-userPaneFamily search-products"
                width={"60%"}
                borderLeft={{ md: "2px solid lightgray" }}
                overflowY={"scroll" }
                height={'70vh'}
              >
                <Box
                  width={"100%"}
                  className="product-padd"
                  paddingLeft={4}
                  paddingRight={4}
                >
                  <Heading
                    marginBottom={4}
                    fontWeight={"bold"}
                    className="common-userPaneFamily"
                    fontSize={{ md: "2xl", base: "xl" }}
                  >
                    Products
                  </Heading>
                  {searchList &&
                    searchList?.matchingProducts &&
                    searchList?.matchingProducts.map((val) => (
                      <Box
                        //   boxShadow={' rgba(0, 0, 0, 0.1) 0px 4px 12px'}
                        width={{
                          base: "100%",
                          sm: "100%",
                          md: "100%",
                          lg: "100%",
                        }}
                        style={{ cursor: "pointer" }}
                        background={"white"}
                        display={"flex"}
                        pos={"relative"}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                        borderBottom={"1px solid #f4f4f5"}
                        marginBottom={"5px"}
                        onClick={() =>
                              handleProductClick(val?.slug)
                            }
                      >
                        <SimpleGrid
                          display={"initial"}
                          width={"78%"}
                          onClick={() =>
                              handleProductClick(val?.slug)
                            }
                        >
                          <Text
                            className="watch-details5"
                            paddingLeft={"10px"}
                            marginBottom={2}
                            onClick={() =>
                              handleProductClick(val?.slug)
                            }
                          >
                            {val?.name}
                          </Text>
                          <Text
                            paddingLeft={"10px"}
                            fontSize={"12px"}
                            marginTop={"-19px"}
                          >
                            {val?.Category?.category_name}
                          </Text>
                          <Text className="watch-price3" paddingLeft={"10px"}>
                            <span>₹</span>
                            {val?.price}
                          </Text>
                        </SimpleGrid>
                        <Box
                          position={"relative"}
                          maxWidth={"80px"}
                          className="image-size"
                        >
                          {val?.ProductImages && val?.ProductImages.length ? (
                            <Image
                              width={{
                                base: "100%",
                                sm: "100%",
                                md: "100%",
                                lg: "100%",
                              }}
                              src={`${process.env.REACT_APP_IMAGE_URL}${val?.ProductImages[0]?.image_url}`}
                              position={"relative"}
                              cursor={"pointer"}
                              onClick={() =>
                                navigate(`/product-details/${val?.slug}`)
                              }
                            />
                          ) : (
                            <Image
                              width={{
                                base: "100%",
                                sm: "100%",
                                md: "100%",
                                lg: "100%",
                              }}
                              src={noImage}
                              position={"relative"}
                              cursor={"pointer"}
                              onClick={() =>
                                navigate(`/product-details/${val?.slug}`)
                              }
                            />
                          )}
                        </Box>
                      </Box>
                    ))}
                </Box>
              </Stack>
              {/* <MenuDivider /> */}
              {/* <MenuItem>My Account</MenuItem>
          <MenuItem>Change Password </MenuItem>
          <MenuItem _hover={{ background: '#ddd' }}>Logout</MenuItem> */}
            </Flex>
          </Stack>
        ) : (
          ""
        )}
      </Menu>
    </Box>
  );
}

export default SearchBar;

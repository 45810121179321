import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import {
  Card,
  Table,
  Stack,
  Paper,
  Button,
  Popover,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  Drawer,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Box,
} from '@mui/material';
import { IOSSwitch } from 'src/utils/toggleButton/toggleButton';
import { FormControlLabel } from '@material-ui/core';
import Notification from 'src/components/notification';
import AddBanner from 'src/components/addBanner/AddBanner';
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';
import TableHeader from '../../sections/@dashboard/user/tableHeader';
import axiosInstance from '../../axios';
import noImages from '../../assets/noImageAvailable.jpg';

// table header
const TABLE_HEAD = [
  { id: 'id', label: 'Id', alignRight: false },
  { id: 'image', label: 'Banner Image', alignRight: false },
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'description', label: 'Description', alignRight: false },
  { id: 'section', label: 'Section', alignRight: false },
  { id: 'priority', label: 'Priority', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: '', alignRight: false },
];

export default function BannerPage() {
  const [open, setOpen] = useState(null);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [bannerListing, setBannerListing] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [bannerId, setBannerId] = useState(null);
  const [addEdit, setAddEdit] = useState('add');

  // banner listing api
  const bannerListAPI = async () => {
    const res = await axiosInstance(`/banner/all-banners`, 'GET', null);
    if (res.status === 200) {
      setBannerListing(
        res.data.data.map((data) => ({
          id: data.id,
          name: data.title,
          description: data.description,
          image: data.image_url,
          section: data.section,
          priority: data.priority,
          status: data.is_active,
        }))
      );
      // Notification('success', 'Category List', res.data.message, 2000);
    } else {
      // Notification('error', 'Category List', res.data.message, 2000);
    }
  };

  // Banner List
  useEffect(() => {
    const fetchData = async () => {
      const res = await axiosInstance(`/banner/all-banners`, 'GET', null);
      if (res.status === 200) {
        setBannerListing(
          res.data.data.map((data) => ({
            id: data.id,
            name: data.title,
            description: data.description,
            image: data.image_url,
            section: data.section,
            priority: data.priority,
            status: data.is_active,
          }))
        );
        Notification('success', 'Banner List', res.data.message, 2000);
      } else {
        Notification('error', 'Banner List', res.data.message, 2000);
      }
    };
    fetchData();
  }, []);

  // open modal for edit and delete banner
  const handleOpenMenu = (event, id) => {
    setOpen(event.currentTarget);
    setBannerId(id);
  };

  // close modal for edit and delete banner
  const handleCloseMenu = () => {
    setOpen(null);
  };

  // handle open delete model
  const handleOpenDeleteModal = () => {
    setOpenDialog(true);
    setOpen(null);
  };

  // handle close delete model
  const handleCloseDeleteModal = () => {
    setOpenDialog(false);
  };

  // delete api call
  const handleDeleteBanner = async () => {
    const response = await axiosInstance(`/banner/${bannerId}`, 'DELETE', null);

    if (response.status === 200) {
      Notification('success', 'Delete Banner', response.data.message, 2000);
      setOpenDialog(false);
      await bannerListAPI();
    } else {
      Notification('error', 'Delete Banner', response.data.message, 2000);
    }
  };

  // status update api
  const updateValue = async (e, id) => {
    const { checked } = e.target;

    const payload = {
      banner_id: id,
      is_active: checked,
    };
    const response = await axiosInstance(`/banner/status`, 'POST', payload);

    await bannerListAPI();
    if (response.status === 200) {
      Notification('success', 'Status', response.data.message, 2000);
    } else {
      Notification('error', 'Status', response.data.message, 2000);
    }
  };

  // handle edit button
  const handleEdit = () => {
    setOpenDrawer(true);
    setOpen(null);
    setAddEdit('edit');
  };

  // handle add banner
  const handleAddData = () => {
    setOpenDrawer(true);
    setAddEdit('add');
  };

  return (
    <>
      <Helmet>
        <title> Banner </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={4}>
          <p className="text-lg font-bold text-gray-700 dark:text-gray-300 headers">Banner</p>
          <div>
            <button
              className="align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-white border border-transparent common-background focus:ring focus:ring-purple-300  rounded-md common-shadow"
              type="button"
              onClick={() => handleAddData()}
            >
              <span className="mr-3">
                <svg
                  stroke="currentColor"
                  fill="none"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <line x1="12" y1="5" x2="12" y2="19" />
                  <line x1="5" y1="12" x2="19" y2="12" />
                </svg>
              </span>
              Add Banner
            </button>
          </div>
        </Stack>
        {/* <Card className="card-style">
          <UserListToolbar />
        </Card> */}
        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <TableHeader headLabel={TABLE_HEAD} checkbox={false} />
                {bannerListing && bannerListing.length > 0 ? (
                  <TableBody>
                    {bannerListing.map((row) => {
                      const { id, name, description, image, section, priority, status } = row;
                      return (
                        <TableRow hover key={id} role="checkbox">
                          <TableCell align="left" width="20%">
                            {id}
                          </TableCell>
                          {image ? (
                            <TableCell align="left">
                              <div
                                style={{
                                  height: '100px',
                                  width: '100px',
                                }}
                              >
                                <img src={`${process.env.REACT_APP_IMAGE_URL}${image}`} alt="" style={{width: '100%', height: '100%'}}/>
                              </div>
                            </TableCell>
                          ) : (
                            <TableCell align="left">
                              <div
                                style={{
                                  height: '100px',
                                  width: '100px',
                                }}
                              >
                                <img src={noImages} alt="" />
                              </div>
                            </TableCell>
                          )}
                          <TableCell align="left" width="20%">
                            {name}
                          </TableCell>

                          <TableCell align="left" width="40%">
                            {description}
                          </TableCell>
                          <TableCell align="left" width={'20%'}>
                            Section {section}
                          </TableCell>
                          <TableCell align="left">{priority}</TableCell>

                          <TableCell align="left">
                            <FormControlLabel
                              control={
                                <IOSSwitch sx={{ m: 1 }} checked={status} onChange={(e) => updateValue(e, id)} />
                              }
                            />
                          </TableCell>

                          <TableCell align="right" width="20%">
                            <IconButton size="large" color="inherit" onClick={(e) => handleOpenMenu(e, id)}>
                              <Iconify icon={'eva:more-vertical-fill'} />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                ) : (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Box
                            className="not-fount-img"
                            component="img"
                            src="/assets/illustrations/illustration_404.svg"
                            sx={{ height: 160, mx: 'auto', my: { xs: 5, sm: 10 } }}
                          />
                          <Typography variant="h4" paragraph className="not-fount-1">
                            No records has been added yet.
                          </Typography>
                          <Typography variant="h6" paragraph className="not-fount-2">
                            Add a new record by simpley clicking the button on top right side.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
        </Card>
      </Container>
      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem onClick={() => handleEdit()}>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        <MenuItem sx={{ color: 'error.main' }} onClick={handleOpenDeleteModal}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>

      <Dialog
        open={openDialog}
        keepMounted
        onClose={handleCloseDeleteModal}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{'Delete Category'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to delete Category?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteModal}>No</Button>
          <Button onClick={() => handleDeleteBanner()}>Yes</Button>
        </DialogActions>
      </Dialog>

      <Drawer anchor={'right'} open={openDrawer} sx={{"& .MuiPaper-root": {
      width: '50%'
    }}} onClose={() => setOpenDrawer(false)} className="drawerStyle">
        <AddBanner setOpenDrawer={setOpenDrawer} bannerListAPI={bannerListAPI} addEdit={addEdit} id={bannerId} />
      </Drawer>
    </>
  );
}

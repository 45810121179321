import React, { useEffect, useState } from "react";
import { Box, Container, Grid, Image, Text } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import Slider from "react-slick";
import Header from "../../../components/header";
import BannerSlider from "../../../components/banner-slider/BannerSlider";
import "./homePage.css";
import NewArrival from "../../../components/new-arrivals/NewArrival";
import VideoSection from "../../../components/video-section/VideoSection";
import HistoryPage from "../../../components/history-section/HistoryPage";
import WatchSlider from "../../../components/watch-slider/WatchSlider";
import Footer from "../../../components/footer/Footer";
import { fetchHomeData } from "../../../actions/HomePageActions";
import FooterSlider from "./FooterSlider";
import Loader from "../../../components/loader/Loader";
import noImage from "../../../assets/noImageAvailable.jpg";

function UserHomePage() {
  const dispatch = useDispatch();
  const { homePageData, loading } = useSelector((state) => state.home);
  useEffect(() => {
    dispatch(fetchHomeData());
  }, []);

  // watch slider
  function SampleNextArrow({ onClick }) {
    return (
      <Box
        fontSize={{ base: "20px", sm: "25px", md: "30px", lg: "30px" }}
        padding={{
          base: "2px 2px",
          sm: "3px 5px",
          md: "4px 8px",
          lg: "11px 12px",
        }}
        // borderRadius="50%"
        color="black"
        // boxShadow="0 0 5px 3px #ccc"
        display="flex"
        width={{ base: "20px", sm: "30px", md: "40px", lg: "54px" }}
        position="absolute"
        top="45%"
        right={{ md: "-29px", base: "-18px" }}
        // backgroundColor="white"
        className="arrow arrow-right"
        onClick={onClick}
        cursor={"pointer"}
      >
        <IoIosArrowForward />
      </Box>
    );
  }
  function SamplePrevArrow({ onClick }) {
    return (
      <Box
        fontSize={{ base: "20px", sm: "25px", md: "30px", lg: "30px" }}
        padding={{
          base: "2px 2px",
          sm: "3px 5px",
          md: "4px 8px",
          lg: "11px 12px",
        }}
        // borderRadius="50%"
        color="black"
        // boxShadow="0 0 5px 3px #ccc"
        // border={'1px solid lightgray'}
        display="flex"
        width={{ base: "20px", sm: "30px", md: "40px", lg: "54px" }}
        position="absolute"
        top="45%"
        left={{ md: "-40px", base: "-18px" }}
        // backgroundColor="white"
        cursor={"pointer"}
        className="arrow arrow-left"
        zIndex={1}
        onClick={onClick}
      >
        <IoIosArrowBack />
      </Box>
    );
  }
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: homePageData?.sectionTwoCard?.length < 4 ? homePageData.sectionTwoCard.length : 4,
    // slidesToScroll: 2,
    arrows: true,
    initialSlide: 0,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          // slidesToScroll: 2,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          // slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          // slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div>
      <Header headerData={homePageData} />
      <Box height={loading ? "100%" : ""}>
        <Loader loading={loading} />
        <div style={{ opacity: loading ? 0.3 : 1 }}>
          {!!homePageData?.sectionOneBanner && (
            <BannerSlider
              bannerData={homePageData?.sectionOneBanner}
              mobileBanner={homePageData?.sectionOneBannerMobile}
            />
          )}
          {!!homePageData?.sectionOneCard && (
            <Box
              width={{ base: "100%", sm: "100%", md: "100%", lg: "100%" }}
              margin="auto"
              className="header trans_300"
            >
              <NewArrival
                cardData={homePageData?.sectionOneCard}
                setting={homePageData?.setting}
              />
            </Box>
          )}
          {!!homePageData?.setting?.homepageVideo && (
            <Box width={"100%"}>
              <VideoSection
                video={homePageData?.setting}
                setting={homePageData?.setting}
              />
            </Box>
          )}
          {!!homePageData?.sectionTwoBanner && (
            <Box
              width={"100%"}
              // margin="auto"
              padding={"20px"}
              marginTop={{ base: "2rem", md: "2rem" }}
            >
              <HistoryPage
                history={homePageData?.sectionTwoBanner}
                setting={homePageData?.setting}
              />
            </Box>
          )}
          {/* {!!homePageData?.productSection.length && (
            <Box
              width={{ base: "80%", sm: "80%", md: "90%", lg: "95%" }}
              marginTop={"1rem"}
              margin="auto"
            >
              <WatchSlider
                sliderData={homePageData?.productSection}
                setting={homePageData?.setting}
              />
            </Box>
          )} */}
          {homePageData && homePageData?.sectionTwoCard && (
            <Box
              width={{ base: "90%", sm: "90%", md: "90%", lg: "100%" }}
              marginTop={"1rem"}
              margin="auto"
              padding={"0 20px"}
            >
              {homePageData?.setting?.sectionSix && (
                <Text className="history-text">
                  {homePageData?.setting?.sectionSix}
                </Text>
              )}

              <Box width={{ md: "100%" }} margin={{ md: "auto" }} >
                <Slider {...settings}>
                  {homePageData?.sectionTwoCard.map((item) => {
                    return (
                      <div style={{ width: "100%", border: "none" }} className="butFrame2">
                        <Image
                          src={`${process.env.REACT_APP_IMAGE_URL}${item.image_url}`}
                          alt=""
                          style={{ height: "100%", width: "100%" }}
                          objectFit={"cover"}
                          className="image-hover-effect2"
                          onClick={() =>
                            item?.section_type &&
                              item?.section_type === "category"
                              ? item.link_url
                              : `${process.env.REACT_APP_URL}/product-section/${item.title}`
                          }
                        />
                        <div className="butTextWrap2">
                          <a
                            href={
                              item?.section_type &&
                                item?.section_type === "category"
                                ? item.link_url
                                : `${process.env.REACT_APP_URL}/product-section/${item.title}`
                            }
                          >
                            <p
                              style={{
                                fontSize: "1.2rem",
                                borderBottom: "3px solid #ed691f",
                                margin: "auto",
                                width: "max-content",
                                maxWidth: "100%",
                              }}
                            >
                              {item.title}
                            </p>
                            <p
                              className="content-text"
                              style={{
                                fontSize: "1rem",
                                textAlign: "center",
                                marginTop: "3rem",
                              }}
                            >
                              {item.description}
                            </p>
                            {/* <p
                              className="content-text2"
                              style={{
                                fontSize: "1rem",
                                textAlign: "center",
                                marginTop: "3rem",
                              }}
                            >
                              {item.description}
                            </p> */}
                          </a>
                        </div>
                      </div>
                    );
                  })}
                </Slider>
              </Box>

              {/* </Grid> */}
            </Box>
          )}
          <Box
            width={"100%"}
            marginTop={"2rem"}
          // margin="auto"
          >
            <FooterSlider bannerData={homePageData?.sectionThreeBanner} />
          </Box>
        </div>
      </Box>
      <Footer />
    </div>
  );
}

export default UserHomePage;

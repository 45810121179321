import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Heading, Stack } from '@chakra-ui/react';
import Header from '../../../components/header';
import newArrival1 from '../../../assets/newArrival1.webp';
import newArrival2 from '../../../assets/newArrival2.webp';
import newArrival3 from '../../../assets/newArrival3.webp';
import Footer from '../../../components/footer/Footer';
import { WishlistItem } from './WishlistItem';
import store from '../../../store';
import { fetchHomeData } from '../../../actions/HomePageActions';

export const wishListData = [
  {
    id: '1',
    price: 1574.0,
    name: 'Canon Analog Watch',
    description: 'Men',
    quantity: 3,
    imageUrl: newArrival1,
  },
  {
    id: '2',
    price: 1574.0,
    name: 'Canon Men’s Square-SLIM EDITION Classic Grey watch',
    description: 'Men',
    quantity: 3,
    imageUrl: newArrival2,
  },
  {
    id: '3',
    price: 1574.0,
    name: 'Canon Men’s Square-SLIM EDITION Silver_Rose watch',
    description: 'Men',
    quantity: 3,
    imageUrl: newArrival3,
  },
];

function WishlistPage() {
  const { homePageData } = store.getState().home;
  const dispatch = useDispatch();
  useEffect(() => {
    if (!homePageData.length) {
      dispatch(fetchHomeData());
    }
  }, []);
  return (
    <div>
      <Header headerData={homePageData} />
      <Box
        maxW={{
          base: '3xl',
          lg: '7xl',
        }}
        mx="auto"
        px={{
          base: '4',
          md: '8',
          lg: '12',
        }}
        py={{
          base: '6',
          md: '8',
          lg: '12',
        }}
        marginTop={{ base: '120px', sm: '120px', md: '120px', lg: 0 }}
      >
        <Stack
          direction={{
            base: 'column',
            lg: 'row',
          }}
          align={{
            lg: 'flex-start',
          }}
          spacing={{
            base: '8',
            md: '16',
          }}
        >
          <Stack
            spacing={{
              base: '8',
              md: '10',
            }}
            flex="2"
          >
            <Heading fontSize="2xl" fontWeight="extrabold" className="common-userPaneFamily">
              My Wishlist
            </Heading>

            <Stack spacing="6">
              <WishlistItem />
            </Stack>
          </Stack>
        </Stack>
      </Box>
      <Footer />
    </div>
  );
}

export default WishlistPage;

import {
  Box,
  Flex,
  Grid,
  Image,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React from "react";
import image from "../../../assets/newArrival6.jpg";

function Specification({ matchData }) {
  return (
    <div>
      {matchData && matchData?.specification_image ? (
        <Grid
          templateColumns={{
            base: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(1, 1fr)",
            lg: "repeat(3, 1fr)",
          }}
          justifyContent={"space-between"}
          marginBottom={8}
          gap="20px"
        // backgroundColor={"red"}
        >
          <Box
            // width={{ lg: "60%" }}
            alignItems={"center"}
            justifyContent={'center'}
            display={{ lg: "flex", md: "flex", base: "none", sm: "none" }}
            className="tab-view-spec"
          >
            <Image
              src={`${process.env.REACT_APP_IMAGE_URL}${matchData.specification_image}`}
              width={{
                base: "200px",
                sm: "100px",
                md: "300px",
                lg: "400px",
              }}
            />
          </Box>
          <TableContainer whiteSpace={"initial"}>
            <Table variant="simple">
              <Tbody>
                {matchData && matchData?.gender && matchData?.gender ? (
                  <Tr>
                    <Td className="specification-td">Gender</Td>
                    <Td className="common-userPaneFamily">
                      {matchData?.gender}
                    </Td>
                  </Tr>
                ) : (
                  ""
                )}
                {matchData &&
                  matchData?.bandmaterial &&
                  matchData?.bandmaterial ? (
                  <Tr>
                    <Td className="specification-td">Band Material</Td>
                    <Td className="common-userPaneFamily">
                      {matchData?.bandmaterial}
                    </Td>
                  </Tr>
                ) : (
                  ""
                )}
                {matchData && matchData?.bandsize && matchData?.bandsize ? (
                  <Tr>
                    <Td className="specification-td">Band Size</Td>
                    <Td className="common-userPaneFamily">
                      {matchData?.bandsize}
                    </Td>
                  </Tr>
                ) : (
                  ""
                )}
                {matchData && matchData?.bandwidth && matchData?.bandwidth ? (
                  <Tr>
                    <Td className="specification-td">Band Width</Td>
                    <Td className="common-userPaneFamily">
                      {matchData?.bandwidth}
                    </Td>
                  </Tr>
                ) : (
                  ""
                )}
                {matchData && matchData?.bandcolour && matchData?.bandcolour ? (
                  <Tr>
                    <Td className="specification-td">Band Colour</Td>
                    <Td className="common-userPaneFamily">
                      {matchData?.bandcolour}
                    </Td>
                  </Tr>
                ) : (
                  ""
                )}
                {matchData &&
                  matchData?.bezelfunction &&
                  matchData?.bezelfunction ? (
                  <Tr>
                    <Td className="specification-td">Bezel Function</Td>
                    <Td className="common-userPaneFamily">
                      {matchData?.bezelfunction}
                    </Td>
                  </Tr>
                ) : (
                  ""
                )}
                {matchData &&
                  matchData?.bezelmaterial &&
                  matchData?.bezelmaterial ? (
                  <Tr>
                    <Td className="specification-td">Bezel Material</Td>
                    <Td className="common-userPaneFamily">
                      {matchData?.bezelmaterial}
                    </Td>
                  </Tr>
                ) : (
                  ""
                )}
                {matchData &&
                  matchData?.movementtype &&
                  matchData?.movementtype ? (
                  <Tr>
                    <Td className="specification-td">Movement Type</Td>
                    <Td className="common-userPaneFamily">
                      {matchData?.movementtype}
                    </Td>
                  </Tr>
                ) : (
                  ""
                )}
              </Tbody>
            </Table>
          </TableContainer>
          <TableContainer whiteSpace={"initial"}>
            <Table variant="simple">
              <Tbody>
                {matchData &&
                  matchData?.casediameter &&
                  matchData?.casediameter ? (
                  <Tr>
                    <Td className="specification-td">Case Diameter</Td>
                    <Td className="common-userPaneFamily">
                      {matchData?.casediameter}
                    </Td>
                  </Tr>
                ) : (
                  ""
                )}
                {matchData &&
                  matchData?.casematerial &&
                  matchData?.casematerial ? (
                  <Tr>
                    <Td className="specification-td">Case Material</Td>
                    <Td className="common-userPaneFamily">
                      {matchData?.casematerial}
                    </Td>
                  </Tr>
                ) : (
                  ""
                )}
                {matchData && matchData?.caseshape && matchData?.caseshape ? (
                  <Tr>
                    <Td className="specification-td">Case Shape</Td>
                    <Td className="common-userPaneFamily">
                      {matchData?.caseshape}
                    </Td>
                  </Tr>
                ) : (
                  ""
                )}
                {matchData && matchData?.colour && matchData?.colour ? (
                  <Tr>
                    <Td className="specification-td">Colour</Td>
                    <Td className="common-userPaneFamily">
                      {matchData?.colour}
                    </Td>
                  </Tr>
                ) : (
                  ""
                )}
                {matchData && matchData?.dialcolour && matchData?.dialcolour ? (
                  <Tr>
                    <Td className="specification-td">Dial Colour</Td>
                    <Td className="common-userPaneFamily">
                      {matchData?.dialcolour}
                    </Td>
                  </Tr>
                ) : (
                  ""
                )}
                {matchData &&
                  matchData?.displaytype &&
                  matchData?.displaytype ? (
                  <Tr>
                    <Td className="specification-td">Display Type</Td>
                    <Td className="common-userPaneFamily">
                      {matchData?.displaytype}
                    </Td>
                  </Tr>
                ) : (
                  ""
                )}
                {matchData &&
                  matchData?.warrantytype &&
                  matchData?.warrantytype ? (
                  <Tr>
                    <Td className="specification-td">Warranty Type</Td>
                    <Td className="common-userPaneFamily">
                      {matchData?.warrantytype}
                    </Td>
                  </Tr>
                ) : (
                  ""
                )}
                {matchData &&
                  matchData?.warrantydescription &&
                  matchData?.warrantydescription ? (
                  <Tr>
                    <Td className="specification-td">Warranty Description</Td>
                    <Td className="common-userPaneFamily">
                      {matchData?.warrantydescription}
                    </Td>
                  </Tr>
                ) : (
                  ""
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </Grid>
      ) : (
        <Grid
          templateColumns={{
            base: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(2, 1fr)",
            lg: "repeat(2, 1fr)",
          }}
          justifyContent={"space-between"}
          marginBottom={8}
          gap="20px"
        >
          <TableContainer whiteSpace={"initial"}>
            <Table variant="simple">
              <Tbody>
                {matchData && matchData?.gender && matchData?.gender ? (
                  <Tr>
                    <Td className="specification-td">Gender</Td>
                    <Td className="common-userPaneFamily">
                      {matchData?.gender}
                    </Td>
                  </Tr>
                ) : (
                  ""
                )}
                {matchData &&
                  matchData?.bandmaterial &&
                  matchData?.bandmaterial ? (
                  <Tr>
                    <Td className="specification-td">Band Material</Td>
                    <Td className="common-userPaneFamily">
                      {matchData?.bandmaterial}
                    </Td>
                  </Tr>
                ) : (
                  ""
                )}
                {matchData && matchData?.bandsize && matchData?.bandsize ? (
                  <Tr>
                    <Td className="specification-td">Band Size</Td>
                    <Td className="common-userPaneFamily">
                      {matchData?.bandsize}
                    </Td>
                  </Tr>
                ) : (
                  ""
                )}
                {matchData && matchData?.bandwidth && matchData?.bandwidth ? (
                  <Tr>
                    <Td className="specification-td">Band Width</Td>
                    <Td className="common-userPaneFamily">
                      {matchData?.bandwidth}
                    </Td>
                  </Tr>
                ) : (
                  ""
                )}
                {matchData && matchData?.bandcolour && matchData?.bandcolour ? (
                  <Tr>
                    <Td className="specification-td">Band Colour</Td>
                    <Td className="common-userPaneFamily">
                      {matchData?.bandcolour}
                    </Td>
                  </Tr>
                ) : (
                  ""
                )}
                {matchData &&
                  matchData?.bezelfunction &&
                  matchData?.bezelfunction ? (
                  <Tr>
                    <Td className="specification-td">Bezel Function</Td>
                    <Td className="common-userPaneFamily">
                      {matchData?.bezelfunction}
                    </Td>
                  </Tr>
                ) : (
                  ""
                )}
                {matchData &&
                  matchData?.bezelmaterial &&
                  matchData?.bezelmaterial ? (
                  <Tr>
                    <Td className="specification-td">Bezel Material</Td>
                    <Td className="common-userPaneFamily">
                      {matchData?.bezelmaterial}
                    </Td>
                  </Tr>
                ) : (
                  ""
                )}
                {matchData &&
                  matchData?.movementtype &&
                  matchData?.movementtype ? (
                  <Tr>
                    <Td className="specification-td">Movement Type</Td>
                    <Td className="common-userPaneFamily">
                      {matchData?.movementtype}
                    </Td>
                  </Tr>
                ) : (
                  ""
                )}
              </Tbody>
            </Table>
          </TableContainer>
          <TableContainer whiteSpace={"initial"}>
            <Table variant="simple">
              <Tbody>
                {matchData &&
                  matchData?.casediameter &&
                  matchData?.casediameter ? (
                  <Tr>
                    <Td className="specification-td">Case Diameter</Td>
                    <Td className="common-userPaneFamily">
                      {matchData?.casediameter}
                    </Td>
                  </Tr>
                ) : (
                  ""
                )}
                {matchData &&
                  matchData?.casematerial &&
                  matchData?.casematerial ? (
                  <Tr>
                    <Td className="specification-td">Case Material</Td>
                    <Td className="common-userPaneFamily">
                      {matchData?.casematerial}
                    </Td>
                  </Tr>
                ) : (
                  ""
                )}
                {matchData && matchData?.caseshape && matchData?.caseshape ? (
                  <Tr>
                    <Td className="specification-td">Case Shape</Td>
                    <Td className="common-userPaneFamily">
                      {matchData?.caseshape}
                    </Td>
                  </Tr>
                ) : (
                  ""
                )}
                {matchData && matchData?.colour && matchData?.colour ? (
                  <Tr>
                    <Td className="specification-td">Colour</Td>
                    <Td className="common-userPaneFamily">
                      {matchData?.colour}
                    </Td>
                  </Tr>
                ) : (
                  ""
                )}
                {matchData && matchData?.dialcolour && matchData?.dialcolour ? (
                  <Tr>
                    <Td className="specification-td">Dial Colour</Td>
                    <Td className="common-userPaneFamily">
                      {matchData?.dialcolour}
                    </Td>
                  </Tr>
                ) : (
                  ""
                )}
                {matchData &&
                  matchData?.displaytype &&
                  matchData?.displaytype ? (
                  <Tr>
                    <Td className="specification-td">Display Type</Td>
                    <Td className="common-userPaneFamily">
                      {matchData?.displaytype}
                    </Td>
                  </Tr>
                ) : (
                  ""
                )}
                {matchData &&
                  matchData?.warrantytype &&
                  matchData?.warrantytype ? (
                  <Tr>
                    <Td className="specification-td">Warranty Type</Td>
                    <Td className="common-userPaneFamily">
                      {matchData?.warrantytype}
                    </Td>
                  </Tr>
                ) : (
                  ""
                )}
                {matchData &&
                  matchData?.warrantydescription &&
                  matchData?.warrantydescription ? (
                  <Tr>
                    <Td className="specification-td">Warranty Description</Td>
                    <Td className="common-userPaneFamily">
                      {matchData?.warrantydescription}
                    </Td>
                  </Tr>
                ) : (
                  ""
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </Grid>
      )}
    </div>
  );
}

export default Specification;

import axios from 'axios';
import store from '../store';

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});
const axiosInstance = async (url, method = 'GET', data = null) => {
  const { pathname } = window.location;
  try {
    const headers = {};
    const token = pathname.includes('/admin')
      ? localStorage.getItem('token') && localStorage.getItem('token') !== ''
        ? localStorage.getItem('token')
        : null
      : store.getState().user.token && store.getState().user.token !== ''
      ? store.getState().user.token
      : null;
    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }
    const response = await api({
      url,
      method,
      data,
      headers,
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

export default axiosInstance;

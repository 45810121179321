import { Box, Image, Text } from "@chakra-ui/react";
import React, { Component, useEffect, useState } from "react";
import Slider from "react-slick";
import "./bannerSlider.css";
import { useNavigate } from "react-router-dom";
import mobileViewImage from '../../assets/dashboard/images.jpg'

const BannerSlider = ({ bannerData, mobileBanner }) => {
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(false);

  const settings = {
    dots: false,
    infinite: true,
    speed: 100,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    prevArrow: false,
    nextArrow: false,
    arrows: false,
  };

  useEffect(() => {
    // Function to check if the screen width is below a certain size (e.g., 768px for mobile)
    const checkIsMobile = () => {
      const mobileSize = 450;
      const isMobileView = window.innerWidth <= mobileSize;
      setIsMobile(isMobileView);
    };

    // Listen for window resize events and update the state accordingly
    window.addEventListener('resize', checkIsMobile);

    // Initial check when the component mounts
    checkIsMobile();

    // Clean up the event listener to prevent memory leaks
    return () => {
      window.removeEventListener('resize', checkIsMobile);
    };
  }, []);
  return (
    <Box
      width={'100%'}
      marginTop={{ base: "130px", sm: "125px", md: "160px", lg: 3 }}
      padding={"0 20px"}
      className="banner-slide"
      position={"relative"}
      // margin="auto"
      marginBottom={{ base: "2rem", sm: "3rem", md: "4rem", lg: "2rem" }}
    >
      {/* {isMobile && mobileBanner?.length ? (
        <img src={`${process.env.REACT_APP_IMAGE_URL}${mobileBanner[0]?.image_url}`} alt="Mobile Image" width={'100%'} style={{height: '80vh'}}/>
      ) : ( */}
      <Slider {...settings}>
        {bannerData &&
          bannerData.map((item) => (
            <a href={item.link_url}>
              <Box borderRadius={"8px"} >
                <Image
                  objectFit={"cover"}
                  // height={{ md: "76vh", lg: "76vh", base: "60vh" }}
                  // margin={"auto"}
                  width={"100%"}
                  src={isMobile ? `${process.env.REACT_APP_IMAGE_URL}${item.mobile_image_url}` : `${process.env.REACT_APP_IMAGE_URL}${item.image_url}`}
                  cursor={"pointer"}
                />
              </Box>
            </a>
          ))}
      </Slider>
      {/* )} */}
    </Box>
  );
};

export default BannerSlider;

import axiosInstance from '../axios';
import { handleApplyOffer } from './OfferActions';

export const ADD_TO_CART_REQUEST = 'ADD_TO_CART_REQUEST';
export const ADD_TO_CART_SUCCESS = 'ADD_TO_CART_SUCCESS';
export const ADD_TO_CART_FAILURE = 'ADD_TO_CART_FAILURE';

export const fetchProductDataRequest = () => ({
  type: ADD_TO_CART_REQUEST,
});

// Action creator for the success state
export const fetchProductDataSuccess = (data) => ({
  type: ADD_TO_CART_SUCCESS,
  payload: data,
});

// Action creator for the failure state
export const fetchProductDataFailure = (error) => ({
  type: ADD_TO_CART_FAILURE,
  payload: error,
});

// Action creator for initiating the API call
export const addtocart = (payload) => (dispatch) => {
  dispatch(fetchProductDataRequest());
  axiosInstance(`/cart/`, 'Post', payload)
    .then(async (response) => {
      // Dispatch success action with the received data
      const getCartItems = await axiosInstance(`/cart/${payload.userId}`, 'get', null);
      dispatch(handleApplyOffer({}));
      dispatch(fetchProductDataSuccess(getCartItems.data.data));
    })
    .catch((error) => {
      // Dispatch failure action with the error message
      dispatch(fetchProductDataFailure(error.message));
    });
};
export const addtocartNew = (payload) => (dispatch) => {
  dispatch(fetchProductDataRequest());
  axiosInstance(`/cart/add-to-cart`, 'Post', payload)
    .then(async (response) => {
      // Dispatch success action with the received data
      const getCartItems = await axiosInstance(`/cart/${payload.userId}`, 'get', null);
      dispatch(handleApplyOffer({}));
      dispatch(fetchProductDataSuccess(getCartItems.data.data));
    })
    .catch((error) => {
      // Dispatch failure action with the error message
      dispatch(fetchProductDataFailure(error.message));
    });
};
export const cartList = (payload) => (dispatch) => {
  dispatch(fetchProductDataRequest());
  axiosInstance(`/cart/${payload}`, 'get')
    .then(async (response) => {
      // Dispatch success action with the received data

      dispatch(fetchProductDataSuccess(response.data.data));
    })
    .catch((error) => {
      // Dispatch failure action with the error message
      dispatch(fetchProductDataFailure(error.message));
    });
};
export const handleCartQuantity = (payload) => (dispatch) => {
  dispatch(fetchProductDataRequest());
  axiosInstance(`/cart/${payload.cartId}`, 'put', { quantity: payload.quantity })
    .then(async (response) => {
      // Dispatch success action with the received data
      const getCartItems = await axiosInstance(`/cart/${payload.userId}`, 'get', null);
      dispatch(handleApplyOffer({}));
      dispatch(fetchProductDataSuccess(getCartItems.data.data));
    })
    .catch((error) => {
      // Dispatch failure action with the error message
      dispatch(fetchProductDataFailure(error.message));
    });
};
export const deleteCart = (payload) => (dispatch) => {
  dispatch(fetchProductDataRequest());
  axiosInstance(`/cart/${payload.cartId}`, 'delete')
    .then(async (response) => {
      // Dispatch success action with the received data
      const getCartItems = await axiosInstance(`/cart/${payload.userId}`, 'get', null);
      dispatch(handleApplyOffer({}));
      dispatch(fetchProductDataSuccess(getCartItems.data.data));
    })
    .catch((error) => {
      // Dispatch failure action with the error message
      dispatch(fetchProductDataFailure(error.message));
    });
};

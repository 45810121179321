import Notification from '../components/notification';
import axiosInstance from '../axios';

export const GET_ORDER_LIST_REQUEST = 'GET_ORDER_LIST_REQUEST';
export const GET_ORDER_LIST_SUCCESS = 'GET_ORDER_LIST_SUCCESS';
export const GET_ORDER_LIST_FAILURE = 'GET_ORDER_LIST_FAILURE';
export const GET_ORDER_LIST_ID_SUCCESS = 'GET_ORDER_LIST_ID_SUCCESS';

export const fetchOrderListRequest = () => ({
  type: GET_ORDER_LIST_REQUEST,
});

// Action creator for the success state
export const fetchOrderListSuccess = (data) => ({
  type: GET_ORDER_LIST_SUCCESS,
  payload: data,
});

// Action creator for the failure state
export const fetchOrderListFailure = (error) => ({
  type: GET_ORDER_LIST_FAILURE,
  payload: error,
});

export const fetchOrderListByIdSuccess = (error) => ({
  type: GET_ORDER_LIST_ID_SUCCESS,
  payload: error,
});

// Action creator for initiating the API call
export const getUserOrderList = (id, pagination) => (dispatch) => {
  dispatch(fetchOrderListSuccess());
  axiosInstance(`/order/get-users-order/${id}?page=${pagination.page}&limit=${pagination.limit}`, 'GET', null)
    .then((response) => {
      // Dispatch success action with the received data
      dispatch(fetchOrderListSuccess(response.data.data));
    })
    .catch((error) => {
      // Dispatch failure action with the error message
      dispatch(fetchOrderListFailure(error.message));
    });
};

export const getUserOrderListById = (id) => (dispatch) => {
  //   dispatch(fetchOrderListSuccess());
  axiosInstance(`/order/get-order/${id}`, 'GET', null)
    .then((response) => {
      // Dispatch success action with the received data
      dispatch(fetchOrderListByIdSuccess(response.data.data));
    })
    .catch((error) => {
      // Dispatch failure action with the error message
      //   dispatch(fetchOrderListFailure(error.message));
    });
};

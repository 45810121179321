// reducers.js
import {
  FETCH_PRODUCTLIST_REQUEST,
  FETCH_PRODUCTLIST_SUCCESS,
  FETCH_PRODUCTLIST_FAILURE,
} from '../actions/ProductListingActions';

const initialState = {
  loading: false,
  ProductList: [],
  // paginationData: {},
  error: '',
};

const ProductListReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PRODUCTLIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_PRODUCTLIST_SUCCESS:
      return {
        loading: false,
        ProductList: action.payload,
        // paginationData: action.payload.data?.pagination,
        error: '',
      };
    case FETCH_PRODUCTLIST_FAILURE:
      return {
        loading: false,
        ProductList: [],
        // paginationData: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

export default ProductListReducer;

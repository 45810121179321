// reducers.js
import {
  FETCH_PRODUCTLIST_ID_FAILURE,
  FETCH_PRODUCTLIST_ID_REQUEST,
  FETCH_PRODUCTLIST_ID_SUCCESS,
} from '../actions/ProductListingActions';

const initialState = {
  loading: false,
  productById: [],
  // paginationData: {},
  error: '',
};

const ProductByIdReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PRODUCTLIST_ID_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_PRODUCTLIST_ID_SUCCESS:
      return {
        loading: false,
        productById: action.payload,
        error: '',
      };
    case FETCH_PRODUCTLIST_ID_FAILURE:
      return {
        loading: false,
        productById: [],
        // paginationData: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

export default ProductByIdReducer;

import React, { useEffect } from "react";
import {
  Box,
  Button,
  Flex,
  HStack,
  Heading,
  Stack,
  Text,
  useColorModeValue as mode,
} from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AiOutlineShoppingCart } from "react-icons/ai";
import Header from "../../../components/header";
import { CartItem } from "./CartItem";
import { CartOrderSummary } from "./CartOrderSummary";
import newArrival1 from "../../../assets/newArrival1.webp";
import newArrival2 from "../../../assets/newArrival2.webp";
import newArrival3 from "../../../assets/newArrival3.webp";
import Footer from "../../../components/footer/Footer";
import {
  cartList,
  deleteCart,
  handleCartQuantity,
} from "../../../actions/CartActions";
import store from "../../../store";
import { fetchHomeData } from "../../../actions/HomePageActions";
import { fetchOffersData } from "../../../actions/OfferActions";
import { notificationStyle } from "../../product-list/ProductListing";

function CartPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { cart } = useSelector((state) => state.cart);
  const { userData } = useSelector((state) => state.user);
  const { homePageData } = useSelector((state) => state.home);
  useEffect(() => {
    if (!homePageData.length) {
      dispatch(fetchHomeData());
    }
    if(userData?.user_id){
      dispatch(cartList(userData.user_id));
    }
  }, []);
  useEffect(() => {
    const findTotal = cart.reduce((acc, obj) => {
      acc += obj.quantity * Number(obj.Product.price);
      return acc;
    }, 0);

    const findDiscountedTotal = cart.reduce((acc, obj) => {
      if (
        obj.Product.discount &&
        obj.Product.discount !== "" &&
        obj.Product.discount !== null
      ) {
        acc +=
          obj.quantity *
          (
            (Number(obj.Product.price) * Number(obj.Product.discount)) /
            100
          ).toFixed(2);
      }
      return acc;
    }, 0);
    dispatch(fetchOffersData(findTotal - findDiscountedTotal));
  }, [cart]);
  const handleQuantity = async (cartId, quantity) => {
    dispatch(
      handleCartQuantity({ cartId, userId: userData.user_id, quantity })
    );
  };
  const handleDeleteItem = async (cartId) => {
    dispatch(deleteCart({ cartId, userId: userData.user_id }));
    toast.success("Remove Successfully", notificationStyle);
  };

  return (
    <div>
      <Header headerData={homePageData} />
      <Box
        maxW={{
          base: "3xl",
          lg: "7xl",
        }}
        mx="auto"
        px={{
          base: "4",
          md: "8",
          lg: "12",
        }}
        py={{
          base: "6",
          md: "8",
          lg: "12",
        }}
        marginTop={{ base: "120px", sm: "120px", md: "120px", lg: 0 }}
      >
        <Stack
          direction={{
            base: "column",
            lg: "row",
          }}
          align={{
            lg: "flex-start",
          }}
          spacing={{
            base: "8",
            md: "16",
          }}
        >
          <Stack
            spacing={{
              base: "8",
              md: "10",
            }}
            flex="2"
          >
            <Heading
              fontSize="2xl"
              fontWeight="extrabold"
              className="common-userPaneFamily"
            >
              Shopping Cart
            </Heading>

            <Stack
              spacing="6"
              borderWidth={{ md: "1px" }}
              overflowY={{
                md: cart.length > 3 ? "scroll" : "",
                base: cart.length > 2 ? "scroll" : "",
              }}
              className="cartItem-scroll"
              height={{ md: "475px", base: cart.length > 2 ? "475px" : "" }}
            >
              {cart.length === 0 ? (
                <Box position={"absolute"} top={"43%"} left={"29%"}>
                  <Box display={"flex"} justifyContent={"center"}>
                    <AiOutlineShoppingCart fontSize={"100px"} color="#f7f5f4" />
                  </Box>
                  <Text className="empty-cart2">Your Cart is Empty</Text>
                  <Button
                    className="continue-shoppingbtn2"
                    onClick={() => navigate("/product")}
                  >
                    Continue Shopping
                  </Button>
                </Box>
              ) : (
                cart &&
                cart.length &&
                cart.map((item) => (
                  <CartItem
                    key={item.id}
                    {...item}
                    handleQuantity={handleQuantity}
                    handleDeleteItem={handleDeleteItem}
                  />
                ))
              )}
            </Stack>
          </Stack>

          <Flex direction="column" align="center" flex="1">
            <CartOrderSummary cart={cart} />
            <HStack
              mt="6"
              fontWeight="semibold"
              className="hover-bordereffect"
              // _hover={{
              //   borderBottom: '1px solid #ed691f',
              // }}
            >
              {/* <p className="common-textColor">or</p> */}
              <Text
                className="common-userPaneFamily common-textColor"
                href="/product"
                cursor={"pointer"}
                onClick={() => navigate("/product")}
              >
                {/* <span className="common-textColor" style={{ fontSize: '18px' }}> */}
                {homePageData &&
                homePageData?.setting &&
                homePageData?.setting?.continueShopping
                  ? homePageData?.setting?.continueShopping
                  : "Continue Shopping"}
                {/* </span>{' '} */}
              </Text>
            </HStack>
          </Flex>
        </Stack>
      </Box>
      <Footer />
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}

export default CartPage;

import React, { useEffect, useState } from "react";
import "../addCategory/addCategory.css";
import "./addSection.css";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  Paper,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  Drawer,
  Box,
  Table,
  Checkbox,
  Card,
} from "@mui/material";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import axios from "axios";
import axiosInstance from "../../axios";
import Notification from "../notification";
import TableHeader from "../../sections/@dashboard/user/tableHeader";
import noImages from "../../assets/noImageAvailable.jpg";
import Scrollbar from "../scrollbar/Scrollbar";

const TABLE_HEAD = [
  { id: "" },
  { id: "id", label: "Id", alignRight: false },
  { id: "image", label: "Image", alignRight: false },
  { id: "title", label: "Title", alignRight: false },
];

function AddSection({ setOpenDrawer, bannerListAPI, addEdit, id }) {
  const [banner, setBanner] = useState({
    title: "",
    description: "",
    link_url: "",
    section: null,
    is_active: false,
    section_type: false,
  });
  const validationSchema = Yup.object({
    title: Yup.string().required("Banner Title is required").trim(),
    description: Yup.string().required("Banner Description is required").trim(),
    // link_url: Yup.string()
    //   .required('Link URL is required')
    //   .matches(
    //     /((http?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
    //     'Enter correct url!'
    //   )
    //   .trim(),
  });

  const [productImage, setProductImage] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [productImageId, setProductImageId] = useState("");
  const [productListing, setProductListing] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [selected, setSelected] = useState([]);
  const [filters, setFilters] = useState({
    search: "",
    category: "",
  });
  // Banner list get by id
  useEffect(() => {
    const ProductListAPI = async () => {
      const res = await axiosInstance(
        `/product/all-product-list/?search=${filters.search}&category=${filters.category}`,
        "GET",
        null
      );
      if (res.status === 200) {
        setProductListing(
          res.data.data.map((data) => ({
            id: data.product_id,
            title: data.name,
            image: data?.ProductImages[0]?.image_url,
          }))
        );
      }
      const category = await axiosInstance(`/category/`, "GET", null);
      if (category.status === 200) {
        setCategoryList(category.data.data);
      }
    };
    ProductListAPI();

    if (addEdit && addEdit === "edit") {
      const fetchData = async () => {
        const res = await axiosInstance(
          `/home-page/home-page-section-2/${id}`,
          "GET",
          null
        );
        if (res.status === 200) {
          if (res.data.data) {
            const data = res.data.data;
            setBanner({
              title: data.title,
              description: data.description,
              link_url: data.link_url,
              section: data.section,
              is_active: data.is_active,
              section_type: data.section_type === "product" ? true : false,
            });
            if (
              data.section_type === "product" &&
              JSON.parse(res.data.data.products) &&
              JSON.parse(res.data.data.products).length
            ) {
              const product = JSON.parse(res.data.data.products);
              setSelected(product);
            }
            if (data.image_url && data.image_url !== "") {
              const allproductImages = {
                id: data.id,
                image: `${process.env.REACT_APP_IMAGE_URL}${data.image_url}`,
              };
              // setProductImage([allproductImages]);
              setSelectedImages([allproductImages]);
            }
          }
        }
      };
      fetchData();
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const response = await axiosInstance(
        `/product/all-product-list/?search=${filters.search}&category=${filters.category}`,
        "GET",
        null
      );

      if (response.status === 200) {
        if (response.data.data.length > 0) {
          setProductListing(
            response.data.data.map((data, index) => ({
              id: data.product_id,
              image: data.ProductImages ? data.ProductImages[0]?.image_url : "",
              title: data.name,
            }))
          );
        } else {
          setProductListing([]);
        }
        // Notification('success', 'Category List', response.data.message, 2000);
      } else {
        // Notification('error', 'Category List', response.data.message, 2000);
      }
    };

    fetchData();
  }, [filters]);

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };
  const handleFilter = (e) => {
    const { name, value } = e.target;
    setFilters({ ...filters, [name]: value });
  };
  // add banner
  const handleBtnSubmit = async (values) => {
    if (addEdit && addEdit !== "edit") {
      let imagename;
      if (productImage[0]) {
        const formData = new FormData();
        // Object.values(productImage).forEach((file) => {
        formData.append("image", productImage[0]);
        // });
        const image = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/common/upload`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        imagename = image.data.data;
      } else {
        imagename = "";
      }

      const response = await axiosInstance(
        `/home-page/home-page-section-2`,
        "POST",
        {
          ...values,
          image_url: imagename,
          section_type: values.section_type === true ? "product" : "category",
          products: JSON.stringify(selected),
        }
      );
      if (response.status === 200) {
        await Notification(
          "success",
          "Add Section",
          response.data.message,
          2000
        );
        setOpenDrawer(false);
        setBanner({
          title: "",
          description: "",
          link_url: "",
          section: null,
          is_active: false,
        });
        setProductImage([]);
        setSelectedImages([]);
        await bannerListAPI();
      } else {
        await Notification("error", "Add Section", response.data.message, 2000);
      }
    }
  };

  // handle image upload
  const handleImageUpload = async (event) => {
    const { files } = event.target;
    const element = files[0];
    const imagesArray = element;
    const previewArray = { id: null, image: URL.createObjectURL(element) };
    setProductImage([imagesArray]);
    setSelectedImages([previewArray]);
  };

  // close delete modal
  const handleCloseDeleteModal = () => {
    setOpenDialog(false);
  };

  // handle delete image
  const handleDeleteImage = async () => {
    const updatedImages = [...selectedImages];
    const index = selectedImages.findIndex(
      (item) => item.id === productImageId
    );
    updatedImages.splice(index, 1);
    setSelectedImages(updatedImages);
    setProductImage(updatedImages);
    const response = await axiosInstance(
      `${process.env.REACT_APP_BASE_URL}/category/image-delete/${productImageId}`,
      "DELETE",
      null
    );
    if (response.status === 200) {
      Notification("success", "Delete Image", response.data.message, 2000);
      setOpenDialog(false);
      // await userListAPI();
    } else {
      Notification("error", "Delete Image", response.data.message, 2000);
    }
  };

  // edit api call
  const handleUpdate = async (values) => {
    let imagename = "";
    if (productImage[0]) {
      const formData = new FormData();
      // Object.values(productImage).forEach((file) => {
      formData.append("image", productImage[0]);
      // });
      const image = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/common/upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      imagename = image.data.data;
    }
    let data;
    if (imagename !== "") {
      data = { ...values, image_url: imagename };
    } else {
      data = values;
    }
    const response = await axiosInstance(
      `/home-page/home-page-section-2/${id}`,
      "PUT",
      {
        ...data,
        section_type: values.section_type === true ? "product" : "category",
        products: JSON.stringify(selected),
      }
    );
    if (response.status === 200) {
      setOpenDrawer(false);
      setBanner({
        title: "",
        description: "",
        link_url: "",
        section: null,
        is_active: false,
      });
      setOpenDrawer(false);
      setSelectedImages([]);
      setProductImage([]);
      await bannerListAPI();
      await Notification("success", "Edit Banner", response.data.message, 2000);
    } else {
      await Notification(
        "error",
        "Edit Banner",
        response.response.data.message,
        2000
      );
    }
  };

  return (
    <div style={{ overflow: "hidden" }}>
      <button
        className="fixed focus:outline-none z-50 text-red-500 hover:bg-red-100 hover:text-gray-700 transition-colors duration-150 bg-white shadow-md mr-6 mt-2 right-0 left-auto w-8 h-8 rounded-full block text-center z-30 mr-7 "
        onClick={() => setOpenDrawer(false)}
      >
        <svg
          stroke="currentColor"
          fill="none"
          strokeWidth="2"
          viewBox="0 0 24 24"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="mx-auto"
          height="1em"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
        >
          <line x1="18" y1="6" x2="6" y2="18" />
          <line x1="6" y1="6" x2="18" y2="18" />
        </svg>
      </button>
      <div className="w-full relative p-border-gray-100 bg-gray-50 border-gray-700 bg-white-800 text-black-300 model-title">
        <div>
          {addEdit && addEdit === "edit" ? (
            <>
              <h4 className="text-xl font-medium">Edit Section</h4>
              <p className="mb-0 text-sm">
                Edit your Section and necessary information from here
              </p>
            </>
          ) : (
            <>
              <h4 className="text-xl font-medium">Add Section</h4>
              <p className="mb-0 text-sm">
                Add your Section and necessary information from here
              </p>
            </>
          )}
        </div>
      </div>
      <div className="mt-12 flex flex-col w-full h-full justify-between">
        <div
          className="w-full md:w-7/12 lg:w-8/12 xl:w-8/12 relative bg-white-700 text-gray-200"
          style={{
            position: "relative",
            overflow: "hidden",
            width: "100%",
            height: "100%",
          }}
        >
          <div
            style={{
              // position: 'absolute',
              inset: "0px",
              overflow: "scroll",
              marginRight: "-21px",
              marginBottom: "-21px",
            }}
          >
            <Formik
              enableReinitialize
              initialValues={banner}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                handleBtnSubmit(values);
                // setSubmitting(true);
                // resetForm();
                // setSubmitting(false);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
              }) => (
                <Form className="block" onSubmit={handleSubmit}>
                  <div className="px-6 pt-12 flex-grow w-full pb-40 max-h-full md:pb-32 lg:pb-32 xl:pb-32 scroll-bar">
                    {/* Banner Image Upload */}

                    <div className="flex grid-cols-6 gap-3 md:gap-5 xl:gap-6 lg:gap-6 mb-8 formLabel">
                      <label className="block text-sm text-gray-700 dark:text-gray-400 col-span-4 sm:col-span-2 font-medium text-sm">
                        Section Image
                      </label>
                      <div className="file-input col-span-8 sm:col-span-4 formInput">
                        <input
                          type="file"
                          className="block w-full px-3 py-1 text-sm focus:outline-none dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white"
                          onChange={(e) => handleImageUpload(e)}
                          onBlur={handleBlur}
                        />
                        <span className="button">Choose File</span>
                      </div>
                    </div>
                    <div className="col-span-8 flex images">
                      {selectedImages && selectedImages.length
                        ? selectedImages
                            .filter((i) => i.image !== "")
                            .map((image, index) => (
                              <div
                                className="image-upload"
                                key={index}
                                style={{ position: "relative" }}
                              >
                                {/* <span onClick={() => handleOpenDeleteModal(index, id)}>
                                  <HighlightOffIcon className="cancel-images" />
                                </span> */}
                                <div className="set-image">
                                  <img
                                    key={index}
                                    src={image.image}
                                    alt=""
                                    height={"65px"}
                                    width={"100px"}
                                  />
                                </div>
                              </div>
                            ))
                        : ""}
                    </div>
                    <div className="flex grid-cols-6 gap-3 md:gap-5 xl:gap-6 lg:gap-6 mb-6 formLabel">
                      <label className="block text-sm text-gray-700 dark:text-gray-400 col-span-4 sm:col-span-2 font-medium text-sm">
                        Section Name <span className="required-field">*</span>
                      </label>
                      <div className="col-span-8 sm:col-span-4 formInput">
                        <Field
                          className={
                            errors.title && touched.title
                              ? "block w-full px-3 py-1 text-sm focus:outline-none dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 text-black dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white input-error"
                              : "block w-full px-3 py-1 text-sm focus:outline-none dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 text-black dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white"
                          }
                          type="text"
                          name="title"
                          placeholder="Section Name"
                          value={values.title}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {touched.title && errors.title && (
                          <div className="error-message">{errors.title}</div>
                        )}
                      </div>
                    </div>
                    <div className="flex grid-cols-6 gap-3 md:gap-5 xl:gap-6 lg:gap-6 mb-6 formLabel">
                      <label className="block text-sm text-gray-700 dark:text-gray-400 col-span-4 sm:col-span-2 font-medium text-sm">
                        Section Description{" "}
                        <span className="required-field">*</span>
                      </label>
                      <div className="col-span-8 sm:col-span-4 formInput">
                        <textarea
                          className={
                            errors.description && touched.description
                              ? "block w-full pl-2 pt-2 text-sm dark:text-gray-300 rounded-md focus:outline-none form-textarea focus:border-green-400 border-gray-300 dark:border-gray-600 text-black dark:focus:border-gray-600 dark:bg-gray-700 dark:focus:ring-gray-300 focus:ring focus:ring-green-300 border text-sm focus:outline-none block w-full bg-gray-100 border-transparent focus:bg-white input-error"
                              : "block w-full text-sm pl-2 pt-2 dark:text-gray-300 rounded-md focus:outline-none text-black form-textarea focus:border-green-400 border-gray-300 dark:border-gray-600 dark:focus:border-gray-600 dark:bg-gray-700 dark:focus:ring-gray-300 focus:ring focus:ring-green-300 border text-sm focus:outline-none block w-full bg-gray-100 border-transparent focus:bg-white"
                          }
                          name="description"
                          placeholder="Section details"
                          rows="4"
                          spellCheck="false"
                          type="text"
                          value={values.description}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {touched.description && errors.description && (
                          <div className="error-message">
                            {errors.description}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="flex grid-cols-6 gap-3 md:gap-5 xl:gap-6 lg:gap-6 mb-6 formLabel">
                      <label className="block text-sm text-gray-700 dark:text-gray-400 col-span-4 sm:col-span-2 font-medium text-sm">
                        Section Type
                      </label>
                      <div className="col-span-8 sm:col-span-4 formInput">
                        <div className="toggle">
                          <input
                            type="checkbox"
                            name="section_type"
                            id="section"
                            checked={values.section_type}
                            onChange={handleChange}
                          />
                          <label htmlFor="section">
                            <span className="sr-only">Toggle Switch</span>
                          </label>
                        </div>
                      </div>
                    </div>
                    {!values.section_type ? (
                      <div className="flex grid-cols-6 gap-3 md:gap-5 xl:gap-6 lg:gap-6 mb-6 formLabel">
                        <label className="block text-sm text-gray-700 dark:text-gray-400 col-span-4 sm:col-span-2 font-medium text-sm">
                          Section Link <span className="required-field">*</span>
                        </label>
                        <div className="col-span-8 sm:col-span-4 formInput">
                          <Field
                            className={
                              errors.link_url && touched.link_url
                                ? "block w-full px-3 py-1 text-sm focus:outline-none dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring text-black focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white input-error"
                                : "block w-full px-3 py-1 text-sm focus:outline-none dark:text-gray-300 leading-5 text-black rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white"
                            }
                            type="text"
                            name="link_url"
                            placeholder="Section Link"
                            value={values.link_url}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {touched.link_url && errors.link_url && (
                            <div className="error-message">
                              {errors.link_url}
                            </div>
                          )}
                        </div>
                      </div>
                    ) : (
                      <div
                        style={{
                          height: "500px",
                          overflowY: "scroll",
                          marginBottom: 25,
                          width: "100%",
                          overflowX: "hidden",
                        }}
                      >
                        <Card className="card-style mb-0.5">
                          <div className="pt-0.2">
                            <form className="px-3 py-3 grid gap-4 lg:gap-6 xl:gap-6 md:flex xl:flex">
                              <div className="flex-grow-0 md:flex-grow lg:flex-grow xl:flex-grow">
                                <input
                                  className="block w-full px-3 py-1 text-sm focus:outline-none dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 border-transparent focus:bg-white"
                                  type="search"
                                  name="search"
                                  placeholder="Search by product name"
                                  value={filters.search}
                                  onChange={(e) => handleFilter(e)}
                                />
                              </div>
                              <div className="flex-grow-0 md:flex-grow lg:flex-grow xl:flex-grow">
                                <select
                                  className="block w-full px-2 py-1 text-sm dark:text-gray-300 focus:outline-none rounded-md form-select focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:shadow-none focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 leading-5 border h-10 text-sm focus:outline-none block w-full bg-gray-100 border-transparent focus:bg-white"
                                  name="category"
                                  onChange={(e) => handleFilter(e)}
                                >
                                  <option value="" hidden="">
                                    Choose a Category
                                  </option>
                                  {categoryList.map((item) => (
                                    <option
                                      value={item.category_id}
                                      key={item.category_id}
                                    >
                                      {item.category_name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </form>
                          </div>
                        </Card>

                        <Card className="card-style">
                          <Scrollbar>
                            <TableContainer>
                              <Table>
                                <TableHeader
                                  // order={order}
                                  // orderBy={orderBy}
                                  headLabel={TABLE_HEAD}
                                  // rowCount={count}
                                  numSelected={selected?.length}
                                  // onRequestSort={handleRequestSort}
                                  checkbox={false}
                                />

                                {productListing.length > 0 ? (
                                  <TableBody>
                                    {/* filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) */}
                                    {productListing.map((row) => {
                                      const { id, image, title } = row;
                                      const isItemSelected = isSelected(id);
                                      // const selectedUser = selected.indexOf(name) !== -1;
                                      return (
                                        <TableRow
                                          hover
                                          key={id}
                                          role="checkbox"
                                          onClick={(event) =>
                                            handleClick(event, id)
                                          }
                                          selected={isItemSelected}
                                          aria-checked={isItemSelected}
                                        >
                                          <TableCell padding="checkbox">
                                            <Checkbox
                                              color="primary"
                                              checked={isItemSelected}
                                              inputProps={{
                                                "aria-labelledby": id,
                                              }}
                                            />
                                          </TableCell>
                                          <TableCell align="left">
                                            {id}
                                          </TableCell>
                                          {image ? (
                                            <TableCell align="left">
                                              <div
                                                style={{
                                                  height: "100px",
                                                  width: "100px",
                                                }}
                                              >
                                                <img
                                                  src={`${process.env.REACT_APP_IMAGE_URL}${image}`}
                                                  alt=""
                                                />
                                              </div>
                                            </TableCell>
                                          ) : (
                                            <TableCell align="left">
                                              <div
                                                style={{
                                                  height: "100px",
                                                  width: "100px",
                                                }}
                                              >
                                                <img src={noImages} alt="" />
                                              </div>
                                            </TableCell>
                                          )}
                                          <TableCell align="left">
                                            {title}
                                          </TableCell>
                                        </TableRow>
                                      );
                                    })}
                                  </TableBody>
                                ) : (
                                  <TableBody>
                                    <TableRow>
                                      <TableCell
                                        align="center"
                                        colSpan={12}
                                        sx={{ py: 3 }}
                                      >
                                        <Paper
                                          sx={{
                                            textAlign: "center",
                                          }}
                                        >
                                          <Box
                                            className="not-fount-img"
                                            component="img"
                                            src="/assets/illustrations/illustration_404.svg"
                                            sx={{
                                              height: 160,
                                              mx: "auto",
                                              my: { xs: 5, sm: 10 },
                                            }}
                                          />
                                          <Typography
                                            variant="h4"
                                            paragraph
                                            className="not-fount-1"
                                          >
                                            No records has been added yet.
                                          </Typography>
                                          {/* <Typography variant="h6" paragraph className="not-fount-2">
                                Add a new record by simpley clicking the button on top right side.
                              </Typography> */}
                                        </Paper>
                                      </TableCell>
                                    </TableRow>
                                  </TableBody>
                                )}
                              </Table>
                            </TableContainer>
                          </Scrollbar>
                        </Card>
                      </div>
                    )}
                    {/* <div className="flex grid-cols-6 gap-3 md:gap-5 xl:gap-6 lg:gap-6 mb-6 formLabel">
                      <label className="block text-sm text-gray-700 dark:text-gray-400 col-span-4 sm:col-span-2 font-medium text-sm">
                        Section <span className="required-field">*</span>
                      </label>
                      <div className="col-span-8 sm:col-span-4 formInput">
                        <Field
                          className={
                            errors.section && touched.section
                              ? 'block w-full px-3 py-1 text-sm focus:outline-none dark:text-gray-300 leading-5 rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring text-black focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white input-error'
                              : 'block w-full px-3 py-1 text-sm focus:outline-none dark:text-gray-300 leading-5 text-black rounded-md focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 border h-10 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white'
                          }
                          type="number"
                          name="section"
                          value={values.section}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          onWheel={(e) => e.target.blur()}
                        />
                        {touched.section && errors.section && <div className="error-message">{errors.section}</div>}
                      </div>
                    </div> */}
                    <div className="flex grid-cols-6 gap-3 md:gap-5 xl:gap-6 lg:gap-6 mb-6 formLabel">
                      <label className="block text-sm text-gray-700 dark:text-gray-400 col-span-4 sm:col-span-2 font-medium text-sm">
                        Section <span className="required-field">*</span>
                      </label>
                      <div className="col-span-8 sm:col-span-4 formInput">
                        <div className="relative">
                          <svg
                            className="w-2 h-2 absolute top-0 right-0 m-4 pointer-events-none"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 412 232"
                          />
                          <select
                            className={
                              errors.section && touched.section
                                ? "col-span-8 sm:col-span-4 border text-black border-gray-300 rounded-md  h-10 pl-5 pr-10 bg-gray-100 hover:border-gray-400 focus:outline-none appearance-none w-full text-sm dark:text-gray-300 rounded-md dark:focus:border-gray-600 dark:bg-gray-700 dark:focus:ring-gray-300 focus:ring focus:ring-green-300 border text-sm focus:outline-none block w-full bg-gray-100 border-transparent focus:bg-white input-error"
                                : "col-span-8 sm:col-span-4 border text-black border-gray-300 rounded-md  h-10 pl-5 pr-10 bg-gray-100 hover:border-gray-400 focus:outline-none appearance-none w-full text-sm dark:text-gray-300 rounded-md dark:focus:border-gray-600 dark:bg-gray-700 dark:focus:ring-gray-300 focus:ring focus:ring-green-300 border text-sm focus:outline-none block w-full bg-gray-100 border-transparent focus:bg-white"
                            }
                            name="section"
                            value={values.section}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          >
                            <option>Choose a Section</option>
                            <option value={1}>1</option>
                            <option value={2}>2</option>
                          </select>
                          {touched.section && errors.section && (
                            <div className="error-message">
                              {errors.section}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="flex grid-cols-6 gap-3 md:gap-5 xl:gap-6 lg:gap-6 mb-6 formLabel">
                      <label className="block text-sm text-gray-700 dark:text-gray-400 col-span-4 sm:col-span-2 font-medium text-sm">
                        Active
                      </label>
                      <div className="col-span-8 sm:col-span-4 formInput">
                        <div className="toggle">
                          <input
                            type="checkbox"
                            name="is_active"
                            id="is_active"
                            checked={values.is_active}
                            onChange={handleChange}
                          />
                          <label htmlFor="is_active">
                            <span className="sr-only">Toggle Switch</span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="px-6 pt-8 fixed bottom-0  right-0 py-4 lg:py-8 px-6 grid gap-4 lg:gap-6 xl:gap-6 md:flex xl:flex bg-gray-50 border-gray-100 border-gray-700 bg-white-800 text-black-300 cancel-btn"
                    style={{ paddingTop: "1rem" }}
                  >
                    <div className="flex-grow-0 md:flex-grow lg:flex-grow xl:flex-grow btn-style">
                      <button
                        className="align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-gray-600 border-gray-200 border text-gray-400 focus:outline-none rounded-lg border border-gray-200 px-4 w-full mr-3 flex items-center justify-center cursor-pointer h-12 bg-gray-200 h-12 bg-white w-full text-red-500 hover:bg-red-50 hover:border-red-100 hover:text-red-600 bg-gray-700 border-white-700 text-gray-500 hover:bg-gray-800 hover:text-red-700"
                        type="button"
                        onClick={() => setOpenDrawer(false)}
                      >
                        Cancel
                      </button>
                    </div>
                    <div className="flex-grow-0 md:flex-grow lg:flex-grow xl:flex-grow btn-style">
                      <button
                        className="align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-white border border-transparent common-background focus:ring focus:ring-green-300 w-full h-12"
                        type="submit"
                        onClick={
                          addEdit && addEdit === "edit"
                            ? () => handleUpdate(values)
                            : ""
                        }
                      >
                        {addEdit && addEdit === "edit" ? (
                          <span>Edit Section</span>
                        ) : (
                          <span>Add Section</span>
                        )}
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          <div
            style={{
              position: "absolute",
              height: "6px",
              right: "2px",
              bottom: "2px",
              left: "2px",
              borderRadius: "3px",
            }}
          >
            <div
              style={{
                position: "relative",
                display: "block",
                height: "100%",
                cursor: "pointer",
                borderRadius: "inherit",
                backgroundColor: "rgba(0, 0, 0, 0.2)",
                width: "0px",
              }}
            />
          </div>
          <Dialog
            open={openDialog}
            keepMounted
            onClose={handleCloseDeleteModal}
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle>{"Delete User"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                Are you sure you want to delete this image?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDeleteModal}>No</Button>
              <Button onClick={() => handleDeleteImage()}>Yes</Button>
            </DialogActions>
          </Dialog>
          {/* <div
            style={{ position: 'absolute', width: '6px', right: '2px', bottom: '2px', top: '2px', borderRadius: '3px' }}
          >
            <div
              style={{
                position: 'relative',
                display: 'block',
                width: '100%',
                cursor: 'pointer',
                borderradius: 'inherit',
                backgroundColor: 'rgba(0, 0, 0, 0.2)',
                height: '359px',
                transform: 'translateY(0px)',
              }}
            />
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default AddSection;

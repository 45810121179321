import {
  FETCH_SEARCHLIST_FAILURE,
  FETCH_SEARCHLIST_REQUEST,
  FETCH_SEARCHLIST_SUCCESS,
} from '../actions/SearchListAction';

const initialState = {
  loading: false,
  searchList: [],
  error: '',
};

const SearchReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SEARCHLIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_SEARCHLIST_SUCCESS:
      return {
        loading: false,
        searchList: action.payload,
        error: '',
      };
    case FETCH_SEARCHLIST_FAILURE:
      return {
        loading: false,
        searchList: [],
        error: action.payload,
      };
    default:
      return state;
  }
};
export default SearchReducer;
